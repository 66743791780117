import * as React from "react"
import { FaEnvelope } from "react-icons/fa6"
import { Button } from "@allied/react-web/Button"
import { Container } from "@allied/react-web/Container"
import { Field, FieldNotif } from "@allied/react-web/Field"
import { InputText, InputPhone, InputEmail } from "@allied/react-web/Input"
import { Link } from "@allied/react-web/Router"
import { Textarea } from "@allied/react-web/Textarea"
import { Meta } from "@allied/react-web/Meta"
import { Select } from "@allied/react-web/Select"
import { useValidator, Validator, useAutoPosition, useRecaptcha } from "@allied/react-web/hooks"
import { toast } from "../components/Toast"
import { Service } from "../services/Service"
import ReactGA from "react-ga4"
import enquiryBackground from "./../assets/image/heroes/enquiry.png"

const { contactService } = Service.createService()

export default function Enquiry(): React.JSX.Element {
  const [enquiryForm, setEnquiryForm] = React.useState({
    loading: false,
    formType: "EnquiryForm",
    name: "",
    email: "",
    phone: "",
    message: "",
    recipient: "general",
  })
  const [enquiryNotif, setEnquiryNotif] = React.useState({
    name: "",
    email: "",
    phone: "",
    message: "",
    recipient: "",
  })
  const [enquiryValidator] = useValidator({
    name: Validator.string().required().min(3).max(256),
    email: Validator.string().required().email().min(3).max(256),
    phone: Validator.string().required().phone("SG").min(5).max(17).trim(),
    message: Validator.string().required().min(3).max(2048),
    recipient: Validator.string().required(),
  })

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value } = e.target
    setEnquiryForm({ ...enquiryForm, [name]: value })
  }

  async function submitEnquiry(form: typeof enquiryForm & { verificationToken: string }) {
    toast.info(
      "Submiting your enquiry, please wait a moment",
      { className: "toast-mesasge" }
    )
    setEnquiryForm((prevState) => {
      return {
        ...prevState,
        loading: true,
      }
    })
    const sendEnquiry = await contactService.SendEnquiry(form)
    setEnquiryForm((prevState) => {
      return {
        ...prevState,
        loading: false,
      }
    })
    if (sendEnquiry.error) {
      console.error("An error occurred while submitting the form", sendEnquiry.error)
      ReactGA.event({
        category: "Contact",
        action: "Email",
        label: "Failed Email",
      })
      toast.error(
        `Failed send enquiry: ${sendEnquiry.error.message}`,
        { className: "toast-mesasge" }
      )
      return
    }

    setEnquiryForm((prevState) => {
      return {
        ...prevState,
        name: "",
        email: "",
        phone: "",
        message: "",
        recipient: "general",
      }
    })
    ReactGA.event({
      category: "Contact",
      action: "Email",
      label: "Successful Email",
    })
    toast.success(
      "Enquiry Submitted Successfully. Our team will get back to you shortly",
      { className: "toast-mesasge" }
    )
  }

  const handleEnquirySubmit = (e: React.FormEvent<HTMLFormElement>) => {
    try {
      e.preventDefault()

      setEnquiryNotif({
        name: "",
        email: "",
        phone: "",
        message: "",
        recipient: "",
      })

      const validateRes = enquiryValidator.validate({
        name: enquiryForm.name,
        email: enquiryForm.email,
        phone: enquiryForm.phone,
        message: enquiryForm.message,
        recipient: enquiryForm.recipient,
      })
      if (validateRes.error) {
        const err = validateRes.error

        if (err instanceof Validator.ValidationError) {
          setEnquiryNotif((prevState) => {
            return {
              ...prevState,
              [err.path]: err.errors.join("\n")
            }
          })
        } else {
          console.warn("unexpected error: ", err)
        }

        return
      }

      // @ts-ignore
      if (!grecaptcha) {
        toast.error(
          "Failed initialized recaptcha",
          { className: "toast-mesasge" }
        )
        return
      }

      // @ts-ignore
      grecaptcha.ready(async () => {
        // @ts-ignore
        const token = await grecaptcha.execute(process.env.REACT_APP_RECAPTCHAV3_SITE, { action: "submit" })
        if (!token) {
          toast.error(
            "Failed verify reCAPTCHA, please try again.",
            { className: "toast-mesasge" }
          )
          return
        }

        submitEnquiry({
          ...enquiryForm,
          verificationToken: token
        })
      })
    } catch (error: any) {
      console.error("Failed submit enquiry", error)
      toast.error(
        "Failed submit enquiry",
        { className: "toast-mesasge" }
      )
    }
  }

  useAutoPosition()
  useRecaptcha({
    siteId: process.env.REACT_APP_RECAPTCHAV3_SITE
  })

  return (
    <>
      <Meta>
        {{
          title: "General Enquiry",
          description: "Contact us if you have any question or requests"
        }}
      </Meta>
      <div className="relative">
        <img src={enquiryBackground} alt="Enquiry"
          className="w-full" width={16} height={9} />
        <div className="absolute text-center text-base md:!text-2xl lg:!text-3xl xl:!text-4xl text-white font-bold w-4/5"
          style={{ top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
          AG Logis Solutions Pte. Ltd. is established in 2017, as a Traders and Suppliers of Lubricants.
          Besides lubricants, the company also offers other products related to logistics.
        </div>
      </div>

      <Container size="md">
        <div className="flex flex-col gap-4 justify-center items-center my-8">
          <hr className="w-3/5 border-aglogis-red" />
          <h2 className="text-center text-3xl font-bold">Contact Us</h2>
          <p className="text-center text-lg">
            Any question or remarks? <br />
            Please complete this form and our respective team will get back to you soon
          </p>
        </div>

        <div className="p-4 my-4 rounded-2xl bg-aglogis-primary">
          <div className="grid grid-cols-12">
            <div className="col-span-12 md:!col-span-4 lg:!col-span-3 text-white">
              <div className="px-2">
                <h2 className="text-3xl font-bold mb-6">
                  Our Team
                </h2>

                <div className="flex flex-col gap-4 my-4">
                  <div className="flex flex-col gap-4">
                    <p className="text-xl font-bold">General Enquiry</p>
                    <div className="flex flex-row justify-start items-center gap-2">
                      <FaEnvelope className="w-6 h-6" aria-hidden="true" />
                      <Link to={`mailto:enquiry@aglogis.com`} target={`_blank`} className="text-lg">
                        enquiry@aglogis.com
                      </Link>
                    </div>
                  </div>
                  <div className="flex flex-col gap-4">
                    <p className="text-xl font-bold">Product Sales</p>
                    <div className="flex flex-row justify-start items-center gap-2">
                      <FaEnvelope className="w-6 h-6" aria-hidden="true" />
                      <Link to={`mailto:sales@aglogis.com`} target={`_blank`} className="text-lg">
                        sales@aglogis.com
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-span-12 md:!col-span-8 lg:!col-span-9">
              <div className="h-full p-6 rounded-2xl bg-white">
                <form onSubmit={handleEnquirySubmit}>
                  <div className="flex flex-col gap-3">
                    <Field>
                      <label htmlFor="recipient">
                        Recipient <span className="text-red-600">*</span>
                      </label>
                      <Select
                        id="recipient"
                        name="recipient"
                        placeholder="Recipient"
                        value={enquiryForm.recipient}
                        disabled={enquiryForm.loading}
                        onChange={handleInputChange}
                        required>
                        <Select.Item value="general">
                          General Enquiry
                        </Select.Item>
                        <Select.Item value="sales">
                          Product Sales
                        </Select.Item>
                      </Select>
                      <FieldNotif>{enquiryNotif.recipient}</FieldNotif>
                    </Field>
                    <Field>
                      <label htmlFor="name">
                        Name <span className="text-red-600">*</span>
                      </label>
                      <InputText
                        id="name"
                        name="name"
                        placeholder="Name"
                        value={enquiryForm.name}
                        onChange={handleInputChange}
                        disabled={enquiryForm.loading}
                        required />
                      <FieldNotif>{enquiryNotif.name}</FieldNotif>
                    </Field>
                    <Field>
                      <label htmlFor="email">
                        Email <span className="text-red-600">*</span>
                      </label>
                      <InputEmail
                        id="email"
                        name="email"
                        placeholder="Email"
                        value={enquiryForm.email}
                        onChange={handleInputChange}
                        disabled={enquiryForm.loading}
                        required />
                      <FieldNotif>{enquiryNotif.email}</FieldNotif>
                    </Field>
                    <Field>
                      <label htmlFor="phone">
                        Telephone / Mobile Number <span className="text-red-600">*</span>
                      </label>
                      <InputPhone
                        id="phone"
                        name="phone"
                        placeholder="Telephone / Mobile Number"
                        value={enquiryForm.phone}
                        onUpdate={(m) => {
                          setEnquiryForm((prevState) => {
                            return {
                              ...prevState,
                              phone: m.phone_number
                            }
                          })
                        }}
                        disabled={enquiryForm.loading}
                        required />
                      <FieldNotif>{enquiryNotif.phone}</FieldNotif>
                    </Field>
                    <Field>
                      <label htmlFor="message">
                        Message <span className="text-red-600">*</span>
                      </label>
                      <Textarea
                        id="message"
                        name="message"
                        placeholder="Message"
                        value={enquiryForm.message}
                        onChange={handleInputChange}
                        disabled={enquiryForm.loading}
                        required>
                      </Textarea>
                      <FieldNotif>{enquiryNotif.message}</FieldNotif>
                    </Field>
                    <Field>
                      <input type="hidden" name="formType" value={enquiryForm.formType} />
                      <div className="g-recaptcha"
                        data-sitekey={process.env.REACT_APP_RECAPTCHAV3_SITE}
                        data-size="invisible"
                        data-action="submit">
                      </div>
                    </Field>
                    <div className="flex justify-end items-center">
                      <Button type="submit" size="lg"
                        disabled={enquiryForm.loading}>
                        Submit
                      </Button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  )
}
