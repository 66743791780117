import * as React from "react"
import ReactGA from "react-ga4"
import { FaXmark, FaMinus, FaPlus } from "react-icons/fa6"
import { Button, ButtonLink } from "@allied/react-web/Button"
import { Container } from "@allied/react-web/Container"
import { Field, FieldNotif } from "@allied/react-web/Field"
import { InputText, InputPhone, InputEmail } from "@allied/react-web/Input"
import { Link } from "@allied/react-web/Router"
import { Textarea } from "@allied/react-web/Textarea"
import { Meta } from "@allied/react-web/Meta"
import { useValidator, Validator, useAutoPosition, useRecaptcha } from "@allied/react-web/hooks"
import { CartContext } from "../features/Cart"
import { ButtonBack } from "../components/Navigation"
import { toast } from "../components/Toast"
import { Service } from "../services/Service"

type CartItem = {
  product: ProductItem
  quantity: number
}

type ProductItem = {
  slug: string
  name: string
  thumbnail: any
  brief?: string
  description?: string
  principals: string[]
  brands: string[]
  types: string[]
}

const { contactService } = Service.createService()

export default function Cart(): React.JSX.Element {
  const {
    items: cartItems,
    removeItem,
    updateItem,
    increaseItem,
    decreaseItem,
    clearItem
  } = React.useContext(CartContext)

  const [enquiryForm, setEnquiryForm] = React.useState({
    loading: false,
    name: "",
    email: "",
    phone: "",
    message: "",
    organisation: "",
    formType: "CartForm",
    cartItems: []
  })
  const [enquiryNotif, setEnquiryNotif] = React.useState({
    name: "",
    email: "",
    phone: "",
    message: "",
    organisation: "",
    cartItems: ""
  })
  const [enquiryValidator] = useValidator({
    name: Validator.string().required().min(3).max(256),
    email: Validator.string().required().email().min(3).max(256),
    phone: Validator.string().required().phone("SG").min(5).max(17).trim(),
    message: Validator.string(),
    organisation: Validator.string().required().min(3).max(256),
  })

  const removeCartItem = (cartItem: CartItem) => {
    if (!window.confirm(`Remove item ${cartItem.product.name} from the cart?`)) {
      return
    }

    removeItem(cartItem.product.slug)
  }

  const decreaseCartItem = (cartItem: CartItem) => {
    if (cartItem.quantity === 1 &&
      !window.confirm(`Decreasing this will delete the item from your cart. Are you sure you want to proceed?`)) {
      return
    }

    decreaseItem(cartItem.product.slug)
  }

  const increaseCartItem = (cartItem: CartItem) => {
    increaseItem(cartItem.product.slug)
  }

  const updateCartItem = (cartItem: CartItem, quantity: number) => {
    if (quantity <= 0) {
      return
    }
    updateItem(cartItem.product.slug, quantity)
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target
    setEnquiryForm({ ...enquiryForm, [name]: value })
  }

  async function submitEnquiry(form: typeof enquiryForm & { verificationToken: string }) {
    toast.info(
      "Submiting your enquiry, please wait a moment",
      { className: "toast-mesasge" }
    )
    setEnquiryForm((prevState) => {
      return {
        ...prevState,
        loading: true,
      }
    })
    const sendEnquiry = await contactService.SendEnquiry(form)
    setEnquiryForm((prevState) => {
      return {
        ...prevState,
        loading: false,
      }
    })
    if (sendEnquiry.error) {
      console.error("An error occurred while submitting the form", sendEnquiry.error)
      ReactGA.event({
        category: "Contact",
        action: "Email",
        label: "Failed Email",
      })
      toast.error(
        `Failed send enquiry: ${sendEnquiry.error.message}`,
        { className: "toast-mesasge" }
      )
      return
    }

    clearItem()
    setEnquiryForm((prevState) => {
      return {
        ...prevState,
        name: "",
        email: "",
        phone: "",
        message: "",
        organisation: "",
        items: []
      }
    })
    ReactGA.event({
      category: "Contact",
      action: "Email",
      label: "Successful Email",
    })
    toast.success(
      "Enquiry Submitted Successfully. Our team will get back to you shortly",
      { className: "toast-mesasge" }
    )
  }

  const handleEnquirySubmit = (e: React.FormEvent<HTMLFormElement>) => {
    try {
      e.preventDefault()

      setEnquiryNotif({
        name: "",
        email: "",
        phone: "",
        message: "",
        organisation: "",
        cartItems: "",
      })

      const validateRes = enquiryValidator.validate({
        name: enquiryForm.name,
        email: enquiryForm.email,
        phone: enquiryForm.phone,
        message: enquiryForm.message,
        organisation: enquiryForm.organisation
      })
      if (validateRes.error) {
        const err = validateRes.error

        if (err instanceof Validator.ValidationError) {
          setEnquiryNotif((prevState) => {
            return {
              ...prevState,
              [err.path]: err.errors.join("\n")
            }
          })
        } else {
          console.warn("unexpected error: ", err)
        }

        return
      }

      if (cartItems.length === 0) {
        toast.error(
          "There is no items in the cart.",
          { className: "toast-mesasge" }
        )
        return
      }

      // @ts-ignore
      if (!grecaptcha) {
        toast.error(
          "Failed initialized recaptcha",
          { className: "toast-mesasge" }
        )
        return
      }

      // @ts-ignore
      grecaptcha.ready(async () => {
        // @ts-ignore
        const token = await grecaptcha.execute(process.env.REACT_APP_RECAPTCHAV3_SITE, { action: "submit" })
        if (!token) {
          toast.error(
            "Failed verify reCAPTCHA, please try again.",
            { className: "toast-mesasge" }
          )
          return
        }

        submitEnquiry({
          ...enquiryForm,
          cartItems: cartItems.map((cartItem) => {
            return {
              slug: cartItem.product.slug,
              sku: cartItem.product.sku,
              name: cartItem.product.name,
              quantity: cartItem.quantity
            }
          }),
          verificationToken: token
        })
      })
    } catch (error: any) {
      console.error("Failed submit enquiry", error)
      toast.error(
        "Failed submit enquiry",
        { className: "toast-mesasge" }
      )
    }
  }

  useAutoPosition()
  useRecaptcha({
    siteId: process.env.REACT_APP_RECAPTCHAV3_SITE
  })

  return (
    <>
      <Meta>
        {{
          title: "My Cart",
          description: "Review and manage your items in the shopping cart. Easily update quantities, remove products, and proceed to checkout. Get ready to complete your purchase with the products you've added to your cart."
        }}
      </Meta>
      <Container size="md">
        <div className="my-6 flex flex-wrap flex-row gap-4">
          <ButtonBack>
            Back
          </ButtonBack>
        </div>

        <div className="my-4 p-6 border-2 border-gray-600 rounded-2xl">
          <h1 className="my-4 font-bold text-4xl">
            My <span className="text-aglogis-primary">Cart</span>
          </h1>

          <div className="my-6">
            {
              cartItems.length === 0 ?
                <div className="flex flex-col gap-4">
                  <div>
                    <h1 className="text-2xl font-semibold">No items on the cart</h1>
                    <p className="text-xl">Add products to the cart</p>
                  </div>
                  <div>
                    <ButtonLink to="/products">Explore Product</ButtonLink>
                  </div>
                </div>
                :
                <div className="grid grid-cols-12 gap-4">
                  {
                    cartItems.map((cartItem, i: number) => {
                      return (
                        <div key={`cart-item-${i}`} className="col-span-12 xl:!col-span-8 border-2 border-gray-600 rounded-2xl">
                          <div className="relative">
                            <button className="absolute top-2.5 right-2.5"
                              onClick={() => removeCartItem(cartItem)}>
                              <FaXmark className="w-6 h-6 font-bold" aria-hidden="true" />
                            </button>
                          </div>

                          <div className="p-3 grid grid-cols-12 gap-4">
                            <div className="col-span-12 lg:!col-span-5 flex justify-center items-center">
                              <img className="w-80 h-80"
                                src={cartItem.product.thumbnail}
                                alt={cartItem.product.name} />
                            </div>
                            <div className="col-span-12 lg:!col-span-7 flex flex-col justify-center items-start gap-4">
                              <Link to={`/products/${cartItem.product.slug}`} className="text-xl md:!text-2xl lg:!text-3xl font-bold">
                                {cartItem.product.name}
                              </Link>
                              <div className="flex flex-row gap-2">
                                <Button onClick={() => decreaseCartItem(cartItem)}>
                                  <FaMinus className="w-4 h-4" aria-hidden="true" />
                                </Button>
                                <input
                                  className="w-32 flex justify-center items-center text-center p-2 rounded-md border border-gray-400"
                                  value={cartItem.quantity}
                                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    const quantity = Number(e.target.value)
                                    updateCartItem(cartItem, quantity)
                                  }} />
                                <Button onClick={() => increaseCartItem(cartItem)}>
                                  <FaPlus className="w-4 h-4" aria-hidden="true" />
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    })
                  }
                </div>
            }
          </div>

          <div className="my-4">
            <form onSubmit={handleEnquirySubmit}>
              <div className="flex flex-col gap-4">
                <Field>
                  <InputText
                    name="name"
                    placeholder="Name"
                    value={enquiryForm.name}
                    onChange={handleInputChange}
                    disabled={enquiryForm.loading}
                    required />
                  <FieldNotif>{enquiryNotif.name}</FieldNotif>
                </Field>
                <Field>
                  <InputText
                    name="organisation"
                    placeholder="Organisation"
                    value={enquiryForm.organisation}
                    onChange={handleInputChange}
                    disabled={enquiryForm.loading}
                    required />
                  <FieldNotif>{enquiryNotif.organisation}</FieldNotif>
                </Field>
                <Field>
                  <InputEmail
                    name="email"
                    placeholder="Email"
                    value={enquiryForm.email}
                    onChange={handleInputChange}
                    disabled={enquiryForm.loading}
                    required />
                  <FieldNotif>{enquiryNotif.email}</FieldNotif>
                </Field>
                <Field>
                  <InputPhone
                    name="phone"
                    placeholder="Telephone / Mobile Number"
                    value={enquiryForm.phone}
                    onUpdate={(m) => {
                      setEnquiryForm((prevState) => {
                        return {
                          ...prevState,
                          phone: m.phone_number
                        }
                      })
                    }}
                    disabled={enquiryForm.loading}
                    required />
                  <FieldNotif>{enquiryNotif.phone}</FieldNotif>
                </Field>
                <Field>
                  <Textarea
                    name="message"
                    placeholder="Additional Remarks (Optional)"
                    value={enquiryForm.message}
                    onChange={handleInputChange}
                    disabled={enquiryForm.loading}>
                  </Textarea>
                  <FieldNotif>{enquiryNotif.message}</FieldNotif>
                </Field>
                <Field>
                  <input type="hidden" name="formType" value={enquiryForm.formType} />
                  <div className="g-recaptcha"
                    data-sitekey={process.env.REACT_APP_RECAPTCHAV3_SITE}
                    data-size="invisible"
                    data-action="submit">
                  </div>
                </Field>
                <hr className="my-4 w-full border-aglogis-red" />
                <div className="flex justify-end items-center">
                  <Button type="submit" size="lg"
                    disabled={enquiryForm.loading}>
                    Enquire
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Container>
    </>
  )
}
