import * as React from "react"
import { Card } from "@allied/react-web/Card"
import { Container } from "@allied/react-web/Container"
import { Link } from "@allied/react-web/Router"
import { Meta } from "@allied/react-web/Meta"
import { useAutoPosition } from "@allied/react-web/hooks"
import { ButtonBack } from "../components/Navigation"
import { Breadcrumb } from "../components/Breadcrumb"
import { Service } from "../services/Service"

const { listingService } = Service.createService()

type PrincipalItem = {
  name: string
  slug: string
  description: string
  logo: any
  website: string
}

export default function Principals(): React.JSX.Element {
  const [principals, setPrincipals] = React.useState<PrincipalItem[]>([])

  React.useEffect(() => {
    async function searchPrincipal() {
      const searchPrincipal = await listingService.SearchPrincipal({})
      if (searchPrincipal.error) {
        return
      }
      setPrincipals(searchPrincipal.data.items)
    }
    searchPrincipal()
  }, [])

  useAutoPosition()

  return (
    <>
      <Meta>
        {{
          title: "Principals",
          description: "Discover the guiding principles that define our company's values and approach."
        }}
      </Meta>
      <Container size="md">
        <div className="my-6 flex flex-wrap flex-row gap-4">
          <ButtonBack>
            Back
          </ButtonBack>
          <Breadcrumb appendClassNames="font-roboto-mono font-light text-lg">
            {{
              items: [
                {
                  content: <>
                    <Link to="/" className="text-aglogis-primary">
                      Home
                    </Link>
                  </>
                },
                {
                  content: <>Principals</>,
                }
              ]
            }}
          </Breadcrumb>
        </div>

        <div className="my-6">
          <h1 className="mb-4 font-bold text-3xl">Principals</h1>
          <div className="grid grid-cols-12 gap-6">
            {
              principals.map((principal, i: number) => {
                return (
                  <div key={`principal-item-${i}`} className="col-span-12 lg:!col-span-6 xl:!col-span-4">
                    <Card size="lg">
                      <div className="flex flex-col px-4" style={{ minHeight: "430px" }}>
                        <img src={principal.logo} alt={principal.name} />
                        <p className="my-4 font-semibold text-lg">
                          {principal.description}
                        </p>
                      </div>
                      <div className="flex flex-col justify-center items-center my-6">
                        <Link to={principal.website} target="_blank"
                          className="font-semibold text-base text-aglogis-primary" hideCrawl>
                          {principal.website}
                        </Link>
                      </div>
                    </Card>
                  </div>
                )
              })
            }
          </div>
        </div>
      </Container>
    </>
  )
}
