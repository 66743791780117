import {
  AddItemParam, AddItemResult,
  RemoveItemParam, RemoveItemResult,
  SearchItemParam, SearchItemResult
} from "../Comparation"
import {
  ListingService
} from "../Listing"
import {
  Status, ACTION_SUCCESS,
  RESOURCE_EXISTS,
  RESOURCE_NOTFOUND,
  UNEXPECTED_ERROR,
} from "../Status"

export class ComparationService {

  constructor(private listingService: ListingService) { }

  public async AddItem(p: AddItemParam): Promise<AddItemResult> {
    try {
      const verifyProduct = await this.listingService.VerifyProduct({
        slugs: [p.slug]
      })
      if (verifyProduct.error) {
        return {
          error: verifyProduct.error
        }
      }
      const valid = verifyProduct.data.items.some((item) => item.exists)
      if (!valid) {
        return {
          error: new Status("item is not available", RESOURCE_NOTFOUND)
        }
      }

      const slugs: string[] = []
      const exists = new Set<string>()
      const comparation = localStorage.getItem("comparation")
      if (comparation) {
        const { items } = JSON.parse(comparation)
        slugs.push(...items)
      }

      slugs.forEach((slug) => {
        exists.add(slug)
      })

      if (exists.has(p.slug)) {
        return {
          error: new Status("product already exists", RESOURCE_EXISTS)
        }
      }

      slugs.push(p.slug)

      localStorage.setItem("comparation", JSON.stringify({
        items: slugs
      }))

      return {
        success: new Status("success add item", ACTION_SUCCESS),
      }
    } catch (err: any) {
      return {
        error: new Status(err.message, UNEXPECTED_ERROR)
      }
    }
  }

  public async RemoveItem(p: RemoveItemParam): Promise<RemoveItemResult> {
    try {
      let slugs: string[] = []
      const comparation = localStorage.getItem("comparation")
      if (comparation) {
        const { items } = JSON.parse(comparation)
        slugs.push(...items)
      }

      slugs = slugs.filter((slug) => slug !== p.slug)

      localStorage.setItem("comparation", JSON.stringify({
        items: slugs
      }))

      return {
        success: new Status("success remove item", ACTION_SUCCESS),
      }
    } catch (err: any) {
      return {
        error: new Status(err.message, UNEXPECTED_ERROR)
      }
    }
  }

  public async SearchItem(p: SearchItemParam): Promise<SearchItemResult> {
    try {
      const slugs: string[] = []

      const comparation = localStorage.getItem("comparation")
      if (comparation) {
        const { items } = JSON.parse(comparation)
        slugs.push(...items)
      }

      if (slugs.length === 0) {
        return {
          success: new Status("success search items", ACTION_SUCCESS),
          data: {
            items: [],
            sumary: {
              page: 1,
              total_items: 0
            }
          }
        }
      }

      const searchProduct = await this.listingService.SearchProduct({
        slugs
      })
      if (searchProduct.error) {
        return {
          error: searchProduct.error
        }
      }


      return {
        success: new Status("success search items", ACTION_SUCCESS),
        data: {
          items: searchProduct.data.items,
          sumary: searchProduct.data.sumary
        }
      }
    } catch (err: any) {
      return {
        error: new Status(err.message, UNEXPECTED_ERROR)
      }
    }
  }

}