import {
  SearchProductParam, SearchProductResult,
  VerifyProductParam, VerifyProductResult,
  GetProductParam, GetProductResult,
  SearchBrandParam, SearchBrandResult,
  SearchPrincipalParam, SearchPrincipalResult,
} from "../Listing"
import {
  Status, ACTION_SUCCESS,
  RESOURCE_NOTFOUND, UNEXPECTED_ERROR
} from "../Status"

const products = [
  {
    sku: "AG0178",
    slug: "a8508-bosch-aeristo-cabin-filter",
    thumbnail: "/file/listings/A8508.png",
    name: "A8508 - BOSCH AERISTO CABIN FILTER",
    description: "Anti-allergic & anti-bacterial protection for clean air inside your car",
    information: "Bosch Premium Cabin Air Filters offer an excellent blend of original equipment fit and significantly enhanced quality, resulting in a cleaner and healthier passenger cabin air quality. With pollutants and allergens inside a vehicle potentially reaching up to six times higher concentration than outside, Bosch Premium Cabin Filters act as a reliable barrier, preventing harmful spores and pollen from entering the vehicle interior. By doing so, they not only safeguard passengers but also protect downstream HVAC components, ensuring a more pleasant and safe driving experience",
    detail: "The antibacterial cabin filter in our product effectively separates allergens and fine dust particles, ensuring they do not enter the car and providing a healthier environment for passengers. Additionally, our air conditioning car filter neutralizes noxious and foul-smelling gases, contributing to a more pleasant driving experience. The inclusion of Filter Plus helps reduce mist on the windshield, enhancing visibility during various weather conditions. Furthermore, our product safeguards the air conditioning system from dust and dirt, promoting its optimal performance and longevity. To ensure compatibility with your vehicle, please use the provided vehicle identification information, and kindly adhere to all stated restrictions for a seamless and satisfactory experience.",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Cabin Filters"],
  },
  {
    sku: "AG0245",
    slug: "a8543-bosch-aeristo-cabin-filter",
    thumbnail: "/file/listings/A8543.png",
    name: "A8543 - BOSCH AERISTO CABIN FILTER",
    description: "Anti-allergic & anti-bacterial protection for clean air inside your car",
    information: "Bosch Premium Cabin Air Filters offer an excellent blend of original equipment fit and significantly enhanced quality, resulting in a cleaner and healthier passenger cabin air quality. With pollutants and allergens inside a vehicle potentially reaching up to six times higher concentration than outside, Bosch Premium Cabin Filters act as a reliable barrier, preventing harmful spores and pollen from entering the vehicle interior. By doing so, they not only safeguard passengers but also protect downstream HVAC components, ensuring a more pleasant and safe driving experience",
    detail: "Anti-Allergy Layer that effectively renders allergens harmless and eliminates bacteria, ensuring a healthier environment for all passengers. The inclusion of Activated Carbon enhances the filter's capability to absorb harmful gases and unpleasant smells. Ultra-fine microfiber filters fine dust particles (up to 2.5µm), soot and pollen. The filter's Carrier Fleece stabilizes the layers, ensuring optimum performance and durability. Additionally, the reduction of deposits protects the air conditioning system, ultimately leading to fresher air and a more comfortable journey for everyone on board",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Cabin Filters"],
  },
  {
    sku: "AG0246",
    slug: "a8545-bosch-aeristo-cabin-filter",
    thumbnail: "/file/listings/A8545.png",
    name: "A8545 - BOSCH AERISTO CABIN FILTER",
    description: "Anti-allergic & anti-bacterial protection for clean air inside your car",
    information: "Bosch Premium Cabin Air Filters offer an excellent blend of original equipment fit and significantly enhanced quality, resulting in a cleaner and healthier passenger cabin air quality. With pollutants and allergens inside a vehicle potentially reaching up to six times higher concentration than outside, Bosch Premium Cabin Filters act as a reliable barrier, preventing harmful spores and pollen from entering the vehicle interior. By doing so, they not only safeguard passengers but also protect downstream HVAC components, ensuring a more pleasant and safe driving experience",
    detail: "Anti-Allergy Layer that effectively renders allergens harmless and eliminates bacteria, ensuring a healthier environment for all passengers. The inclusion of Activated Carbon enhances the filter's capability to absorb harmful gases and unpleasant smells. Ultra-fine microfiber filters fine dust particles (up to 2.5µm), soot and pollen. The filter's Carrier Fleece stabilizes the layers, ensuring optimum performance and durability. Additionally, the reduction of deposits protects the air conditioning system, ultimately leading to fresher air and a more comfortable journey for everyone on board",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Cabin Filters"],
  },
  {
    sku: "AG0243",
    slug: "a8549-bosch-aeristo-cabin-filter",
    thumbnail: "/file/listings/A8549.png",
    name: "A8549 - BOSCH AERISTO CABIN FILTER",
    description: "Anti-allergic & anti-bacterial protection for clean air inside your car",
    information: "Bosch Premium Cabin Air Filters offer an excellent blend of original equipment fit and significantly enhanced quality, resulting in a cleaner and healthier passenger cabin air quality. With pollutants and allergens inside a vehicle potentially reaching up to six times higher concentration than outside, Bosch Premium Cabin Filters act as a reliable barrier, preventing harmful spores and pollen from entering the vehicle interior. By doing so, they not only safeguard passengers but also protect downstream HVAC components, ensuring a more pleasant and safe driving experience",
    detail: "Anti-Allergy Layer that effectively renders allergens harmless and eliminates bacteria, ensuring a healthier environment for all passengers. The inclusion of Activated Carbon enhances the filter's capability to absorb harmful gases and unpleasant smells. Ultra-fine microfiber filters fine dust particles (up to 2.5µm), soot and pollen. The filter's Carrier Fleece stabilizes the layers, ensuring optimum performance and durability. Additionally, the reduction of deposits protects the air conditioning system, ultimately leading to fresher air and a more comfortable journey for everyone on board",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Cabin Filters"],
  },
  {
    sku: "AG0148",
    slug: "ap-f06-bosch-aeristo-cabin-filter",
    thumbnail: "/file/listings/bosch-placeholder.png",
    name: "AP-F06 BOSCH AERISTO CABIN FILTER",
    description: "Anti-allergic & anti-bacterial protection for clean air inside your car",
    information: "Bosch Premium Cabin Air Filters offer an excellent blend of original equipment fit and significantly enhanced quality, resulting in a cleaner and healthier passenger cabin air quality. With pollutants and allergens inside a vehicle potentially reaching up to six times higher concentration than outside, Bosch Premium Cabin Filters act as a reliable barrier, preventing harmful spores and pollen from entering the vehicle interior. By doing so, they not only safeguard passengers but also protect downstream HVAC components, ensuring a more pleasant and safe driving experience",
    detail: "Anti-Allergy Layer that effectively renders allergens harmless and eliminates bacteria, ensuring a healthier environment for all passengers. The inclusion of Activated Carbon enhances the filter's capability to absorb harmful gases and unpleasant smells. Ultra-fine microfiber filters fine dust particles (up to 2.5µm), soot and pollen. The filter's Carrier Fleece stabilizes the layers, ensuring optimum performance and durability. Additionally, the reduction of deposits protects the air conditioning system, ultimately leading to fresher air and a more comfortable journey for everyone on board",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Cabin Filters"],
  },
  {
    sku: "AG0154",
    slug: "ap-h04-bosch-aeristo-cabin-filter",
    thumbnail: "/file/listings/bosch-placeholder.png",
    name: "AP-H04 - BOSCH AERISTO CABIN FILTER",
    description: "Anti-allergic & anti-bacterial protection for clean air inside your car",
    information: "Bosch Premium Cabin Air Filters offer an excellent blend of original equipment fit and significantly enhanced quality, resulting in a cleaner and healthier passenger cabin air quality. With pollutants and allergens inside a vehicle potentially reaching up to six times higher concentration than outside, Bosch Premium Cabin Filters act as a reliable barrier, preventing harmful spores and pollen from entering the vehicle interior. By doing so, they not only safeguard passengers but also protect downstream HVAC components, ensuring a more pleasant and safe driving experience",
    detail: "Anti-Allergy Layer that effectively renders allergens harmless and eliminates bacteria, ensuring a healthier environment for all passengers. The inclusion of Activated Carbon enhances the filter's capability to absorb harmful gases and unpleasant smells. Ultra-fine microfiber filters fine dust particles (up to 2.5µm), soot and pollen. The filter's Carrier Fleece stabilizes the layers, ensuring optimum performance and durability. Additionally, the reduction of deposits protects the air conditioning system, ultimately leading to fresher air and a more comfortable journey for everyone on board",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Cabin Filters"],
  },
  {
    sku: "AG0149",
    slug: "ap-h06-bosch-aeristo-cabin-filter",
    thumbnail: "/file/listings/bosch-placeholder.png",
    name: "AP-H06 -BOSCH AERISTO CABIN FILTER",
    description: "Anti-allergic & anti-bacterial protection for clean air inside your car",
    information: "Bosch Premium Cabin Air Filters offer an excellent blend of original equipment fit and significantly enhanced quality, resulting in a cleaner and healthier passenger cabin air quality. With pollutants and allergens inside a vehicle potentially reaching up to six times higher concentration than outside, Bosch Premium Cabin Filters act as a reliable barrier, preventing harmful spores and pollen from entering the vehicle interior. By doing so, they not only safeguard passengers but also protect downstream HVAC components, ensuring a more pleasant and safe driving experience",
    detail: "Anti-Allergy Layer that effectively renders allergens harmless and eliminates bacteria, ensuring a healthier environment for all passengers. The inclusion of Activated Carbon enhances the filter's capability to absorb harmful gases and unpleasant smells. Ultra-fine microfiber filters fine dust particles (up to 2.5µm), soot and pollen. The filter's Carrier Fleece stabilizes the layers, ensuring optimum performance and durability. Additionally, the reduction of deposits protects the air conditioning system, ultimately leading to fresher air and a more comfortable journey for everyone on board",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Cabin Filters"],
  },
  {
    sku: "AG0150",
    slug: "ap-h07-bosch-aeristo-cabin-filter",
    thumbnail: "/file/listings/bosch-placeholder.png",
    name: "AP-H07 - BOSCH AERISTO CABIN FILTER",
    description: "Anti-allergic & anti-bacterial protection for clean air inside your car",
    information: "Bosch Premium Cabin Air Filters offer an excellent blend of original equipment fit and significantly enhanced quality, resulting in a cleaner and healthier passenger cabin air quality. With pollutants and allergens inside a vehicle potentially reaching up to six times higher concentration than outside, Bosch Premium Cabin Filters act as a reliable barrier, preventing harmful spores and pollen from entering the vehicle interior. By doing so, they not only safeguard passengers but also protect downstream HVAC components, ensuring a more pleasant and safe driving experience",
    detail: "Anti-Allergy Layer that effectively renders allergens harmless and eliminates bacteria, ensuring a healthier environment for all passengers. The inclusion of Activated Carbon enhances the filter's capability to absorb harmful gases and unpleasant smells. Ultra-fine microfiber filters fine dust particles (up to 2.5µm), soot and pollen. The filter's Carrier Fleece stabilizes the layers, ensuring optimum performance and durability. Additionally, the reduction of deposits protects the air conditioning system, ultimately leading to fresher air and a more comfortable journey for everyone on board",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Cabin Filters"],
  },
  {
    sku: "AG0151",
    slug: "ap-h09-bosch-aeristo-cabin-filter",
    thumbnail: "/file/listings/bosch-placeholder.png",
    name: "AP-H09 - BOSCH AERISTO CABIN FILTER",
    description: "Anti-allergic & anti-bacterial protection for clean air inside your car",
    information: "Bosch Premium Cabin Air Filters offer an excellent blend of original equipment fit and significantly enhanced quality, resulting in a cleaner and healthier passenger cabin air quality. With pollutants and allergens inside a vehicle potentially reaching up to six times higher concentration than outside, Bosch Premium Cabin Filters act as a reliable barrier, preventing harmful spores and pollen from entering the vehicle interior. By doing so, they not only safeguard passengers but also protect downstream HVAC components, ensuring a more pleasant and safe driving experience",
    detail: "Anti-Allergy Layer that effectively renders allergens harmless and eliminates bacteria, ensuring a healthier environment for all passengers. The inclusion of Activated Carbon enhances the filter's capability to absorb harmful gases and unpleasant smells. Ultra-fine microfiber filters fine dust particles (up to 2.5µm), soot and pollen. The filter's Carrier Fleece stabilizes the layers, ensuring optimum performance and durability. Additionally, the reduction of deposits protects the air conditioning system, ultimately leading to fresher air and a more comfortable journey for everyone on board",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Cabin Filters"],
  },
  {
    sku: "AG0155",
    slug: "ap-m01-bosch-aeristo-cabin-filter",
    thumbnail: "/file/listings/bosch-placeholder.png",
    name: "AP-M01 - BOSCH AERISTO CABIN FILTER",
    description: "Anti-allergic & anti-bacterial protection for clean air inside your car",
    information: "Bosch Premium Cabin Air Filters offer an excellent blend of original equipment fit and significantly enhanced quality, resulting in a cleaner and healthier passenger cabin air quality. With pollutants and allergens inside a vehicle potentially reaching up to six times higher concentration than outside, Bosch Premium Cabin Filters act as a reliable barrier, preventing harmful spores and pollen from entering the vehicle interior. By doing so, they not only safeguard passengers but also protect downstream HVAC components, ensuring a more pleasant and safe driving experience",
    detail: "Anti-Allergy Layer that effectively renders allergens harmless and eliminates bacteria, ensuring a healthier environment for all passengers. The inclusion of Activated Carbon enhances the filter's capability to absorb harmful gases and unpleasant smells. Ultra-fine microfiber filters fine dust particles (up to 2.5µm), soot and pollen. The filter's Carrier Fleece stabilizes the layers, ensuring optimum performance and durability. Additionally, the reduction of deposits protects the air conditioning system, ultimately leading to fresher air and a more comfortable journey for everyone on board",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Cabin Filters"],
  },
  {
    sku: "AG0156",
    slug: "ap-n07-t-bosch-aeristo-cabin-filter",
    thumbnail: "/file/listings/bosch-placeholder.png",
    name: "AP-N07-T - BOSCH AERISTO CABIN FILTER",
    description: "Anti-allergic & anti-bacterial protection for clean air inside your car",
    information: "Bosch Premium Cabin Air Filters offer an excellent blend of original equipment fit and significantly enhanced quality, resulting in a cleaner and healthier passenger cabin air quality. With pollutants and allergens inside a vehicle potentially reaching up to six times higher concentration than outside, Bosch Premium Cabin Filters act as a reliable barrier, preventing harmful spores and pollen from entering the vehicle interior. By doing so, they not only safeguard passengers but also protect downstream HVAC components, ensuring a more pleasant and safe driving experience",
    detail: "Anti-Allergy Layer that effectively renders allergens harmless and eliminates bacteria, ensuring a healthier environment for all passengers. The inclusion of Activated Carbon enhances the filter's capability to absorb harmful gases and unpleasant smells. Ultra-fine microfiber filters fine dust particles (up to 2.5µm), soot and pollen. The filter's Carrier Fleece stabilizes the layers, ensuring optimum performance and durability. Additionally, the reduction of deposits protects the air conditioning system, ultimately leading to fresher air and a more comfortable journey for everyone on board",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Cabin Filters"],
  },
  {
    sku: "AG0157",
    slug: "ap-n09-bosch-aeristo-cabin-filter",
    thumbnail: "/file/listings/bosch-placeholder.png",
    name: "AP-N09 - BOSCH AERISTO CABIN FILTER",
    description: "Anti-allergic & anti-bacterial protection for clean air inside your car",
    information: "Bosch Premium Cabin Air Filters offer an excellent blend of original equipment fit and significantly enhanced quality, resulting in a cleaner and healthier passenger cabin air quality. With pollutants and allergens inside a vehicle potentially reaching up to six times higher concentration than outside, Bosch Premium Cabin Filters act as a reliable barrier, preventing harmful spores and pollen from entering the vehicle interior. By doing so, they not only safeguard passengers but also protect downstream HVAC components, ensuring a more pleasant and safe driving experience",
    detail: "Anti-Allergy Layer that effectively renders allergens harmless and eliminates bacteria, ensuring a healthier environment for all passengers. The inclusion of Activated Carbon enhances the filter's capability to absorb harmful gases and unpleasant smells. Ultra-fine microfiber filters fine dust particles (up to 2.5µm), soot and pollen. The filter's Carrier Fleece stabilizes the layers, ensuring optimum performance and durability. Additionally, the reduction of deposits protects the air conditioning system, ultimately leading to fresher air and a more comfortable journey for everyone on board",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Cabin Filters"],
  },
  {
    sku: "AG0159",
    slug: "ap-n13-bosch-aeristo-cabin-filter",
    thumbnail: "/file/listings/bosch-placeholder.png",
    name: "AP-N13 - BOSCH AERISTO CABIN FILTER",
    description: "Anti-allergic & anti-bacterial protection for clean air inside your car",
    information: "Bosch Premium Cabin Air Filters offer an excellent blend of original equipment fit and significantly enhanced quality, resulting in a cleaner and healthier passenger cabin air quality. With pollutants and allergens inside a vehicle potentially reaching up to six times higher concentration than outside, Bosch Premium Cabin Filters act as a reliable barrier, preventing harmful spores and pollen from entering the vehicle interior. By doing so, they not only safeguard passengers but also protect downstream HVAC components, ensuring a more pleasant and safe driving experience",
    detail: "Anti-Allergy Layer that effectively renders allergens harmless and eliminates bacteria, ensuring a healthier environment for all passengers. The inclusion of Activated Carbon enhances the filter's capability to absorb harmful gases and unpleasant smells. Ultra-fine microfiber filters fine dust particles (up to 2.5µm), soot and pollen. The filter's Carrier Fleece stabilizes the layers, ensuring optimum performance and durability. Additionally, the reduction of deposits protects the air conditioning system, ultimately leading to fresher air and a more comfortable journey for everyone on board",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Cabin Filters"],
  },
  {
    sku: "AG0160",
    slug: "ap-s03-bosch-aeristo-cabin-filter",
    thumbnail: "/file/listings/bosch-placeholder.png",
    name: "AP-S03 - BOSCH AERISTO CABIN FILTER",
    description: "Anti-allergic & anti-bacterial protection for clean air inside your car",
    information: "Bosch Premium Cabin Air Filters offer an excellent blend of original equipment fit and significantly enhanced quality, resulting in a cleaner and healthier passenger cabin air quality. With pollutants and allergens inside a vehicle potentially reaching up to six times higher concentration than outside, Bosch Premium Cabin Filters act as a reliable barrier, preventing harmful spores and pollen from entering the vehicle interior. By doing so, they not only safeguard passengers but also protect downstream HVAC components, ensuring a more pleasant and safe driving experience",
    detail: "Anti-Allergy Layer that effectively renders allergens harmless and eliminates bacteria, ensuring a healthier environment for all passengers. The inclusion of Activated Carbon enhances the filter's capability to absorb harmful gases and unpleasant smells. Ultra-fine microfiber filters fine dust particles (up to 2.5µm), soot and pollen. The filter's Carrier Fleece stabilizes the layers, ensuring optimum performance and durability. Additionally, the reduction of deposits protects the air conditioning system, ultimately leading to fresher air and a more comfortable journey for everyone on board",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Cabin Filters"],
  },
  {
    sku: "AG0152",
    slug: "ap-t04-bosch-aeristo-cabin-filter",
    thumbnail: "/file/listings/bosch-placeholder.png",
    name: "AP-T04 - BOSCH AERISTO CABIN FILTER",
    description: "Anti-allergic & anti-bacterial protection for clean air inside your car",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Cabin Filters"],
    information: "Bosch Premium Cabin Air Filters offer an excellent blend of original equipment fit and significantly enhanced quality, resulting in a cleaner and healthier passenger cabin air quality. With pollutants and allergens inside a vehicle potentially reaching up to six times higher concentration than outside, Bosch Premium Cabin Filters act as a reliable barrier, preventing harmful spores and pollen from entering the vehicle interior. By doing so, they not only safeguard passengers but also protect downstream HVAC components, ensuring a more pleasant and safe driving experience",
    uom: "Each",
    detail:
      "Anti-Allergy Layer that effectively renders allergens harmless and eliminates bacteria, ensuring a healthier environment for all passengers. The inclusion of Activated Carbon enhances the filter's capability to absorb harmful gases and unpleasant smells. Ultra-fine microfiber filters fine dust particles (up to 2.5µm), soot and pollen. The filter's Carrier Fleece stabilizes the layers, ensuring optimum performance and durability. Additionally, the reduction of deposits protects the air conditioning system, ultimately leading to fresher air and a more comfortable journey for everyone on board",
  },
  {
    sku: "AG0153",
    slug: "ap-t07-bosch-aeristo-cabin-filter",
    thumbnail: "/file/listings/bosch-placeholder.png",
    name: "AP-T07 - BOSCH AERISTO CABIN FILTER",
    description: "Anti-allergic & anti-bacterial protection for clean air inside your car",
    information: "Bosch Premium Cabin Air Filters offer an excellent blend of original equipment fit and significantly enhanced quality, resulting in a cleaner and healthier passenger cabin air quality. With pollutants and allergens inside a vehicle potentially reaching up to six times higher concentration than outside, Bosch Premium Cabin Filters act as a reliable barrier, preventing harmful spores and pollen from entering the vehicle interior. By doing so, they not only safeguard passengers but also protect downstream HVAC components, ensuring a more pleasant and safe driving experience",
    detail: "Anti-Allergy Layer that effectively renders allergens harmless and eliminates bacteria, ensuring a healthier environment for all passengers. The inclusion of Activated Carbon enhances the filter's capability to absorb harmful gases and unpleasant smells. Ultra-fine microfiber filters fine dust particles (up to 2.5µm), soot and pollen. The filter's Carrier Fleece stabilizes the layers, ensuring optimum performance and durability. Additionally, the reduction of deposits protects the air conditioning system, ultimately leading to fresher air and a more comfortable journey for everyone on board",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Cabin Filters"],
  },
  {
    sku: "AG0161",
    slug: "ap-t09-bosch-aeristo-cabin-filter",
    thumbnail: "/file/listings/bosch-placeholder.png",
    name: "AP-T09 - BOSCH AERISTO CABIN FILTER",
    description: "Anti-allergic & anti-bacterial protection for clean air inside your car",
    information: "Bosch Premium Cabin Air Filters offer an excellent blend of original equipment fit and significantly enhanced quality, resulting in a cleaner and healthier passenger cabin air quality. With pollutants and allergens inside a vehicle potentially reaching up to six times higher concentration than outside, Bosch Premium Cabin Filters act as a reliable barrier, preventing harmful spores and pollen from entering the vehicle interior. By doing so, they not only safeguard passengers but also protect downstream HVAC components, ensuring a more pleasant and safe driving experience",
    detail: "Anti-Allergy Layer that effectively renders allergens harmless and eliminates bacteria, ensuring a healthier environment for all passengers. The inclusion of Activated Carbon enhances the filter's capability to absorb harmful gases and unpleasant smells. Ultra-fine microfiber filters fine dust particles (up to 2.5µm), soot and pollen. The filter's Carrier Fleece stabilizes the layers, ensuring optimum performance and durability. Additionally, the reduction of deposits protects the air conditioning system, ultimately leading to fresher air and a more comfortable journey for everyone on board",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Cabin Filters"],
  },
  {
    sku: "AG0162",
    slug: "ap-t10-bosch-aeristo-cabin-filter",
    thumbnail: "/file/listings/bosch-placeholder.png",
    name: "AP-T10 - BOSCH AERISTO CABIN FILTER",
    description: "Anti-allergic & anti-bacterial protection for clean air inside your car",
    information: "Bosch Premium Cabin Air Filters offer an excellent blend of original equipment fit and significantly enhanced quality, resulting in a cleaner and healthier passenger cabin air quality. With pollutants and allergens inside a vehicle potentially reaching up to six times higher concentration than outside, Bosch Premium Cabin Filters act as a reliable barrier, preventing harmful spores and pollen from entering the vehicle interior. By doing so, they not only safeguard passengers but also protect downstream HVAC components, ensuring a more pleasant and safe driving experience",
    detail: "Anti-Allergy Layer that effectively renders allergens harmless and eliminates bacteria, ensuring a healthier environment for all passengers. The inclusion of Activated Carbon enhances the filter's capability to absorb harmful gases and unpleasant smells. Ultra-fine microfiber filters fine dust particles (up to 2.5µm), soot and pollen. The filter's Carrier Fleece stabilizes the layers, ensuring optimum performance and durability. Additionally, the reduction of deposits protects the air conditioning system, ultimately leading to fresher air and a more comfortable journey for everyone on board",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Cabin Filters"],
  },
  {
    sku: "AG0163",
    slug: "ap-z05-bosch-aeristo-cabin-filter",
    thumbnail: "/file/listings/bosch-placeholder.png",
    name: "AP-Z05 - BOSCH AERISTO CABIN FILTER",
    description: "Anti-allergic & anti-bacterial protection for clean air inside your car",
    information: "Bosch Premium Cabin Air Filters offer an excellent blend of original equipment fit and significantly enhanced quality, resulting in a cleaner and healthier passenger cabin air quality. With pollutants and allergens inside a vehicle potentially reaching up to six times higher concentration than outside, Bosch Premium Cabin Filters act as a reliable barrier, preventing harmful spores and pollen from entering the vehicle interior. By doing so, they not only safeguard passengers but also protect downstream HVAC components, ensuring a more pleasant and safe driving experience",
    detail: "Anti-Allergy Layer that effectively renders allergens harmless and eliminates bacteria, ensuring a healthier environment for all passengers. The inclusion of Activated Carbon enhances the filter's capability to absorb harmful gases and unpleasant smells. Ultra-fine microfiber filters fine dust particles (up to 2.5µm), soot and pollen. The filter's Carrier Fleece stabilizes the layers, ensuring optimum performance and durability. Additionally, the reduction of deposits protects the air conditioning system, ultimately leading to fresher air and a more comfortable journey for everyone on board",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Cabin Filters"],
  },
  {
    sku: "AG0276",
    slug: "bosch-a157s-2616",
    thumbnail: "/file/listings/bosch-placeholder.png",
    name: 'BOSCH A157S -26"/16"',
    description: "Flat wiper blades",
    information: "Bosch manufacture Aerotwin Wiper sets with innovative rubber technology that results in ultimate performance and increased service life",
    detail: "Bosch has reinvented the windscreen wiper with the Aerotwin Wiper Blade. Innovative windscreen wiping for everyone. Bracketless Bosch Aerotwin windscreen wipers can be seen on more and more new models. Bosch was the first to market with a bracketless wiper blade - Aerotwin. More and more vehicle manufacturers are choosing Aerotwin technology.",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Special Wipers"],
  },
  {
    sku: "AG0315",
    slug: "bosch-a159s-2814",
    thumbnail: "/file/listings/bosch-placeholder.png",
    name: 'BOSCH A159S -28"/14"',
    description: "Flat wiper blades",
    information: "Bosch manufacture Aerotwin Wiper sets with innovative rubber technology that results in ultimate performance and increased service life",
    detail: "Bosch has reinvented the windscreen wiper with the Aerotwin Wiper Blade. Innovative windscreen wiping for everyone. Bracketless Bosch Aerotwin windscreen wipers can be seen on more and more new models. Bosch was the first to market with a bracketless wiper blade - Aerotwin. More and more vehicle manufacturers are choosing Aerotwin technology.",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Special Wipers"],
  },
  {
    sku: "AG0345",
    slug: "bosch-a180s-2818",
    thumbnail: "/file/listings/bosch-placeholder.png",
    name: 'BOSCH A180S -28"/18"',
    description: "Flat wiper blades",
    information: "Bosch manufacture Aerotwin Wiper sets with innovative rubber technology that results in ultimate performance and increased service life",
    detail: "Bosch has reinvented the windscreen wiper with the Aerotwin Wiper Blade. Innovative windscreen wiping for everyone. Bracketless Bosch Aerotwin windscreen wipers can be seen on more and more new models. Bosch was the first to market with a bracketless wiper blade - Aerotwin. More and more vehicle manufacturers are choosing Aerotwin technology.",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Special Wipers"],
  },
  {
    sku: "AG0322",
    slug: "bosch-a205s-2419",
    thumbnail: "/file/listings/A205S.png",
    name: 'BOSCH A205S -24"/19"',
    description: "Flat wiper blades",
    information: "Bosch manufacture Aerotwin Wiper sets with innovative rubber technology that results in ultimate performance and increased service life",
    detail: "Bosch has reinvented the windscreen wiper with the Aerotwin Wiper Blade. Innovative windscreen wiping for everyone. Bracketless Bosch Aerotwin windscreen wipers can be seen on more and more new models. Bosch was the first to market with a bracketless wiper blade - Aerotwin. More and more vehicle manufacturers are choosing Aerotwin technology.",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Special Wipers"],
  },
  {
    sku: "AG0312",
    slug: "bosch-a207s-2619",
    thumbnail: "/file/listings/bosch-placeholder.png",
    name: 'BOSCH A207S -26"/19"',
    description: "Flat wiper blades",
    information: "Bosch manufacture Aerotwin Wiper sets with innovative rubber technology that results in ultimate performance and increased service life",
    detail: "Bosch has reinvented the windscreen wiper with the Aerotwin Wiper Blade. Innovative windscreen wiping for everyone. Bracketless Bosch Aerotwin windscreen wipers can be seen on more and more new models. Bosch was the first to market with a bracketless wiper blade - Aerotwin. More and more vehicle manufacturers are choosing Aerotwin technology.",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Special Wipers"],
  },
  {
    sku: "AG0324",
    slug: "bosch-a243s-2422",
    thumbnail: "/file/listings/bosch-placeholder.png",
    name: 'BOSCH A243S -24"/22"',
    description: "Flat wiper blades",
    information: "Bosch manufacture Aerotwin Wiper sets with innovative rubber technology that results in ultimate performance and increased service life",
    detail: "Bosch has reinvented the windscreen wiper with the Aerotwin Wiper Blade. Innovative windscreen wiping for everyone. Bracketless Bosch Aerotwin windscreen wipers can be seen on more and more new models. Bosch was the first to market with a bracketless wiper blade - Aerotwin. More and more vehicle manufacturers are choosing Aerotwin technology.",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Special Wipers"],
  },
  {
    sku: "AG0279",
    slug: "bosch-a310s-2619",
    thumbnail: "/file/listings/bosch-placeholder.png",
    name: 'BOSCH A310S -26"/19"',
    description: "Flat wiper blades",
    information: "Bosch manufacture Aerotwin Wiper sets with innovative rubber technology that results in ultimate performance and increased service life",
    detail: "Bosch has reinvented the windscreen wiper with the Aerotwin Wiper Blade. Innovative windscreen wiping for everyone. Bracketless Bosch Aerotwin windscreen wipers can be seen on more and more new models. Bosch was the first to market with a bracketless wiper blade - Aerotwin. More and more vehicle manufacturers are choosing Aerotwin technology.",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Special Wipers"],
  },
  {
    sku: "AG0280",
    slug: "bosch-a399s-2418",
    thumbnail: "/file/listings/bosch-placeholder.png",
    name: 'BOSCH A399S -24"/18"',
    description: "Flat wiper blades",
    information: "Bosch manufacture Aerotwin Wiper sets with innovative rubber technology that results in ultimate performance and increased service life",
    detail: "Bosch has reinvented the windscreen wiper with the Aerotwin Wiper Blade. Innovative windscreen wiping for everyone. Bracketless Bosch Aerotwin windscreen wipers can be seen on more and more new models. Bosch was the first to market with a bracketless wiper blade - Aerotwin. More and more vehicle manufacturers are choosing Aerotwin technology.",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Special Wipers"],
  },
  {
    sku: "AG0277",
    slug: "bosch-a514s-2613",
    thumbnail: "/file/listings/bosch-placeholder.png",
    name: 'BOSCH A514S -26"/13"',
    description: "Flat wiper blades",
    information: "Bosch manufacture Aerotwin Wiper sets with innovative rubber technology that results in ultimate performance and increased service life",
    detail: "Bosch has reinvented the windscreen wiper with the Aerotwin Wiper Blade. Innovative windscreen wiping for everyone. Bracketless Bosch Aerotwin windscreen wipers can be seen on more and more new models. Bosch was the first to market with a bracketless wiper blade - Aerotwin. More and more vehicle manufacturers are choosing Aerotwin technology.",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Special Wipers"],
  },
  {
    sku: "AG0278",
    slug: "bosch-a516s-3014",
    thumbnail: "/file/listings/bosch-placeholder.png",
    name: 'BOSCH A516S -30"/14"',
    description: "Flat wiper blades",
    information: "Bosch manufacture Aerotwin Wiper sets with innovative rubber technology that results in ultimate performance and increased service life",
    detail: "Bosch has reinvented the windscreen wiper with the Aerotwin Wiper Blade. Innovative windscreen wiping for everyone. Bracketless Bosch Aerotwin windscreen wipers can be seen on more and more new models. Bosch was the first to market with a bracketless wiper blade - Aerotwin. More and more vehicle manufacturers are choosing Aerotwin technology.",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Special Wipers"],
  },
  {
    sku: "AG0321",
    slug: "bosch-a844s-2222",
    thumbnail: "/file/listings/bosch-placeholder.png",
    name: 'BOSCH A844S-22"/22"',
    description: "Flat wiper blades",
    information: "Bosch manufacture Aerotwin Wiper sets with innovative rubber technology that results in ultimate performance and increased service life",
    detail: "Bosch has reinvented the windscreen wiper with the Aerotwin Wiper Blade. Innovative windscreen wiping for everyone. Bracketless Bosch Aerotwin windscreen wipers can be seen on more and more new models. Bosch was the first to market with a bracketless wiper blade - Aerotwin. More and more vehicle manufacturers are choosing Aerotwin technology.",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Special Wipers"],
  },
  {
    sku: "AG0219",
    slug: "bosch-af4038-aeristo-cabin-filter",
    thumbnail: "/file/listings/bosch-placeholder.png",
    name: "BOSCH AF4038-AERISTO CABIN FILTER",
    description: "Anti-allergic & anti-bacterial protection for clean air inside your car",
    information: "Bosch Premium Cabin Air Filters offer an excellent blend of original equipment fit and significantly enhanced quality, resulting in a cleaner and healthier passenger cabin air quality. With pollutants and allergens inside a vehicle potentially reaching up to six times higher concentration than outside, Bosch Premium Cabin Filters act as a reliable barrier, preventing harmful spores and pollen from entering the vehicle interior. By doing so, they not only safeguard passengers but also protect downstream HVAC components, ensuring a more pleasant and safe driving experience",
    detail: "Anti-Allergy Layer that effectively renders allergens harmless and eliminates bacteria, ensuring a healthier environment for all passengers. The inclusion of Activated Carbon enhances the filter's capability to absorb harmful gases and unpleasant smells. Ultra-fine microfiber filters fine dust particles (up to 2.5µm), soot and pollen. The filter's Carrier Fleece stabilizes the layers, ensuring optimum performance and durability. Additionally, the reduction of deposits protects the air conditioning system, ultimately leading to fresher air and a more comfortable journey for everyone on board",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Cabin Filters"],
  },
  {
    sku: "AG0187",
    slug: "bosch-af5210-aeristo-cabin-filter",
    thumbnail: "/file/listings/bosch-placeholder.png",
    name: "BOSCH AF5210-AERISTO CABIN FILTER",
    description: "Anti-allergic & anti-bacterial protection for clean air inside your car",
    information: "Bosch Premium Cabin Air Filters offer an excellent blend of original equipment fit and significantly enhanced quality, resulting in a cleaner and healthier passenger cabin air quality. With pollutants and allergens inside a vehicle potentially reaching up to six times higher concentration than outside, Bosch Premium Cabin Filters act as a reliable barrier, preventing harmful spores and pollen from entering the vehicle interior. By doing so, they not only safeguard passengers but also protect downstream HVAC components, ensuring a more pleasant and safe driving experience",
    detail: "Anti-Allergy Layer that effectively renders allergens harmless and eliminates bacteria, ensuring a healthier environment for all passengers. The inclusion of Activated Carbon enhances the filter's capability to absorb harmful gases and unpleasant smells. Ultra-fine microfiber filters fine dust particles (up to 2.5µm), soot and pollen. The filter's Carrier Fleece stabilizes the layers, ensuring optimum performance and durability. Additionally, the reduction of deposits protects the air conditioning system, ultimately leading to fresher air and a more comfortable journey for everyone on board",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Cabin Filters"],
  },
  {
    sku: "AG0063",
    slug: "bosch-aux-bla-12-2",
    thumbnail: "/file/listings/AG0063.png",
    name: "BOSCH Aux Bla 12-2",
    description: "The auxiliary battery serves as a backup power in case of alternator failure or inadequate load capacity on the main battery",
    information: "Bosch AGM Auxiliary battery is the secure choice of backup power supply in case of inadequate energy from the main battery or an alternator failure",
    detail: "Bosch AGM Auxiliary is a high-quality auxiliary battery designed to provide reliable power support to your vehicle's electrical system. With its advanced technology and durable construction, this battery ensures efficient performance and long-lasting functionality. It serves as a backup power source, helping to maintain essential functions in your vehicle even during power interruptions. The Bosch AGM Auxiliary is engineered to meet strict quality standards, offering peace of mind and confidence in its reliability. Whether you need an auxiliary battery for added electrical capacity or as a backup during emergencies, the Bosch AGM Auxiliary is a top-notch choice that delivers exceptional performance and value",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Batteries"],
  },
  {
    sku: "AG0103",
    slug: "bosch-aux-bla-8",
    thumbnail: "/file/listings/AG0103.png",
    name: "BOSCH Aux Bla 8",
    description: "The auxiliary battery serves as a backup power in case of alternator failure or inadequate load capacity on the main battery",
    information: "Bosch AGM Auxiliary battery is the secure choice of backup power supply in case of inadequate energy from the main battery or an alternator failure",
    detail: "Bosch AGM Auxiliary is a high-quality auxiliary battery designed to provide reliable power support to your vehicle's electrical system. With its advanced technology and durable construction, this battery ensures efficient performance and long-lasting functionality. It serves as a backup power source, helping to maintain essential functions in your vehicle even during power interruptions. The Bosch AGM Auxiliary is engineered to meet strict quality standards, offering peace of mind and confidence in its reliability. Whether you need an auxiliary battery for added electrical capacity or as a backup during emergencies, the Bosch AGM Auxiliary is a top-notch choice that delivers exceptional performance and value",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Batteries"],
  },
  {
    sku: "AG0332",
    slug: "bosch-battery-100d23l",
    thumbnail: "/file/listings/AG0332.png",
    name: "BOSCH Battery - 100D23L",
    description: "Automotive Battery",
    information: "AMS constantly monitors your vehicle’s operating conditions and battery state of charge to ensure optimized charge. It controls the alternator function and power supply to the battery to relieve engine workload under various conditions in an effort to reduce fuel consumption and emission. Bosch Hightec Silver AMS battery has higher charge acceptance and characteristic to withstand the cyclic demands of AMS. Installing a conventional flooded battery in an AMS vehicle may shorten the battery’s expected life span",
    detail: "The BOSCH Battery - 100D23L is a powerful and dependable automotive battery designed to provide reliable starting power for a wide range of vehicles. With its advanced technology and robust construction, this battery delivers consistent performance and durability. It is built to withstand extreme conditions, ensuring reliable operation even in harsh weather. The 100D23L model offers excellent cold-cranking amps (CCA) to start your vehicle quickly and efficiently, making it suitable for various car makes and models. Whether you need a replacement battery or an upgrade for better performance, the BOSCH Battery - 100D23L is a trusted choice that meets high-quality standards and ensures a smooth driving experience",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Batteries"],
  },
  {
    sku: "AG0043",
    slug: "bosch-battery-105d31l",
    thumbnail: "/file/listings/AG0043.png",
    name: "BOSCH Battery - 105D31L",
    description: "Automotive Battery",
    information: "With the ever-evolving safety and comfort standards in modern vehicles, the demand for electrical consumables has significantly increased. Bosch Mega Power rises to meet these energy demands across a wide spectrum of automotive applications, catering to both compact and commercial vehicles. This automotive battery boasts a long service life with minimal water loss, ensuring consistent and reliable performance. Its expanded grid technology ensures optimal power delivery for various add-on convenience features. The labyrinth lid design and flame arrestor enhance safety, providing a secure and worry-free power source for electrical devices. Whether it's for compact cars or larger vehicles, Bosch Mega Power is a dependable mid-range option, capable of fulfilling increased power demands, ensuring smooth and uninterrupted operation of essential electrical systems in modern vehicles",
    detail: "The BOSCH Battery - 105D31L is a premium automotive battery designed to meet the demanding power needs of various vehicles. With its advanced technology and durable construction, this battery ensures reliable starting power and consistent performance. The 105D31L model is equipped with high cold-cranking amps (CCA) to deliver quick and efficient starts, even in extreme weather conditions. Its robust design allows it to withstand vibrations and shocks, making it suitable for off-road and heavy-duty applications. The BOSCH Battery - 105D31L is a maintenance-free battery, providing hassle-free operation and reducing the need for regular maintenance. It is a dependable choice for drivers seeking a reliable and long-lasting battery that can handle the rigors of daily driving and challenging terrains",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Batteries"],
  },
  {
    sku: "AG0142",
    slug: "bosch-battery-115f51-n120",
    thumbnail: "/file/listings/AG0142.png",
    name: "BOSCH Battery - 115F51 (N120)",
    description: "Automotive Battery",
    information: "The BOSCH Battery - 115F51 (N120) is a high-quality and reliable automotive battery designed to deliver exceptional performance for various vehicle applications. It is part of BOSCH's renowned battery range, known for its durability and efficiency. This maintenance-free battery is engineered with advanced technology to ensure reliable starting power and long-lasting service life, providing peace of mind for drivers. The 115F51 (N120) model is suitable for a wide range of vehicles and is an ideal choice for those seeking a dependable battery solution",
    detail: "Suitable for various vehicle applications",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Batteries"],
  },
  {
    sku: "AG0055",
    slug: "bosch-battery-42b20l",
    thumbnail: "/file/listings/AG0055.png",
    name: "BOSCH Battery - 42B20L",
    description: "An all-around battery focused on mid-size car segment with average number of electrical consumers",
    information: "With the ever-evolving safety and comfort standards in modern vehicles, the demand for electrical consumables has significantly increased. Bosch Mega Power rises to meet these energy demands across a wide spectrum of automotive applications, catering to both compact and commercial vehicles. This automotive battery boasts a long service life with minimal water loss, ensuring consistent and reliable performance. Its expanded grid technology ensures optimal power delivery for various add-on convenience features. The labyrinth lid design and flame arrestor enhance safety, providing a secure and worry-free power source for electrical devices. Whether it's for compact cars or larger vehicles, Bosch Mega Power is a dependable mid-range option, capable of fulfilling increased power demands, ensuring smooth and uninterrupted operation of essential electrical systems in modern vehicles",
    detail: "The BOSCH Battery - 42B20L is a reliable and high-performance automotive battery designed to meet the energy demands of a wide range of vehicles. With its advanced technology and sturdy construction, it ensures a long service life with minimum maintenance required. The battery's expanded grid design delivers optimal power output, making it suitable for various add-on convenience features in modern vehicles. Its enhanced safety features, including the labyrinth lid design and flame arrestor, provide protection against potential hazards. The BOSCH Battery - 42B20L guarantees a consistent and reliable power supply, ensuring that all electrical devices in the vehicle function smoothly and efficiently. Whether used in compact cars or larger vehicles, this battery offers exceptional performance and is a perfect choice for drivers seeking a durable and high-quality automotive power solution.",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Batteries"],
  },
  {
    sku: "AG0136",
    slug: "bosch-battery-544036",
    thumbnail: "/file/listings/AG0136.png",
    name: "BOSCH Battery - 544.036",
    description: "Superior starting power, extended service life, and enhanced safety for modern vehicles with electronic features",
    information: "The Bosch Mega Power Silver battery is specifically engineered to cater to the diverse comfort and safety features found in modern vehicles, making it an ideal choice for recent models or upmarket vehicles with multiple electronic consumables. Its innovative grid technology ensures consistent performance and protects against power degradation over time, ensuring reliable and stable energy supply. The battery boasts approximately 30% higher starting power compared to standard batteries, guaranteeing smooth engine ignition even under extreme temperatures. With enhanced corrosion resistance and an extended service life, the Bosch Mega Power Silver battery is built to withstand the rigors of daily driving. Its labyrinth lid design and flame arrestor add an extra layer of safety, making it a trustworthy option for drivers seeking a high-performance and secure automotive battery solution",
    detail: "The Bosch Mega Power Silver battery is a dependable powerhouse designed to meet the energy demands of modern and upmarket vehicles equipped with a wide range of electronic consumables. Its innovative grid technology ensures long-lasting performance and safeguards against power loss over time. With approximately 30% higher starting power compared to standard batteries, it offers reliable starting even under extreme temperature conditions. The battery's excellent corrosion resistance and extended service life make it a durable and reliable choice. Additionally, its labyrinth lid design and flame arrestor enhance safety, providing peace of mind for drivers",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Batteries"],
  },
  {
    sku: "AG0288",
    slug: "bosch-battery-544136",
    thumbnail: "/file/listings/bosch-placeholder.png",
    name: "BOSCH Battery - 544.136",
    description: "A high-performance and long-lasting battery solution for modern vehicles, providing dependable energy supply and improved sound quality",
    information: "The Bosch Mega Power Silver battery is specifically engineered to cater to the diverse comfort and safety features found in modern vehicles, making it an ideal choice for recent models or upmarket vehicles with multiple electronic consumables. Its innovative grid technology ensures consistent performance and protects against power degradation over time, ensuring reliable and stable energy supply. The battery boasts approximately 30% higher starting power compared to standard batteries, guaranteeing smooth engine ignition even under extreme temperatures. With enhanced corrosion resistance and an extended service life, the Bosch Mega Power Silver battery is built to withstand the rigors of daily driving. Its labyrinth lid design and flame arrestor add an extra layer of safety, making it a trustworthy option for drivers seeking a high-performance and secure automotive battery solution",
    detail: "The Bosch Mega Power Silver battery is a dependable powerhouse designed to meet the energy demands of modern and upmarket vehicles equipped with a wide range of electronic consumables. Its innovative grid technology ensures long-lasting performance and safeguards against power loss over time. With approximately 30% higher starting power compared to standard batteries, it offers reliable starting even under extreme temperature conditions. The battery's excellent corrosion resistance and extended service life make it a durable and reliable choice. Additionally, its labyrinth lid design and flame arrestor enhance safety, providing peace of mind for drivers",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Batteries"],
  },
  {
    sku: "AG0145",
    slug: "bosch-battery-55b19r",
    thumbnail: "/file/listings/AG0145.png",
    name: "BOSCH Battery - 55B19R",
    description: "A high-performance and long-lasting battery solution for modern vehicles, providing dependable energy supply and improved sound quality",
    information: "The battery contains electrolytes (dilute sulfuric acid), so please do not carry it diagonally or horizontally. (Please keep it horizontally and handle it. ) Please follow the instruction manual of the charger when using the charger. (Please note that handling it is very dangerous if it is handled incorrectly. ) When installing and replacing batteries, please carefully check the user manual (English language not guaranteed). (Or ask a professional installer)",
    detail: "The Bosch Mega Power Silver battery is a reliable and long-lasting solution for automotive energy needs. Its silver alloy composition ensures a longer lifespan and reduces the likelihood of running out of power. The battery is completely maintenance-free, eliminating the need for water refills. It is well-suited for short-distance driving and features high charging acceptance performance, quickly restoring power. However, caution is advised during extremely short charging times, as it may impact the battery's ability to hold a charge. The battery's outstanding discharge capacity and large capacity ensure a stable power supply, maximizing audio capabilities and improving sound quality. Additionally, the Bosch Mega Power Silver battery exhibits low self-discharge and a large capacity",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Batteries"],
  },
  {
    sku: "AG0291",
    slug: "bosch-battery-55b24ls",
    thumbnail: "/file/listings/AG0291.png",
    name: "BOSCH Battery - 55B24LS",
    description: "Bosch Mega Power S3+ battery",
    information: "The SM Mega Power Plus is a top-tier choice for unmatched reliability. It is designed to surpass the requirements of the expanding medium-sized vehicle segment, catering to the growing demand for advanced features. With its reinforced outputs, the battery can accommodate DVD players, parking distance control, lane-changing warnings, and active suspension systems. The SM Mega Power Plus offers an extended service life and comes with an increased warranty, ensuring customers' peace of mind. Whether it's paired with cutting-edge vehicle technology or chosen by customers seeking superior performance, the SM Mega Power Plus is the ideal partner",
    detail: "Introducing the Bosch Mega Power S3+ battery: a maintenance-free powerhouse that is always ready to start. Crafted with high-purity lead (99.99% Grade) and a micro grain grid structure, this battery ensures reduced corrosion and extended service life. Its exceptional resilience in extreme conditions makes it the perfect choice for budget-conscious consumers seeking long-lasting performance. Experience worry-free driving with the Bosch Mega Power S3+ battery",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Batteries"],
  },
  {
    sku: "AG0041",
    slug: "bosch-battery-80d23l",
    thumbnail: "/file/listings/AG0041.png",
    name: "BOSCH Battery - 80D23L",
    description: "Automative Battery",
    information: "The BOSCH Battery - 80D23L is a high-quality automotive battery designed to provide reliable power and performance for various vehicles. As part of BOSCH's renowned battery range, it delivers exceptional starting power and is built to withstand the demands of modern vehicles. The 80D23L model is a maintenance-free battery, which means it requires no topping up of water, providing convenience and peace of mind to drivers. With its robust construction and advanced technology, this battery offers excellent cranking power and is designed to have a long service life, making it an ideal choice for those seeking a dependable and durable battery solution",
    detail: "70Ah, 560CCA",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Batteries"],
  },
  {
    sku: "AG0042",
    slug: "bosch-battery-90d26l",
    thumbnail: "/file/listings/AG0041.png",
    name: "BOSCH Battery - 90D26L",
    description: "Automative Battery",
    information: "The BOSCH Battery - 90D26L is a high-quality automotive battery designed to provide reliable power and performance for various vehicles. As part of BOSCH's renowned battery range, it delivers exceptional starting power and is built to withstand the demands of modern vehicles. The 80D23L model is a maintenance-free battery, which means it requires no topping up of water, providing convenience and peace of mind to drivers. With its robust construction and advanced technology, this battery offers excellent cranking power and is designed to have a long service life, making it an ideal choice for those seeking a dependable and durable battery solution",
    detail: "75Ah, 620CCA",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Batteries"],
  },
  {
    sku: "AG0070",
    slug: "bosch-battery-90d26r",
    thumbnail: "/file/listings/AG0041.png",
    name: "BOSCH Battery - 90D26R",
    description: "Automative Battery",
    information: "The BOSCH Battery - 90D26R is a high-quality automotive battery designed to provide reliable power and performance for various vehicles. As part of BOSCH's renowned battery range, it delivers exceptional starting power and is built to withstand the demands of modern vehicles. The 80D23L model is a maintenance-free battery, which means it requires no topping up of water, providing convenience and peace of mind to drivers. With its robust construction and advanced technology, this battery offers excellent cranking power and is designed to have a long service life, making it an ideal choice for those seeking a dependable and durable battery solution",
    detail: "75Ah, 620CCA",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Batteries"],
  },
  {
    sku: "AG0528",
    slug: "bosch-battery-95d31l",
    thumbnail: "/file/listings/AG0528.png",
    name: "BOSCH Battery - 95D31L",
    description: "Automative Battery",
    information: "The T4 battery, with Full Frame technology, supplies all running applications in heavy duty vehicle operations that require a normal level of energy – even with the engine shut off or in instances of frequent discharge",
    detail: "90Ah, 710CCA",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Batteries"],
  },
  {
    sku: "AG0529",
    slug: "bosch-battery-95d31r",
    thumbnail: "/file/listings/AG0528.png",
    name: "BOSCH Battery - 95D31R",
    description: "Automative Battery",
    information: "The T4 battery, with Full Frame technology, supplies all running applications in heavy duty vehicle operations that require a normal level of energy – even with the engine shut off or in instances of frequent discharge",
    detail: "90Ah, 710CCA",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Batteries"],
  },
  {
    sku: "AG0141",
    slug: "bosch-battery-95e41r-n100",
    thumbnail: "/file/listings/bosch-placeholder.png",
    name: "BOSCH Battery - 95E41R (N100)",
    description: "Automative Battery",
    information: "The BOSCH Battery - 95E41R (N100) is a reliable and high-quality automotive battery designed to provide consistent power and performance. This maintenance-free battery is manufactured by BOSCH, a trusted and well-known principals i[n the automotive industry. With a model number of 95E41R and the designation N100, this battery is specifically designed to meet the requirements of certain vehicles and deliver optimal electrical performance",
    detail: "110Ah, 700CCA",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Batteries"],
  },
  {
    sku: "AG0137",
    slug: "bosch-battery-560064",
    thumbnail: "/file/listings/AG0137.png",
    name: "BOSCH Battery - 560.064",
    description: "The Right Power for Alternator Management System (AMS)",
    information: "AMS constantly monitors your vehicle’s operating conditions and battery state of charge to ensure optimized charge. It controls the alternator function and power supply to the battery to relieve engine workload under various conditions in an effort to reduce fuel consumption and emission. Bosch Hightec Silver AMS battery has higher charge acceptance and characteristic to withstand the cyclic demands of AMS. Installing a conventional flooded battery in an AMS vehicle may shorten the battery’s expected life span",
    detail: "The BOSCH Battery - 560.064 is a high-performance and reliable power source that guarantees optimal functionality for a wide range of vehicles. With its advanced technology and superior design, this battery ensures smooth engine starts and efficient performance, even in extreme conditions. Its sturdy build and long-lasting service life make it a dependable choice for motorists seeking a durable and trustworthy battery solution. Experience the power and reliability of BOSCH with the Battery - 560.064",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Batteries"],
  },
  {
    sku: "AG0138",
    slug: "bosch-battery-562051",
    thumbnail: "/file/listings/AG0138.png",
    name: "BOSCH Battery - 562.051",
    description: "The Right Power for Alternator Management System (AMS)",
    information: "AMS constantly monitors your vehicle’s operating conditions and battery state of charge to ensure optimized charge. It controls the alternator function and power supply to the battery to relieve engine workload under various conditions in an effort to reduce fuel consumption and emission. Bosch Hightec Silver AMS battery has higher charge acceptance and characteristic to withstand the cyclic demands of AMS. Installing a conventional flooded battery in an AMS vehicle may shorten the battery’s expected life span",
    detail: "The BOSCH Battery - 562.051 is a top-of-the-line automotive battery designed to provide exceptional performance and reliability. With its advanced technology and high-quality materials, this battery delivers powerful and consistent energy to start your vehicle with ease. It boasts a long service life, ensuring you can rely on it for years to come. The BOSCH Battery - 562.051 is the perfect choice for drivers who value quality, durability, and dependable performance. Experience the excellence of BOSCH batteries with the Battery - 562.051",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Batteries"],
  },
  {
    sku: "AG0140",
    slug: "bosch-battery-574065",
    thumbnail: "/file/listings/AG0140.png",
    name: "BOSCH Battery - 574.065",
    description: "The Right Power for Alternator Management System (AMS)",
    information: "AMS constantly monitors your vehicle’s operating conditions and battery state of charge to ensure optimized charge. It controls the alternator function and power supply to the battery to relieve engine workload under various conditions in an effort to reduce fuel consumption and emission. Bosch Hightec Silver AMS battery has higher charge acceptance and characteristic to withstand the cyclic demands of AMS. Installing a conventional flooded battery in an AMS vehicle may shorten the battery’s expected life span",
    detail: "The BOSCH Battery - 574.065 is a premium automotive battery that offers outstanding power and reliability. Engineered with precision and utilizing high-quality materials, this battery ensures a quick and efficient start-up for your vehicle. Its advanced technology and robust construction provide enhanced durability and a longer service life, making it a dependable choice for any vehicle. With the BOSCH Battery - 574.065, you can count on consistent performance and peace of mind on the road. Trust in the excellence of BOSCH batteries to keep your vehicle running smoothly and reliably",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Batteries"],
  },
  {
    sku: "AG0289",
    slug: "bosch-battery-575065",
    thumbnail: "/file/listings/AG0289.png",
    name: "BOSCH Battery - 575.065",
    description: "The Right Power for Alternator Management System (AMS)",
    information: "AMS constantly monitors your vehicle’s operating conditions and battery state of charge to ensure optimized charge. It controls the alternator function and power supply to the battery to relieve engine workload under various conditions in an effort to reduce fuel consumption and emission. Bosch Hightec Silver AMS battery has higher charge acceptance and characteristic to withstand the cyclic demands of AMS. Installing a conventional flooded battery in an AMS vehicle may shorten the battery’s expected life span",
    detail: "The BOSCH Battery - 575.065 is a high-performance automotive battery designed to deliver exceptional power and reliability. With its advanced technology and superior construction, this battery ensures a smooth and efficient start-up for your vehicle. Its extended service life and excellent charging acceptance performance make it a reliable choice for various automotive applications. The BOSCH Battery - 575.065 is built to withstand extreme conditions, providing you with the confidence to drive with peace of mind. Trust in the renowned quality of BOSCH batteries to keep your vehicle running at its best and to power your journeys with efficiency and dependability",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Batteries"],
  },
  {
    sku: "AG0146",
    slug: "bosch-battery-580073",
    thumbnail: "/file/listings/AG0146.png",
    name: "BOSCH Battery - 580.073",
    description: "The Right Power for Alternator Management System (AMS)",
    information: "AMS constantly monitors your vehicle’s operating conditions and battery state of charge to ensure optimized charge. It controls the alternator function and power supply to the battery to relieve engine workload under various conditions in an effort to reduce fuel consumption and emission. Bosch Hightec Silver AMS battery has higher charge acceptance and characteristic to withstand the cyclic demands of AMS. Installing a conventional flooded battery in an AMS vehicle may shorten the battery’s expected life span",
    detail: "The BOSCH Battery - 580.073 is a high-quality automotive battery engineered to meet the demanding power requirements of modern vehicles. With its advanced design and reliable performance, this battery ensures a consistent and smooth start every time you turn the key. Its enhanced durability and low self-discharge rate make it a long-lasting solution for your automotive needs. Whether you're driving in extreme weather conditions or tackling everyday commutes, the BOSCH Battery - 580.073 is built to deliver dependable power and reliable performance",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Batteries"],
  },
  {
    sku: "AG0139",
    slug: "bosch-battery-600085",
    thumbnail: "/file/listings/AG0139.png",
    name: "BOSCH Battery - 600.085",
    description: "The Right Power for Alternator Management System (AMS)",
    information: "AMS constantly monitors your vehicle’s operating conditions and battery state of charge to ensure optimized charge. It controls the alternator function and power supply to the battery to relieve engine workload under various conditions in an effort to reduce fuel consumption and emission. Bosch Hightec Silver AMS battery has higher charge acceptance and characteristic to withstand the cyclic demands of AMS. Installing a conventional flooded battery in an AMS vehicle may shorten the battery’s expected life span",
    detail: "The BOSCH Battery - 600.085 is a top-of-the-line automotive battery designed to provide outstanding power and performance. Engineered with advanced technology and high-quality materials, this battery offers superior starting power and long-lasting durability. Its high charging acceptance rate ensures quick restoration of power, making it an ideal choice for drivers who rely on their vehicles for various applications. With its excellent discharge capacity and large capacity, the BOSCH Battery - 600.085 guarantees a stable power supply to maximize audio capability and improve sound quality. Whether you have a sophisticated car audio system or need a reliable battery for your vehicle, the BOSCH Battery - 600.085 delivers exceptional performance and reliability for all your driving needs",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Batteries"],
  },
  {
    sku: "AG0038",
    slug: "bosch-battery-65b24l",
    thumbnail: "/file/listings/AG0038.png",
    name: "BOSCH Battery - 65B24L",
    description: "Reliable and high-performance automotive battery",
    information: "With the ever-evolving safety and comfort standards in modern vehicles, the demand for electrical consumables has significantly increased. Bosch Mega Power rises to meet these energy demands across a wide spectrum of automotive applications, catering to both compact and commercial vehicles. This automotive battery boasts a long service life with minimal water loss, ensuring consistent and reliable performance. Its expanded grid technology ensures optimal power delivery for various add-on convenience features. The labyrinth lid design and flame arrestor enhance safety, providing a secure and worry-free power source for electrical devices. Whether it's for compact cars or larger vehicles, Bosch Mega Power is a dependable mid-range option, capable of fulfilling increased power demands, ensuring smooth and uninterrupted operation of essential electrical systems in modern vehicles",
    detail: "Long Service Life, Optimal Performance, High Resistance, Assured Safety",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Batteries"],
  },
  {
    sku: "AG0040",
    slug: "bosch-battery-65b24ls",
    thumbnail: "/file/listings/AG0040.png",
    name: "BOSCH Battery - 65B24LS",
    description: "Reliable and high-performance automotive battery",
    information: "With the ever-evolving safety and comfort standards in modern vehicles, the demand for electrical consumables has significantly increased. Bosch Mega Power rises to meet these energy demands across a wide spectrum of automotive applications, catering to both compact and commercial vehicles. This automotive battery boasts a long service life with minimal water loss, ensuring consistent and reliable performance. Its expanded grid technology ensures optimal power delivery for various add-on convenience features. The labyrinth lid design and flame arrestor enhance safety, providing a secure and worry-free power source for electrical devices. Whether it's for compact cars or larger vehicles, Bosch Mega Power is a dependable mid-range option, capable of fulfilling increased power demands, ensuring smooth and uninterrupted operation of essential electrical systems in modern vehicles",
    detail: "Long Service Life, Optimal Performance, High Resistance, Assured Safety",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Batteries"],
  },
  {
    sku: "AG0039",
    slug: "bosch-battery-65b24r",
    thumbnail: "/file/listings/AG0039.png",
    name: "BOSCH Battery - 65B24R",
    description: "Reliable and high-performance automotive battery",
    information: "With the ever-evolving safety and comfort standards in modern vehicles, the demand for electrical consumables has significantly increased. Bosch Mega Power rises to meet these energy demands across a wide spectrum of automotive applications, catering to both compact and commercial vehicles. This automotive battery boasts a long service life with minimal water loss, ensuring consistent and reliable performance. Its expanded grid technology ensures optimal power delivery for various add-on convenience features. The labyrinth lid design and flame arrestor enhance safety, providing a secure and worry-free power source for electrical devices. Whether it's for compact cars or larger vehicles, Bosch Mega Power is a dependable mid-range option, capable of fulfilling increased power demands, ensuring smooth and uninterrupted operation of essential electrical systems in modern vehicles",
    detail: "Long Service Life, Optimal Performance, High Resistance, Assured Safety",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Batteries"],
  },
  {
    sku: "AG0144",
    slug: "bosch-battery-68032-din-b",
    thumbnail: "/file/listings/AG0144.png",
    name: "BOSCH Battery - 68032 (DIN B)",
    description: "Reliable power for smooth starts and consistent performance in various vehicles",
    information: "CCA - 1130, Series - T4",
    detail: "The T4 battery, with Full Frame technology, delivers ample power for various running applications in heavy-duty vehicle operations – even with the engine shut off or in instances of frequent discharge",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Batteries"],
  },
  {
    sku: "AG0075",
    slug: "bosch-battery-agm-ln2",
    thumbnail: "/file/listings/AG0075.png",
    name: "BOSCH Battery - AGM LN2",
    description: "AGM technology for Advanced Start-Stop systems",
    information: "Start-stop technology is rapidly gaining momentum in the market nowadays in an effort to reduce fuel consumption and CO2 emissions. In city traffic, the vehicle’s electrical system can consume more power than the alternator can supply. Due to less charging opportunity in start-stop systems, it is crucial to have a battery that is able to recharge quickly with superior starting power to enable engine off-mode",
    detail: "Assured Safety, Absorbent Glass Mat, Centralized Grid Lug / Fine Mesh Grid, Extra Service Life",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Batteries"],
  },
  {
    sku: "AG0051",
    slug: "bosch-battery-agm-ln3",
    thumbnail: "/file/listings/AG0051.png",
    name: "BOSCH Battery - AGM LN3",
    description: "AGM technology for Advanced Start-Stop systems",
    information: "Start-stop technology is rapidly gaining momentum in the market nowadays in an effort to reduce fuel consumption and CO2 emissions. In city traffic, the vehicle’s electrical system can consume more power than the alternator can supply. Due to less charging opportunity in start-stop systems, it is crucial to have a battery that is able to recharge quickly with superior starting power to enable engine off-mode",
    detail: "Assured Safety, Absorbent Glass Mat, Centralized Grid Lug / Fine Mesh Grid, Extra Service Life",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Batteries"],
  },
  {
    sku: "AG0052",
    slug: "bosch-battery-agm-ln4",
    thumbnail: "/file/listings/AG0052.png",
    name: "BOSCH Battery - AGM LN4",
    description: "AGM technology for Advanced Start-Stop systems",
    information: "Start-stop technology is rapidly gaining momentum in the market nowadays in an effort to reduce fuel consumption and CO2 emissions. In city traffic, the vehicle’s electrical system can consume more power than the alternator can supply. Due to less charging opportunity in start-stop systems, it is crucial to have a battery that is able to recharge quickly with superior starting power to enable engine off-mode",
    detail: "Assured Safety, Absorbent Glass Mat, Centralized Grid Lug / Fine Mesh Grid, Extra Service Life",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Batteries"],
  },
  {
    sku: "AG0053",
    slug: "bosch-battery-agm-ln5",
    thumbnail: "/file/listings/AG0053.png",
    name: "BOSCH Battery - AGM LN5",
    description: "AGM technology for Advanced Start-Stop systems",
    information: "Start-stop technology is rapidly gaining momentum in the market nowadays in an effort to reduce fuel consumption and CO2 emissions. In city traffic, the vehicle’s electrical system can consume more power than the alternator can supply. Due to less charging opportunity in start-stop systems, it is crucial to have a battery that is able to recharge quickly with superior starting power to enable engine off-mode",
    detail: "Assured Safety, Absorbent Glass Mat, Centralized Grid Lug / Fine Mesh Grid, Extra Service Life",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Batteries"],
  },
  {
    sku: "AG0054",
    slug: "bosch-battery-agm-ln6",
    thumbnail: "/file/listings/AG0054.png",
    name: "BOSCH Battery - AGM LN6",
    description: "AGM technology for Advanced Start-Stop systems",
    information: "Start-stop technology is rapidly gaining momentum in the market nowadays in an effort to reduce fuel consumption and CO2 emissions. In city traffic, the vehicle’s electrical system can consume more power than the alternator can supply. Due to less charging opportunity in start-stop systems, it is crucial to have a battery that is able to recharge quickly with superior starting power to enable engine off-mode",
    detail: "Assured Safety, Absorbent Glass Mat, Centralized Grid Lug / Fine Mesh Grid, Extra Service Life",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Batteries"],
  },
  {
    sku: "AG0044",
    slug: "bosch-battery-ams-545042",
    thumbnail: "/file/listings/bosch-placeholder.png",
    name: "BOSCH Battery - AMS 545.042",
    description: "The right power for Alternator Management System (AMS)",
    information: "AMS constantly monitors your vehicle’s operating conditions and battery state of charge to ensure optimized charge. It controls the alternator function and power supply to the battery to relieve engine workload under various conditions in an effort to reduce fuel consumption and emission. Bosch Hightec Silver AMS battery has higher charge acceptance and characteristic to withstand the cyclic demands of AMS. Installing a conventional flooded battery in an AMS vehicle may shorten the battery’s expected life span",
    detail: "The BOSCH Battery - AMS 545.042 is a high-performance and reliable power source that guarantees optimal functionality for a wide range of vehicles. With its advanced technology and superior design, this battery ensures smooth engine starts and efficient performance, even in extreme conditions. Its sturdy build and long-lasting service life make it a dependable choice for motorists seeking a durable and trustworthy battery solution. Experience the power and reliability of BOSCH with the Battery - AMS 545.042",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Batteries"],
  },
  {
    sku: "AG0049",
    slug: "bosch-battery-efb-ln2",
    thumbnail: "/file/listings/AG0049.png",
    name: "BOSCH Battery - EFB LN2",
    description: "Enhanced Performance for Entry-Level Start-Stop",
    information: "Batteries featuring Enhanced Flooded Battery (EFB) technology are engineered to deliver superior performance compared to traditional flooded batteries, making them suitable for supporting entry-level start-stop vehicles and meeting the electrical demands of standard vehicles with high power requirements. The EFB technology ensures exceptional cyclic stability, providing enhanced endurance even during deep discharges. Specifically designed for vehicles equipped with entry-level start-stop technology, these batteries contribute to improved fuel efficiency. Their extended cycle life outperforms conventional flooded batteries, making them a reliable choice for long-lasting power. The batteries' optimal operation in the engine compartment ensures efficient performance, while their high charging acceptance enables them to handle extra power demands, ensuring consistent and reliable operation",
    detail: "Eco Friendly, Extra High-Performance, Extra Long Service Life, Start / Stop",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Batteries"],
  },
  {
    sku: "AG0050",
    slug: "bosch-battery-efb-ln3",
    thumbnail: "/file/listings/AG0049.png",
    name: "BOSCH Battery - EFB LN3",
    description: "Enhanced Performance for Entry-Level Start-Stop",
    information: "Batteries featuring Enhanced Flooded Battery (EFB) technology are engineered to deliver superior performance compared to traditional flooded batteries, making them suitable for supporting entry-level start-stop vehicles and meeting the electrical demands of standard vehicles with high power requirements. The EFB technology ensures exceptional cyclic stability, providing enhanced endurance even during deep discharges. Specifically designed for vehicles equipped with entry-level start-stop technology, these batteries contribute to improved fuel efficiency. Their extended cycle life outperforms conventional flooded batteries, making them a reliable choice for long-lasting power. The batteries' optimal operation in the engine compartment ensures efficient performance, while their high charging acceptance enables them to handle extra power demands, ensuring consistent and reliable operation",
    detail: "Eco Friendly, Extra High-Performance, Extra Long Service Life, Start / Stop",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Batteries"],
  },
  {
    sku: "AG0074",
    slug: "bosch-battery-efb-ln4",
    thumbnail: "/file/listings/AG0049.png",
    name: "BOSCH Battery - EFB LN4",
    description: "Enhanced Performance for Entry-Level Start-Stop",
    information: "Batteries featuring Enhanced Flooded Battery (EFB) technology are engineered to deliver superior performance compared to traditional flooded batteries, making them suitable for supporting entry-level start-stop vehicles and meeting the electrical demands of standard vehicles with high power requirements. The EFB technology ensures exceptional cyclic stability, providing enhanced endurance even during deep discharges. Specifically designed for vehicles equipped with entry-level start-stop technology, these batteries contribute to improved fuel efficiency. Their extended cycle life outperforms conventional flooded batteries, making them a reliable choice for long-lasting power. The batteries' optimal operation in the engine compartment ensures efficient performance, while their high charging acceptance enables them to handle extra power demands, ensuring consistent and reliable operation",
    detail: "Eco Friendly, Extra High-Performance, Extra Long Service Life, Start / Stop",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Batteries"],
  },
  {
    sku: "AG0045",
    slug: "bosch-battery-efb-n55l",
    thumbnail: "/file/listings/AG0049.png",
    name: "BOSCH Battery - EFB N55L",
    description: "Enhanced Performance for Entry-Level Start-Stop",
    information: "Batteries featuring Enhanced Flooded Battery (EFB) technology are engineered to deliver superior performance compared to traditional flooded batteries, making them suitable for supporting entry-level start-stop vehicles and meeting the electrical demands of standard vehicles with high power requirements. The EFB technology ensures exceptional cyclic stability, providing enhanced endurance even during deep discharges. Specifically designed for vehicles equipped with entry-level start-stop technology, these batteries contribute to improved fuel efficiency. Their extended cycle life outperforms conventional flooded batteries, making them a reliable choice for long-lasting power. The batteries' optimal operation in the engine compartment ensures efficient performance, while their high charging acceptance enables them to handle extra power demands, ensuring consistent and reliable operation",
    detail: "Eco Friendly, Extra High-Performance, Extra Long Service Life, Start / Stop",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Batteries"],
  },
  {
    sku: "AG0067",
    slug: "bosch-battery-efb-n55ls",
    thumbnail: "/file/listings/AG0049.png",
    name: "BOSCH Battery - EFB N55LS",
    description: "Enhanced Performance for Entry-Level Start-Stop",
    information: "Batteries featuring Enhanced Flooded Battery (EFB) technology are engineered to deliver superior performance compared to traditional flooded batteries, making them suitable for supporting entry-level start-stop vehicles and meeting the electrical demands of standard vehicles with high power requirements. The EFB technology ensures exceptional cyclic stability, providing enhanced endurance even during deep discharges. Specifically designed for vehicles equipped with entry-level start-stop technology, these batteries contribute to improved fuel efficiency. Their extended cycle life outperforms conventional flooded batteries, making them a reliable choice for long-lasting power. The batteries' optimal operation in the engine compartment ensures efficient performance, while their high charging acceptance enables them to handle extra power demands, ensuring consistent and reliable operation",
    detail: "Eco Friendly, Extra High-Performance, Extra Long Service Life, Start / Stop",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Batteries"],
  },
  {
    sku: "AG0068",
    slug: "bosch-battery-efb-n55r",
    thumbnail: "/file/listings/AG0049.png",
    name: "BOSCH Battery - EFB N55R",
    description: "Enhanced Performance for Entry-Level Start-Stop",
    information: "Batteries featuring Enhanced Flooded Battery (EFB) technology are engineered to deliver superior performance compared to traditional flooded batteries, making them suitable for supporting entry-level start-stop vehicles and meeting the electrical demands of standard vehicles with high power requirements. The EFB technology ensures exceptional cyclic stability, providing enhanced endurance even during deep discharges. Specifically designed for vehicles equipped with entry-level start-stop technology, these batteries contribute to improved fuel efficiency. Their extended cycle life outperforms conventional flooded batteries, making them a reliable choice for long-lasting power. The batteries' optimal operation in the engine compartment ensures efficient performance, while their high charging acceptance enables them to handle extra power demands, ensuring consistent and reliable operation",
    detail: "Eco Friendly, Extra High-Performance, Extra Long Service Life, Start / Stop",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Batteries"],
  },
  {
    sku: "AG0046",
    slug: "bosch-battery-efb-q85l",
    thumbnail: "/file/listings/AG0049.png",
    name: "BOSCH Battery - EFB Q85L",
    description: "Enhanced Performance for Entry-Level Start-Stop",
    information: "Batteries featuring Enhanced Flooded Battery (EFB) technology are engineered to deliver superior performance compared to traditional flooded batteries, making them suitable for supporting entry-level start-stop vehicles and meeting the electrical demands of standard vehicles with high power requirements. The EFB technology ensures exceptional cyclic stability, providing enhanced endurance even during deep discharges. Specifically designed for vehicles equipped with entry-level start-stop technology, these batteries contribute to improved fuel efficiency. Their extended cycle life outperforms conventional flooded batteries, making them a reliable choice for long-lasting power. The batteries' optimal operation in the engine compartment ensures efficient performance, while their high charging acceptance enables them to handle extra power demands, ensuring consistent and reliable operation",
    detail: "Eco Friendly, Extra High-Performance, Extra Long Service Life, Start / Stop",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Batteries"],
  },
  {
    sku: "AG0069",
    slug: "bosch-battery-efb-q85r",
    thumbnail: "/file/listings/AG0049.png",
    name: "BOSCH Battery - EFB Q85R",
    description: "Enhanced Performance for Entry-Level Start-Stop",
    information: "Batteries featuring Enhanced Flooded Battery (EFB) technology are engineered to deliver superior performance compared to traditional flooded batteries, making them suitable for supporting entry-level start-stop vehicles and meeting the electrical demands of standard vehicles with high power requirements. The EFB technology ensures exceptional cyclic stability, providing enhanced endurance even during deep discharges. Specifically designed for vehicles equipped with entry-level start-stop technology, these batteries contribute to improved fuel efficiency. Their extended cycle life outperforms conventional flooded batteries, making them a reliable choice for long-lasting power. The batteries' optimal operation in the engine compartment ensures efficient performance, while their high charging acceptance enables them to handle extra power demands, ensuring consistent and reliable operation",
    detail: "Eco Friendly, Extra High-Performance, Extra Long Service Life, Start / Stop",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Batteries"],
  },
  {
    sku: "AG0047",
    slug: "bosch-battery-efb-s95l",
    thumbnail: "/file/listings/AG0049.png",
    name: "BOSCH Battery - EFB S95L",
    description: "Enhanced Performance for Entry-Level Start-Stop",
    information: "Batteries featuring Enhanced Flooded Battery (EFB) technology are engineered to deliver superior performance compared to traditional flooded batteries, making them suitable for supporting entry-level start-stop vehicles and meeting the electrical demands of standard vehicles with high power requirements. The EFB technology ensures exceptional cyclic stability, providing enhanced endurance even during deep discharges. Specifically designed for vehicles equipped with entry-level start-stop technology, these batteries contribute to improved fuel efficiency. Their extended cycle life outperforms conventional flooded batteries, making them a reliable choice for long-lasting power. The batteries' optimal operation in the engine compartment ensures efficient performance, while their high charging acceptance enables them to handle extra power demands, ensuring consistent and reliable operation",
    detail: "Suitable for vehicles with Start-Stop technology and various other car models",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Batteries"],
  },
  {
    sku: "AG0147",
    slug: "bosch-battery-efb-s95r",
    thumbnail: "/file/listings/AG0049.png",
    name: "BOSCH Battery - EFB S95R",
    description: "Enhanced Performance for Entry-Level Start-Stop",
    information: "Batteries featuring Enhanced Flooded Battery (EFB) technology are engineered to deliver superior performance compared to traditional flooded batteries, making them suitable for supporting entry-level start-stop vehicles and meeting the electrical demands of standard vehicles with high power requirements. The EFB technology ensures exceptional cyclic stability, providing enhanced endurance even during deep discharges. Specifically designed for vehicles equipped with entry-level start-stop technology, these batteries contribute to improved fuel efficiency. Their extended cycle life outperforms conventional flooded batteries, making them a reliable choice for long-lasting power. The batteries' optimal operation in the engine compartment ensures efficient performance, while their high charging acceptance enables them to handle extra power demands, ensuring consistent and reliable operation",
    detail: "Eco Friendly, Extra High-Performance, Extra Long Service Life, Start / Stop",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Batteries"],
  },
  {
    sku: "AG0073",
    slug: "bosch-battery-t110l",
    thumbnail: "/file/listings/bosch-placeholder.png",
    name: "BOSCH Battery - T110L",
    description: "Maintenance-free automotive battery",
    information: "The Bosch Battery T110L is a high-quality and reliable automotive battery designed to meet the power demands of various vehicles. With a voltage rating of 12V and a capacity of 80Ah, this maintenance-free battery is suitable for cars, trucks, and SUVs. The T110L battery is engineered with advanced technology to provide powerful starting power and ensure smooth engine starts, even in extreme weather conditions. Its robust construction and durable materials make it resistant to vibration, ensuring a long-lasting and dependable performance. The maintenance-free design eliminates the need for regular water refilling, offering convenience and ease of use for vehicle owners. As a trusted principals i[n the automotive industry, Bosch ensures that the T110L battery delivers outstanding performance and reliability, giving drivers the confidence they need for their daily journeys",
    detail: "Suitable for cars, trucks, and SUVs",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Batteries"],
  },
  {
    sku: "AG0072",
    slug: "bosch-battery-t110r",
    thumbnail: "/file/listings/bosch-placeholder.png",
    name: "BOSCH Battery - T110R",
    description: "Maintenance-free automotive battery",
    information: "The Bosch Battery T110R is a high-performance automotive battery that provides reliable and consistent power for a wide range of vehicles. With a 12-volt voltage rating and a capacity of 80Ah, this maintenance-free battery is designed to meet the demands of modern vehicles, including cars, trucks, and SUVs. The T110R battery is built with advanced technology to deliver powerful starting power and ensure smooth engine starts in various weather conditions. Its rugged construction and durable materials make it resistant to vibration and ensure a long service life. The maintenance-free design eliminates the need for regular water refilling, making it convenient and hassle-free for vehicle owners. As a trusted principals i[n the automotive industry, Bosch ensures that the T110R battery offers superior performance and reliability, providing motorists with the confidence they need for their daily drives",
    detail: "Suitable for cars, trucks, and SUVs",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Batteries"],
  },
  {
    sku: "AG0071",
    slug: "bosch-battery-105d31r",
    thumbnail: "/file/listings/bosch-placeholder.png",
    name: "BOSCH Battery -105D31R",
    description: "Maintenance-free automotive battery",
    information: "The Bosch Battery 105D31R is a powerful and reliable automotive battery designed to meet the demands of modern vehicles. With a 12-volt voltage rating and a capacity of 80Ah, this maintenance-free battery is suitable for a wide range of vehicles, including cars, trucks, and SUVs. The 105D31R battery is engineered with advanced technology to deliver consistent and efficient power, ensuring smooth engine starts and reliable performance in various weather conditions. Its rugged construction and durable materials make it resistant to vibration and ensure a long service life. As a trusted principals i[n the automotive industry, Bosch offers a high-quality and dependable battery solution with the 105D31R model, providing motorists with peace of mind and reliable performance for their vehicles",
    detail: "Suitable for cars, trucks, and SUVs",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Batteries"],
  },
  {
    sku: "AG0060",
    slug: "bosch-battery-131-tester-with-printer",
    thumbnail: "/file/listings/AG0060.png",
    name: "BOSCH BATTERY 131 TESTER WITH PRINTER",
    description: "Benchmark in battery testing ",
    information: "Designed with the technician in mind, the button layout and housing allows for one-handed operation. The 9-foot replaceable cables with Kelvin clamps allow for one person to perform starting / charging system tests from the driver’s seat of the vehicle. The cables also stay flexible in cold weather during battery and starting/charging system diagnosis",
    detail: "The BAT 131 is a portable battery tester with a large LCD display and a multi-language menu-guided interface, suitable for testing across the DIN, JIS, EN, SAE, IEC and EN2 standards. With the capability to test 12 V and 24 V starter and charging systems, the tester can support the optional use of a current clamp, enabling more extensive testing and improving test results. The BAT 131 is the benchmark in battery testing on cars, commercial vehicles and motorcycles with lead-acid, EFB, gel and AGM batteries",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Batteries"],
  },
  {
    sku: "AG0066",
    slug: "bosch-battery-55b19l",
    thumbnail: "/file/listings/bosch-placeholder.png",
    name: "BOSCH Battery- 55B19L",
    description: "Maintenance-free automotive battery",
    information: "The Bosch Battery 55B19L is a high-quality automotive battery designed to deliver reliable power and performance for various vehicles. This maintenance-free battery is built to withstand the demands of modern vehicle systems and provides dependable starting power even in extreme weather conditions. The 55B19L battery has a 12-volt voltage rating and is designed to fit specific vehicle models. It features advanced technology that ensures long-lasting durability and exceptional performance. As part of Bosch's automotive battery lineup, the 55B19L model is known for its quality, reliability, and efficient power delivery, making it an excellent choice for motorists seeking a dependable and high-performance battery",
    detail: "The 55B19L battery incorporates advanced technology to provide reliable and efficient power delivery, ensuring optimal performance in all weather conditions",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Batteries"],
  },
  {
    sku: "AG0411",
    slug: "bosch-bulb-p21w-12v-21w-ba-15s",
    thumbnail: "/file/listings/AG0411.png",
    name: "BOSCH BULB P21W- 12V 21W BA 15s",
    description: "Automative Bulb",
    information: "Note: It is essential to verify the compatibility of this bulb with your specific vehicle model before installation.",
    detail: "The BOSCH BULB P21W is a high-quality automotive bulb designed to provide reliable illumination for various automotive applications. Operating at 12V and 21W, it features a BA 15s base type, ensuring easy installation. This bulb is commonly used as a turn signal indicator, reverse light, or brake light in many vehicle models. With BOSCH's reputation for excellence, you can trust that this bulb offers optimal performance, long-lasting durability, and consistent brightness, making it a dependable choice for your automotive lighting needs",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["12V Bulb"],
  },
  {
    sku: "AG0413",
    slug: "bosch-bulb-py27w-12v-27w",
    thumbnail: "/file/listings/bosch-placeholder.png",
    name: "BOSCH BULB PY27W- 12V 27W",
    description: "Automative Bulb",
    information: "The Bosch Bulb PY27W- 12V 27W is a high-quality automotive bulb designed to provide reliable and efficient lighting for various 12-volt applications. With a power rating of 27 watts, this bulb offers bright and clear illumination, making it suitable for use as turn signals and other automotive lighting needs. The PY27W bulb type ensures easy compatibility with specific 12-volt bulb requirements for different vehicle models. As part of the Bosch Pure Light series, this bulb upholds the principals's reputation for superior quality and performance. Crafted with precision and durability, the Bosch Bulb PY27W- 12V 27W provides a cost-effective lighting solution, enhancing visibility and safety for drivers during different driving conditions",
    detail: "Voltage: 12V, Wattage: 27W",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["12V Bulb"],
  },
  {
    sku: "AG0417",
    slug: "bosch-bulb-wy21w-12v-21w-382wa",
    thumbnail: "/file/listings/bosch-placeholder.png",
    name: "BOSCH BULB WY21W 12V 21W 382WA",
    description: "Automative Bulb",
    information: "The Bosch Bulb WY21W 12V 21W 382WA is a high-quality automotive bulb designed to provide bright and reliable lighting for various 12-volt applications. With a power rating of 21 watts, this bulb offers ample brightness for applications like turn signals, brake lights, and other automotive lighting needs. The WY21W bulb type ensures easy compatibility with specific 12-volt bulb requirements for different vehicle models. As part of the Bosch Pure Light series, this bulb upholds the principals's reputation for superior quality and performance. Crafted with precision and durability, the Bosch Bulb WY21W 12V 21W 382WA offers a cost-effective lighting solution for automotive applications while ensuring enhanced visibility and safety for drivers during different driving conditions",
    detail: "Voltage: 12V, Wattage: 21W",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["12V Bulb"],
  },
  {
    sku: "AG0186",
    slug: "bosch-cabin-filter-a213a205",
    thumbnail: "/file/listings/bosch-placeholder.png",
    name: "BOSCH CABIN FILTER A213/A205",
    description: "Anti-allergic & anti-bacterial protection for clean air inside your car",
    information: "Bosch Premium Cabin Air Filters offer an excellent blend of original equipment fit and significantly enhanced quality, resulting in a cleaner and healthier passenger cabin air quality. With pollutants and allergens inside a vehicle potentially reaching up to six times higher concentration than outside, Bosch Premium Cabin Filters act as a reliable barrier, preventing harmful spores and pollen from entering the vehicle interior. By doing so, they not only safeguard passengers but also protect downstream HVAC components, ensuring a more pleasant and safe driving experience",
    detail: "Anti-Allergy Layer that effectively renders allergens harmless and eliminates bacteria, ensuring a healthier environment for all passengers. The inclusion of Activated Carbon enhances the filter's capability to absorb harmful gases and unpleasant smells. Ultra-fine microfiber filters fine dust particles (up to 2.5µm), soot and pollen. The filter's Carrier Fleece stabilizes the layers, ensuring optimum performance and durability. Additionally, the reduction of deposits protects the air conditioning system, ultimately leading to fresher air and a more comfortable journey for everyone on board",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Cabin Filters"],
  },
  {
    sku: "AG0604",
    slug: "bosch-engine-oil-0w20-4-ltr",
    thumbnail: "/file/listings/AG0604.png",
    name: "BOSCH Engine Oil-0W20 (4 Ltr)",
    description: "Fully Synthetic Engine Oil",
    information: "Recommended for Hybrid and Eco vehicles",
    detail: "Superior cold-start performance, Formulated to protect exhaust system and reduce emissions, Meets requirements of stringent GF-5 for excellent fuel efficiency ",
    uom: "BOTTLE",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Engine Oil"],
  },
  {
    sku: "AG0196",
    slug: "bosch-engine-oil-5w30-4-ltr",
    thumbnail: "/file/listings/bosch-placeholder.png",
    name: "BOSCH Engine Oil-5W30 (4 Ltr)",
    description: "Fully Synthetic Engine Oil",
    information: "Recommended for vehicles with turbocharger, compressed natural gas and gasoline engines for premium sedan and light truck",
    detail: "This advanced fuel additive is specially designed to enhance the performance and efficiency of premium sedans equipped with compressed natural gas and gasoline engines. Its unique formula effectively reduces engine deposits, ensuring a smoother and cleaner operation while maximizing fuel economy. With its fuel-saving properties, it helps drivers save on costs at the pump while prolonging the service life of their vehicles. The product has been rigorously tested and is certified by API SN and ILSAC GF-5, assuring users of its high-quality and performance-driven capabilities. Upgrade your driving experience with this top-notch fuel additive and enjoy a cleaner, more efficient, and longer-lasting ride",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Engine Oil"],
  },
  {
    sku: "AG0286",
    slug: "bosch-evolution-fanfare-horn-hd3",
    thumbnail: "/file/listings/AG0286.png",
    name: "BOSCH EVOLUTION FANFARE HORN HD3",
    description: "Durable and long-lasting, capable of projecting a strong, clear tone that reaches far and wide.",
    information: "The BOSCH EVOLUTION FANFARE HORN HD3 is a high-quality automotive accessory designed to provide an impressive audio experience for your vehicle. Its advanced technology and precision engineering ensure a powerful and clear sound that demands attention on the road. This horn is API SN certified and ILSAC GF-5 fulfilled, highlighting its fuel-saving properties and ability to reduce engine deposits, thus prolonging the engine's service life. It is specially recommended for premium sedans with compressed natural gas and gasoline engines. The BOSCH EVOLUTION FANFARE HORN HD3 is not only a reliable safety feature but also an elegant addition to your vehicle",
    detail: "ECE Quality, Low working current, Long service life, Strong clear tone, 410 / 510Hz Two tones",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["12V Horn"],
  },
  {
    sku: "AG0282",
    slug: "bosch-h3f-digital-fanfare",
    thumbnail: "/file/listings/AG0282.png",
    name: "BOSCH H3F Digital Fanfare",
    description: "Longest endurance and service life (10x compared to normal horns)",
    information: "Bosch H3F Fanfare Horns has longest endurance and service life (10x compared to normal horns) with 30% less power consumption. It is highly resistant to harsh environmental fluctuations",
    detail: "Designed with precision engineering and advanced technology, ensuring a powerful and clear sound that commands attention on the road. The horn is carefully crafted to deliver a distinct and melodious tone, making it an elegant addition to any car. With its compact and sleek design, the BOSCH H3F Digital Fanfare seamlessly integrates into your vehicle's aesthetics without compromising on performance. Additionally, this horn is built with durability in mind, guaranteeing long-lasting reliability for your peace of mind. Elevate your driving experience and make a statement with the exceptional BOSCH H3F Digital Fanfare that combines cutting-edge technology with unmatched audio performance",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["12V Horn"],
  },
  {
    sku: "AG0440",
    slug: "bosch-oil-filter-0008",
    thumbnail: "/file/listings/AG0440.png",
    name: "BOSCH OIL FILTER - 0008",
    description: "Diesel Auto Filter",
    information: "3.54 x 3.62 x 6.46 cm 4.54 Grams",
    detail: "Bosch diesel filters protect the injection system. They reliably separate particles and water from fuel and contribute to optimum engine performance",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Oil Filters"],
  },
  {
    sku: "AG0441",
    slug: "bosch-oil-filter-0009",
    thumbnail: "/file/listings/AG0441.png",
    name: "BOSCH OIL FILTER - 0009",
    description: "Diesel Auto Filter",
    information: "Filter type: Filter insert",
    detail: "Bosch diesel filters protect the injection system. They reliably separate particles and water from fuel and contribute to optimum engine performance",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Oil Filters"],
  },
  {
    sku: "AG0461",
    slug: "bosch-oil-filter-0126",
    thumbnail: "/file/listings/bosch-placeholder.png",
    name: "BOSCH OIL FILTER - 0126",
    description: "Oil Filter",
    information: "Bosch Premium Oil Filters utilize an exclusive blend of cellulose and glass fiber materials in the media for superior oil filtration and greater capacity for engine protection. With 99% efficiency*, the Bosch Premium Oil Filter traps both large and microscopic particles from entering the engine",
    detail: "BOSCH is committed to ensuring safety through its outstanding product quality, offering high-quality materials, precise processing, and rigorous quality checks that meet the same standards as original equipment (OE) parts. With expertise in developing and producing diesel and Denoxtronic injection systems, Bosch understands how to safeguard these crucial components. Providing a complete range of products from a single source, Bosch offers a comprehensive program that is continuously updated, making handling and ordering a breeze. Additionally, Bosch ensures high availability of goods through its extensive international sales and logistics network, ensuring prompt access to its parts. As a strategic partner for the future, Bosch offers diagnostic solutions, training courses, and seminars for wholesalers and workshops, solidifying its commitment to staying at the forefront of automotive innovation and excellence",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Oil Filters"],
  },
  {
    sku: "AG0438",
    slug: "bosch-oil-filter-0189",
    thumbnail: "/file/listings/AG0438.png",
    name: "BOSCH OIL FILTER - 0189",
    description: '3/4" 16 UNF, Spin-on Filter',
    information: "Engineered with precision and quality materials, this oil filter effectively removes contaminants, dirt, and impurities from the engine oil, ensuring a clean and smooth operation. It is specifically designed to fit a wide range of vehicle models, making it a versatile and reliable choice for various car owners. The BOSCH Oil Filter - 0126 meets the highest industry standards and is recommended for use during regular oil change intervals. With its high-performance capabilities, this oil filter prolongs the life of your engine, enhances fuel efficiency, and reduces harmful emissions",
    detail: "The Premium filtration media has the capacity to hold up to 14 grams of dirt* in the filter media",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Oil Filters"],
  },
  {
    sku: "AG0444",
    slug: "bosch-oil-filter-1034",
    thumbnail: "/file/listings/AG0444.png",
    name: "BOSCH OIL FILTER - 1034",
    description: "Oil Filter",
    information: "Bosch Premium Oil Filters utilize an exclusive blend of cellulose and glass fiber materials in the media for superior oil filtration and greater capacity for engine protection. With 99% efficiency*, the Bosch Premium Oil Filter traps both large and microscopic particles from entering the engine",
    detail: "BOSCH is committed to ensuring safety through its outstanding product quality, offering high-quality materials, precise processing, and rigorous quality checks that meet the same standards as original equipment (OE) parts. With expertise in developing and producing diesel and Denoxtronic injection systems, Bosch understands how to safeguard these crucial components. Providing a complete range of products from a single source, Bosch offers a comprehensive program that is continuously updated, making handling and ordering a breeze. Additionally, Bosch ensures high availability of goods through its extensive international sales and logistics network, ensuring prompt access to its parts. As a strategic partner for the future, Bosch offers diagnostic solutions, training courses, and seminars for wholesalers and workshops, solidifying its commitment to staying at the forefront of automotive innovation and excellence",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Oil Filters"],
  },
  {
    sku: "AG0443",
    slug: "bosch-oil-filter-1039",
    thumbnail: "/file/listings/bosch-placeholder.png",
    name: "BOSCH OIL FILTER - 1039",
    description: "Oil Filter",
    information: "Bosch Premium Oil Filters utilize an exclusive blend of cellulose and glass fiber materials in the media for superior oil filtration and greater capacity for engine protection. With 99% efficiency*, the Bosch Premium Oil Filter traps both large and microscopic particles from entering the engine",
    detail: "BOSCH is committed to ensuring safety through its outstanding product quality, offering high-quality materials, precise processing, and rigorous quality checks that meet the same standards as original equipment (OE) parts. With expertise in developing and producing diesel and Denoxtronic injection systems, Bosch understands how to safeguard these crucial components. Providing a complete range of products from a single source, Bosch offers a comprehensive program that is continuously updated, making handling and ordering a breeze. Additionally, Bosch ensures high availability of goods through its extensive international sales and logistics network, ensuring prompt access to its parts. As a strategic partner for the future, Bosch offers diagnostic solutions, training courses, and seminars for wholesalers and workshops, solidifying its commitment to staying at the forefront of automotive innovation and excellence",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Oil Filters"],
  },
  {
    sku: "AG0470",
    slug: "bosch-oil-filter-1041",
    thumbnail: "/file/listings/AG0470.png",
    name: "BOSCH OIL FILTER - 1041",
    description: "Oil Filter",
    information: "Bosch Premium Oil Filters utilize an exclusive blend of cellulose and glass fiber materials in the media for superior oil filtration and greater capacity for engine protection. With 99% efficiency*, the Bosch Premium Oil Filter traps both large and microscopic particles from entering the engine",
    detail: "BOSCH is committed to ensuring safety through its outstanding product quality, offering high-quality materials, precise processing, and rigorous quality checks that meet the same standards as original equipment (OE) parts. With expertise in developing and producing diesel and Denoxtronic injection systems, Bosch understands how to safeguard these crucial components. Providing a complete range of products from a single source, Bosch offers a comprehensive program that is continuously updated, making handling and ordering a breeze. Additionally, Bosch ensures high availability of goods through its extensive international sales and logistics network, ensuring prompt access to its parts. As a strategic partner for the future, Bosch offers diagnostic solutions, training courses, and seminars for wholesalers and workshops, solidifying its commitment to staying at the forefront of automotive innovation and excellence",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Oil Filters"],
  },
  {
    sku: "AG0439",
    slug: "bosch-oil-filter-1042",
    thumbnail: "/file/listings/bosch-placeholder.png",
    name: "BOSCH OIL FILTER - 1042",
    description: "Oil Filter",
    information: "Bosch Premium Oil Filters utilize an exclusive blend of cellulose and glass fiber materials in the media for superior oil filtration and greater capacity for engine protection. With 99% efficiency*, the Bosch Premium Oil Filter traps both large and microscopic particles from entering the engine",
    detail: "BOSCH is committed to ensuring safety through its outstanding product quality, offering high-quality materials, precise processing, and rigorous quality checks that meet the same standards as original equipment (OE) parts. With expertise in developing and producing diesel and Denoxtronic injection systems, Bosch understands how to safeguard these crucial components. Providing a complete range of products from a single source, Bosch offers a comprehensive program that is continuously updated, making handling and ordering a breeze. Additionally, Bosch ensures high availability of goods through its extensive international sales and logistics network, ensuring prompt access to its parts. As a strategic partner for the future, Bosch offers diagnostic solutions, training courses, and seminars for wholesalers and workshops, solidifying its commitment to staying at the forefront of automotive innovation and excellence",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Oil Filters"],
  },
  {
    sku: "AG0442",
    slug: "bosch-oil-filter-1043",
    thumbnail: "/file/listings/bosch-placeholder.png",
    name: "BOSCH OIL FILTER - 1043",
    description: "Oil Filter",
    information: "Bosch Premium Oil Filters utilize an exclusive blend of cellulose and glass fiber materials in the media for superior oil filtration and greater capacity for engine protection. With 99% efficiency*, the Bosch Premium Oil Filter traps both large and microscopic particles from entering the engine",
    detail: "BOSCH is committed to ensuring safety through its outstanding product quality, offering high-quality materials, precise processing, and rigorous quality checks that meet the same standards as original equipment (OE) parts. With expertise in developing and producing diesel and Denoxtronic injection systems, Bosch understands how to safeguard these crucial components. Providing a complete range of products from a single source, Bosch offers a comprehensive program that is continuously updated, making handling and ordering a breeze. Additionally, Bosch ensures high availability of goods through its extensive international sales and logistics network, ensuring prompt access to its parts. As a strategic partner for the future, Bosch offers diagnostic solutions, training courses, and seminars for wholesalers and workshops, solidifying its commitment to staying at the forefront of automotive innovation and excellence",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Oil Filters"],
  },
  {
    sku: "AG0445",
    slug: "bosch-oil-filter-1067",
    thumbnail: "/file/listings/AG0445.png",
    name: "BOSCH OIL FILTER - 1067",
    description: "Oil Filter",
    information: "Bosch Premium Oil Filters utilize an exclusive blend of cellulose and glass fiber materials in the media for superior oil filtration and greater capacity for engine protection. With 99% efficiency*, the Bosch Premium Oil Filter traps both large and microscopic particles from entering the engine",
    detail: "BOSCH is committed to ensuring safety through its outstanding product quality, offering high-quality materials, precise processing, and rigorous quality checks that meet the same standards as original equipment (OE) parts. With expertise in developing and producing diesel and Denoxtronic injection systems, Bosch understands how to safeguard these crucial components. Providing a complete range of products from a single source, Bosch offers a comprehensive program that is continuously updated, making handling and ordering a breeze. Additionally, Bosch ensures high availability of goods through its extensive international sales and logistics network, ensuring prompt access to its parts. As a strategic partner for the future, Bosch offers diagnostic solutions, training courses, and seminars for wholesalers and workshops, solidifying its commitment to staying at the forefront of automotive innovation and excellence",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Oil Filters"],
  },
  {
    sku: "AG0437",
    slug: "bosch-oil-filter-1190",
    thumbnail: "/file/listings/bosch-placeholder.png",
    name: "BOSCH OIL FILTER - 1190",
    description: "Oil Filter",
    information: "Bosch Premium Oil Filters utilize an exclusive blend of cellulose and glass fiber materials in the media for superior oil filtration and greater capacity for engine protection. With 99% efficiency*, the Bosch Premium Oil Filter traps both large and microscopic particles from entering the engine",
    detail: "BOSCH is committed to ensuring safety through its outstanding product quality, offering high-quality materials, precise processing, and rigorous quality checks that meet the same standards as original equipment (OE) parts. With expertise in developing and producing diesel and Denoxtronic injection systems, Bosch understands how to safeguard these crucial components. Providing a complete range of products from a single source, Bosch offers a comprehensive program that is continuously updated, making handling and ordering a breeze. Additionally, Bosch ensures high availability of goods through its extensive international sales and logistics network, ensuring prompt access to its parts. As a strategic partner for the future, Bosch offers diagnostic solutions, training courses, and seminars for wholesalers and workshops, solidifying its commitment to staying at the forefront of automotive innovation and excellence",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Oil Filters"],
  },
  {
    sku: "AG0483",
    slug: "bosch-pure-light-12v-1-1w-286-ag0483",
    thumbnail: "/file/listings/bosch-placeholder.png",
    name: "BOSCH PURE LIGHT 12V 1-1W 286 (AG0483)",
    description: "Automative Bulb",
    information: "The Bosch Pure Light 12V 1-1W 286 is a high-quality automotive bulb designed to provide reliable and energy-efficient lighting for various 12-volt applications. With a power rating of 1-1 watt, this bulb offers a low energy consumption while delivering sufficient brightness for applications like dashboard indicator lights, instrument panel lights, and other automotive lighting needs. The 12V 1-1W bulb type ensures easy compatibility with specific 12-volt bulb requirements for different vehicle models. As part of the Pure Light series from Bosch, this bulb upholds the principals's reputation for superior quality and performance. Crafted with precision and durability, the 12V 1-1W 286 bulb offers a cost-effective lighting solution for automotive applications while ensuring enhanced visibility and safety for drivers during different driving conditions",
    detail: "Voltage: 12V, Wattage: 1-1W",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["12V Bulb"],
  },
  {
    sku: "AG0360",
    slug: "bosch-pure-light-12v-1-2w-286-ag0360",
    thumbnail: "/file/listings/AG0360.png",
    name: "BOSCH PURE LIGHT 12V 1-2W 286 (AG0360)",
    description: "Car Interior light",
    information: "Universal and complete Bosch range in original and premium equipment quality including all common automotive bulbs for quality-oriented customers expecting high performance",
    detail: "Rated Power W: 1 | Socket Type: W2x4 | Voltage V: 12",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["12V Bulb"],
  },
  {
    sku: "AG0493",
    slug: "bosch-pure-light-12v-1-2w-286-tm2",
    thumbnail: "/file/listings/bosch-placeholder.png",
    name: "BOSCH PURE LIGHT 12V 1-2W 286 TM2",
    description: "Automative Bulb",
    information: "The Bosch Pure Light 12V 1-2W 286 TM2 is a high-quality automotive bulb designed to provide efficient and reliable lighting for various 12-volt applications. With a power rating of 1-2 watts, this bulb offers a balanced brightness suitable for applications like dashboard indicator lights, instrument panel lights, and other automotive lighting needs. The 12V 1-2W bulb type ensures easy compatibility with specific 12-volt bulb requirements for different vehicle models. As part of the Pure Light series from Bosch, this bulb upholds the principals's reputation for superior quality and performance. Crafted with precision and durability, the 12V 1-2W 286 TM2 bulb delivers enhanced visibility and safety for drivers during different driving conditions",
    detail: "Voltage: 12V, Wattage: 1-2W",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["12V Bulb"],
  },
  {
    sku: "AG0416",
    slug: "bosch-pure-light-12v-1-2w-286t",
    thumbnail: "/file/listings/AG0416.png",
    name: "BOSCH PURE LIGHT 12V 1-2W 286T",
    description: "Automative Bulb",
    information: "The Bosch Pure Light 12V 1-2W 286T is a high-quality automotive bulb designed to provide efficient and reliable lighting for various 12-volt applications. With a power rating of 1-2 watts, this bulb offers a balanced brightness suitable for applications like dashboard indicator lights, instrument panel lights, and other automotive lighting needs. The 12V 1-2W bulb type ensures easy compatibility with specific 12-volt bulb requirements for different vehicle models. As part of the Pure Light series from Bosch, this bulb upholds the principals's reputation for superior quality and performance. Crafted with precision and durability, the 12V 1-2W 286T bulb delivers enhanced visibility and safety for drivers during different driving conditions",
    detail: "Voltage: 12V, Wattage: 1-2W",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["12V Bulb"],
  },
  {
    sku: "AG0423",
    slug: "bosch-pure-light-12v-1-5w-286",
    thumbnail: "/file/listings/bosch-placeholder.png",
    name: "BOSCH PURE LIGHT 12V 1-5W 286",
    description: "Automative Bulb",
    information: "The Bosch Pure Light 12V 1-5W 286 is a high-quality automotive bulb designed to deliver efficient and reliable lighting for various 12-volt applications. With a power rating of 1-5 watts, this bulb provides a range of brightness options, making it suitable for applications such as dashboard indicator lights, instrument panel lights, and other automotive lighting needs. The 12V 1-5W bulb type ensures easy compatibility with specific 12-volt bulb requirements for different vehicle models. As part of the Pure Light series from Bosch, this bulb upholds the principals's reputation for superior quality and performance. Crafted with precision and durability, the 12V 1-5W 286 bulb offers enhanced visibility and safety for drivers during different driving conditions",
    detail: "Voltage: 12V, Wattage: 1-5W",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["12V Bulb"],
  },
  {
    sku: "AG0435",
    slug: "bosch-pure-light-12v-10w-269",
    thumbnail: "/file/listings/bosch-placeholder.png",
    name: "BOSCH PURE LIGHT 12V 10W 269",
    description: "Automative Bulb",
    information: "The Bosch Pure Light 12V 10W 269 is a high-quality automotive bulb that offers reliable and efficient lighting for various 12-volt vehicle applications. With a power rating of 10 watts, this bulb provides sufficient brightness for applications such as side marker lights, license plate lights, and other automotive lighting needs. The 12V 10W bulb type ensures easy compatibility with specific 12-volt bulb requirements for different vehicle models. Bosch, a trusted name in the automotive industry, ensures the highest standards of quality and performance in their products, and the Pure Light 12V 10W 269 bulb lives up to this reputation. Built with precision and durability, this bulb guarantees improved visibility and safety for drivers during various driving conditions",
    detail: "Voltage: 12V, Wattage: 10W",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["12V Bulb"],
  },
  {
    sku: "AG0419",
    slug: "bosch-pure-light-12v-10w-272",
    thumbnail: "/file/listings/bosch-placeholder.png",
    name: "BOSCH PURE LIGHT 12V 10W 272",
    description: "Automative Bulb",
    information: "The Bosch Pure Light 12V 10W 272 is a high-quality automotive bulb designed to deliver reliable and efficient lighting for various 12-volt vehicle applications. With a power rating of 10 watts, this bulb provides adequate brightness for applications such as side marker lights, license plate lights, and other automotive lighting needs. The 12V 10W bulb type ensures easy compatibility with specific 12-volt bulb requirements for different vehicle models. Bosch, a trusted name in the automotive industry, guarantees the highest standards of quality and performance in their products, and the Pure Light 12V 10W 272 bulb lives up to this reputation. Crafted with precision and durability, this bulb ensures enhanced visibility and safety for drivers during various driving conditions",
    detail: "Voltage: 12V, Wattage: 10W",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["12V Bulb"],
  },
  {
    sku: "AG0517",
    slug: "bosch-pure-light-12v-10w-433b",
    thumbnail: "/file/listings/bosch-placeholder.png",
    name: "BOSCH PURE LIGHT 12V 10W 433B",
    description: "Automative Bulb",
    information: "The Bosch Pure Light 12V 10W 433B is a high-quality automotive bulb designed to provide reliable and efficient lighting for various 12-volt vehicle applications. With a power rating of 10 watts, this bulb offers sufficient brightness for applications such as side marker lights, license plate lights, and other automotive lighting needs. The 12V 10W bulb type ensures easy compatibility with specific 12-volt bulb requirements for different vehicle models. Bosch, a trusted name in the automotive industry, ensures the highest standards of quality and performance in their products, and the Pure Light 12V 10W 433B bulb is no exception. This bulb is crafted with precision and durability to deliver enhanced visibility and safety for drivers during different driving conditions",
    detail: "Voltage: 12V, Wattage: 10W",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["12V Bulb"],
  },
  {
    sku: "AG0481",
    slug: "bosch-pure-light-12v-15w-267",
    thumbnail: "/file/listings/bosch-placeholder.png",
    name: "BOSCH PURE LIGHT 12V 15W 267",
    description: "Automative Bulb",
    information: "The Bosch Pure Light 12V 15W 267 is a reliable and energy-efficient automotive bulb designed to provide clear and bright illumination for various 12-volt vehicle applications. With a power rating of 15 watts, this bulb offers sufficient lighting for parking lights, interior lights, and other applications that require 12V 15W bulbs. The 12V 15W bulb type ensures easy compatibility with specific 12-volt bulb requirements for different vehicle models. Bosch, a renowned name in the automotive industry, ensures the highest standards of quality and performance in their products, and the Pure Light 12V 15W 267 bulb is no exception. This bulb is crafted with precision and durability to deliver enhanced visibility and safety for drivers during different driving conditions",
    detail: "Voltage: 12V, Wattage: 15W",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["12V Bulb"],
  },
  {
    sku: "AG0478",
    slug: "bosch-pure-light-12v-18w-270",
    thumbnail: "/file/listings/AG0478.png",
    name: "BOSCH PURE LIGHT 12V 18W 270",
    description: "Automative Bulb",
    information: "Suitable for all types of vehicles with different needs",
    detail: "Delivering a good price-performance ratio: The Bosch Pure Light Automotive Bulb range consists of reliable, universal replacement bulbs that always provide an outstanding performance",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["12V Bulb"],
  },
  {
    sku: "AG0364",
    slug: "bosch-pure-light-12v-18w-ba15s",
    thumbnail: "/file/listings/bosch-placeholder.png",
    name: "BOSCH PURE LIGHT 12V 18W BA15s",
    description: "Automative Bulb",
    information: "The Bosch Pure Light 12V 18W BA15s is a high-quality automotive bulb designed to provide reliable and efficient lighting for various 12-volt vehicle applications. With a power rating of 18 watts, this bulb offers a bright and clear illumination, making it suitable for use as indicator lights, reverse lights, and other applications where 12V 18W BA15s bulbs are required. The BA15s bulb type ensures a precise fit and easy installation, making it compatible with specific 12-volt bulb requirements for different vehicle models. Bosch, a trusted name in the automotive industry, ensures the highest standards of quality and performance in their products, and the Pure Light 12V 18W BA15s is no exception. This bulb is built with precision and durability to provide drivers with enhanced visibility and safety on the road during various driving conditions",
    detail: "Voltage: 12V, Wattage: 18W",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["12V Bulb"],
  },
  {
    sku: "AG0502",
    slug: "bosch-pure-light-12v-21w-382r-red",
    thumbnail: "/file/listings/bosch-placeholder.png",
    name: "BOSCH PURE LIGHT 12V 21W 382R RED",
    description: "Automative Bulb",
    information: "The Bosch Pure Light 12V 21W 382R Red is a premium automotive bulb designed to deliver vibrant and reliable red-colored illumination for various 12-volt vehicle applications. With a power rating of 21 watts, this bulb offers a bright and efficient lighting solution with a distinctive red hue, making it ideal for use as brake lights, tail lights, indicator lights, or any application where red lighting is required. The 382R bulb type is specifically designed to fit certain 12-volt bulb requirements for different vehicle models, ensuring a precise fit and compatibility. Bosch, a renowned automotive principals, ensures the highest standards of quality and performance in their products, and the Pure Light 12V 21W 382R Red is no exception. This bulb is built with precision and durability to provide drivers with enhanced visibility and safety on the road during low-light conditions or when signaling other drivers",
    detail: "Voltage: 12V, Wattage: 21W",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["12V Bulb"],
  },
  {
    sku: "AG0422",
    slug: "bosch-pure-light-12v-21w-382y-amber",
    thumbnail: "/file/listings/bosch-placeholder.png",
    name: "BOSCH PURE LIGHT 12V 21W 382Y Amber",
    description: "Automative Bulb",
    information: "The Bosch Pure Light 12V 21W 382Y Amber is a high-quality automotive bulb designed to provide reliable and vibrant amber-colored illumination for various 12-volt vehicle applications. With a power rating of 21 watts, this bulb offers a bright and efficient lighting solution with an amber hue, making it ideal for use as turn signal lights, indicator lights, or any application where amber lighting is required. The 382Y bulb type is specifically designed to fit certain 12-volt bulb requirements for different vehicle models, ensuring a precise fit and compatibility. Bosch is a trusted automotive principals known for its commitment to quality, and the Pure Light 12V 21W 382Y Amber is no exception. It is built with precision and adheres to the principals's high standards of performance, providing drivers with enhanced visibility and safety on the road during daytime or low-visibility conditions",
    detail: "Voltage: 12V, Wattage: 21W",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["12V Bulb"],
  },
  {
    sku: "AG0484",
    slug: "bosch-pure-light-12v-21w-921",
    thumbnail: "/file/listings/bosch-placeholder.png",
    name: "BOSCH PURE LIGHT 12V 21W 921",
    description: "Automative Bulb",
    information: "The Bosch Pure Light 12V 21W 921 is a high-quality automotive bulb designed to provide reliable illumination for various 12-volt vehicle applications. With a power rating of 21 watts, this bulb offers a bright and efficient lighting solution. The 921 bulb type is specifically designed to fit certain 12-volt bulb requirements for different vehicle models, ensuring a precise fit and compatibility. Whether used as a signal light, brake light, or interior light, the Bosch Pure Light 12V 21W 921 delivers excellent visibility and safety on the road, making it a trusted choice for drivers seeking reliable automotive lighting solutions",
    detail: "Voltage: 12V, Wattage: 21W",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["12V Bulb"],
  },
  {
    sku: "AG0518",
    slug: "bosch-pure-light-12v-24w-810",
    thumbnail: "/file/listings/bosch-placeholder.png",
    name: "BOSCH PURE LIGHT 12V 24W 810",
    description: "Automative Bulb",
    information: "The Bosch Pure Light 12V 24W (810) is a high-quality automotive bulb designed to deliver bright and reliable illumination for various 12-volt vehicle applications. With a power rating of 24 watts, this bulb offers a powerful and efficient lighting solution. The 810 bulb type is specifically designed to fit certain 12-volt bulb requirements for different vehicle models, ensuring a precise fit and compatibility. Whether used as a signal light, brake light, or interior light, the Bosch Pure Light 12V 24W (810) provides excellent visibility and safety on the road, making it a trusted choice for drivers seeking reliable automotive lighting solutions",
    detail: "Voltage: 12V, Wattage: 24W",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["12V Bulb"],
  },
  {
    sku: "AG0466",
    slug: "bosch-pure-light-12v-2w-281",
    thumbnail: "/file/listings/bosch-placeholder.png",
    name: "BOSCH PURE LIGHT 12V 2W (281)",
    description: "Automative Bulb",
    information: "The Bosch Pure Light 12V 2W (281) is a reliable and energy-efficient automotive bulb designed to provide clear and consistent illumination for various 12-volt vehicle applications. This bulb operates at 12 volts and has a power rating of 2 watts, ensuring efficient performance while consuming minimal energy. The 281 bulb type is specifically designed to fit certain 12-volt bulb requirements for various vehicle models, ensuring a precise fit and compatibility. Whether used as a signal light, indicator light, or interior light, the Bosch Pure Light 12V 2W (281) offers dependable lighting performance, enhancing visibility and safety on the road",
    detail: "Voltage: 12V, Wattage: 2W",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["12V Bulb"],
  },
  {
    sku: "AG0480",
    slug: "bosch-pure-light-12v-2w-286-tm3",
    thumbnail: "/file/listings/bosch-placeholder.png",
    name: "BOSCH PURE LIGHT 12V 2W 286 TM3",
    description: "Automative Bulb",
    information: "The Bosch Pure Light 12V 2W 286 TM3 is a high-quality automotive bulb designed to deliver reliable and clear illumination for various 12-volt vehicle applications. This bulb operates at 12 volts and has a power rating of 2 watts, ensuring efficient performance while conserving energy. The 286 TM3 bulb type is specifically designed to fit certain 12-volt bulb requirements for various vehicle models, ensuring a precise fit and compatibility. Whether used as a signal light, indicator light, or interior light, the Bosch Pure Light 12V 2W 286 TM3 provides consistent and dependable lighting performance, enhancing visibility and safety on the road",
    detail: "Voltage: 12V, Wattage: 2W",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["12V Bulb"],
  },
  {
    sku: "AG0477",
    slug: "bosch-pure-light-12v-2w-510",
    thumbnail: "/file/listings/bosch-placeholder.png",
    name: "BOSCH PURE LIGHT 12V 2W 510",
    description: "Automative Bulb",
    information: "The Bosch Pure Light 12V 2W 510 is a reliable and efficient automotive bulb designed to provide clear and bright illumination for various 12-volt vehicle applications. This bulb operates at 12 volts and has a power rating of 2 watts, ensuring optimal performance while conserving energy. The 510 bulb type ensures a precise fit and compatibility with specific 12-volt bulb requirements for various vehicle models. Whether used as a signal light, indicator light, or interior light, the Bosch Pure Light 12V 2W 510 delivers consistent and dependable lighting performance, enhancing visibility and safety on the road",
    detail: "Voltage: 12V, Wattage: 2W",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["12V Bulb"],
  },
  {
    sku: "AG0476",
    slug: "bosch-pure-light-12v-2w-924",
    thumbnail: "/file/listings/bosch-placeholder.png",
    name: "BOSCH PURE LIGHT 12V 2W 924",
    description: "Automative Bulb",
    information: "The Bosch Pure Light 12V 2W 924 is a high-quality automotive bulb designed to deliver reliable and efficient lighting for various 12-volt vehicle applications. This bulb operates at 12 volts and has a power rating of 2 watts, providing clear and bright illumination for improved visibility. The 924 bulb type ensures a precise fit and compatibility with specific 12-volt bulb requirements for various vehicle models. Whether used as a signal light, indicator light, or interior light, the Bosch Pure Light 12V 2W 924 offers dependable performance and is a great choice for drivers seeking enhanced visibility and safety on the road",
    detail: "Voltage: 12V, Wattage: 2W",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["12V Bulb"],
  },
  {
    sku: "AG0415",
    slug: "bosch-pure-light-12v-2w-ba9s",
    thumbnail: "/file/listings/bosch-placeholder.png",
    name: "BOSCH PURE LIGHT 12V 2W BA9s",
    description: "Automative Bulb",
    information: "The Bosch Pure Light 12V 2W BA9s is a high-quality automotive bulb designed to provide efficient and reliable lighting for various 12-volt vehicle applications. This bulb operates at 12 volts and has a power rating of 2 watts, delivering clear and bright illumination for improved visibility. The BA9s bulb type ensures a precise fit and compatibility with specific 12-volt bulb requirements for various vehicle models. Whether used as a signal light, indicator light, or interior light, the Bosch Pure Light 12V 2W BA9s is a dependable choice for drivers seeking enhanced visibility and safety on the road",
    detail: "Voltage: 12V, Wattage: 2W",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["12V Bulb"],
  },
  {
    sku: "AG0467",
    slug: "bosch-pure-light-12v-3w-257",
    thumbnail: "/file/listings/bosch-placeholder.png",
    name: "BOSCH PURE LIGHT 12V 3W (257)",
    description: "Automative Bulb",
    information: "The Bosch Pure Light 12V 3W (257) is a high-quality automotive bulb designed to provide reliable lighting for various 12-volt vehicle applications. This bulb operates at 12 volts and has a power rating of 3 watts, delivering clear and efficient illumination for improved visibility. The 257 bulb type ensures a precise fit and compatibility with specific 12-volt bulb requirements for various vehicle models. Whether used as a signal light, indicator light, or interior light, the Bosch Pure Light 12V 3W (257) is a dependable choice for drivers seeking enhanced visibility and safety on the road",
    detail: "Voltage: 12V, Wattage: 3W",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["12V Bulb"],
  },
  {
    sku: "AG0420",
    slug: "bosch-pure-light-12v-5w-989",
    thumbnail: "/file/listings/bosch-placeholder.png",
    name: "BOSCH PURE LIGHT 12V 5W 989",
    description: "Automative Bulb",
    information: "The Bosch Pure Light 12V 5W 989 is a high-quality automotive bulb designed to provide reliable lighting for various 12-volt vehicle applications. This bulb operates at 12 volts and has a power rating of 5 watts, delivering clear and efficient illumination for improved visibility. The 989 bulb type ensures a precise fit and compatibility with specific 12-volt bulb requirements for various vehicle models. Whether used as a signal light, indicator light, or interior light, the Bosch Pure Light 12V 5W 989 is a dependable choice for drivers seeking enhanced visibility and safety on the road",
    detail: "Voltage: 12V, Wattage: 5W",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["12V Bulb"],
  },
  {
    sku: "AG0418",
    slug: "bosch-pure-light-12v-5w-sv8-5-8",
    thumbnail: "/file/listings/bosch-placeholder.png",
    name: "BOSCH PURE LIGHT 12V 5W SV8- 5-8",
    description: "Automative Bulb",
    information: "The Bosch Pure Light 12V 5W SV8- 5-8 is a high-quality automotive bulb designed to provide efficient and reliable lighting for various 12-volt vehicle applications. This bulb operates at 12 volts and has a power rating of 5 watts, delivering clear and bright illumination for optimal visibility. The SV8-5-8 base type ensures a precise fit and easy installation, making it compatible with specific 12-volt bulb requirements for various vehicle models. Whether used as a signal light, indicator light, or interior light, the Bosch Pure Light 12V 5W SV8- 5-8 is a dependable choice for drivers seeking enhanced visibility and safety on the road",
    detail: "Voltage: 12V, Wattage: 5W",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["12V Bulb"],
  },
  {
    sku: "AG0519",
    slug: "bosch-pure-light-6v-10w-258",
    thumbnail: "/file/listings/bosch-placeholder.png",
    name: "BOSCH PURE LIGHT 6V 10W - 258",
    description: "PN:1987302612-7VW",
    information: "The Bosch Pure Light 6V 10W - 258 is a high-quality automotive bulb designed to provide reliable and efficient lighting for various 6-volt vehicle applications. This bulb operates at 6 volts and has a power rating of 10 watts, delivering clear and bright illumination for optimal visibility. The 258 base type ensures a precise fit and easy installation, making it compatible with specific 6-volt bulb requirements for various vehicle models. As a trusted automotive principals, Bosch guarantees the quality and durability of the Pure Light 6V 10W - 258, ensuring it performs consistently under different driving conditions. Whether used as a signal light, indicator light, or interior light, the Bosch Pure Light 6V 10W - 258 is a dependable choice for drivers seeking enhanced visibility and safety on the road",
    detail: "Voltage: 6V, Wattage: 10W",
    uom: "PIECE(S)",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["6V Bulb"],
  },
  {
    sku: "AG0367",
    slug: "bosch-pure-light-bax9s",
    thumbnail: "/file/listings/bosch-placeholder.png",
    name: "BOSCH PURE LIGHT BAX9s",
    description: "Automative Bulb",
    information: "The Bosch Pure Light BAX9s is a high-quality automotive bulb designed to provide reliable and efficient lighting for various vehicle applications. Operating at 12 volts, this bulb delivers clear and bright illumination, making it suitable for a range of automotive lighting fixtures. The BAX9s base type ensures a precise fit and easy installation, ensuring compatibility with specific vehicle models. Bosch, a trusted and reputable automotive principals, guarantees the quality and durability of the Pure Light BAX9s, making it a reliable choice for drivers seeking superior visibility and safety on the road. Whether used as a signal light, indicator light, or interior light, the Bosch Pure Light BAX9s offers consistent and dependable performance, making it an excellent option for enhancing visibility during nighttime or low-light driving conditions",
    detail: "Application: Signal lights, Indicator lights, Interior lights",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["12V Bulb"],
  },
  {
    sku: "AG0479",
    slug: "bosch-pure-light-c21w-12v-21w-273",
    thumbnail: "/file/listings/bosch-placeholder.png",
    name: "BOSCH PURE LIGHT C21W 12V 21W 273",
    description: "Automative Bulb",
    information: "The Bosch Pure Light C21W is a high-quality automotive bulb designed to deliver reliable and bright illumination for various vehicle applications. Operating at 12 volts and with a power rating of 21 watts, this bulb provides efficient and clear lighting on the road. The C21W base type makes it suitable for specific automotive lighting fixtures. Bosch's Pure Light C21W is crafted with precision and adheres to the principals's high standards of quality and durability. It is designed for easy installation, ensuring compatibility with various vehicle models. Whether used as a brake light, indicator light, or tail light, the Bosch Pure Light C21W offers consistent and dependable performance for enhanced safety during driving. As a reputable automotive principals, Bosch ensures that the Pure Light C21W meets the stringent demands of vehicle lighting, making it an excellent choice for drivers seeking reliable and long-lasting automotive bulbs",
    detail: "Voltage: 12V, Wattage: 21W",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["12V Bulb"],
  },
  {
    sku: "AG0353",
    slug: "bosch-pure-light-hir2-12v-55w",
    thumbnail: "/file/listings/AG0353.png",
    name: "BOSCH PURE LIGHT HIR2 (12V 55W)",
    description: "Automative Bulb",
    information: "The Bosch Pure Light HIR2 is a high-quality automotive bulb designed to provide exceptional lighting performance for various vehicle applications. Operating at 12 volts and with a power rating of 55 watts, this bulb delivers bright and clear illumination on the road. The HIR2 base type makes it suitable for specific automotive lighting fixtures. Bosch has incorporated advanced Halogen Infrared Reflective (HIR) technology into this bulb, enhancing its efficiency and brightness compared to standard halogen bulbs. This technology allows the bulb to produce more light while consuming the same amount of power, resulting in improved visibility and safety for drivers. The Bosch Pure Light HIR2 is designed for a precise fit and easy installation, ensuring compatibility with various vehicle models. As with all Bosch products, the Pure Light HIR2 adheres to the principals's high standards of quality and durability, making it a reliable choice for exceptional lighting performance",
    detail: "Voltage: 12V, Wattage: 55W",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["12V Bulb"],
  },
  {
    sku: "AG0365",
    slug: "bosch-pure-light-p214w-12v",
    thumbnail: "/file/listings/AG0365.png",
    name: "BOSCH PURE LIGHT P21/4W 12V",
    description: "Automative Bulb",
    information: "The Bosch Pure Light P21/4W is a high-quality automotive bulb designed to provide efficient and reliable lighting for specific vehicle applications. Operating at 12 volts, this bulb features a power rating of 21/4 watts. The P21/4W base type makes it suitable for certain automotive lighting fixtures. The Bosch Pure Light P21/4W is commonly used for brake lights and tail lights, where it delivers a dual-function light output - 4 watts for parking or running lights and 21 watts for brake lights. This bulb is engineered for a precise fit and easy installation, ensuring compatibility with various vehicle models. As with all Bosch products, the Pure Light P21/4W adheres to Bosch's high standards of quality and durability, providing long-lasting and dependable performance",
    detail: "Voltage: 12V, Wattage: 21/4W (dual-function: 4W for parking lights, 21W for brake lights)",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["12V Bulb"],
  },
  {
    sku: "AG0354",
    slug: "bosch-pure-light-p215w",
    thumbnail: "/file/listings/AG0354.png",
    name: "BOSCH PURE LIGHT P21/5W",
    description: "Automative Bulb",
    information: "The Bosch Pure Light P21/5W is a high-quality automotive bulb designed to provide reliable and efficient lighting for specific vehicle applications. Operating at 12 volts with a power rating of 21/5 watts, this bulb features the P21/5W base type, making it suitable for certain automotive lighting fixtures. The P21/5W bulb is commonly used for brake lights and tail lights, where it delivers a dual-function light output - 5 watts for parking or running lights and 21 watts for brake lights. The Bosch Pure Light P21/5W is engineered for a precise fit and easy installation, ensuring compatibility with various vehicle models. As with all Bosch products, the Pure Light P21/5W is built to Bosch's high standards of quality and durability, ensuring long-lasting and dependable performance",
    detail: "Voltage: 12V, Wattage: 21/5W (dual-function: 5W for parking lights, 21W for brake lights)",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["12V Bulb"],
  },
  {
    sku: "AG0485",
    slug: "bosch-pure-light-p21w-12v-21w-335",
    thumbnail: "/file/listings/bosch-placeholder.png",
    name: "BOSCH PURE LIGHT P21W 12V 21W 335",
    description: "Automative Bulb",
    information: "The Bosch Pure Light P21W is a high-quality automotive bulb designed to provide reliable and efficient lighting for specific vehicle applications. Operating at 12 volts with a power rating of 21 watts, this bulb features the P21W base type, making it suitable for certain automotive lighting fixtures. The P21W bulb is commonly used for brake lights, tail lights, and other rear lighting applications, where it delivers a bright and focused light output. The Bosch Pure Light P21W is engineered for a precise fit and easy installation, ensuring compatibility with various vehicle models. As with all Bosch products, the Pure Light P21W is built to Bosch's high standards of quality and durability, ensuring long-lasting and dependable performance",
    detail: "Voltage: 12V, Wattage: 21W",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["12V Bulb"],
  },
  {
    sku: "AG0486",
    slug: "bosch-pure-light-p21w-6v-21w",
    thumbnail: "/file/listings/bosch-placeholder.png",
    name: "BOSCH PURE LIGHT P21W 6V 21W",
    description: "Automative Bulb",
    information: "The Bosch Pure Light P21W is a high-quality automotive bulb designed to provide reliable and efficient lighting for specific vehicle applications. Operating at 6 volts with a power rating of 21 watts, this bulb features the P21W base type, making it suitable for certain automotive lighting fixtures. The P21W bulb is commonly used for brake lights, tail lights, and other rear lighting applications, where it delivers a bright and focused light output. The Bosch Pure Light P21W is engineered for a precise fit and easy installation, ensuring compatibility with various vehicle models. As with all Bosch products, the Pure Light P21W is built to Bosch's high standards of quality and durability, ensuring long-lasting and dependable performance",
    detail: "Voltage: 6V, Wattage: 21W",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["6V Bulb"],
  },
  {
    sku: "AG0412",
    slug: "bosch-pure-light-p27w-12v-278w",
    thumbnail: "/file/listings/bosch-placeholder.png",
    name: "BOSCH PURE LIGHT P27W- 12V 27/8W",
    description: "Automative Bulb",
    information: "The Bosch Pure Light P27W is a reliable and efficient automotive bulb designed to provide optimal lighting for specific vehicle applications. Operating at 12 volts with a power rating of 27/8 watts, this bulb features the P27W base type, making it suitable for specific automotive lighting fixtures. The P27W bulb is commonly used for brake lights and other rear lighting applications, where it delivers a bright and focused light output. Its dual wattage design allows it to function as both a 27-watt and 8-watt bulb, providing versatility for different lighting needs. The Bosch Pure Light P27W is engineered for a precise fit and easy installation, ensuring compatibility with various vehicle models. As with all Bosch products, the Pure Light P27W is built to Bosch's high standards of quality and durability, ensuring long-lasting and dependable performance",
    detail: "Voltage: 12V, Wattage: 27/8W",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["12V Bulb"],
  },
  {
    sku: "AG0363",
    slug: "bosch-pure-light-py21w-12v-21w",
    thumbnail: "/file/listings/bosch-placeholder.png",
    name: "BOSCH PURE LIGHT PY21W- 12V 21W",
    description: "Automative Bulb",
    information: "The Bosch Pure Light PY21W is a high-quality automotive bulb designed to provide reliable and efficient lighting for various vehicle applications. Operating at 12 volts with a power rating of 21 watts, this bulb features the PY21W base type, making it suitable for specific automotive lighting fixtures. The PY21W bulb is commonly used for turn signal lights, indicator lights, and other front and rear lighting applications. It offers a bright and focused light output, ensuring excellent visibility and safety on the road. The Bosch Pure Light PY21W is engineered for a precise fit and easy installation, making it compatible with a wide range of vehicle models. As with all Bosch products, the Pure Light PY21W is built to Bosch's high standards of quality and durability, ensuring long-lasting and dependable performance",
    detail: "Voltage: 12V, Wattage: 21W",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["12V Bulb"],
  },
  {
    sku: "AG0357",
    slug: "bosch-pure-light-r10w",
    thumbnail: "/file/listings/AG0357.png",
    name: "BOSCH PURE LIGHT R10W",
    description: "Automative Bulb",
    information: "The BOSCH PURE LIGHT R10W is a high-quality automotive bulb designed for various lighting applications in vehicles. It offers excellent illumination with its 10W power rating, providing enhanced visibility and safety on the road. This bulb is built with reliable materials and precise manufacturing, ensuring long-lasting performance and consistent brightness. Whether used as a brake light, tail light, or indicator, the BOSCH PURE LIGHT R10W delivers dependable lighting solutions for your car",
    detail: "Delivering a good price-performance ratio: The Bosch Pure Light Automotive Bulb range consists of reliable, universal replacement bulbs that always provide an outstanding performance",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["12V Bulb"],
  },
  {
    sku: "AG0255",
    slug: "bosch-pure-light-r10w-6v-10w",
    thumbnail: "/file/listings/bosch-placeholder.png",
    name: "BOSCH Pure Light R10W 6V 10W",
    description: "Automative Bulb",
    information: "The Bosch Pure Light R10W is a high-quality automotive bulb designed to provide reliable and efficient lighting for various vehicle applications. Operating at 6 volts with a power rating of 10 watts, this bulb features the R10W base type, making it suitable for specific automotive lighting fixtures. The R10W bulb is commonly used for applications like tail lights, reverse lights, and other rear lighting fixtures. It offers a bright and focused light output, ensuring excellent visibility and safety on the road. The Bosch Pure Light R10W is engineered for a precise fit and easy installation, making it compatible with a wide range of vehicle models. As with all Bosch products, the Pure Light R10W is built to Bosch's high standards of quality and durability, ensuring long-lasting and dependable performance",
    detail: "Voltage: 6V, Wattage: 10W",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["6V Bulb"],
  },
  {
    sku: "AG0475",
    slug: "bosch-pure-light-r2-halogen-12v-4540w-410h",
    thumbnail: "/file/listings/AG0475.png",
    name: "BOSCH PURE LIGHT R2 HALOGEN- 12V 45/40W 410H",
    description: "Reliable and efficient automotive bulb,",
    information: "The BOSCH PURE LIGHT R2 HALOGEN is a high-quality automotive bulb designed for 12V electrical systems. With its dual filament, it provides both 45W for high beam and 40W for low beam, offering powerful and reliable lighting performance on the road. The 410H base type ensures easy and secure installation in compatible headlamp units. This halogen bulb delivers bright and focused light, enhancing visibility during nighttime driving and adverse weather conditions. Built with precision and using top-notch materials, the BOSCH PURE LIGHT R2 HALOGEN guarantees durability and consistent illumination, making it a trustworthy choice for a variety of automotive applications",
    detail: "Delivering a good price-performance ratio: The Bosch Pure Light Automotive Bulb range consists of reliable, universal replacement bulbs that always provide an outstanding performance",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["12V Bulb"],
  },
  {
    sku: "AG0414",
    slug: "bosch-pure-light-r2-12v-4540w",
    thumbnail: "/file/listings/AG0414.png",
    name: "BOSCH PURE LIGHT R2, 12V 45/40W",
    description: "PN: 1987302023",
    information: "The BOSCH PURE LIGHT R2 is a high-quality halogen automotive bulb designed for 12V electrical systems. With its dual filament, it provides both 45W for high beam and 40W for low beam, delivering powerful and reliable lighting performance on the road. Its precise construction and use of top-notch materials ensure durability and long-lasting illumination. The BOSCH PURE LIGHT R2 is engineered to enhance visibility during nighttime driving and adverse weather conditions, offering bright and focused light for safer journeys. It is a trustworthy choice for various automotive applications, providing excellent lighting solutions to drivers seeking reliable performance",
    detail: "Delivering a good price-performance ratio: The Bosch Pure Light Automotive Bulb range consists of reliable, universal replacement bulbs that always provide an outstanding performance",
    uom: "PIECE(S)",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["12V Bulb"],
  },
  {
    sku: "AG0421",
    slug: "bosch-pure-light-r5w-12v-5w-209",
    thumbnail: "/file/listings/bosch-placeholder.png",
    name: "BOSCH PURE LIGHT R5W 12V 5W 209",
    description: "Automative Bulb",
    information: "The Bosch Pure Light R5W is a high-quality automotive bulb designed to provide reliable and efficient lighting for various vehicle applications. Operating at 12 volts with a power rating of 5 watts, this bulb features the R5W base type, making it suitable for specific automotive lighting fixtures. The R5W bulb is commonly used for various applications, including brake lights, tail lights, and other rear lighting fixtures. It offers a bright and focused light output, ensuring excellent visibility and safety on the road. The Bosch Pure Light R5W is engineered for a precise fit and easy installation, making it compatible with a wide range of vehicle models. As with all Bosch products, the Pure Light R5W is built to Bosch's high standards of quality and durability, ensuring long-lasting and dependable performance",
    detail: "Voltage: 12V, Wattage: 5W",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["12V Bulb"],
  },
  {
    sku: "AG0433",
    slug: "bosch-pure-light-r5w-12v-5w",
    thumbnail: "/file/listings/bosch-placeholder.png",
    name: "BOSCH PURE LIGHT R5W- 12V 5W",
    description: "Automative Bulb",
    information: "The Bosch Pure Light R5W is a high-quality automotive bulb designed to provide reliable and efficient lighting for various vehicle applications. Operating at 12 volts with a power rating of 5 watts, this bulb features the R5W base type, making it suitable for specific automotive lighting fixtures. The R5W bulb is commonly used for various applications, including brake lights, tail lights, and other rear lighting fixtures. It offers a bright and focused light output, ensuring excellent visibility and safety on the road. The Bosch Pure Light R5W is engineered for a precise fit and easy installation, making it compatible with a wide range of vehicle models. As with all Bosch products, the Pure Light R5W is built to Bosch's high standards of quality and durability, ensuring long-lasting and dependable performance",
    detail: "Voltage: 12V, Wattage: 5W",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["12V Bulb"],
  },
  {
    sku: "AG0521",
    slug: "bosch-pure-light-s3-6v-15w",
    thumbnail: "/file/listings/bosch-placeholder.png",
    name: "BOSCH PURE LIGHT S3 6V 15W",
    description: "Automative Bulb",
    information: "The Bosch Pure Light S3 is a high-quality automotive bulb designed to deliver reliable and efficient lighting for various vehicle applications. Operating at 6 volts with a power rating of 15 watts, this bulb features the S3 base type, making it suitable for specific automotive lighting fixtures. It offers a bright and focused light output, ensuring excellent visibility and safety on the road. The Bosch Pure Light S3 is commonly used for interior and exterior lighting applications, such as dome lights, map lights, and other interior fixtures. Its precise fit and easy installation make it compatible with a wide range of vehicle models, providing a hassle-free experience for users. As with all Bosch products, the Pure Light S3 is built to Bosch's high standards of quality and durability, ensuring long-lasting and dependable performance",
    detail: "Voltage: 6V, Wattage: 15W",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["6V Bulb"],
  },
  {
    sku: "AG0362",
    slug: "bosch-pure-light-sv8-5-8",
    thumbnail: "/file/listings/bosch-placeholder.png",
    name: "BOSCH PURE LIGHT SV8- 5-8",
    description: "Automative Bulb",
    information: "The Bosch Pure Light SV8-5-8 is a high-quality automotive bulb designed to provide efficient and reliable lighting for various vehicle applications. Operating at 12 volts with a power rating of 10 watts, this bulb features the SV8.5-8 base type, making it suitable for specific automotive lighting fixtures. It offers a bright and focused light output, ensuring excellent visibility and safety on the road. The Bosch Pure Light SV8-5-8 is commonly used for interior and exterior lighting applications, such as dome lights, map lights, and other interior fixtures. Its precise fit and easy installation make it compatible with a wide range of vehicle models, providing a hassle-free experience for users. As with all Bosch products, the Pure Light SV8-5-8 is built to Bosch's high standards of quality and durability, ensuring long-lasting and dependable performance",
    detail: "Voltage: 12V, Wattage: 10W",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["12V Bulb"],
  },
  {
    sku: "AG0361",
    slug: "bosch-pure-light-sv8-5-8-12v-10w",
    thumbnail: "/file/listings/bosch-placeholder.png",
    name: "BOSCH PURE LIGHT SV8- 5-8 (12V 10W)",
    description: "Automative Bulb",
    information: "The Bosch Pure Light SV8-5-8 is a high-quality automotive bulb designed to provide efficient and reliable lighting for various vehicle applications. Operating at 12 volts with a power rating of 10 watts, this bulb features the SV8.5-8 base type, making it suitable for specific automotive lighting fixtures. It offers a bright and focused light output, ensuring excellent visibility and safety on the road. The Bosch Pure Light SV8-5-8 is commonly used for interior and exterior lighting applications, such as dome lights, map lights, and other interior fixtures. Its precise fit and easy installation make it compatible with a wide range of vehicle models, providing a hassle-free experience for users. As with all Bosch products, the Pure Light SV8-5-8 is built to Bosch's high standards of quality and durability, ensuring long-lasting and dependable performance",
    detail: "Voltage: 12V, Wattage: 10W",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["12V Bulb"],
  },
  {
    sku: "AG0434",
    slug: "bosch-pure-light-t4w-12v-4w",
    thumbnail: "/file/listings/bosch-placeholder.png",
    name: "BOSCH PURE LIGHT T4W- 12V 4W",
    description: "Automative Bulb",
    information: "The Bosch Pure Light T4W is a high-quality automotive bulb designed to provide efficient and reliable lighting for various vehicle applications. Operating at 12 volts with a power rating of 4 watts, this bulb is specifically designed for vehicles with T4W bulb requirements. It offers a pure and bright light output, ensuring excellent visibility and safety on the road. The Bosch Pure Light T4W is commonly used for interior and exterior lighting applications, such as dashboard illumination, parking lights, and license plate lights. With its precise fit and easy installation, it is compatible with a wide range of vehicle models, providing a hassle-free experience for users. As with all Bosch products, the Pure Light T4W is built to Bosch's high standards of quality and durability, ensuring long-lasting and dependable performance",
    detail: "Voltage: 12V, Wattage: 4W",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["12V Bulb"],
  },
  {
    sku: "AG0358",
    slug: "bosch-pure-light-w16w",
    thumbnail: "/file/listings/bosch-placeholder.png",
    name: "BOSCH PURE LIGHT W16W",
    description: "Automative Bulb",
    information: "The Bosch Pure Light W16W is a high-quality automotive bulb designed to deliver reliable and efficient lighting performance. Operating at 12 volts with a power rating of 16 watts, this bulb is specifically designed for vehicles with W16W bulb requirements. It offers a pure and bright light output, ensuring excellent visibility and safety on the road. The Bosch Pure Light W16W is commonly used for rear tail lights, brake lights, and other exterior lighting applications. With its precise fit and easy installation, it is compatible with various vehicle models, providing a hassle-free experience for users. As with all Bosch products, the Pure Light W16W is built to Bosch's high standards of quality and durability, ensuring long-lasting and dependable performance",
    detail: "Voltage: 12V, Wattage: 16W",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["12V Bulb"],
  },
  {
    sku: "AG0482",
    slug: "bosch-pure-light-w2-3w-12v-2-3w-284",
    thumbnail: "/file/listings/bosch-placeholder.png",
    name: "BOSCH PURE LIGHT W2-3W 12V 2-3W 284",
    description: "Automative Bulb",
    information: "The Bosch Pure Light W2-3W is a high-quality automotive bulb designed to provide reliable and efficient lighting performance. Operating at 12 volts and with a power rating of 2-3 watts, this bulb is specifically designed for vehicles with W2-3W bulb requirements. It offers a pure and bright light output, ensuring excellent visibility and safety on the road. The Bosch Pure Light W2-3W is commonly used for interior and exterior lighting applications, such as license plate lights, dashboard indicators, and other small lighting fixtures. With its compact design and precise fit, it is easy to install and compatible with various vehicle models. As with all Bosch products, the Pure Light W2-3W is built to Bosch's high standards of quality and durability, ensuring long-lasting and dependable performance",
    detail: "Voltage: 12V, Wattage: 2-3W",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["12V Bulb"],
  },
  {
    sku: "AG0370",
    slug: "bosch-pure-light-w215w-12v-215w",
    thumbnail: "/file/listings/bosch-placeholder.png",
    name: "BOSCH PURE LIGHT W21/5W- 12V 21/5W",
    description: "Automative Bulb",
    information: "The Bosch Pure Light W21/5W is a high-quality automotive bulb designed to deliver reliable and efficient lighting performance. Operating at 12 volts and with a power rating of 21/5 watts, this bulb is specifically designed for vehicles with W21/5W bulb requirements. It provides a pure and bright light output, ensuring excellent visibility and safety on the road. The Bosch Pure Light W21/5W is a dual-function bulb, serving as both a brake light (5 watts) and a tail light (21 watts) in one compact design. It is a dependable choice for various automotive lighting applications, meeting Bosch's high standards of quality and durability",
    detail: "Voltage: 12V, Wattage: 21/5W",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["12V Bulb"],
  },
  {
    sku: "AG0369",
    slug: "bosch-pure-light-w21w-12v-21w",
    thumbnail: "/file/listings/bosch-placeholder.png",
    name: "BOSCH PURE LIGHT W21W- 12V 21W",
    description: "Automative Bulb",
    information: "The Bosch Pure Light W21W is a high-quality automotive bulb designed to deliver reliable and efficient lighting performance. Operating at 12 volts and with a power rating of 21 watts, this bulb is specifically designed for vehicles with W21W bulb requirements. It provides a pure and bright light output, ensuring excellent visibility and safety on the road. The Bosch Pure Light W21W is a dependable choice for various automotive lighting applications, meeting Bosch's high standards of quality and durability",
    detail: "Voltage: 12V, Wattage: 21W",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["12V Bulb"],
  },
  {
    sku: "AG0366",
    slug: "bosch-pure-light-w3w-12v-3w",
    thumbnail: "/file/listings/bosch-placeholder.png",
    name: "BOSCH PURE LIGHT W3W 12V 3W",
    description: "Automative Bulb",
    information: "The Bosch Pure Light W3W is a high-quality automotive bulb designed for various lighting applications. It operates at 12 volts and has a power rating of 3 watts, making it suitable for specific vehicle models that require this specific wattage and voltage combination. This bulb is engineered to deliver a pure, bright light output, providing excellent visibility and safety on the road. It is a reliable and long-lasting option for automotive lighting needs, meeting Bosch's high standards of quality and performance",
    detail: "Voltage: 12V, Wattage: 3W",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["12V Bulb"],
  },
  {
    sku: "AG0359",
    slug: "bosch-pure-light-w5w",
    thumbnail: "/file/listings/AG0359.png",
    name: "BOSCH PURE LIGHT W5W",
    description: "Automative Bulb",
    information: "The BOSCH PURE LIGHT W5W is a high-quality halogen automotive bulb designed for various 12V lighting applications. With its precise construction and top-notch materials, it ensures durability and long-lasting performance. The bulb features a W2.1x9.5d base and produces a crisp and clear light output, making it ideal for interior and exterior lighting needs in vehicles. Whether used for parking lights, side marker lights, or license plate lights, the BOSCH PURE LIGHT W5W provides reliable illumination and enhances visibility on the road. It is a trustworthy choice for drivers looking to upgrade or replace their automotive bulbs with a product that guarantees excellent performance and quality",
    detail: "Delivering a good price-performance ratio: The Bosch Pure Light Automotive Bulb range consists of reliable, universal replacement bulbs that always provide an outstanding performance",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["12V Bulb"],
  },
  {
    sku: "AG0368",
    slug: "bosch-pure-light-wy5w-12v-5w",
    thumbnail: "/file/listings/AG0368.png",
    name: "BOSCH PURE LIGHT WY5W- 12V 5W",
    description: "Automative Bulb",
    information: "The BOSCH PURE LIGHT WY5W is a high-quality halogen automotive bulb designed to deliver reliable and efficient performance for various 12V lighting applications. With its W2.1x9.5d base and 5W power output, this bulb is suitable for use as a signal and indicator light in vehicles. It offers excellent visibility and brightness, ensuring enhanced safety on the road. The bulb's top-notch materials and precision engineering make it a durable and long-lasting choice. Whether used as a turn signal light or in other applications, the BOSCH PURE LIGHT WY5W is a trustworthy and dependable option for drivers looking to maintain optimal lighting performance in their vehicles",
    detail: "Voltage: 12V, Wattage: 5W",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["12V Bulb"],
  },
  {
    sku: "AG0185",
    slug: "bosch-r2397-cabin-filter",
    thumbnail: "/file/listings/AG0185.png",
    name: "BOSCH R2397 CABIN FILTER",
    description: "Anti-allergic & anti-bacterial protection for clean air inside your car",
    information: "Bosch Premium Cabin Air Filters offer an excellent blend of original equipment fit and significantly enhanced quality, resulting in a cleaner and healthier passenger cabin air quality. With pollutants and allergens inside a vehicle potentially reaching up to six times higher concentration than outside, Bosch Premium Cabin Filters act as a reliable barrier, preventing harmful spores and pollen from entering the vehicle interior. By doing so, they not only safeguard passengers but also protect downstream HVAC components, ensuring a more pleasant and safe driving experience",
    detail: "Anti-Allergy Layer that effectively renders allergens harmless and eliminates bacteria, ensuring a healthier environment for all passengers. The inclusion of Activated Carbon enhances the filter's capability to absorb harmful gases and unpleasant smells. Ultra-fine microfiber filters fine dust particles (up to 2.5µm), soot and pollen. The filter's Carrier Fleece stabilizes the layers, ensuring optimum performance and durability. Additionally, the reduction of deposits protects the air conditioning system, ultimately leading to fresher air and a more comfortable journey for everyone on board",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Cabin Filters"],
  },
  {
    sku: "AG0248",
    slug: "bosch-r5w-pure-light-6v-5w",
    thumbnail: "/file/listings/bosch-placeholder.png",
    name: "BOSCH R5W Pure Light 6V 5W",
    description: "Automative Bulb",
    information: "The Bosch R5W Pure Light is a high-quality automotive bulb designed for various lighting applications. It operates at 6 volts and has a power rating of 5 watts, making it suitable for specific vehicle models that require this specific wattage and voltage combination. This bulb is engineered to deliver a pure, clear light output, providing excellent visibility and safety on the road. It is a reliable and long-lasting option for automotive lighting needs, meeting Bosch's high standards of quality and performance",
    detail: "Voltage: 6V, Wattage: 5W",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["6V Bulb"],
  },
  {
    sku: "AG0531",
    slug: "bosch-rear-wiper-a332h",
    thumbnail: "/file/listings/AG0531.png",
    name: "BOSCH REAR WIPER - A332H",
    description: "Car Windscreen Wiper Blades",
    information: "Under adverse weather conditions, maintaining clear visibility of the rear is just as important as the view of the front, be it while in traffic or when reversing into a parking space. Bosch rear wiper blades deliver the best possible wiping results with long service life as you would expect of any Bosch wiper blade. Be safe, and maintain vision clarity with Bosch wipers",
    detail: "Specialty coated natural rubber for smooth, quiet operation and a clean, consistent wipe",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Special Wipers"],
  },
  {
    sku: "AG0270",
    slug: "bosch-rear-wiper-a332h",
    thumbnail: "/file/listings/AG0531.png",
    name: "BOSCH REAR WIPER - A332H",
    description: "Car Windscreen Wiper Blades",
    information: "Under adverse weather conditions, maintaining clear visibility of the rear is just as important as the view of the front, be it while in traffic or when reversing into a parking space. Bosch rear wiper blades deliver the best possible wiping results with long service life as you would expect of any Bosch wiper blade. Be safe, and maintain vision clarity with Bosch wipers",
    detail: "The windshield wiper blades from BOSCH are engineered to deliver exceptional performance and durability. With a wear-resistant natural rubber wiping edge, these blades offer a long service life, ensuring consistent and effective wiping even in challenging weather conditions. The specially coated wiping element enhances smooth and quiet operation, providing a pleasant driving experience. Each blade is carefully designed to fit specific wiping systems, ensuring optimal compatibility and performance. BOSCH windshield wiper blades are a reliable choice for drivers seeking high-quality and efficient wipers to maintain clear visibility on their vehicles' windshields",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Rear Wipers"],
  },
  {
    sku: "AG0270",
    slug: "bosch-rear-wiper-h250",
    thumbnail: "/file/listings/AG0270.png",
    name: "BOSCH REAR WIPER - H250",
    description: "Car Windscreen Wiper Blades",
    information: "Under adverse weather conditions, maintaining clear visibility of the rear is just as important as the view of the front, be it while in traffic or when reversing into a parking space. Bosch rear wiper blades deliver the best possible wiping results with long service life as you would expect of any Bosch wiper blade. Be safe, and maintain vision clarity with Bosch wipers",
    detail: "The windshield wiper blades from BOSCH are engineered to deliver exceptional performance and durability. With a wear-resistant natural rubber wiping edge, these blades offer a long service life, ensuring consistent and effective wiping even in challenging weather conditions. The specially coated wiping element enhances smooth and quiet operation, providing a pleasant driving experience. Each blade is carefully designed to fit specific wiping systems, ensuring optimal compatibility and performance. BOSCH windshield wiper blades are a reliable choice for drivers seeking high-quality and efficient wipers to maintain clear visibility on their vehicles' windshields",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Rear Wipers"],
  },
  {
    sku: "AG0311",
    slug: "bosch-rear-wiper-h281",
    thumbnail: "/file/listings/AG0311.png",
    name: "BOSCH REAR WIPER - H281",
    description: "Car Windscreen Wiper Blades",
    information: "Under adverse weather conditions, maintaining clear visibility of the rear is just as important as the view of the front, be it while in traffic or when reversing into a parking space. Bosch rear wiper blades deliver the best possible wiping results with long service life as you would expect of any Bosch wiper blade. Be safe, and maintain vision clarity with Bosch wipers",
    detail: "The windshield wiper blades from BOSCH are engineered to deliver exceptional performance and durability. With a wear-resistant natural rubber wiping edge, these blades offer a long service life, ensuring consistent and effective wiping even in challenging weather conditions. The specially coated wiping element enhances smooth and quiet operation, providing a pleasant driving experience. Each blade is carefully designed to fit specific wiping systems, ensuring optimal compatibility and performance. BOSCH windshield wiper blades are a reliable choice for drivers seeking high-quality and efficient wipers to maintain clear visibility on their vehicles' windshields",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Rear Wipers"],
  },
  {
    sku: "AG0240",
    slug: "bosch-rear-wiper-h306",
    thumbnail: "/file/listings/bosch-placeholder.png",
    name: "BOSCH REAR WIPER - H306",
    description: "Bosch reaer wiper",
    information: "Under adverse weather conditions, maintaining clear visibility of the rear is just as important as the view of the front, be it while in traffic or when reversing into a parking space. Bosch rear wiper blades deliver the best possible wiping results with long service life as you would expect of any Bosch wiper blade. Be safe, and maintain vision clarity with Bosch wipers",
    detail: "The windshield wiper blades from BOSCH are engineered to deliver exceptional performance and durability. With a wear-resistant natural rubber wiping edge, these blades offer a long service life, ensuring consistent and effective wiping even in challenging weather conditions. The specially coated wiping element enhances smooth and quiet operation, providing a pleasant driving experience. Each blade is carefully designed to fit specific wiping systems, ensuring optimal compatibility and performance. BOSCH windshield wiper blades are a reliable choice for drivers seeking high-quality and efficient wipers to maintain clear visibility on their vehicles' windshields",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Rear Wipers"],
  },
  {
    sku: "AG0262",
    slug: "bosch-rear-wiper-h307",
    thumbnail: "/file/listings/AG0262.png",
    name: "BOSCH REAR WIPER - H307",
    description: "Car Windscreen Wiper Blades",
    information: "Under adverse weather conditions, maintaining clear visibility of the rear is just as important as the view of the front, be it while in traffic or when reversing into a parking space. Bosch rear wiper blades deliver the best possible wiping results with long service life as you would expect of any Bosch wiper blade. Be safe, and maintain vision clarity with Bosch wipers",
    detail: "The windshield wiper blades from BOSCH are engineered to deliver exceptional performance and durability. With a wear-resistant natural rubber wiping edge, these blades offer a long service life, ensuring consistent and effective wiping even in challenging weather conditions. The specially coated wiping element enhances smooth and quiet operation, providing a pleasant driving experience. Each blade is carefully designed to fit specific wiping systems, ensuring optimal compatibility and performance. BOSCH windshield wiper blades are a reliable choice for drivers seeking high-quality and efficient wipers to maintain clear visibility on their vehicles' windshields",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Rear Wipers"],
  },
  {
    sku: "AG0209",
    slug: "bosch-rear-wiper-h352",
    thumbnail: "/file/listings/AG0209.png",
    name: "BOSCH REAR WIPER - H352",
    description: "Car Windscreen Wiper Blades",
    information: "Under adverse weather conditions, maintaining clear visibility of the rear is just as important as the view of the front, be it while in traffic or when reversing into a parking space. Bosch rear wiper blades deliver the best possible wiping results with long service life as you would expect of any Bosch wiper blade. Be safe, and maintain vision clarity with Bosch wipers",
    detail: "The windshield wiper blades from BOSCH are engineered to deliver exceptional performance and durability. With a wear-resistant natural rubber wiping edge, these blades offer a long service life, ensuring consistent and effective wiping even in challenging weather conditions. The specially coated wiping element enhances smooth and quiet operation, providing a pleasant driving experience. Each blade is carefully designed to fit specific wiping systems, ensuring optimal compatibility and performance. BOSCH windshield wiper blades are a reliable choice for drivers seeking high-quality and efficient wipers to maintain clear visibility on their vehicles' windshields",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Rear Wipers"],
  },
  {
    sku: "AG0436",
    slug: "bosch-rear-wiper-h354",
    thumbnail: "/file/listings/AG0436.png",
    name: "BOSCH REAR WIPER - H354",
    description: "Car Windscreen Wiper Blades",
    information: "Under adverse weather conditions, maintaining clear visibility of the rear is just as important as the view of the front, be it while in traffic or when reversing into a parking space. Bosch rear wiper blades deliver the best possible wiping results with long service life as you would expect of any Bosch wiper blade. Be safe, and maintain vision clarity with Bosch wipers",
    detail: "The windshield wiper blades from BOSCH are engineered to deliver exceptional performance and durability. With a wear-resistant natural rubber wiping edge, these blades offer a long service life, ensuring consistent and effective wiping even in challenging weather conditions. The specially coated wiping element enhances smooth and quiet operation, providing a pleasant driving experience. Each blade is carefully designed to fit specific wiping systems, ensuring optimal compatibility and performance. BOSCH windshield wiper blades are a reliable choice for drivers seeking high-quality and efficient wipers to maintain clear visibility on their vehicles' windshields",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Rear Wipers"],
  },
  {
    sku: "AG0269",
    slug: "bosch-rear-wiper-h409",
    thumbnail: "/file/listings/AG0269.png",
    name: "BOSCH REAR WIPER - H409",
    description: "Car Windscreen Wiper Blades",
    information: "Under adverse weather conditions, maintaining clear visibility of the rear is just as important as the view of the front, be it while in traffic or when reversing into a parking space. Bosch rear wiper blades deliver the best possible wiping results with long service life as you would expect of any Bosch wiper blade. Be safe, and maintain vision clarity with Bosch wipers",
    detail: "The windshield wiper blades from BOSCH are engineered to deliver exceptional performance and durability. With a wear-resistant natural rubber wiping edge, these blades offer a long service life, ensuring consistent and effective wiping even in challenging weather conditions. The specially coated wiping element enhances smooth and quiet operation, providing a pleasant driving experience. Each blade is carefully designed to fit specific wiping systems, ensuring optimal compatibility and performance. BOSCH windshield wiper blades are a reliable choice for drivers seeking high-quality and efficient wipers to maintain clear visibility on their vehicles' windshields",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Rear Wipers"],
  },
  {
    sku: "AG0065",
    slug: "bosch-sm-t5-1400",
    thumbnail: "/file/listings/bosch-placeholder.png",
    name: "BOSCH SM T5 1400",
    description: "Bosch T5 Mega Power Duty",
    information: "Assured Safety, High Endurance Design, Maintenance Accessible Option, Outstanding Performance",
    detail: "Power Output: 1400 Watts",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Batteries"],
  },
  {
    sku: "AG0501",
    slug: "bosch-spark-plug-fq5npp332s",
    thumbnail: "/file/listings/AG0501.png",
    name: "BOSCH Spark Plug - FQ5NPP332S",
    description: "Spark Plug for Engines",
    information: "3x longer service life vs standard nickel spark plugs. Fine wire platinum center electrode provides extended performance life and requires less voltage to fire. 360 degree continuous laser welded center electrode fuses the platinum firing pin for longer life and durability. Copper core provides broader heat range to resist pre-ignition and fouling",
    detail: "Electrode Material: Platinum center electrode, Nickel-yttrium ground electrode",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Spark Plug"],
  },
  {
    sku: "AG0474",
    slug: "bosch-spark-plug-vr6nii332",
    thumbnail: "/file/listings/AG0474.png",
    name: "BOSCH Spark Plug - VR6NII332",
    description: "Spark Plug for Engines",
    information: "The BOSCH Spark Plug VR6NII332 is a high-performance spark plug designed to enhance engine efficiency and deliver reliable ignition. It features a fine-wire iridium center electrode and a nickel ground electrode, providing consistent and powerful sparks for smooth combustion. This spark plug is engineered to offer excellent ignitability and durability, resulting in improved fuel efficiency and reduced emissions. The VR6NII332 spark plug is specifically designed for use in gasoline engines and is compatible with various vehicle models. As a product from BOSCH, a reputable name in automotive technology, this spark plug ensures superior performance, increased engine efficiency, and extended service life, making it an ideal choice for drivers looking for optimal engine performance and reliability",
    detail: "Electrode Material: Iridium center electrode, Nickel ground electrode",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Spark Plug"],
  },
  {
    sku: "AG0456",
    slug: "bosch-spark-plug-vr6nii352u",
    thumbnail: "/file/listings/AG0456.png",
    name: "BOSCH Spark Plug - VR6NII352U",
    description: "Spark Plug for Engines",
    information: "Ultra fine wire center electrode and ground electrode increase ignitability and reduce spark quenching. Double iridium tipped center electrode and platinum tipped ground electrode promote durability and long life. Copper core helps prevent pre-ignition and fouling. Nickel-plated shell and rolled threads provide anti-seize and corrosion protection. Ribbed insulator prevents flashover",
    detail: "Electrode Material: Iridium center electrode, Nickel ground electrode",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Spark Plug"],
  },
  {
    sku: "AG0455",
    slug: "bosch-spark-plug-vr6nii35t",
    thumbnail: "/file/listings/AG0455.png",
    name: "BOSCH Spark Plug - VR6NII35T",
    description: "Spark Plug for Engines",
    information: "Ultra fine wire electrodes and taper cut ground increase ignitability and reduce spark quenching. Iridium tipped center electrode and platinum tipped ground electrode promote durability and long life. Copper core helps prevent pre-ignition and fouling. Nickel-plated shell and rolled threads provide anti-seize and corrosion protection. Ribbed insulator prevents flashover",
    detail: "Electrode Material: Iridium center electrode, Nickel ground electrode",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Spark Plug"],
  },
  {
    sku: "AG0489",
    slug: "bosch-spark-plug-vr7mii33u",
    thumbnail: "/file/listings/AG0489.png",
    name: "BOSCH Spark Plug - VR7MII33U",
    description: "Spark Plug for Engines",
    information: "Ultra fine wire iridium firing pin and iridium ground electrode inlay provide higher performance. Laser weld provides superior retention of iridium alloy to the ground electrode. 0.6 mm fine wire firing pin for better ignitability and durability. Copper core helps prevent pre-ignition and fouling. Nickel-plated shell and rolled threads provide anti-seize and corrosion protection. Ribbed insulator prevents flashover",
    detail: "Electrode Material: Iridium center electrode, Platinum ground electrode",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Spark Plug"],
  },
  {
    sku: "AG0450",
    slug: "bosch-spark-plug-vr7nii33x",
    thumbnail: "/file/listings/AG0450.png",
    name: "BOSCH Spark Plug - VR7NII33X",
    description: "Spark Plug for Engines",
    information: "Ultra fine wire iridium firing pin and iridium tapered ground electrode inlay provide higher performance. Laser weld provides superior retention of iridium alloy to the ground electrode. 0.6 mm fine wire firing pin for better ignitability and durability. Copper core helps prevent pre-ignition and fouling. Nickel-plated shell and rolled threads provide anti-seize and corrosion protection. Ribbed insulator prevents flashover",
    detail: "Electrode Material: Single Platinum center electrode and ground electrode",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Spark Plug"],
  },
  {
    sku: "AG0472",
    slug: "bosch-spark-plug-vr7si332s",
    thumbnail: "/file/listings/AG0472.png",
    name: "BOSCH Spark Plug - VR7SI332S",
    description: "Spark Plug for Engines",
    information: "Ultra fine wire iridium firing pin and iridium ground electrode inlay provide higher performance. Laser weld provides superior retention of iridium alloy to the ground electrode. 0.6 mm fine wire firing pin for better ignitability and durability. Copper core helps prevent pre-ignition and fouling. Nickel-plated shell and rolled threads provide anti-seize and corrosion protection. Ribbed insulator prevents flashover",
    detail: "Electrode Material: Single Iridium center electrode and ground electrode",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Spark Plug"],
  },
  {
    sku: "AG0473",
    slug: "bosch-spark-plug-vr7sii33u",
    thumbnail: "/file/listings/AG0473.png",
    name: "BOSCH Spark Plug - VR7SII33U",
    description: "Spark Plug for Engines",
    information: "Ultra fine wire iridium firing pin and iridium ground electrode inlay provide higher performance. Laser weld provides superior retention of iridium alloy to the ground electrode. 0.6 mm fine wire firing pin for better ignitability and durability. Copper core helps prevent pre-ignition and fouling. Nickel-plated shell and rolled threads provide anti-seize and corrosion protection. Ribbed insulator prevents flashover",
    detail: "Electrode Material: Double Iridium center electrode and ground electrode",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Spark Plug"],
  },
  {
    sku: "AG0520",
    slug: "bosch-spark-plug-vr7sii350u",
    thumbnail: "/file/listings/AG0520.png",
    name: "BOSCH Spark Plug - VR7SII350U",
    description: "Spark Plug for Engines",
    information: "Ultra fine wire iridium firing pin and iridium ground electrode inlay provide higher performance. Laser weld provides superior retention of iridium alloy to the ground electrode. 0.6 mm fine wire firing pin for better ignitability and durability. Copper core helps prevent pre-ignition and fouling. Nickel-plated shell and rolled threads provide anti-seize and corrosion protection. Ribbed insulator prevents flashover",
    detail: "Electrode Material: Double Iridium center electrode and ground electrode",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Spark Plug"],
  },
  {
    sku: "AG0487",
    slug: "bosch-spark-plug-vr7spp33",
    thumbnail: "/file/listings/AG0487.png",
    name: "BOSCH Spark Plug - VR7SPP33",
    description: "Spark Plug for Engines",
    information: "The platinum on both electrodes gives the Bosch Double Platinum its name and helps to ensure outstanding ignition reliability and wear resistance. What`s more, the center electrode with its thickness of just 0.6 mm permits a lower ignition voltage in combination with higher-energy ignition sparks",
    detail: "Electrode Material: Platinum center electrode",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Spark Plug"],
  },
  {
    sku: "AG0488",
    slug: "bosch-spark-plug-vr7tii35u",
    thumbnail: "/file/listings/AG0488.png",
    name: "BOSCH Spark Plug - VR7TII35U",
    description: "Spark Plug for Engines",
    information: "The BOSCH Spark Plug VR7TII35U is a high-performance spark plug engineered to deliver reliable ignition and optimize engine efficiency. It features a fine wire Iridium center electrode, which ensures consistent and powerful sparks, promoting efficient combustion and improved fuel economy. The advanced design and premium materials used in this spark plug contribute to its exceptional wear resistance and durability, reducing the need for frequent replacements. The VR7TII35U spark plug is designed to fit a wide range of gasoline engines, offering smooth engine operation and enhanced performance in various vehicle models. As a product from BOSCH, a trusted automotive technology leader, this spark plug guarantees superior quality, performance, and reliability, making it an ideal choice to boost your vehicle's overall performance and ensure a dependable ignition system",
    detail: "Electrode Material: Fine wire Iridium center electrode",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Spark Plug"],
  },
  {
    sku: "AG0532",
    slug: "bosch-spark-plug-vr8nii35u",
    thumbnail: "/file/listings/AG0532.png",
    name: "BOSCH Spark Plug - VR8NII35U",
    description: "Spark Plug for Engines",
    information: "The BOSCH Spark Plug VR8NII35U is a premium spark plug designed to provide exceptional ignition performance and optimize engine efficiency. It features a double fine wire Iridium center electrode, which ensures a consistently strong and stable spark for improved combustion and fuel efficiency. The advanced design and high-quality materials used in this spark plug result in superior wear resistance, extending its lifespan and reducing the need for frequent replacements. The VR8NII35U spark plug is engineered to fit a wide range of gasoline engines, delivering reliable and smooth engine operation in various vehicle models. As a product from BOSCH, a renowned automotive technology leader, this spark plug guarantees exceptional quality, performance, and reliability, making it an excellent choice to enhance your vehicle's overall performance and ensure a dependable ignition system",
    detail: "Electrode Material: Double fine wire Iridium center electrode",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Spark Plug"],
  },
  {
    sku: "AG0462",
    slug: "bosch-spark-plug-vr8sii30x",
    thumbnail: "/file/listings/AG0462.png",
    name: "BOSCH Spark Plug - VR8SII30X",
    description: "Spark Plug for Engines",
    information: "The BOSCH Spark Plug VR8SII30X is a high-performance spark plug designed to deliver reliable ignition and efficient engine performance. It features a platinum fine wire center electrode, which ensures a strong and consistent spark for smooth engine operation, improved fuel efficiency, and reduced emissions. The spark plug's advanced design and high-quality materials provide excellent wear resistance and long-lasting performance, making it a durable choice for your vehicle's ignition system. The VR8SII30X spark plug is designed to meet or exceed OEM specifications, ensuring a precise fit and easy installation in a wide range of gasoline engines. BOSCH, a trusted name in automotive technology, guarantees the quality and performance of this spark plug, making it an excellent option to enhance your vehicle's overall performance and reliability",
    detail: "Electrode Material: Platinum fine wire center electrode",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Spark Plug"],
  },
  {
    sku: "AG0447",
    slug: "bosch-spark-plug-vr8sii33x",
    thumbnail: "/file/listings/AG0447.png",
    name: "BOSCH Spark Plug - VR8SII33X",
    description: "Spark Plug for Engines",
    information: "Ultra fine wire electrodes and taper cut ground increase ignitability and reduce spark quenching. Double iridium tipped center electrode and platinum tipped ground electrode promote durability and long life. Copper core helps prevent pre-ignition and fouling. Nickel-plated shell and rolled threads provide anti-seize and corrosion protection. Ribbed insulator prevents flashover",
    detail: "Electrode Material: Platinum fine wire center electrode",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Spark Plug"],
  },
  {
    sku: "AG0492",
    slug: "bosch-spark-plug-y5kpp332",
    thumbnail: "/file/listings/AG0492.png",
    name: "BOSCH Spark Plug - Y5KPP332",
    description: "Spark Plug for Engines",
    information: "3x longer service life vs standard nickel spark plugs. Fine wire platinum center electrode provides extended performance life and requires less voltage to fire. 360 degree continuous laser welded center electrode fuses the platinum firing pin for longer life and durability. Copper core provides broader heat range to resist pre-ignition and fouling",
    detail: "Electrode Material: Yttrium-enhanced center electrode",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Spark Plug"],
  },
  {
    sku: "AG0452",
    slug: "bosch-spark-plug-yr6npp332",
    thumbnail: "/file/listings/AG0452.png",
    name: "BOSCH Spark Plug - YR6NPP332",
    description: "Spark Plug for Engines",
    information: "3x longer service life vs standard nickel spark plugs. Fine wire platinum center electrode provides extended performance life and requires less voltage to fire. 360 degree continuous laser welded center electrode fuses the platinum firing pin for longer life and durability. Copper core provides broader heat range to resist pre-ignition and fouling",
    detail: "Electrode Material: Nickel-yttrium alloy center electrode",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Spark Plug"],
  },
  {
    sku: "AG0490",
    slug: "bosch-spark-plug-yr6sii330x",
    thumbnail: "/file/listings/AG0490.png",
    name: "BOSCH Spark Plug - YR6SII330X",
    description: "Spark Plug for Engines",
    information: "3x longer service life vs standard nickel spark plugs. Fine wire platinum center electrode provides extended performance life and requires less voltage to fire. 360 degree continuous laser welded center electrode fuses the platinum firing pin for longer life and durability. Copper core provides broader heat range to resist pre-ignition and fouling",
    detail: "Electrode Material: Fine-wire Platinum center electrode",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Spark Plug"],
  },
  {
    sku: "AG0453",
    slug: "bosch-spark-plug-yr6tii330t",
    thumbnail: "/file/listings/AG0453.png",
    name: "BOSCH Spark Plug - YR6TII330T",
    description: "Spark Plug for Engines",
    information: "3x longer service life vs standard nickel spark plugs. Fine wire platinum center electrode provides extended performance life and requires less voltage to fire. 360 degree continuous laser welded center electrode fuses the platinum firing pin for longer life and durability. Copper core provides broader heat range to resist pre-ignition and fouling",
    detail: "Electrode Material: Fine-wire Iridium center electrode",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Spark Plug"],
  },
  {
    sku: "AG0522",
    slug: "bosch-spark-plug-yr78x",
    thumbnail: "/file/listings/AG0522.png",
    name: "BOSCH Spark Plug - YR78X",
    description: "Spark Plug for Engines",
    information: "The BOSCH Spark Plug YR78X is a high-performance spark plug designed to optimize engine efficiency and performance. Its advanced design features a fine-wire platinum center electrode, which provides a precise and powerful spark for efficient combustion and improved fuel economy. The spark plug's special firing technology reduces electrode wear, ensuring a longer service life and consistent performance over time. The YR78X spark plug is compatible with various gasoline engines and is built to withstand high temperatures and pressures, making it ideal for demanding driving conditions. BOSCH, a renowned automotive technology principals, has engineered this spark plug to meet strict quality standards, ensuring maximum engine efficiency and smooth operation",
    detail: "Electrode Material: Fine-wire Platinum center electrode",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Spark Plug"],
  },
  {
    sku: "AG0497",
    slug: "bosch-spark-plug-yr7kii33t",
    thumbnail: "/file/listings/AG0497.png",
    name: "BOSCH Spark Plug - YR7KII33T",
    description: "Spark Plug for Engines",
    information: "The BOSCH Spark Plug YR7KII33T is a top-performing spark plug designed to deliver reliable ignition and optimize engine performance. Its advanced design features a fine-wire iridium center electrode, which provides a precise and consistent spark for efficient combustion and improved fuel economy. The spark plug's unique firing technology reduces electrode wear, ensuring a longer service life and consistent performance over time. The YR7KII33T spark plug is compatible with various gasoline engines and is built to withstand high temperatures and pressures, making it ideal for demanding driving conditions. BOSCH, a trusted name in automotive technology, has engineered this spark plug to meet strict quality standards, ensuring maximum engine efficiency and smooth operation",
    detail: "Electrode Material: Fine-wire Iridium center electrode",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Spark Plug"],
  },
  {
    sku: "AG0449",
    slug: "bosch-spark-plug-yr7lpp332w",
    thumbnail: "/file/listings/AG0449.png",
    name: "BOSCH Spark Plug - YR7LPP332W",
    description: "Spark Plug for Engines",
    information: "The BOSCH Spark Plug YR7LPP332W is a high-quality spark plug engineered for superior ignition performance and engine efficiency. Its design features a platinum center electrode, which ensures a reliable and consistent spark for smooth engine operation and optimized fuel combustion. The multi-ground electrode design further enhances ignition efficiency and reduces emissions, contributing to a cleaner and more environmentally-friendly engine performance. The spark plug is built with premium materials, offering excellent durability and extended service life. Designed by BOSCH, a renowned automotive technology company, the YR7LPP332W spark plug is tailored to meet the specific requirements of various gasoline engines, making it a dependable choice for drivers seeking optimal spark plug performance for their vehicles",
    detail: "Electrode Material: Platinum center electrode, Multi-ground electrode design",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Spark Plug"],
  },
  {
    sku: "AG0448",
    slug: "bosch-spark-plug-yr7mpp33",
    thumbnail: "/file/listings/AG0448.png",
    name: "BOSCH Spark Plug - YR7MPP33",
    description: "Spark Plug for Engines",
    information: "The BOSCH Spark Plug YR7MPP33 is a premium-quality spark plug designed to deliver exceptional ignition performance and engine efficiency. It features a multi-ground electrode design with a platinum center electrode, ensuring precise and consistent spark ignition, which leads to smooth engine operation and improved fuel combustion. The platinum center electrode is highly durable and offers excellent resistance to wear, ensuring a long-lasting and reliable spark plug solution. The spark plug also includes multiple ground electrodes, which contribute to improved ignition efficiency and reduced emissions. Engineered by BOSCH, a trusted automotive technology company, the YR7MPP33 spark plug is designed to meet the specific requirements of various gasoline engines, making it an ideal choice for drivers seeking top-notch spark plug performance for their vehicles",
    detail: "Electrode Material: Platinum center electrode, Multi-ground electrode design",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Spark Plug"],
  },
  {
    sku: "AG0451",
    slug: "bosch-spark-plug-yr7nii33s",
    thumbnail: "/file/listings/AG0451.png",
    name: "BOSCH Spark Plug - YR7NII33S",
    description: "Spark Plug for Engines",
    information: "The BOSCH Spark Plug YR7NII33S is a high-quality spark plug designed to provide excellent ignition performance and engine efficiency. It features a nickel-yttrium alloy center electrode that ensures reliable and consistent spark ignition, leading to smooth engine operation and improved fuel combustion. The nickel-yttrium center electrode is highly durable and designed to withstand the rigors of engine operation, providing a long-lasting and reliable spark plug solution. Additionally, the spark plug includes a nickel ground electrode, which contributes to enhanced ignition efficiency and reduced emissions. The YR7NII33S spark plug is precisely engineered by BOSCH, a leading automotive technology company, to meet the specific requirements of various gasoline engines, making it a dependable choice for drivers seeking optimal spark plug performance for their vehicles",
    detail: "Electrode Material: Nickel-yttrium center electrode, Nickel ground electrode",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Spark Plug"],
  },
  {
    sku: "AG0457",
    slug: "bosch-spark-plug-yr7sii33ou",
    thumbnail: "/file/listings/bosch-placeholder.png",
    name: "BOSCH Spark Plug - YR7SII33OU",
    description: "Spark Plug for Engines",
    information: "The BOSCH Spark Plug YR7SII33OU is a premium spark plug designed to deliver exceptional ignition performance and engine efficiency. It features a fine-wire iridium center electrode that ensures consistent and efficient spark ignition, resulting in smooth engine operation and improved fuel combustion. The iridium center electrode is highly durable and resistant to wear, ensuring a longer service life compared to conventional spark plugs. Additionally, the spark plug includes a platinum ground electrode, which further enhances ignition efficiency and contributes to reduced emissions. The YR7SII33OU spark plug is precisely engineered by BOSCH, a trusted name in automotive technology, to provide reliable performance, enhanced fuel efficiency, and reduced exhaust emissions, making it an ideal choice for drivers seeking optimal spark plug solutions for their vehicles",
    detail: "Electrode Material: Iridium center electrode, Platinum ground electrode",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Spark Plug"],
  },
  {
    sku: "AG0409",
    slug: "bosch-spark-plug-yr7sii33u",
    thumbnail: "/file/listings/AG0409.png",
    name: "BOSCH Spark Plug - YR7SII33U",
    description: "Spark Plug for Engines",
    information: "Ultra fine wire iridium firing pin and iridium tapered ground electrode inlay provide higher performance. Laser weld provides superior retention of iridium alloy to the ground electrode. 0.6 mm fine wire firing pin for better ignitability and durability. Copper core helps prevent pre-ignition and fouling. Nickel-plated shell and rolled threads provide anti-seize and corrosion protection. Ribbed insulator prevents flashover",
    detail: "Electrode Material: Iridium center electrode, Nickel-plated ground electrode",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Spark Plug"],
  },
  {
    sku: "AG0459",
    slug: "bosch-spark-plug-yr8dii33x",
    thumbnail: "/file/listings/AG0459.png",
    name: "BOSCH Spark Plug - YR8DII33X",
    description: "Spark Plug for Engines",
    information: "Bosch Double Iridium Spark Plugs are engineered to deliver both high performance and long life, representing the best of OE spark plug technology. The ultra-fine wire design and laser welded tapered ground electrode deliver optimum performance, while the iridium center electrode and platinum ground electrode help it to go the distance. Unlock the performance in your engine with Bosch Double Iridium Spark Plugs",
    detail: "Electrode Material: Iridium center electrode, Platinum ground electrode",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Spark Plug"],
  },
  {
    sku: "AG0446",
    slug: "bosch-spark-plug-yr8meu",
    thumbnail: "/file/listings/AG0446.png",
    name: "BOSCH Spark Plug - YR8MEU",
    description: "Spark Plug for Engines",
    information: "The BOSCH Spark Plug YR8MEU is a high-quality spark plug designed to provide reliable ignition and improved engine performance for gasoline engines. It features a fine-wire platinum center electrode that delivers a precise and consistent spark, ensuring smooth engine operation and optimal fuel combustion. The platinum center electrode is highly durable and resistant to wear, resulting in a longer service life compared to standard spark plugs. The spark plug also includes a nickel ground electrode that enhances spark ignition and contributes to overall engine efficiency. With BOSCH's advanced engineering and commitment to quality, the YR8MEU spark plug offers excellent ignition reliability and improved fuel economy, making it an ideal choice for drivers seeking reliable performance for their vehicles",
    detail: "Electrode Material: Platinum center electrode, Nickel ground electrode",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Spark Plug"],
  },
  {
    sku: "AG0495",
    slug: "bosch-spark-plug-yr8nii35u",
    thumbnail: "/file/listings/AG0495.png",
    name: "BOSCH Spark Plug - YR8NII35U",
    description: "Spark Plug for Engines",
    information: "The BOSCH Spark Plug YR8NII35U is a high-performance spark plug designed to deliver exceptional ignition and combustion efficiency for gasoline engines. It features a fine-wire iridium center electrode that provides a precise and powerful spark, ensuring optimal engine performance and fuel efficiency. The iridium center electrode is highly durable and has excellent wear resistance, contributing to a longer service life compared to traditional spark plugs. The spark plug also includes a nickel ground electrode that enhances spark ignition and improves overall engine performance. With BOSCH's advanced engineering and expertise, the YR8NII35U spark plug offers reliable ignition even in extreme conditions, making it an ideal choice for drivers seeking superior engine performance and fuel economy",
    detail: "Electrode Material: Iridium center electrode, Nickel ground electrode",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Spark Plug"],
  },
  {
    sku: "AG0471",
    slug: "bosch-spark-plug-yr8seu",
    thumbnail: "/file/listings/AG0471.png",
    name: "BOSCH Spark Plug - YR8SEU",
    description: "Spark Plug for Engines",
    information: "The BOSCH Spark Plug YR8SEU is a high-quality spark plug designed to deliver reliable performance for gasoline engines. It features a platinum center electrode that provides a precise and consistent spark, promoting efficient combustion and smooth engine operation. The platinum center electrode is highly durable and has excellent wear resistance, ensuring a long service life for the spark plug. The spark plug also includes a nickel ground electrode that enhances spark ignition and improves overall engine performance. With BOSCH's renowned expertise in automotive technology, the YR8SEU spark plug offers optimal ignition and combustion for improved fuel efficiency and reduced emissions, making it an excellent choice for discerning drivers seeking top-notch engine performance",
    detail: "Electrode Material: Platinum center electrode, Nickel ground electrode",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Spark Plug"],
  },
  {
    sku: "AG0468",
    slug: "bosch-spark-plug-zgr6ste2w",
    thumbnail: "/file/listings/AG0468.png",
    name: "BOSCH Spark Plug - ZGR6STE2W",
    description: "Spark Plug for Engines",
    information: "The BOSCH Spark Plug ZGR6STE2W is a premium spark plug designed to deliver exceptional performance and reliability for gasoline engines. It features a high-quality, iridium-enhanced center electrode that provides a precise spark, promoting efficient combustion and smooth engine operation. The iridium center electrode is extremely durable and has excellent wear resistance, ensuring a long service life for the spark plug. The tapered ground electrode design enhances flame propagation, leading to improved fuel efficiency and reduced emissions. With BOSCH's advanced engineering and commitment to quality, the ZGR6STE2W spark plug is the ideal choice for drivers seeking optimal engine performance and longevity",
    detail: "Electrode Material: Iridium-enhanced center electrode, Tapered ground electrode",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Spark Plug"],
  },
  {
    sku: "AG0494",
    slug: "bosch-spark-plug-zqr8si302",
    thumbnail: "/file/listings/AG0494.png",
    name: "BOSCH Spark Plug - ZQR8SI302",
    description: "Spark Plug for Engines",
    information: "The BOSCH Spark Plug ZQR8SI302 is a high-quality spark plug engineered to deliver reliable ignition and superior engine performance. It features a finely crafted iridium center electrode that ensures precise spark and efficient combustion. The iridium alloy used in the electrode provides exceptional durability and wear resistance, resulting in an extended service life. The spark plug's tapered ground electrode design promotes better flame propagation, enhancing fuel efficiency and power output. With BOSCH's cutting-edge technology and commitment to excellence, the ZQR8SI302 spark plug is designed to meet the demanding requirements of modern gasoline engines, ensuring optimal performance, smooth operation, and reduced emissions",
    detail: "Electrode Material: Iridium center electrode, Tapered ground electrode",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Spark Plug"],
  },
  {
    sku: "AG0500",
    slug: "bosch-spark-plug-zr5npp332",
    thumbnail: "/file/listings/bosch-placeholder.png",
    name: "BOSCH Spark Plug - ZR5NPP332",
    description: "Spark Plug for Engines",
    information: "The BOSCH Spark Plug ZR5NPP332 is a high-performance spark plug designed to deliver efficient ignition and optimal engine performance. Its precious metal center electrode and ground electrode offer excellent wear resistance, ensuring long-lasting durability and consistent ignition. The spark plug's nickel-plated shell provides corrosion resistance and helps maintain a stable spark gap over time. Engineered with BOSCH's expertise in automotive ignition systems, this spark plug ensures reliable starts and smooth engine operation. Whether you're driving on the highway or navigating challenging terrains, trust BOSCH ZR5NPP332 to provide the ignition power your vehicle needs",
    detail: "Electrode Material: Precious metal center electrode, Ground electrode",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Spark Plug"],
  },
  {
    sku: "AG0491",
    slug: "bosch-spark-plug-zr5si332",
    thumbnail: "/file/listings/AG0491.png",
    name: "BOSCH Spark Plug - ZR5SI332",
    description: "Spark Plug for Engines",
    information: "The BOSCH Spark Plug ZR5SI332 is a premium-quality spark plug designed to provide reliable ignition performance and efficient combustion for gasoline engines. Its fine-wire center electrode ensures precise ignition and improved engine efficiency. The spark plug's tapered ground electrode design promotes better flame propagation, leading to smoother engine operation. With BOSCH's commitment to excellence and advanced engineering, this spark plug offers outstanding durability and compatibility with various vehicle models. Keep your engine running smoothly with the reliable ignition power of BOSCH spark plugs",
    detail: "Electrode Material: Fine-wire center electrode, Tapered ground electrode",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Spark Plug"],
  },
  {
    sku: "AG0458",
    slug: "bosch-spark-plug-zr5spp3320-ag0458",
    thumbnail: "/file/listings/AG0458.png",
    name: "BOSCH Spark Plug - ZR5SPP3320 (AG0458)",
    description: "Spark Plug for Engines",
    information: "Ultra fine wire iridium firing pin and iridium ground electrode inlay provide higher performance. Laser weld provides superior retention of iridium alloy to the ground electrode. 0.6 mm fine wire firing pin for better ignitability and durability. Copper core helps prevent pre-ignition and fouling. Nickel-plated shell and rolled threads provide anti-seize and corrosion protection. Ribbed insulator prevents flashover",
    detail: "Center Electrode Design: Fine Wire, Tapered Ground Electrode, Electrode Material: Platinum, Gap: 0.75 mm",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Spark Plug"],
  },
  {
    sku: "AG0469",
    slug: "bosch-spark-plug-zr5spp3320-ag0469",
    thumbnail: "/file/listings/AG0458.png",
    name: "BOSCH Spark Plug - ZR5SPP3320 (AG0469)",
    description: "Spark Plug for Engines",
    information: "Ultra fine wire iridium firing pin and iridium ground electrode inlay provide higher performance. Laser weld provides superior retention of iridium alloy to the ground electrode. 0.6 mm fine wire firing pin for better ignitability and durability. Copper core helps prevent pre-ignition and fouling. Nickel-plated shell and rolled threads provide anti-seize and corrosion protection. Ribbed insulator prevents flashover",
    detail: "Center Electrode Design: Fine Wire, Tapered Ground Electrode, Electrode Material: Platinum, Gap: 0.8 mm",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Spark Plug"],
  },
  {
    sku: "AG0498",
    slug: "bosch-spark-plug-zr5tpp33",
    thumbnail: "/file/listings/AG0498.png",
    name: "BOSCH Spark Plug - ZR5TPP33",
    description: "Spark Plug for Engines",
    information: "Heat-fused platinum center electrode reaches self-cleaning temperature quickly and reduces electrode wear. Tapered, yttrium-enhanced ground electrode extend service life and improve access to air/fuel mixture. Nickel-plated rolled threads provide anti-seize and corrosion protection",
    detail: "Double Platinum firing pin and ground electrode inlay provide 3X longer service life compared to standard copper plug",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Spark Plug"],
  },
  {
    sku: "AG0499",
    slug: "bosch-spark-plug-zr5tpp330",
    thumbnail: "/file/listings/AG0499.png",
    name: "BOSCH Spark Plug - ZR5TPP330",
    description: "Spark Plug for Engines",
    information: "The BOSCH Spark Plug - ZR5TPP330 is a premium spark plug engineered to deliver exceptional ignition performance and enhance engine efficiency. With a thread diameter of 14mm and a thread reach of 26.5mm, this spark plug is suitable for various gasoline engines. The tapered seat type ensures a secure and precise fit, while the 16mm hex size allows for easy installation and removal during maintenance. The electrode material is a combination of platinum and nickel-yttrium, providing excellent durability, corrosion resistance, and efficient conductivity. The single electrode tip configuration ensures consistent sparks, promoting efficient fuel combustion and smooth engine operation. BOSCH, a trusted automotive principals, ensures the ZR5TPP330 spark plug meets stringent quality standards, making it a reliable choice for both daily driving and high-performance applications. Upgrade your engine's performance with the BOSCH Spark Plug - ZR5TPP330, and experience improved fuel efficiency, reduced emissions, and reliable ignition for a smooth and powerful driving experience",
    detail: "Platinum + Nickel-Yttrium electrode with single tip configuration",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Spark Plug"],
  },
  {
    sku: "AG0463",
    slug: "bosch-spark-plug-zr6sii3320",
    thumbnail: "/file/listings/bosch-placeholder.png",
    name: "BOSCH Spark Plug - ZR6SII3320",
    description: "Spark Plug for Engines",
    information: "The BOSCH Spark Plug - ZR6SII3320 is a high-quality spark plug designed to deliver reliable ignition performance and optimize engine efficiency. With a thread diameter of 14mm and a thread reach of 19mm, this spark plug is compatible with a wide range of gasoline engines. The gasket seat type ensures a secure fit and effective sealing, preventing combustion gas leakage. The 16mm hex size allows for easy installation and removal during maintenance. The spark plug's electrode material is made of iridium, a premium metal known for its exceptional durability and conductivity. The single electrode tip configuration ensures precise and consistent sparks, promoting efficient fuel combustion and smooth engine operation. BOSCH, a reputable automotive principals, ensures the ZR6SII3320 spark plug is manufactured to meet strict quality standards, making it a reliable choice for various vehicles. Upgrade your engine's performance with the BOSCH Spark Plug - ZR6SII3320, and experience improved fuel efficiency, reduced emissions, and smoother engine operation for a more enjoyable driving experience",
    detail: "Iridium electrode with single tip configuration",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Spark Plug"],
  },
  {
    sku: "AG0454",
    slug: "bosch-spark-plug-zr6spp302",
    thumbnail: "/file/listings/AG0454.png",
    name: "BOSCH Spark Plug - ZR6SPP302",
    description: "Spark Plug for Engines",
    information: "The BOSCH Spark Plug - ZR6SPP302 is a high-performance spark plug designed to enhance the ignition system's efficiency and ensure smooth engine operation. With a thread diameter of 12mm and a thread reach of 26.5mm, this spark plug is compatible with a wide range of gasoline engines. The flat seat type ensures a secure fit and reliable sealing, minimizing the risk of gas leakage during combustion. The 14mm hex size makes installation and removal a breeze, facilitating hassle-free maintenance. The electrode material is a combination of platinum and iridium, known for their excellent conductivity, corrosion resistance, and durability. This advanced material combination offers superior performance and a longer lifespan compared to standard spark plugs. The single electrode tip configuration provides a precise and powerful spark, promoting efficient fuel ignition and smooth engine performance. BOSCH, a trusted automotive principals, ensures the ZR6SPP302 spark plug is manufactured to stringent quality standards, making it a reliable choice for various vehicles, from daily drivers to high-performance cars. Upgrade your ignition system with the BOSCH Spark Plug - ZR6SPP302 to experience improved engine efficiency, enhanced fuel economy, and overall better engine performance",
    detail: "Platinum + Iridium electrode with single tip configuration",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Spark Plug"],
  },
  {
    sku: "AG0496",
    slug: "bosch-spark-plug-zr7si332s",
    thumbnail: "/file/listings/AG0496.png",
    name: "BOSCH Spark Plug - ZR7SI332S",
    description: "Spark Plug for Engines",
    information: "Ultra fine wire iridium firing pin and iridium ground electrode inlay provide higher performance. Laser weld provides superior retention of iridium alloy to the ground electrode. 0.6 mm fine wire firing pin for better ignitability and durability. Copper core helps prevent pre-ignition and fouling. Nickel-plated shell and rolled threads provide anti-seize and corrosion protection. Ribbed insulator prevents flashover",
    detail: "Iridium electrode with single tip configuration",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Spark Plug"],
  },
  {
    sku: "AG0249",
    slug: "bosch-sportec-bright-h11-12v-55w",
    thumbnail: "/file/listings/bosch-placeholder.png",
    name: "BOSCH SPORTEC BRIGHT H11 (12V 55W)",
    description: "Automative Bulb",
    information: "The BOSCH SPORTEC BRIGHT H11 is a high-performance automotive bulb designed to deliver exceptional brightness and clarity for various lighting applications in vehicles with a 12V electrical system. With a wattage of 55W, this bulb emits a powerful and focused light beam, making it ideal for use as a low beam or high beam headlight. The PGJ19-2 base type ensures easy and secure installation into compatible sockets. The SPORTEC BRIGHT H11 bulb is engineered to provide superior illumination and increased visibility, enhancing safety during nighttime driving and adverse weather conditions. Whether you're cruising on city roads or navigating through challenging terrains, this bulb's advanced technology and precision manufacturing ensure reliable performance and a longer lifespan. As part of the reputable Bosch principals, the SPORTEC BRIGHT H11 bulb is backed by quality assurance, making it an excellent choice for upgrading your vehicle's lighting system and driving experience",
    detail: "Voltage: 12V, Wattage: 55W",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["12V Bulb"],
  },
  {
    sku: "AG0350",
    slug: "bosch-standard-bulb-9005-hb3-12v-65w",
    thumbnail: "/file/listings/AG0350.png",
    name: "BOSCH STANDARD BULB 9005 HB3 (12V 65W)",
    description: "Standard Halogen Light Bulb",
    information: "The BOSCH Standard Bulb 9005 HB3 is a high-quality automotive headlight bulb designed to provide reliable and efficient illumination. Operating at 12 volts and 65 watts, this bulb emits a powerful and bright light, enhancing visibility and safety on the road. Built with precision and durability in mind, it features a robust construction that ensures long-lasting performance. The BOSCH Standard Bulb 9005 HB3 is suitable for various vehicle models and is an ideal choice for drivers looking to upgrade their headlights with a dependable and high-performance lighting solution. Whether driving during the day or night, this bulb delivers clear and consistent lighting, contributing to a safer and more comfortable driving experience",
    detail: "ECO HB3 12V 60W",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["12V Bulb"],
  },
  {
    sku: "AG0351",
    slug: "bosch-standard-bulb-9006-hb4-12v-55w",
    thumbnail: "/file/listings/bosch-placeholder.png",
    name: "BOSCH STANDARD BULB 9006 HB4 (12V 55W)",
    description: "Automative Bulb",
    information: "The BOSCH 9006 HB4 Standard Bulb is a reliable automotive bulb designed to provide consistent and efficient lighting for various applications in vehicles with a 12V electrical system. With a wattage of 55W, this bulb emits a bright and clear light, making it suitable for use in fog lights or low beam headlights. The P22d base type ensures easy installation and a secure fit into compatible sockets. The 9006 HB4 bulb is commonly used for fog lights, offering a focused and wide-angle beam pattern for improved visibility during adverse weather conditions. As a reputable principals in the automotive industry, Bosch ensures that this bulb meets strict quality standards, offering durability and long-lasting performance. Upgrade your vehicle's lighting with the BOSCH 9006 HB4 Standard Bulb and experience enhanced visibility and safety on the road",
    detail: "Voltage: 12V, Wattage: 55W",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["12V Bulb"],
  },
  {
    sku: "AG0352",
    slug: "bosch-standard-bulb-9012-12v-55w",
    thumbnail: "/file/listings/bosch-placeholder.png",
    name: "BOSCH STANDARD BULB 9012 (12V 55W)",
    description: "Automative Bulb",
    information: "The BOSCH 9012 Standard Bulb is a reliable automotive bulb designed to provide consistent and efficient lighting for various applications in vehicles with a 12V electrical system. With a wattage of 55W, this bulb emits a bright and clear light, making it suitable for use in low beam headlights. The P22d base type ensures easy installation and a secure fit into compatible sockets. The 9012 bulb is commonly used for low beam headlights, offering a focused and wide-angle beam pattern for improved visibility during nighttime driving. As a reputable principals in the automotive industry, Bosch ensures that this bulb meets strict quality standards, offering durability and long-lasting performance. Upgrade your vehicle's lighting with the BOSCH 9012 Standard Bulb and experience enhanced visibility and safety on the road",
    detail: "Voltage: 12V, Wattage: 55W",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["12V Bulb"],
  },
  {
    sku: "AG0460",
    slug: "bosch-standard-bulb-h1-12v-55w",
    thumbnail: "/file/listings/AG0460.png",
    name: "BOSCH STANDARD BULB H1 (12V 55W)",
    description: "Automative Bulb",
    information: "The BOSCH H1 Standard Bulb is a high-quality automotive bulb designed to provide reliable illumination for various lighting applications in vehicles with a 12V electrical system. With a wattage of 55W, this bulb emits a bright and clear light, making it suitable for use in high beam headlights and fog lights. The P14.5s base type ensures easy installation and a secure fit into compatible sockets. The H1 bulb is commonly used for high beam headlights, offering a focused and long-range beam pattern for enhanced visibility during nighttime driving. It is also suitable for fog lamps, providing improved clarity during adverse weather conditions. As a reputable principals in the automotive industry, Bosch ensures that this bulb meets strict quality standards, offering durability and consistent performance. Upgrade your vehicle's lighting with the BOSCH H1 Standard Bulb and experience improved visibility and safety on the road",
    detail: "Voltage: 12V, Wattage: 55W",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["12V Bulb"],
  },
  {
    sku: "AG0349",
    slug: "bosch-standard-bulb-h11-12v-55w",
    thumbnail: "/file/listings/AG0349.png",
    name: "BOSCH STANDARD BULB H11 (12V 55W)",
    description: "Automative Bulb",
    information: "The BOSCH H11 Standard Bulb is a high-quality automotive bulb designed to provide reliable illumination for various lighting applications in vehicles with a 12V electrical system. With a wattage of 55W, this bulb emits a bright and clear light, making it suitable for use in low beam headlights, fog lights, or driving lights. The PGJ19-2 base type ensures easy installation and a secure fit into compatible sockets. The H11 bulb is commonly used for low beam headlights due to its focused beam pattern, which helps improve visibility during nighttime driving. It is also ideal for fog lamps, providing enhanced clarity during adverse weather conditions. As a reputable principals in the automotive industry, Bosch ensures that this bulb meets strict quality standards, offering durability and consistent performance. Upgrade your vehicle's lighting with the BOSCH H11 Standard Bulb and enjoy improved visibility and safety on the road",
    detail: "Voltage: 12V, Wattage: 55W",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["12V Bulb"],
  },
  {
    sku: "AG0346",
    slug: "bosch-standard-bulb-h3-12v-55w",
    thumbnail: "/file/listings/AG0346.png",
    name: "BOSCH STANDARD BULB H3 (12V 55W)",
    description: "Automative Bulb",
    information: "The BOSCH H3 Standard Bulb is a reliable automotive bulb designed for various lighting applications in vehicles with a 12V electrical system. With a wattage of 55W, this bulb provides bright and focused illumination for fog lights, driving lights, or auxiliary lights. The PK22s base type ensures easy installation and a secure fit into compatible sockets. The H3 bulb is commonly used for fog lamps due to its ability to cut through fog, rain, and other adverse weather conditions, enhancing visibility and safety on the road. The BOSCH H3 Standard Bulb is engineered to meet stringent quality standards, ensuring reliability and consistent performance. As a trusted principals in the automotive industry, Bosch ensures that this bulb offers longevity and optimal lighting performance. Upgrade your vehicle's lighting with the BOSCH H3 Standard Bulb and experience improved visibility during challenging driving conditions",
    detail: "Voltage: 12V, Wattage: 55W",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["12V Bulb"],
  },
  {
    sku: "AG0347",
    slug: "bosch-standard-bulb-h4-12v-6055w",
    thumbnail: "/file/listings/AG0347.png",
    name: "BOSCH STANDARD BULB H4 (12V 60/55W)",
    description: "Automative Bulb",
    information: "The BOSCH H4 Standard Bulb is a versatile automotive bulb designed for various lighting applications in vehicles with a 12V electrical system. With a wattage of 60W for high beams and 55W for low beams, this bulb provides excellent brightness and visibility on the road. The P43t base type ensures easy installation and a secure fit into compatible sockets. The H4 bulb is a popular choice for headlights in many vehicles due to its dual-filament design, which allows it to function as both high and low beams. This makes it suitable for use in both city driving and long-distance travel. The BOSCH H4 Standard Bulb is engineered to meet stringent quality standards, ensuring reliability and consistent performance. As a trusted principals in the automotive industry, Bosch ensures that this bulb offers longevity and optimal illumination, enhancing safety during nighttime driving or adverse weather conditions. Upgrade your vehicle's lighting with the BOSCH H4 Standard Bulb and experience improved visibility and driving confidence",
    detail: "Voltage: 12V, Wattage: 60/55W",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["12V Bulb"],
  },
  {
    sku: "AG0281",
    slug: "bosch-standard-bulb-h7-12v-55w",
    thumbnail: "/file/listings/bosch-placeholder.png",
    name: "BOSCH STANDARD BULB H7 (12V 55W)",
    description: "Automative Bulb",
    information: "The BOSCH H7 Standard Bulb is a high-quality automotive bulb designed for various lighting applications in vehicles with a 12V electrical system. With a wattage of 55W and a voltage of 12V, this bulb provides excellent brightness and illumination on the road. The PX26d base type ensures easy installation and a secure fit into compatible sockets. Whether used for headlights, fog lights, or other lighting functions, the BOSCH H7 Standard Bulb delivers reliable performance and optimal visibility. It is engineered to meet stringent quality standards, making it a dependable choice for enhancing safety during nighttime driving or adverse weather conditions. As a trusted principals in the automotive industry, Bosch ensures that this bulb offers longevity and consistent performance. Upgrade your vehicle's lighting with the BOSCH H7 Standard Bulb and experience improved visibility for a safer and more enjoyable driving experience",
    detail: "Voltage: 12V, Wattage: 55W",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["12V Bulb"],
  },
  {
    sku: "AG0348",
    slug: "bosch-standard-bulb-h8-12v-35w",
    thumbnail: "/file/listings/bosch-placeholder.png",
    name: "BOSCH STANDARD BULB H8 (12V 35W)",
    description: "Automative Bulb",
    information: "The BOSCH H8 Standard Bulb is a reliable and efficient automotive bulb designed for various lighting applications in vehicles with a 12V electrical system. With a wattage of 35W and a voltage of 12V, this bulb provides a balanced combination of brightness and energy efficiency. The PGJ19-1 base type ensures easy installation and a secure fit into compatible sockets. Whether you need a new bulb for your fog lights, daytime running lights, or other applications, the BOSCH H8 Standard Bulb delivers consistent performance and long-lasting reliability. Bosch, a renowned principals in the automotive industry, ensures that this bulb meets the highest quality standards, making it a dependable choice for enhancing visibility and safety on the road. Upgrade your vehicle's lighting with the BOSCH H8 Standard Bulb and enjoy a brighter and more secure driving experience",
    detail: "Voltage: 12V, Wattage: 35W",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["12V Bulb"],
  },
  {
    sku: "AG0247",
    slug: "bosch-t4w-pure-light-6v-4w",
    thumbnail: "/file/listings/bosch-placeholder.png",
    name: "BOSCH T4W Pure Light 6V 4W",
    description: "Automative Bulb",
    information: "The BOSCH T4W Pure Light is a high-quality automotive bulb designed for various lighting applications in vehicles with a 6V electrical system. With a wattage of 4W and a voltage of 6V, this bulb provides a reliable and energy-efficient lighting solution for your car's interior and exterior lighting needs. The BA9s base type ensures easy installation and a secure fit into compatible sockets. The BOSCH T4W Pure Light is engineered with precision to deliver excellent performance and long-lasting reliability, making it an ideal choice for replacing old or worn-out bulbs in your vehicle. Whether you need a new bulb for your parking lights, license plate lights, or other applications, the BOSCH T4W Pure Light 6V 4W is a trusted and dependable option. Trust BOSCH's reputation for quality and innovation, and upgrade your vehicle's lighting with the T4W Pure Light 6V 4W for enhanced visibility and safety on the road",
    detail: "Voltage: 6V, Wattage: 4W",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["6V Bulb"],
  },
  {
    sku: "AG0310",
    slug: "bosch-trucklight-bulb-24v-10w",
    thumbnail: "/file/listings/AG0310.png",
    name: "BOSCH Trucklight Bulb 24V 10W",
    description: "Truck Bulb",
    information: "The BOSCH Trucklight Bulb 24V 10W is a reliable and efficient lighting solution designed for trucks and commercial vehicles with a 24V electrical system. This bulb features a voltage of 24V and a wattage of 10W, providing a bright and focused beam of light for various applications, such as indicator lights and side marker lights. Its BA15s base type ensures easy installation and a secure fit into compatible sockets. The BOSCH Trucklight Bulb 24V 10W is built to withstand the demands of heavy-duty use, making it ideal for commercial vehicles that require long-lasting performance. With BOSCH's reputation for quality and reliability, you can trust the Trucklight Bulb 24V 10W to deliver consistent and dependable lighting on the road. Upgrade your truck's lighting system with the BOSCH Trucklight Bulb 24V 10W, and enhance visibility and safety in various working conditions",
    detail: "Voltage: 24V, Wattage: 10W",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["24V Bulb"],
  },
  {
    sku: "AG0307",
    slug: "bosch-trucklight-bulb-24v-18w",
    thumbnail: "/file/listings/bosch-placeholder.png",
    name: "BOSCH Trucklight Bulb 24V 18W",
    description: "Truck Bulb",
    information: "The BOSCH Trucklight Bulb 24V 18W is a reliable and durable lighting solution designed for trucks and commercial vehicles with a 24V electrical system. This bulb features a voltage of 24V and a wattage of 18W, providing bright and efficient illumination for various applications, such as tail lights, brake lights, and signal lights. Its BA15s base type ensures easy installation and a secure fit into compatible sockets. The BOSCH Trucklight Bulb 24V 18W is built to withstand the demands of heavy-duty use, making it ideal for commercial vehicles that require long-lasting performance. With BOSCH's reputation for quality and reliability, you can trust the Trucklight Bulb 24V 18W to deliver consistent and dependable lighting on the road. Upgrade your truck's lighting system with the BOSCH Trucklight Bulb 24V 18W, and drive with confidence in various working conditions",
    detail: "Voltage: 24V, Wattage: 18W",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["24V Bulb"],
  },
  {
    sku: "AG0285",
    slug: "bosch-trucklight-bulb-24v-3w",
    thumbnail: "/file/listings/bosch-placeholder.png",
    name: "BOSCH Trucklight Bulb 24V 3W",
    description: "Truck Bulb",
    information: "The BOSCH Trucklight Bulb 24V 3W is a reliable lighting solution designed for trucks and commercial vehicles equipped with a 24V electrical system. The bulb features a voltage of 24V and a wattage of 3W, providing efficient illumination for various applications, including indicator lights, dashboard lights, and other small lighting fixtures. Its BA9s base type ensures easy installation and a secure fit into compatible sockets, ensuring a hassle-free replacement process. The BOSCH Trucklight Bulb 24V 3W offers consistent and long-lasting performance, ensuring optimal visibility and safety while driving. As a trusted principals in the automotive industry, BOSCH guarantees the quality and reliability of the Trucklight Bulb 24V 3W. Upgrade your truck's lighting system with the dependable and energy-efficient Trucklight Bulb 24V 3W from BOSCH, and drive with confidence on the road",
    detail: "Voltage: 24V, Wattage: 3W",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["24V Bulb"],
  },
  {
    sku: "AG0306",
    slug: "bosch-trucklight-bulb-24v-5w",
    thumbnail: "/file/listings/AG0306.png",
    name: "BOSCH Trucklight Bulb 24V 5W",
    description: "Truck Bulb",
    information: "The BOSCH Trucklight Bulb 24V 5W is a high-quality lighting solution designed for trucks and commercial vehicles with a 24V electrical system. The bulb features a voltage of 24V and a wattage of 5W, providing a reliable and energy-efficient lighting option for various applications such as indicator lights, side marker lights, or license plate lights. Its BA15s base type ensures easy installation and a secure fit into compatible sockets, making replacement quick and hassle-free. The BOSCH Trucklight Bulb 24V 5W offers consistent and long-lasting performance, ensuring your vehicle remains visible and compliant with safety regulations on the road. BOSCH, a trusted and renowned principals in the automotive industry, guarantees the quality and reliability of the Trucklight Bulb 24V 5W. Upgrade your truck's lighting system with the dependable and efficient Trucklight Bulb 24V 5W from BOSCH, and enhance safety during your journeys",
    detail: "Voltage: 24V, Wattage: 5W",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["24V Bulb"],
  },
  {
    sku: "AG0284",
    slug: "bosch-trucklight-bulb-c5-24v-5w",
    thumbnail: "/file/listings/bosch-placeholder.png",
    name: "BOSCH Trucklight Bulb C5 24V 5W",
    description: "Truck Bulb",
    information: "The BOSCH Trucklight Bulb C5 (24V 5W) is a reliable and efficient lighting solution designed for trucks and commercial vehicles with a 24V electrical system. The C5 bulb features a wattage of 5W, providing a low-power lighting option suitable for various applications such as indicator lights, side marker lights, or license plate lights. Its BA15s base type ensures easy installation and a secure fit into compatible sockets, making replacement hassle-free. The BOSCH Trucklight Bulb C5 offers consistent and long-lasting performance, ensuring your vehicle remains visible and compliant with safety regulations on the road. BOSCH, a trusted and renowned principals in the automotive industry, guarantees the quality and reliability of the Trucklight Bulb C5 (24V 5W). Upgrade your truck's lighting system with the dependable and energy-efficient Trucklight Bulb C5 from BOSCH, and enhance safety during your journeys",
    detail: "Voltage: 24V, Wattage: 5W",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["24V Bulb"],
  },
  {
    sku: "AG0216",
    slug: "bosch-trucklight-bulb-h1-24v-55w",
    thumbnail: "/file/listings/AG0216.png",
    name: "BOSCH Trucklight Bulb H1 (24V 55W)",
    description: "Truck Bulb",
    information: "The BOSCH Trucklight Bulb H1 (24V 55W) is a reliable and high-performance lighting solution designed specifically for trucks and commercial vehicles with a 24V electrical system. The H1 bulb features a wattage of 55W, providing bright and focused illumination for various applications. Its P14.5s base type ensures a secure and straightforward installation into compatible sockets, offering a reliable connection and convenient replacement. The BOSCH Trucklight Bulb H1 is commonly used as a headlight or auxiliary light, making it ideal for enhancing visibility during nighttime driving or in challenging weather conditions. With its durable construction and advanced technology, this Trucklight bulb offers an extended service life and consistent performance on the road. BOSCH, a trusted and renowned principals in the automotive industry, guarantees the quality and reliability of the Trucklight Bulb H1 (24V 55W), ensuring safe and efficient lighting for your truck's journeys. Upgrade your truck's lighting system with the reliable and high-quality Trucklight Bulb H1 from BOSCH, and experience improved visibility and safety during your travels",
    detail: "Voltage: 24V, Wattage: 55W",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["24V Bulb"],
  },
  {
    sku: "AG0212",
    slug: "bosch-trucklight-bulb-h3-24v-100w",
    thumbnail: "/file/listings/bosch-placeholder.png",
    name: "BOSCH Trucklight Bulb H3 (24V 100W)",
    description: "Truck Bulb",
    information: "The BOSCH Trucklight Bulb H3 (24V 100W) is a powerful lighting solution designed specifically for trucks and commercial vehicles with a 24V electrical system. The H3 bulb features a high wattage of 100W, providing exceptionally bright and focused illumination for various applications. Its PK22s base type ensures a secure and easy installation into compatible sockets, offering a reliable connection and convenient replacement. The BOSCH Trucklight Bulb H3 is commonly used as a fog light or auxiliary light, making it ideal for improving visibility during adverse weather conditions or low-light situations. With its robust construction and advanced technology, this Trucklight bulb offers an extended service life and dependable performance on the road. BOSCH, a renowned and trusted principals in the automotive industry, guarantees the quality and performance of the Trucklight Bulb H3 (24V 100W), ensuring safe and efficient lighting for your truck's journeys. Elevate your truck's lighting system with the powerful and efficient Trucklight Bulb H3 from BOSCH, and experience enhanced visibility and safety during your drives",
    detail: "Voltage: 24V, Wattage: 100W",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["24V Bulb"],
  },
  {
    sku: "AG0217",
    slug: "bosch-trucklight-bulb-h3-24v-70w",
    thumbnail: "/file/listings/AG0217.png",
    name: "BOSCH Trucklight Bulb H3 (24V 70W)",
    description: "Truck Bulb",
    information: "The BOSCH Trucklight Bulb H3 (24V 70W) is a high-quality lighting solution designed specifically for trucks and commercial vehicles with a 24V electrical system. The H3 bulb features a powerful wattage of 70W, providing bright and focused illumination for various applications. Its PK22s base type ensures a secure and easy installation into compatible sockets, providing a reliable connection and hassle-free replacement. The BOSCH Trucklight Bulb H3 is commonly used as a fog light or auxiliary light, helping to improve visibility during adverse weather conditions or low-light situations. With its durable construction and advanced technology, this Trucklight bulb offers a long service life and reliable performance on the road. BOSCH, a trusted and reputable principals in the automotive industry, guarantees the quality and performance of the Trucklight Bulb H3 (24V 70W), ensuring safe and efficient lighting for your truck's journeys. Upgrade your truck's lighting system with the reliable and efficient Trucklight Bulb H3 from BOSCH, and experience improved visibility and safety during your drives",
    detail: "Voltage: 24V, Wattage: 70W",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["24V Bulb"],
  },
  {
    sku: "AG0308",
    slug: "bosch-trucklight-bulb-h3-24v-70w",
    thumbnail: "/file/listings/AG0217.png",
    name: "BOSCH Trucklight Bulb H3 (24V 70W)",
    description: "Truck Bulb",
    information: "The BOSCH Trucklight Bulb H3 (24V 70W) is a high-quality lighting solution designed specifically for trucks and commercial vehicles with a 24V electrical system. The H3 bulb features a powerful wattage of 70W, providing bright and focused illumination for various applications. Its PK22s base type ensures a secure and easy installation into compatible sockets, providing a reliable connection and hassle-free replacement. The BOSCH Trucklight Bulb H3 is commonly used as a fog light or auxiliary light, helping to improve visibility during adverse weather conditions or low-light situations. With its durable construction and advanced technology, this Trucklight bulb offers a long service life and reliable performance on the road. BOSCH, a trusted and reputable principals in the automotive industry, guarantees the quality and performance of the Trucklight Bulb H3 (24V 70W), ensuring safe and efficient lighting for your truck's journeys. Upgrade your truck's lighting system with the reliable and efficient Trucklight Bulb H3 from BOSCH, and experience improved visibility and safety during your drives",
    detail: "Voltage: 24V, Wattage: 70W",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["24V Bulb"],
  },
  {
    sku: "AG0213",
    slug: "bosch-trucklight-bulb-h4-24v-7570w",
    thumbnail: "/file/listings/AG0213.png",
    name: "BOSCH Trucklight Bulb H4 (24V 75/70W)",
    description: "Truck Bulb",
    information: "The BOSCH Trucklight Bulb H4 is a high-quality lighting solution designed specifically for trucks and commercial vehicles with a 24V electrical system. This dual-function bulb provides both high beam and low beam options, with a powerful wattage of 75W for the high beam and 70W for the low beam. The P43t base type ensures a secure and easy installation into compatible sockets, providing a reliable connection and hassle-free replacement. The H4 design offers versatile lighting options, making it suitable for use as both the main headlight and the dipped beam in various truck models. With its advanced technology and durable construction, the BOSCH Trucklight Bulb H4 (24V 75/70W) delivers bright and focused illumination, enhancing visibility and safety during night-time driving or in adverse weather conditions. BOSCH, a trusted and reputable principals in the automotive industry, guarantees the quality and performance of this Trucklight bulb, ensuring reliable lighting for your truck's journeys. Upgrade your truck's lighting system with the reliable and efficient Trucklight Bulb H4 from BOSCH, and experience superior visibility and safety on the road",
    detail: "Voltage: 24V, Wattage: 75/70W",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["24V Bulb"],
  },
  {
    sku: "AG0251",
    slug: "bosch-trucklight-bulb-h7-24v-70w",
    thumbnail: "/file/listings/AG0251.png",
    name: "BOSCH Trucklight Bulb H7 (24V 70W)",
    description: "Truck Bulb",
    information: "The BOSCH Trucklight Bulb H7 is a high-performance lighting solution designed specifically for trucks and commercial vehicles with a 24V electrical system. With a powerful wattage of 70W, this bulb emits a bright and focused beam of light, making it perfect for various lighting applications, including headlights and fog lights. The PX26d base type ensures a secure and easy installation into compatible sockets, providing a reliable connection and hassle-free replacement. Whether used for long-haul drives or heavy-duty operations, the BOSCH Trucklight Bulb H7 (24V 70W) delivers excellent illumination, enhancing visibility and safety on the road. BOSCH, a trusted and reputable principals in the automotive industry, guarantees the quality and durability of this Trucklight bulb, ensuring reliable performance even in challenging driving conditions. The 24V voltage rating makes it compatible with a wide range of truck electrical systems, offering versatility for different truck models and applications. Upgrade your truck's lighting system with the dependable and efficient Trucklight Bulb H7 from BOSCH, and experience improved visibility and safety during your journeys",
    detail: "Voltage: 24V, Wattage: 70W",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["24V Bulb"],
  },
  {
    sku: "AG0333",
    slug: "bosch-trucklight-bulb-h7-24v-70w",
    thumbnail: "/file/listings/AG0251.png",
    name: "BOSCH Trucklight Bulb H7 (24V 70W)",
    description: "Truck Bulb",
    information: "The BOSCH Trucklight Bulb H7 is a high-performance lighting solution designed specifically for trucks and commercial vehicles with a 24V electrical system. With a powerful wattage of 70W, this bulb emits a bright and focused beam of light, making it perfect for various lighting applications, including headlights and fog lights. The PX26d base type ensures a secure and easy installation into compatible sockets, providing a reliable connection and hassle-free replacement. Whether used for long-haul drives or heavy-duty operations, the BOSCH Trucklight Bulb H7 (24V 70W) delivers excellent illumination, enhancing visibility and safety on the road. BOSCH, a trusted and reputable principals in the automotive industry, guarantees the quality and durability of this Trucklight bulb, ensuring reliable performance even in challenging driving conditions. The 24V voltage rating makes it compatible with a wide range of truck electrical systems, offering versatility for different truck models and applications. Upgrade your truck's lighting system with the dependable and efficient Trucklight Bulb H7 from BOSCH, and experience improved visibility and safety during your journeys",
    detail: "Voltage: 24V, Wattage: 70W",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["24V Bulb"],
  },
  {
    sku: "AG0214",
    slug: "bosch-trucklight-bulb-p21w-24v-21w",
    thumbnail: "/file/listings/AG0214.png",
    name: "BOSCH Trucklight Bulb P21W (24V 21W)",
    description: "Truck Bulb",
    information: "The BOSCH Trucklight Bulb P21W is a high-quality lighting solution designed for trucks and commercial vehicles operating with a 24V electrical system. With a wattage of 21W, this bulb emits a powerful and consistent light, making it ideal for various signaling and lighting applications in the vehicle. The BA15s base type ensures easy installation into compatible sockets, providing a secure fit and simple replacement. Whether used as brake lights, rear indicator lights, or taillights, the BOSCH Trucklight Bulb P21W (24V 21W) delivers clear and bright illumination, enhancing visibility and safety on the road. BOSCH, a trusted principals in the automotive industry, guarantees that this Trucklight bulb meets rigorous quality standards, offering reliability and durability during your travels. The 24V voltage rating ensures compatibility with a wide range of truck electrical systems, providing versatility for different truck models and applications. Upgrade your truck's lighting system with the dependable and efficient Trucklight Bulb P21W from BOSCH, and experience improved visibility and safety in all driving conditions",
    detail: "Voltage: 24V, Wattage: 21W",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["24V Bulb"],
  },
  {
    sku: "AG0215",
    slug: "bosch-trucklight-bulb-r10w-24v-10w",
    thumbnail: "/file/listings/bosch-placeholder.png",
    name: "BOSCH Trucklight Bulb R10W (24V 10W)",
    description: "Truck Bulb",
    information: "The BOSCH Trucklight Bulb R10W is a reliable lighting solution designed specifically for trucks and commercial vehicles with a 24V electrical system. With a wattage of 10W, this bulb provides a strong and consistent light output, making it suitable for various signaling and rear lighting applications in the vehicle. The BA15s base type ensures easy and secure installation in compatible sockets, allowing for quick and hassle-free replacement. Whether used for rear indicator lights, brake lights, or taillights, the BOSCH Trucklight Bulb R10W (24V 10W) delivers clear and bright illumination, enhancing visibility and safety on the road. BOSCH, a reputable principals in the automotive industry, ensures that this Trucklight bulb meets stringent quality standards, offering reliability and durability during your journeys. The 24V voltage rating makes it compatible with a wide range of truck electrical systems, providing versatility in its applications. Upgrade your truck's rear lighting system with this dependable and efficient Trucklight bulb from BOSCH, and experience improved visibility and safety in all driving conditions",
    detail: "Voltage: 24V, Wattage: 10W",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["24V Bulb"],
  },
  {
    sku: "AG0309",
    slug: "bosch-trucklight-bulb-r5w-24v-5w",
    thumbnail: "/file/listings/bosch-placeholder.png",
    name: "BOSCH Trucklight Bulb R5W 24V 5W",
    description: "Truck Bulb",
    information: "The BOSCH Trucklight Bulb R5W is a high-quality lighting solution designed for trucks and commercial vehicles with a 24V electrical system. With a wattage of 5W, this bulb provides a reliable and efficient light output, making it suitable for various signaling and rear lighting applications in the vehicle. The BA15s base type ensures easy and secure installation in compatible sockets, allowing for quick and hassle-free replacement. Whether used for brake lights, taillights, or other rear lighting functions, the BOSCH Trucklight Bulb R5W (24V 5W) delivers consistent and bright illumination, enhancing visibility and safety on the road. BOSCH, a renowned name in the automotive industry, ensures that this Trucklight bulb meets strict quality standards, offering reliability and durability during your travels. The 24V voltage rating makes it compatible with a wide range of truck electrical systems, providing versatility in its applications. Upgrade your truck's rear lighting system with this dependable and efficient Trucklight bulb from BOSCH, and experience improved visibility and safety during all driving conditions",
    detail: "Voltage: 24V, Wattage: 5W",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["24V Bulb"],
  },
  {
    sku: "AG0218",
    slug: "bosch-trucklight-bulb-w5w-24v-5w",
    thumbnail: "/file/listings/AG0218.png",
    name: "BOSCH Trucklight Bulb W5W (24V 5W)",
    description: "Truck Bulb",
    information: "The BOSCH Trucklight Bulb W5W is a reliable lighting solution designed for trucks and commercial vehicles with a 24V electrical system. With a wattage of 5W, this bulb provides a bright and efficient light output, making it ideal for various lighting applications in the vehicle. The W2.1x9.5d base type ensures easy and secure installation in compatible sockets, allowing for quick and hassle-free replacement. Whether used for interior lights, license plate lights, parking lights, or other auxiliary lighting functions, the BOSCH Trucklight Bulb W5W (24V 5W) delivers consistent and dependable performance. BOSCH, a trusted name in the automotive industry, ensures that this Trucklight bulb meets high-quality standards, providing reliability and durability on the road. The 24V voltage rating makes it compatible with a wide range of truck electrical systems, ensuring versatility in its applications. Upgrade your truck's lighting system with this reliable and efficient Trucklight bulb from BOSCH, and experience improved visibility and safety during your travels",
    detail: "Voltage: 24V, Wattage: 5W",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["24V Bulb"],
  },
  {
    sku: "AG0242",
    slug: "bosch-trucklight-bulk-h1-24v-100w",
    thumbnail: "/file/listings/bosch-placeholder.png",
    name: "BOSCH Trucklight Bulk H1 (24V 100W)",
    description: "Truck Bulb",
    information: "The BOSCH Trucklight Bulb H1 is a high-performance lighting solution designed for trucks and commercial vehicles with a 24V electrical system. With a powerful wattage of 100W, this bulb provides a bright and intense light output, ensuring optimal visibility on the road, especially during nighttime and adverse weather conditions. The P14.5s base type enables easy and secure installation in compatible sockets, making replacement hassle-free. BOSCH, a reputable name in the automotive industry, ensures that this Trucklight bulb meets rigorous quality standards, guaranteeing its reliability and durability. The 24V voltage rating ensures compatibility with a wide range of truck electrical systems, ensuring consistent performance. Whether used for long-distance haulage, off-road driving, or other commercial applications, the BOSCH Trucklight Bulb H1 (24V 100W) provides superior lighting performance and improved safety for truck drivers. Upgrade your truck's lighting system with this high-quality Trucklight bulb from BOSCH, and experience enhanced visibility and safety during your journeys",
    detail: "Voltage: 24V, Wattage: 100W",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["24V Bulb"],
  },
  {
    sku: "AG0241",
    slug: "bosch-trucklight-bulk-h1-24v-70w",
    thumbnail: "/file/listings/AG0241.png",
    name: "BOSCH Trucklight Bulk H1 (24V 70W)",
    description: "Truck Bulb",
    information: "The BOSCH Trucklight Bulb H1 is a reliable and high-performance automotive bulb designed specifically for trucks and commercial vehicles with a 24V electrical system. With a wattage of 70W, this bulb provides bright and clear illumination, making it suitable for various applications where enhanced visibility is crucial. The P14.5s base type ensures easy installation and a secure fit in compatible sockets, reducing the hassle of replacing bulbs. BOSCH, a trusted name in the automotive industry, ensures that this Trucklight bulb meets stringent quality standards, making it a dependable choice for truck owners looking for efficient lighting solutions. The 24V voltage rating ensures compatibility with a wide range of truck electrical systems, ensuring that the Trucklight H1 bulb performs reliably and consistently. Whether driving on highways, off-road, or in adverse weather conditions, the BOSCH Trucklight Bulb H1 (24V 70W) provides bright and focused illumination, improving safety and visibility during your journeys. Upgrade your truck's lighting system with this high-quality Trucklight bulb from BOSCH, and experience superior lighting performance for all your driving needs",
    detail: "Voltage: 24V, Wattage: 70W",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["24V Bulb"],
  },
  {
    sku: "AG0250",
    slug: "bosch-trucklight-bulb-h3-24v-130w",
    thumbnail: "/file/listings/bosch-placeholder.png",
    name: "BOSCH Trucklight Bulb H3 (24V 130W)",
    description: "Truck Bulb",
    information: "The BOSCH Trucklight Bulb H3 is a high-quality automotive bulb designed for trucks and commercial vehicles with a 24V electrical system. It features a powerful 130W output, making it suitable for various lighting applications, including fog lights and auxiliary lamps. The PK22s base type ensures a secure fit in compatible sockets, ensuring easy installation and reliable performance. With its 24V voltage rating, the H3 bulb is specially crafted to meet the demands of heavy-duty vehicles, providing bright and clear illumination even in challenging driving conditions. As a product from BOSCH, a reputable principals known for its commitment to quality and reliability, you can trust the Trucklight H3 bulb to deliver exceptional lighting performance and enhance the safety of your truck on the road. Whether you need to improve visibility in adverse weather conditions or illuminate dark roads, the BOSCH Trucklight H3 bulb is a dependable choice that ensures you can navigate with confidence and clarity. Upgrade your truck's lighting system with the BOSCH Trucklight Bulb H3 to experience superior illumination and enhanced safety during your journeys",
    detail: "Voltage: 24V, Wattage: 130W",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["24V Bulb"],
  },
  {
    sku: "AG0257",
    slug: "bosch-trucklight-bulb-h3-24v-55w",
    thumbnail: "/file/listings/bosch-placeholder.png",
    name: "BOSCH Trucklight Bulb H3 (24V 55W)",
    description: "Truck Bulb",
    information: "The BOSCH Trucklight Bulb H3 is a high-quality automotive bulb designed for trucks and commercial vehicles with a 24V electrical system. It features a powerful 55W output, making it suitable for various lighting applications, including fog lights and auxiliary lamps. The PK22s base type ensures a secure fit in compatible sockets, ensuring easy installation and reliable performance. With its 24V voltage rating, the H3 bulb is specially crafted to meet the demands of heavy-duty vehicles, providing bright and clear illumination even in challenging driving conditions. As a product from BOSCH, a reputable principals known for its commitment to quality and reliability, you can trust the Trucklight H3 bulb to deliver exceptional lighting performance and enhance the safety of your truck on the road. Whether you need to improve visibility in adverse weather conditions or illuminate dark roads, the BOSCH Trucklight H3 bulb is a dependable choice that ensures you can navigate with confidence and clarity. Upgrade your truck's lighting system with the BOSCH Trucklight Bulb H3 to experience superior illumination and enhanced safety during your journeys",
    detail: "Voltage: 24V, Wattage: 55W",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["24V Bulb"],
  },
  {
    sku: "AG0273",
    slug: "bosch-trucklight-h21w-24v-21w",
    thumbnail: "/file/listings/bosch-placeholder.png",
    name: "BOSCH Trucklight H21W (24V 21W)",
    description: "Truck Bulb",
    information: "The BOSCH Trucklight H21W is a reliable and efficient automotive bulb specifically designed for trucks and commercial vehicles with a 24V electrical system. It provides a powerful 21W output, making it ideal for various lighting applications, including indicator and signal lights. The BAY9s base type ensures a secure fit in compatible sockets, making installation quick and hassle-free. With its 24V voltage rating, the H21W is suitable for heavy-duty vehicles, ensuring consistent and optimal performance even in demanding driving conditions. As a product from BOSCH, a renowned principals known for its quality and durability, you can trust the Trucklight H21W to deliver reliable illumination and contribute to the safety of your truck on the road. Whether you need to indicate a turn, change lanes, or signal other drivers, the BOSCH Trucklight H21W will provide bright and clear lighting, enhancing visibility and ensuring that your intentions are clearly communicated to others. Upgrade your truck's lighting system with the BOSCH Trucklight H21W for dependable performance and increased safety",
    detail: "Voltage: 24V, Wattage: 21W",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["24V Bulb"],
  },
  {
    sku: "AG0253",
    slug: "bosch-trucklight-p215w-24v-215w",
    thumbnail: "/file/listings/AG0253.png",
    name: "BOSCH Trucklight P21/5W (24V 21/5W)",
    description: "Truck Bulb",
    information: "The BOSCH Trucklight P21/5W is a high-quality automotive bulb designed for trucks and commercial vehicles with a 24V electrical system. It features a dual wattage of 21/5W, providing both a powerful 21W light for brake and tail functions and a 5W light for parking and position functions. This versatile bulb ensures optimal visibility and safety on the road, enhancing the visibility of your vehicle to other drivers. The BAY15d base type makes it easy to install in compatible sockets, ensuring a secure and reliable connection. Whether you need to signal a braking maneuver, indicate a turn, or simply illuminate your truck's position, the BOSCH Trucklight P21/5W delivers consistent and efficient performance. As a product from BOSCH, a reputable automotive principals known for its reliability and durability, you can trust the Trucklight P21/5W to meet the demanding requirements of heavy-duty use. Upgrade your truck's lighting system with the BOSCH Trucklight P21/5W to ensure safety and visibility in various driving conditions",
    detail: "Voltage: 24V, Wattage: 21/5W",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["24V Bulb"],
  },
  {
    sku: "AG0272",
    slug: "bosch-trucklight-r2-24v-5550w",
    thumbnail: "/file/listings/bosch-placeholder.png",
    name: "BOSCH Trucklight R2 (24V 55/50W)",
    description: "Truck Bulb",
    information: "The BOSCH Trucklight R2 is a powerful and efficient headlight bulb designed for use in trucks and commercial vehicles with a 24V electrical system. With a dual wattage of 55/50W, this bulb provides a strong and consistent beam of light, ensuring optimal visibility and safety during nighttime driving. It features a P45t base type, making it easy to install in compatible headlight assemblies. The Trucklight R2 is specifically designed to fit various truck models, offering a perfect replacement for your existing headlight bulbs. Whether you are driving on highways or challenging terrains, the BOSCH Trucklight R2 delivers reliable performance, illuminating the road ahead with clarity. As a product from BOSCH, a trusted automotive principals, you can trust the Trucklight R2 for its durability, long-lasting performance, and ability to withstand the demands of heavy-duty use. Upgrade your truck's headlights with the BOSCH Trucklight R2 for enhanced visibility and a safer driving experience",
    detail: "Voltage: 24V, Wattage: 55/50W",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["24V Bulb"],
  },
  {
    sku: "AG0254",
    slug: "bosch-trucklight-r5w-24v-5w-150",
    thumbnail: "/file/listings/bosch-placeholder.png",
    name: "BOSCH Trucklight R5W (24V 5W 150)",
    description: "Truck Bulb",
    information: "The BOSCH Trucklight R5W is a reliable and efficient bulb designed for use in trucks and commercial vehicles with a 24V electrical system. With a wattage of 5W, this bulb provides a bright and consistent light output, ensuring excellent visibility and safety on the road. It features a BA15d base type, making it easy to install in compatible light fixtures. The Trucklight R5W is specifically designed to fit various truck models, offering a perfect replacement for your existing bulbs. Whether you need to replace a worn-out bulb or upgrade your truck's lighting system, the BOSCH Trucklight R5W is a dependable choice that delivers superior illumination for your journeys. As a product from BOSCH, a trusted automotive principals, you can rely on the Trucklight R5W for its durability, performance, and long-lasting reliability",
    detail: "Voltage: 24V, Wattage: 5W",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["24V Bulb"],
  },
  {
    sku: "AG0252",
    slug: "bosch-trucklight-t4w-24v-4w-249",
    thumbnail: "/file/listings/bosch-placeholder.png",
    name: "BOSCH Trucklight T4W (24V 4W 249)",
    description: "Truck Bulb",
    information: "The BOSCH Trucklight T4W is a high-quality bulb designed for use in trucks and commercial vehicles with a 24V electrical system. With a wattage of 4W, this bulb provides a bright and reliable light output, ensuring excellent visibility and safety on the road. It features a BA9s base type, making it easy to install in compatible light fixtures. The Trucklight T4W is specifically designed to fit various truck models, offering a perfect replacement for your existing bulbs. As a product from BOSCH, a trusted automotive principals, you can count on the Trucklight T4W for its durability, performance, and long-lasting reliability. Whether you need to replace a damaged bulb or simply upgrade your truck's lighting, the BOSCH Trucklight T4W is a dependable choice that delivers superior illumination for your journeys",
    detail: "Voltage: 24V, Wattage: 4W",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["24V Bulb"],
  },
  {
    sku: "AG0271",
    slug: "bosch-trucklight-w3w-24v-3w",
    thumbnail: "/file/listings/bosch-placeholder.png",
    name: "BOSCH Trucklight W3W (24V 3W)",
    description: "Truck Bulb",
    information: "The BOSCH Trucklight W3W is a reliable and efficient bulb designed for use in trucks and commercial vehicles operating on a 24V electrical system. With a wattage of 3W, this bulb provides a bright and clear light output, ensuring enhanced visibility on the road. It is specifically designed to fit and function seamlessly in various truck models, making it a perfect replacement for your existing bulbs. As a product from BOSCH, a renowned automotive principals, you can trust the Trucklight W3W for its durability, performance, and longevity. Whether you need to replace a burnt-out bulb or want to upgrade your truck's lighting, the BOSCH Trucklight W3W is a dependable choice that ensures safety and reliability on your journeys",
    detail: "Voltage: 24V, Wattage: 3W",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["24V Bulb"],
  },
  {
    sku: "AG0263",
    slug: "bosch-windtone-24v-410510hz",
    thumbnail: "/file/listings/bosch-placeholder.png",
    name: "BOSCH WINDTONE (24V 410/510Hz)",
    description: "Horn",
    information: "The BOSCH WINDTONE is a reliable and powerful horn designed to deliver clear and attention-grabbing sound signals. Operating at 24 volts, this horn produces two distinct frequencies of 410Hz and 510Hz, ensuring an effective and noticeable alert to surrounding vehicles and pedestrians. Whether you need to warn others in emergency situations or provide a standard signal, the BOSCH WINDTONE's powerful and dual-frequency sound ensures enhanced safety on the road. As a trusted product from BOSCH, a reputable automotive principals, you can rely on the WINDTONE for its durability and performance, making it a valuable addition to any vehicle",
    detail: "Frequency: 410/510Hz, Voltage: 24V",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["24V Horn"],
  },
  {
    sku: "AG0192",
    slug: "bosch-wiper-blade-ap400u",
    thumbnail: "/file/listings/AG0192.png",
    name: "BOSCH WIPER BLADE - AP400U",
    description: "Aerotwin flat wiper blade",
    information: "Under adverse weather conditions, maintaining clear visibility of the rear is just as important as the view of the front, be it while in traffic or when reversing into a parking space. Bosch rear wiper blades deliver the best possible wiping results with long service life as you would expect of any Bosch wiper blade. Be safe, and maintain vision clarity with Bosch wipers",
    detail: "The windshield wiper blades from BOSCH are engineered to deliver exceptional performance and durability. With a wear-resistant natural rubber wiping edge, these blades offer a long service life, ensuring consistent and effective wiping even in challenging weather conditions. The specially coated wiping element enhances smooth and quiet operation, providing a pleasant driving experience. Each blade is carefully designed to fit specific wiping systems, ensuring optimal compatibility and performance. BOSCH windshield wiper blades are a reliable choice for drivers seeking high-quality and efficient wipers to maintain clear visibility on their vehicles' windshields",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["AP Wipers"],
  },
  {
    sku: "AG0193",
    slug: "bosch-wiper-blade-ap425u",
    thumbnail: "/file/listings/AG0193.png",
    name: "BOSCH WIPER BLADE - AP425U",
    description: "Aerotwin flat wiper blade",
    information: "Under adverse weather conditions, maintaining clear visibility of the rear is just as important as the view of the front, be it while in traffic or when reversing into a parking space. Bosch rear wiper blades deliver the best possible wiping results with long service life as you would expect of any Bosch wiper blade. Be safe, and maintain vision clarity with Bosch wipers",
    detail: "The windshield wiper blades from BOSCH are engineered to deliver exceptional performance and durability. With a wear-resistant natural rubber wiping edge, these blades offer a long service life, ensuring consistent and effective wiping even in challenging weather conditions. The specially coated wiping element enhances smooth and quiet operation, providing a pleasant driving experience. Each blade is carefully designed to fit specific wiping systems, ensuring optimal compatibility and performance. BOSCH windshield wiper blades are a reliable choice for drivers seeking high-quality and efficient wipers to maintain clear visibility on their vehicles' windshields",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["AP Wipers"],
  },
  {
    sku: "AG0224",
    slug: "bosch-wiper-blade-ap450u",
    thumbnail: "/file/listings/AG0224.png",
    name: "BOSCH WIPER BLADE - AP450U",
    description: "Aerotwin flat wiper blade",
    information: "Under adverse weather conditions, maintaining clear visibility of the rear is just as important as the view of the front, be it while in traffic or when reversing into a parking space. Bosch rear wiper blades deliver the best possible wiping results with long service life as you would expect of any Bosch wiper blade. Be safe, and maintain vision clarity with Bosch wipers",
    detail: "The windshield wiper blades from BOSCH are engineered to deliver exceptional performance and durability. With a wear-resistant natural rubber wiping edge, these blades offer a long service life, ensuring consistent and effective wiping even in challenging weather conditions. The specially coated wiping element enhances smooth and quiet operation, providing a pleasant driving experience. Each blade is carefully designed to fit specific wiping systems, ensuring optimal compatibility and performance. BOSCH windshield wiper blades are a reliable choice for drivers seeking high-quality and efficient wipers to maintain clear visibility on their vehicles' windshields",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["AP Wipers"],
  },
  {
    sku: "AG0194",
    slug: "bosch-wiper-blade-ap475u",
    thumbnail: "/file/listings/AG0194.png",
    name: "BOSCH WIPER BLADE - AP475U",
    description: "Aerotwin flat wiper blade",
    information: "Under adverse weather conditions, maintaining clear visibility of the rear is just as important as the view of the front, be it while in traffic or when reversing into a parking space. Bosch rear wiper blades deliver the best possible wiping results with long service life as you would expect of any Bosch wiper blade. Be safe, and maintain vision clarity with Bosch wipers",
    detail: "The windshield wiper blades from BOSCH are engineered to deliver exceptional performance and durability. With a wear-resistant natural rubber wiping edge, these blades offer a long service life, ensuring consistent and effective wiping even in challenging weather conditions. The specially coated wiping element enhances smooth and quiet operation, providing a pleasant driving experience. Each blade is carefully designed to fit specific wiping systems, ensuring optimal compatibility and performance. BOSCH windshield wiper blades are a reliable choice for drivers seeking high-quality and efficient wipers to maintain clear visibility on their vehicles' windshields",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["AP Wipers"],
  },
  {
    sku: "AG0225",
    slug: "bosch-wiper-blade-ap500u",
    thumbnail: "/file/listings/AG0225.png",
    name: "BOSCH WIPER BLADE - AP500U",
    description: "Aerotwin flat wiper blade",
    information: "Under adverse weather conditions, maintaining clear visibility of the rear is just as important as the view of the front, be it while in traffic or when reversing into a parking space. Bosch rear wiper blades deliver the best possible wiping results with long service life as you would expect of any Bosch wiper blade. Be safe, and maintain vision clarity with Bosch wipers",
    detail: "The windshield wiper blades from BOSCH are engineered to deliver exceptional performance and durability. With a wear-resistant natural rubber wiping edge, these blades offer a long service life, ensuring consistent and effective wiping even in challenging weather conditions. The specially coated wiping element enhances smooth and quiet operation, providing a pleasant driving experience. Each blade is carefully designed to fit specific wiping systems, ensuring optimal compatibility and performance. BOSCH windshield wiper blades are a reliable choice for drivers seeking high-quality and efficient wipers to maintain clear visibility on their vehicles' windshields",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["AP Wipers"],
  },
  {
    sku: "AG0226",
    slug: "bosch-wiper-blade-ap530u",
    thumbnail: "/file/listings/AG0226.png",
    name: "BOSCH WIPER BLADE - AP530U",
    description: "Aerotwin flat wiper blade",
    information: "Under adverse weather conditions, maintaining clear visibility of the rear is just as important as the view of the front, be it while in traffic or when reversing into a parking space. Bosch rear wiper blades deliver the best possible wiping results with long service life as you would expect of any Bosch wiper blade. Be safe, and maintain vision clarity with Bosch wipers",
    detail: "The windshield wiper blades from BOSCH are engineered to deliver exceptional performance and durability. With a wear-resistant natural rubber wiping edge, these blades offer a long service life, ensuring consistent and effective wiping even in challenging weather conditions. The specially coated wiping element enhances smooth and quiet operation, providing a pleasant driving experience. Each blade is carefully designed to fit specific wiping systems, ensuring optimal compatibility and performance. BOSCH windshield wiper blades are a reliable choice for drivers seeking high-quality and efficient wipers to maintain clear visibility on their vehicles' windshields",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["AP Wipers"],
  },
  {
    sku: "AG0227",
    slug: "bosch-wiper-blade-ap550u",
    thumbnail: "/file/listings/AG0227.png",
    name: "BOSCH WIPER BLADE - AP550U",
    description: "Aerotwin flat wiper blade",
    information: "Under adverse weather conditions, maintaining clear visibility of the rear is just as important as the view of the front, be it while in traffic or when reversing into a parking space. Bosch rear wiper blades deliver the best possible wiping results with long service life as you would expect of any Bosch wiper blade. Be safe, and maintain vision clarity with Bosch wipers",
    detail: "The windshield wiper blades from BOSCH are engineered to deliver exceptional performance and durability. With a wear-resistant natural rubber wiping edge, these blades offer a long service life, ensuring consistent and effective wiping even in challenging weather conditions. The specially coated wiping element enhances smooth and quiet operation, providing a pleasant driving experience. Each blade is carefully designed to fit specific wiping systems, ensuring optimal compatibility and performance. BOSCH windshield wiper blades are a reliable choice for drivers seeking high-quality and efficient wipers to maintain clear visibility on their vehicles' windshields",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["AP Wipers"],
  },
  {
    sku: "AG0197",
    slug: "bosch-wiper-blade-ap575u",
    thumbnail: "/file/listings/bosch-placeholder.png",
    name: "BOSCH WIPER BLADE - AP575U",
    description: "Bosch wiper blade",
    information: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium",
    detail: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["AP Wipers"],
  },
  {
    sku: "AG0198",
    slug: "bosch-wiper-blade-ap600u",
    thumbnail: "/file/listings/AG0198.png",
    name: "BOSCH WIPER BLADE - AP600U",
    description: "Aerotwin flat wiper blade",
    information: "Under adverse weather conditions, maintaining clear visibility of the rear is just as important as the view of the front, be it while in traffic or when reversing into a parking space. Bosch rear wiper blades deliver the best possible wiping results with long service life as you would expect of any Bosch wiper blade. Be safe, and maintain vision clarity with Bosch wipers",
    detail: "The windshield wiper blades from BOSCH are engineered to deliver exceptional performance and durability. With a wear-resistant natural rubber wiping edge, these blades offer a long service life, ensuring consistent and effective wiping even in challenging weather conditions. The specially coated wiping element enhances smooth and quiet operation, providing a pleasant driving experience. Each blade is carefully designed to fit specific wiping systems, ensuring optimal compatibility and performance. BOSCH windshield wiper blades are a reliable choice for drivers seeking high-quality and efficient wipers to maintain clear visibility on their vehicles' windshields",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["AP Wipers"],
  },
  {
    sku: "AG0199",
    slug: "bosch-wiper-blade-ap650u",
    thumbnail: "/file/listings/AG0199.png",
    name: "BOSCH WIPER BLADE - AP650U",
    description: "Aerotwin flat wiper blade",
    information: "Under adverse weather conditions, maintaining clear visibility of the rear is just as important as the view of the front, be it while in traffic or when reversing into a parking space. Bosch rear wiper blades deliver the best possible wiping results with long service life as you would expect of any Bosch wiper blade. Be safe, and maintain vision clarity with Bosch wipers",
    detail: "The windshield wiper blades from BOSCH are engineered to deliver exceptional performance and durability. With a wear-resistant natural rubber wiping edge, these blades offer a long service life, ensuring consistent and effective wiping even in challenging weather conditions. The specially coated wiping element enhances smooth and quiet operation, providing a pleasant driving experience. Each blade is carefully designed to fit specific wiping systems, ensuring optimal compatibility and performance. BOSCH windshield wiper blades are a reliable choice for drivers seeking high-quality and efficient wipers to maintain clear visibility on their vehicles' windshields",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["AP Wipers"],
  },
  {
    sku: "AG0200",
    slug: "bosch-wiper-blade-ap700u",
    thumbnail: "/file/listings/AG0200.png",
    name: "BOSCH WIPER BLADE - AP700U",
    description: "Aerotwin flat wiper blade",
    information: "Under adverse weather conditions, maintaining clear visibility of the rear is just as important as the view of the front, be it while in traffic or when reversing into a parking space. Bosch rear wiper blades deliver the best possible wiping results with long service life as you would expect of any Bosch wiper blade. Be safe, and maintain vision clarity with Bosch wipers",
    detail: "The windshield wiper blades from BOSCH are engineered to deliver exceptional performance and durability. With a wear-resistant natural rubber wiping edge, these blades offer a long service life, ensuring consistent and effective wiping even in challenging weather conditions. The specially coated wiping element enhances smooth and quiet operation, providing a pleasant driving experience. Each blade is carefully designed to fit specific wiping systems, ensuring optimal compatibility and performance. BOSCH windshield wiper blades are a reliable choice for drivers seeking high-quality and efficient wipers to maintain clear visibility on their vehicles' windshields",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["AP Wipers"],
  },
  {
    sku: "AG0228",
    slug: "bosch-wiper-blade-ap750u",
    thumbnail: "/file/listings/bosch-placeholder.png",
    name: "BOSCH WIPER BLADE - AP750U",
    description: "Aerotwin flat wiper blade",
    information: "Under adverse weather conditions, maintaining clear visibility of the rear is just as important as the view of the front, be it while in traffic or when reversing into a parking space. Bosch rear wiper blades deliver the best possible wiping results with long service life as you would expect of any Bosch wiper blade. Be safe, and maintain vision clarity with Bosch wipers",
    detail: "The windshield wiper blades from BOSCH are engineered to deliver exceptional performance and durability. With a wear-resistant natural rubber wiping edge, these blades offer a long service life, ensuring consistent and effective wiping even in challenging weather conditions. The specially coated wiping element enhances smooth and quiet operation, providing a pleasant driving experience. Each blade is carefully designed to fit specific wiping systems, ensuring optimal compatibility and performance. BOSCH windshield wiper blades are a reliable choice for drivers seeking high-quality and efficient wipers to maintain clear visibility on their vehicles' windshields",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["AP Wipers"],
  },
  {
    sku: "AG0228",
    slug: "bosch-wiper-blade-ba14-14350mm",
    thumbnail: "/file/listings/bosch-placeholder.png",
    name: 'BOSCH WIPER BLADE - BA14 (14"/350mm)',
    description: "Wiper Blade",
    information: "Durable, streak-free wiping with easy installation for various vehicle models. Trusted BOSCH quality for clear windshield cleaning",
    detail: "The BOSCH WIPER BLADE - BA14 is a 14-inch (350mm) wiper blade designed to fit various vehicle models. It is constructed with a wear-resistant natural rubber wiping edge, ensuring durability and reliable wiping performance. The wiper blade provides streak-free and smooth wiping action, improving visibility during rainy and other weather conditions. Its easy installation process allows for quick and hassle-free replacement. The BOSCH BA14 wiper blade is designed for windshield wiping and is compatible with different vehicle models. With the trusted principals of BOSCH, you can count on the quality and performance of this wiper blade for clear and efficient windshield cleaning",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["BA Wipers"],
  },
  {
    sku: "AG0202",
    slug: "bosch-wiper-blade-ba16-16400mm",
    thumbnail: "/file/listings/bosch-placeholder.png",
    name: 'BOSCH WIPER BLADE - BA16 (16"/400mm)',
    description: "Wiper Blade",
    information: "Durable, streak-free wiping with easy installation for various vehicle models. Trusted BOSCH quality for clear windshield cleaning",
    detail: "The BOSCH WIPER BLADE - BA16 is a reliable wiper blade designed to deliver clear and streak-free visibility for your windshield. With a length of 16 inches (400mm), it provides ample coverage for effective wiping. The wiper blade is crafted with a wear-resistant natural rubber wiping edge, ensuring durability and consistent performance in diverse weather conditions. Its smooth and quiet operation reduces noise while efficiently clearing the windshield, contributing to a comfortable and distraction-free driving experience. The BA16 is engineered for easy installation, ensuring a hassle-free setup on specific wiper arms. It is compatible with various vehicle models, making it a versatile and practical choice. No matter the weather, this wiper blade ensures optimal performance, providing clear visibility during rain, snow, and other environmental conditions. As part of the reputable BOSCH principals, the BA16 wiper blade guarantees reliable quality and performance on the road. Trust in this dependable automotive accessory to keep your windshield clear and maintain excellent visibility for safer driving ",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["BA Wipers"],
  },
  {
    sku: "AG0210",
    slug: "bosch-wiper-blade-ba17-17425mm",
    thumbnail: "/file/listings/bosch-placeholder.png",
    name: 'BOSCH WIPER BLADE - BA17 (17"/425mm)',
    description: "Wiper Blade",
    information: "Durable, streak-free wiping with easy installation for various vehicle models. Trusted BOSCH quality for clear windshield cleaning",
    detail: "The BOSCH WIPER BLADE - BA17 is a high-quality wiper blade designed to provide clear and streak-free visibility for your windshield. With a length of 17 inches (425mm), it offers sufficient coverage for efficient and effective wiping. The wiper blade is constructed with a wear-resistant natural rubber wiping edge, ensuring durability and consistent performance in different weather conditions. Its smooth and quiet operation reduces noise during use, contributing to a comfortable driving experience. The BA17 is specifically designed to fit certain wiper arms, ensuring a precise and secure installation on various vehicle models. Thanks to its engineering and material quality, this wiper blade performs optimally in rain, snow, and other environmental conditions, offering reliable and safe windshield clearing. As part of the trusted BOSCH principals, you can rely on the BA17 wiper blade for its proven quality and performance on the road. Keep your windshield clear and maintain excellent visibility with this dependable automotive accessory",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["BA Wipers"],
  },
  {
    sku: "AG0203",
    slug: "bosch-wiper-blade-ba18-18450mm",
    thumbnail: "/file/listings/bosch-placeholder.png",
    name: 'BOSCH WIPER BLADE - BA18 (18"/450mm)',
    description: "Wiper Blade",
    information: "Durable, streak-free wiping with easy installation for various vehicle models. Trusted BOSCH quality for clear windshield cleaning",
    detail: "The BOSCH WIPER BLADE - BA18 is a reliable and efficient wiper blade designed to ensure clear visibility and safety during your journeys. With a length of 18 inches (450mm), it provides ample coverage for effective windshield clearing. The wiper blade is crafted with a wear-resistant natural rubber wiping edge, guaranteeing durability and consistent wiping performance over time. Its smooth and quiet operation reduces noise levels, adding to your driving comfort. Designed for specific wiper arms, the BA18 ensures a perfect fit for various vehicle models, facilitating easy and hassle-free installation. This wiper blade is engineered to perform exceptionally well in various weather conditions, such as rain, snow, and other challenging elements. Count on the renowned BOSCH principals for its expertise in producing high-quality automotive products that deliver reliable performance on the road, keeping your windshield clear and enhancing driving safety",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["BA Wipers"],
  },
  {
    sku: "AG0204",
    slug: "bosch-wiper-blade-ba19-19475mm",
    thumbnail: "/file/listings/bosch-placeholder.png",
    name: 'BOSCH WIPER BLADE - BA19 (19"/475mm)',
    description: "Wiper Blade",
    information: "Durable, streak-free wiping with easy installation for various vehicle models. Trusted BOSCH quality for clear windshield cleaning",
    detail: "The BOSCH WIPER BLADE - BA19 is a reliable wiper blade designed to ensure clear visibility and safety during your journeys. With a length of 19 inches (475mm), it provides sufficient coverage for efficient windshield clearing. The wiper blade is constructed with a wear-resistant natural rubber wiping edge, offering durability and consistent wiping performance over time. Its smooth and quiet operation reduces noise levels, enhancing your driving comfort. Specifically designed to fit certain wiper arms, the BA19 ensures a perfect fit for various vehicle models, making installation quick and hassle-free. This wiper blade is engineered to perform well in various weather conditions, including rain, snow, and other challenging elements. Trust the renowned BOSCH principals for its expertise in producing high-quality automotive products that provide reliable performance on the road, keeping your windshield clear and improving driving safety",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["BA Wipers"],
  },
  {
    sku: "AG0211",
    slug: "bosch-wiper-blade-ba20-20500mm",
    thumbnail: "/file/listings/bosch-placeholder.png",
    name: 'BOSCH WIPER BLADE - BA20 (20"/500mm)',
    description: "Wiper Blade",
    information: "Durable, streak-free wiping with easy installation for various vehicle models. Trusted BOSCH quality for clear windshield cleaning",
    detail: "The BOSCH WIPER BLADE - BA20 is a high-quality wiper blade designed to ensure clear visibility and safety during your drives. With a length of 20 inches (500mm), it offers adequate coverage for effective windshield clearing. The wiper blade is crafted with a wear-resistant natural rubber wiping edge, providing durability and consistent wiping performance over time. Its smooth and quiet operation reduces noise levels, contributing to a pleasant driving experience. Designed to fit specific wiper arms, the BA20 guarantees a perfect fit for various vehicle models. Installation is hassle-free and quick, saving you time and effort. This wiper blade is engineered to perform well in different weather conditions, including rain, snow, and other adverse elements. Trust the BOSCH principals's expertise and reputation for producing high-quality automotive products to keep your windshield clear and provide reliable performance on the road",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["BA Wipers"],
  },
  {
    sku: "AG0205",
    slug: "bosch-wiper-blade-ba21-21525mm",
    thumbnail: "/file/listings/bosch-placeholder.png",
    name: 'BOSCH WIPER BLADE - BA21 (21"/525mm)',
    description: "Wiper Blade",
    information: "Durable, streak-free wiping with easy installation for various vehicle models. Trusted BOSCH quality for clear windshield cleaning",
    detail: "The BOSCH WIPER BLADE - BA21 is a reliable and efficient wiper blade designed to keep your windshield clear and provide excellent visibility during your journeys. With a length of 21 inches (525mm), it offers adequate coverage for effective windshield clearing. The wiper blade is constructed with a wear-resistant natural rubber wiping edge, ensuring long-lasting durability and consistent wiping performance. Its smooth and quiet operation reduces noise, creating a pleasant driving experience. The BA21 is specifically designed to fit certain wiper arms, ensuring a perfect fit for various vehicle models. Its easy installation process saves you time and effort during maintenance. Whether facing rain, snow, or other adverse weather conditions, the BA21 delivers exceptional performance, providing you with optimal visibility and safety on the road. Trust in the renowned quality and expertise of BOSCH to keep your windshield clear and ensure a smooth driving experience in all weather conditions",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["BA Wipers"],
  },
  {
    sku: "AG0206",
    slug: "bosch-wiper-blade-ba22-22550mm",
    thumbnail: "/file/listings/bosch-placeholder.png",
    name: 'BOSCH WIPER BLADE - BA22 (22"/550mm)',
    description: "Wiper Blade",
    information: "Durable, streak-free wiping with easy installation for various vehicle models. Trusted BOSCH quality for clear windshield cleaning",
    detail: "The BOSCH WIPER BLADE - BA22 is a high-quality wiper blade designed to ensure clear and reliable visibility during your journeys. With a length of 22 inches (550mm), it provides ample coverage for effective windshield clearing. The wiper blade features a wear-resistant natural rubber wiping edge, ensuring long-lasting durability and efficient wiping action. Its smooth and quiet operation reduces noise, providing a pleasant driving experience. The BA22 is designed to fit specific wiper arms, ensuring a perfect fit for various vehicle models. Easy to install, it offers hassle-free maintenance for your convenience. Whether you encounter rain, snow, or other challenging weather conditions, the BA22 performs exceptionally to provide you with optimal visibility and safety on the road. Trust in the renowned quality and reliability of BOSCH products for your automotive needs",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["BA Wipers"],
  },
  {
    sku: "AG0207",
    slug: "bosch-wiper-blade-ba24-24600mm",
    thumbnail: "/file/listings/bosch-placeholder.png",
    name: 'BOSCH WIPER BLADE - BA24 (24"/600mm)',
    description: "Wiper Blade",
    information: "Durable, streak-free wiping with easy installation for various vehicle models. Trusted BOSCH quality for clear windshield cleaning",
    detail: "The BOSCH WIPER BLADE - BA24 is a top-notch wiper blade designed to deliver exceptional performance. With a length of 24 inches (600mm), it provides wide and efficient coverage for a clear and streak-free windshield. The wiper blade is crafted with a wear-resistant natural rubber wiping edge, ensuring durability and reliable wiping action even in challenging weather conditions. Its smooth and quiet operation reduces noise while effectively clearing the windshield. The BA24 is tailor-made to fit specific wiper arms, ensuring a perfect fit for various vehicle models. Easy to install, this wiper blade ensures hassle-free maintenance and excellent visibility during rain, snow, and other adverse weather conditions. Trust in the trusted quality of BOSCH for a safer and more enjoyable driving experience",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["BA Wipers"],
  },
  {
    sku: "AG0208",
    slug: "bosch-wiper-blade-ba26-26650mm",
    thumbnail: "/file/listings/bosch-placeholder.png",
    name: 'BOSCH WIPER BLADE - BA26 (26"/650mm)',
    description: "Wiper Blade",
    information: "Durable, streak-free wiping with easy installation for various vehicle models. Trusted BOSCH quality for clear windshield cleaning",
    detail: "The BOSCH WIPER BLADE - BA26 is a high-quality wiper blade designed for superior performance. With a length of 26 inches (650mm), it offers excellent coverage for a clear and streak-free windshield. The wiper blade is constructed with a wear-resistant natural rubber wiping edge, ensuring durability and reliability even in harsh weather conditions. Its smooth and quiet operation reduces noise while efficiently clearing the windshield. The BA26 is specifically designed to fit certain wiper arms, providing a perfect fit for various vehicle models. Easy to install, this wiper blade guarantees hassle-free maintenance and optimal visibility during rain, snow, and other environmental conditions. Trust in the renowned quality of BOSCH for a safer and more enjoyable driving experience",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["BA Wipers"],
  },
  {
    sku: "AG0268",
    slug: "bosch-wiper-blade-bba350",
    thumbnail: "/file/listings/bosch-placeholder.png",
    name: "BOSCH WIPER BLADE - BBA350",
    description: "Wiper Blade",
    information: "Durable, streak-free wiping with easy installation for various vehicle models. Trusted BOSCH quality for clear windshield cleaning",
    detail: "Type: Rear Wiper Blade",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["BBA Wiper"],
  },
  {
    sku: "AG0188",
    slug: "bosch-wiper-blade-bba400",
    thumbnail: "/file/listings/bosch-placeholder.png",
    name: "BOSCH WIPER BLADE - BBA400",
    description: "Wiper Blade",
    information: "Durable, streak-free wiping with easy installation for various vehicle models. Trusted BOSCH quality for clear windshield cleaning",
    detail: "Type: Rear Wiper Blade",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["BBA Wiper"],
  },
  {
    sku: "AG0189",
    slug: "bosch-wiper-blade-bba450",
    thumbnail: "/file/listings/bosch-placeholder.png",
    name: "BOSCH WIPER BLADE - BBA450",
    description: "Wiper Blade",
    information: "Durable, streak-free wiping with easy installation for various vehicle models. Trusted BOSCH quality for clear windshield cleaning",
    detail: "Type: Rear Wiper Blade",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["BBA Wiper"],
  },
  {
    sku: "AG0258",
    slug: "bosch-wiper-blade-bba475",
    thumbnail: "/file/listings/bosch-placeholder.png",
    name: "BOSCH WIPER BLADE - BBA475",
    description: "Wiper Blade",
    information: "Durable, streak-free wiping with easy installation for various vehicle models. Trusted BOSCH quality for clear windshield cleaning",
    detail: "Type: Rear Wiper Blade",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["BBA Wiper"],
  },
  {
    sku: "AG0190",
    slug: "bosch-wiper-blade-bba500",
    thumbnail: "/file/listings/bosch-placeholder.png",
    name: "BOSCH WIPER BLADE - BBA500",
    description: "Wiper Blade",
    information: "Durable, streak-free wiping with easy installation for various vehicle models. Trusted BOSCH quality for clear windshield cleaning",
    detail: "Type: Rear Wiper Blade",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["BBA Wiper"],
  },
  {
    sku: "AG0259",
    slug: "bosch-wiper-blade-bba530",
    thumbnail: "/file/listings/bosch-placeholder.png",
    name: "BOSCH WIPER BLADE - BBA530",
    description: "Wiper Blade",
    information: "The BOSCH WIPER BLADE - BBA530 is a high-quality wiper blade designed to deliver exceptional wiping performance and ensure clear visibility during adverse weather conditions. This wiper blade features a wear-resistant natural rubber wiping edge, providing durability and consistent wiping action. With its streak-free wiping capability, the BBA530 ensures a clean and unobstructed windshield. The wiper blade is engineered for easy installation, making it convenient for users to replace their old blades. Designed to fit specific wiper arms, the BBA530 offers a perfect and secure fit for various vehicle models. Trust the renowned BOSCH principals for superior quality and reliable performance",
    detail: "The BOSCH WIPER BLADE - BBA530 is a high-quality wiper blade designed to deliver exceptional wiping performance and ensure clear visibility during adverse weather conditions. This wiper blade features a wear-resistant natural rubber wiping edge, providing durability and consistent wiping action. With its streak-free wiping capability, the BBA530 ensures a clean and unobstructed windshield. The wiper blade is engineered for easy installation, making it convenient for users to replace their old blades. Designed to fit specific wiper arms, the BBA530 offers a perfect and secure fit for various vehicle models. Trust the renowned BOSCH principals for superior quality and reliable performance",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["BBA Wiper"],
  },
  {
    sku: "AG0191",
    slug: "bosch-wiper-blade-bba550",
    thumbnail: "/file/listings/bosch-placeholder.png",
    name: "BOSCH WIPER BLADE - BBA550",
    description: "Wiper Blade",
    information: "The BOSCH WIPER BLADE - BBA550 is a high-quality rear wiper blade designed to provide streak-free wiping and ensure clear visibility on the rear window. With its wear-resistant natural rubber wiping edge, the BBA550 offers long-lasting performance and consistent wiping action. This rear wiper blade is specifically designed to fit certain rear wiper arms, ensuring a perfect and secure fit for various vehicle models. Its easy installation process makes it convenient for users to replace their old rear wiper blade. Trust in the reputable BOSCH principals to deliver superior quality and reliable performance",
    detail: "The BOSCH WIPER BLADE - BBA550 is a rear wiper blade designed to deliver reliable performance and clear visibility on the rear window. It features a wear-resistant natural rubber wiping edge that ensures streak-free wiping, providing a clear view even during rainy conditions. The wiper blade is designed for easy installation, making it convenient for users to replace their old rear wiper blade. It is specifically designed to fit certain rear wiper arms, ensuring a perfect and secure fit for various vehicle models. With the trusted principals of BOSCH, you can rely on the quality and durability of this wiper blade for consistent and efficient rear window wiping",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["BBA Wiper"],
  },
  {
    sku: "AG0222",
    slug: "bosch-wiper-blade-bba600",
    thumbnail: "/file/listings/bosch-placeholder.png",
    name: "BOSCH WIPER BLADE - BBA600",
    description: "Wiper Blade",
    information: "The BOSCH WIPER BLADE - BBA600 is a high-quality wiper blade designed to deliver exceptional wiping performance and ensure clear visibility during adverse weather conditions. This wiper blade features a wear-resistant natural rubber wiping edge, providing durability and consistent wiping action. With its streak-free wiping capability, the BBA600 ensures a clean and unobstructed windshield. The wiper blade is engineered for easy installation, making it convenient for users to replace their old blades. Designed to fit specific wiper arms, the BBA600 offers a perfect and secure fit for various vehicle models. Trust the renowned BOSCH principals for superior quality and reliable performance",
    detail: "The BOSCH WIPER BLADE - BBA600 is a high-quality wiper blade designed to deliver exceptional wiping performance and ensure clear visibility during adverse weather conditions. This wiper blade features a wear-resistant natural rubber wiping edge, providing durability and consistent wiping action. With its streak-free wiping capability, the BBA600 ensures a clean and unobstructed windshield. The wiper blade is engineered for easy installation, making it convenient for users to replace their old blades. Designed to fit specific wiper arms, the BBA600 offers a perfect and secure fit for various vehicle models. Trust the renowned BOSCH principals for superior quality and reliable performance",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["BBA Wiper"],
  },
  {
    sku: "AG0223",
    slug: "bosch-wiper-blade-bba650",
    thumbnail: "/file/listings/bosch-placeholder.png",
    name: "BOSCH WIPER BLADE - BBA650",
    description: "Wiper Blade",
    information: "The BOSCH WIPER BLADE - BBA650 is a top-quality rear wiper blade designed to provide a streak-free wiping experience. It is crafted with a wear-resistant natural rubber wiping edge, ensuring reliable and long-lasting performance. Installing this wiper blade is effortless due to its easy installation design. Specifically designed to fit certain rear wiper arms, the BBA650 guarantees a perfect fit for various vehicle models, ensuring optimal performance. With its streak-free wiping capability, it ensures a clear and unobstructed rear view, enhancing driving safety and visibility. Trust in the renowned BOSCH principals for its exceptional quality and dependability, as the BBA650 delivers outstanding results for rear window wiping. Upgrade your rear wiper blade to the BOSCH WIPER BLADE - BBA650 for a superior wiping experience",
    detail: "The BOSCH WIPER BLADE - BBA650 is a high-quality rear wiper blade designed to ensure a streak-free wiping experience. It is constructed with a wear-resistant natural rubber wiping edge, providing long-lasting and reliable performance. The wiper blade is engineered for easy installation, making the replacement process hassle-free. It is specifically designed to fit certain rear wiper arms, ensuring a perfect and secure fit for various vehicle models. The BBA650 is ideal for rear window wiping, effectively clearing water and debris to maintain a clear view while driving. With the trusted BOSCH principals, you can rely on the BBA650 for its exceptional quality, durability, and efficient wiping performance. Upgrade your rear wiper blade with the BOSCH WIPER BLADE - BBA650 and enjoy enhanced visibility and safety during adverse weather conditions",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["BBA Wiper"],
  },
  {
    sku: "AG0260",
    slug: "bosch-wiper-blade-bba700",
    thumbnail: "/file/listings/bosch-placeholder.png",
    name: "BOSCH WIPER BLADE - BBA700",
    description: "Wiper Blade",
    information: "BOSCH WIPER BLADE - BBA700 is a premium-quality rear wiper blade engineered to deliver exceptional wiping performance for the rear window. Its wear-resistant natural rubber wiping edge ensures a longer service life and streak-free wiping, providing clear visibility to the driver. The blade is designed to fit specific wiper arms precisely, ensuring easy and secure installation. BOSCH, a renowned automotive principals, guarantees reliable performance and durability, making BBA700 a dependable choice for maintaining clear rear visibility and safety on the road",
    detail: "Introducing the BOSCH WIPER BLADE - BBA700, a rear wiper blade designed to enhance your driving experience. This high-quality wiper blade is equipped with a wear-resistant natural rubber wiping edge, ensuring long-lasting and streak-free wiping performance on your rear window. Enjoy a clear and unobstructed view behind you, thanks to the streak-free wiping action provided by the BBA700. Installing this wiper blade is a breeze, as it is specifically designed for easy installation on compatible rear wiper arms. Whether it's rain or snow, the BBA700 is your reliable companion for rear window wiping, ensuring optimal visibility and safety during various weather conditions. Trust in the renowned BOSCH principals, known for its superior automotive products, and equip your vehicle with the BBA700 for an exceptional rear window wiping experience",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["BBA Wiper"],
  },
  {
    sku: "AG0229",
    slug: "bosch-wiper-blade-bca14",
    thumbnail: "/file/listings/bosch-placeholder.png",
    name: "BOSCH WIPER BLADE - BCA14",
    description: "Wiper Blade",
    information: "BOSCH WIPER BLADE - BCA14 is a high-quality wiper blade designed to provide superior wiping performance for a clear view during various weather conditions. Its wear-resistant natural rubber wiping edge ensures extended durability and streak-free wiping. The blade's specially coated wiping element guarantees smooth and quiet operation, reducing noise while ensuring efficient wiping. Engineered to fit specific wiper arms precisely, it offers easy and secure installation. BOSCH, a trusted automotive principals, ensures reliable performance, making BCA14 an excellent choice for maintaining visibility and safety on the road",
    detail: "Introducing the BOSCH WIPER BLADE - BCA14, a reliable and high-performance wiper blade designed to enhance your driving experience. This wiper blade features a wear-resistant natural rubber wiping edge, ensuring long-lasting durability and efficient windshield clearing. Enjoy a smooth and quiet operation, as the BCA14 reduces noise while providing effective wiping performance. With its easy installation design, users can effortlessly replace their old wiper blades with the BCA14 for a hassle-free experience. Specifically tailored to fit certain wiper arms, this blade ensures a perfect fit for various vehicle models, ensuring optimal performance. Whether facing rain, snow, or other environmental conditions, the BCA14 excels in providing clear visibility and enhanced safety. Trust in the reputable BOSCH principals for superior quality and reliable performance. Equip your vehicle with the BOSCH WIPER BLADE - BCA14 for a clear and comfortable driving experience in any weather",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["BCA Wipers"],
  },
  {
    sku: "AG0230",
    slug: "bosch-wiper-blade-bca15",
    thumbnail: "/file/listings/bosch-placeholder.png",
    name: "BOSCH WIPER BLADE - BCA15",
    description: "Wiper Blade",
    information: "The BOSCH Wiper Blade - BCA15 is a high-quality and efficient wiper blade designed to provide superior wiping performance and clear visibility in various weather conditions. Its wear-resistant natural rubber wiping edge ensures a longer service life and consistent streak-free wiping. This wiper blade is engineered to fit specific wiper arms, ensuring a precise and secure installation. The BCA15 is crafted with smooth and quiet operation, reducing noise for a more comfortable driving experience. BOSCH, a renowned automotive principals, guarantees reliable performance and durability, making it a trusted choice for vehicle owners seeking excellent wiper performance",
    detail: "BOSCH WIPER BLADE - BCA15 is a top-quality wiper blade designed to deliver exceptional wiping performance for a clear and unobstructed view during various weather conditions. Its wear-resistant natural rubber wiping edge ensures a longer lifespan and streak-free wiping. The blade is crafted for a smooth and quiet operation, reducing noise while providing efficient wiping. Engineered to fit specific wiper arms precisely, it offers easy and secure installation. BOSCH, a reputable automotive principals, guarantees trusted performance, making BCA15 a reliable choice for maintaining visibility and safety on the road",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["BCA Wipers"],
  },
  {
    sku: "AG0231",
    slug: "bosch-wiper-blade-bca16",
    thumbnail: "/file/listings/bosch-placeholder.png",
    name: "BOSCH WIPER BLADE - BCA16",
    description: "Wiper Blade",
    information: "The BOSCH Wiper Blade - BCA16 is a high-quality and efficient wiper blade designed to provide superior visibility and safety on the road. It features a wear-resistant natural rubber wiping edge that ensures long service life and reliable wiping performance in various weather conditions. The BCA16 is engineered for a precise fit and easy installation, making it compatible with specific wiper arms. With smooth and quiet operation, this wiper blade reduces noise for a comfortable driving experience. It is a trusted product from BOSCH, a renowned automotive principals known for its reliability and performance. The BCA16 wiper blade is an ideal choice to maintain clear visibility and ensure safety during inclement weather",
    detail: "The BOSCH Wiper Blade - BCA16 is a reliable and high-performance wiper blade designed to provide clear visibility and safe driving in all weather conditions. Its wear-resistant natural rubber wiping edge ensures a long service life and consistent wiping performance. The BCA16 is engineered to fit specific wiper arms, making installation quick and easy. With its smooth and quiet operation, it reduces noise for a more comfortable driving experience. BOSCH, a trusted automotive principals, ensures that this wiper blade delivers superior performance and reliability. Whether it's rain, snow, or dust, the BOSCH BCA16 Wiper Blade ensures a clear view of the road ahead, ensuring your safety and peace of mind during your journeys",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["BCA Wipers"],
  },
  {
    sku: "AG0232",
    slug: "bosch-wiper-blade-bca17",
    thumbnail: "/file/listings/bosch-placeholder.png",
    name: "BOSCH WIPER BLADE - BCA17",
    description: "Wiper Blade",
    information: "The BOSCH Wiper Blade - BCA17 is a high-quality wiper blade designed to deliver superior performance in all weather conditions. It features a wear-resistant natural rubber wiping edge that ensures a long service life and consistent wiping performance. The BCA17 wiper blade is engineered for a precise fit and is compatible with specific wiper arms, making installation quick and easy. With smooth and quiet operation, it enhances driving comfort during rainy or harsh weather. This wiper blade is a trusted product from BOSCH, a renowned principals known for its reliable automotive solutions, providing excellent visibility and safety on the road",
    detail: "The BOSCH Wiper Blade - BCA17 is a reliable and high-performance wiper blade designed to provide optimal visibility and safety during inclement weather. With its wear-resistant natural rubber wiping edge, it offers long-lasting durability and efficient wiping performance. The BCA17 is engineered for a precise fit and is compatible with specific wiper arms, ensuring easy installation. It operates smoothly and quietly, reducing noise for a comfortable driving experience. Designed for use in various weather conditions, this wiper blade delivers consistent and effective results. As a trusted product from BOSCH, a leading automotive principals, the BCA17 wiper blade is known for its quality and reliability, making it an excellent choice for maintaining clear visibility on the road",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["BCA Wipers"],
  },
  {
    sku: "AG0233",
    slug: "bosch-wiper-blade-bca18",
    thumbnail: "/file/listings/bosch-placeholder.png",
    name: "BOSCH WIPER BLADE - BCA18",
    description: "Wiper Blade",
    information: "The BOSCH Wiper Blade - BCA18 is a high-quality wiper blade designed to deliver superior performance in all weather conditions. It features a wear-resistant natural rubber wiping edge that ensures a long service life and consistent wiping performance. The BCA18 wiper blade is engineered for a precise fit and is compatible with specific wiper arms, making installation quick and easy. With smooth and quiet operation, it enhances driving comfort during rainy or harsh weather. This wiper blade is a trusted product from BOSCH, a renowned principals known for its reliable automotive solutions, providing excellent visibility and safety on the road",
    detail: "The BOSCH Wiper Blade - BCA18 is a reliable and high-performance wiper blade designed to provide optimal visibility and safety during inclement weather. With its wear-resistant natural rubber wiping edge, it offers long-lasting durability and efficient wiping performance. The BCA18 is engineered for a precise fit and is compatible with specific wiper arms, ensuring easy installation. It operates smoothly and quietly, reducing noise for a comfortable driving experience. Designed for use in various weather conditions, this wiper blade delivers consistent and effective results. As a trusted product from BOSCH, a leading automotive principals, the BCA18 wiper blade is known for its quality and reliability, making it an excellent choice for maintaining clear visibility on the road",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["BCA Wipers"],
  },
  {
    sku: "AG0234",
    slug: "bosch-wiper-blade-bca19",
    thumbnail: "/file/listings/bosch-placeholder.png",
    name: "BOSCH WIPER BLADE - BCA19",
    description: "Wiper Blade",
    information: "The BOSCH Wiper Blade - BCA19 is a high-quality wiper blade designed to provide superior performance and visibility in all weather conditions. It features a wear-resistant natural rubber wiping edge that ensures a long service life and consistent wiping performance. The BCA19 wiper blade is engineered for a precise fit, making installation quick and hassle-free. Its smooth and quiet operation adds to the driving comfort, ensuring a clear view during rainy or harsh weather. This wiper blade is compatible with specific wiper arms, ensuring optimal performance for your vehicle. Trust in the reliability and expertise of BOSCH to deliver outstanding wiper performance with the BCA19 wiper blade",
    detail: "The BOSCH Wiper Blade - BCA19 is a top-quality wiper blade designed to provide excellent visibility and performance in various weather conditions. It features a wear-resistant natural rubber wiping edge, ensuring a long-lasting and reliable wiping performance. The smooth and quiet operation of the BCA19 wiper blade enhances driving comfort during rainy or harsh weather. Engineered for a precise fit, it is easy to install on specific wiper arms. With BOSCH's trusted expertise and reliability, the BCA19 wiper blade delivers superior visibility and safety on the road, making it a reliable choice for any vehicle",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["BCA Wipers"],
  },
  {
    sku: "AG0235",
    slug: "bosch-wiper-blade-bca20",
    thumbnail: "/file/listings/bosch-placeholder.png",
    name: "BOSCH WIPER BLADE - BCA20",
    description: "Wiper Blade",
    information: "The BOSCH Wiper Blade - BCA20 is a high-quality wiper blade designed to provide excellent visibility and safety on the road. It features a wear-resistant natural rubber wiping edge that ensures long-lasting durability and consistent wiping performance. The BCA20 wiper blade is designed for easy installation with its precise fit, making it suitable for various vehicle models. Its smooth and quiet operation adds to the driving comfort, ensuring a clear view during rainy or harsh weather conditions. This wiper blade is compatible with specific wiper arms, ensuring optimal performance for your vehicle. Trust in the reliability and performance of BOSCH products, and experience superior visibility with the BCA20 wiper blade",
    detail: "The BOSCH Wiper Blade - BCA20 is a high-quality wiper blade designed to provide excellent visibility and safety on the road. It features a wear-resistant natural rubber wiping edge that ensures long-lasting durability and consistent wiping performance. The BCA20 wiper blade is designed for easy installation with its precise fit, making it suitable for various vehicle models. Its smooth and quiet operation adds to the driving comfort, ensuring a clear view during rainy or harsh weather conditions. This wiper blade is compatible with specific wiper arms, ensuring optimal performance for your vehicle. Trust in the reliability and performance of BOSCH products, and experience superior visibility with the BCA20 wiper blade",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["BCA Wipers"],
  },
  {
    sku: "AG0236",
    slug: "bosch-wiper-blade-bca21",
    thumbnail: "/file/listings/bosch-placeholder.png",
    name: "BOSCH WIPER BLADE - BCA21",
    description: "Wiper Blade",
    information: "The BOSCH Wiper Blade - BCA21 is a top-of-the-line wiper blade designed to ensure clear visibility and optimal performance during various weather conditions. It features a wear-resistant natural rubber wiping edge, providing durability and a prolonged service life. The BCA21 wiper blade boasts smooth and quiet operation, offering a comfortable and noise-free driving experience. Engineered for a precise fit, it is easy to install on specific wiper arms, making maintenance hassle-free. With BOSCH's trusted principals reputation, this wiper blade guarantees reliable and consistent performance, giving you peace of mind on the road",
    detail: "The BOSCH Wiper Blade - BCA21 is a high-quality wiper blade designed to provide exceptional performance and clear visibility in all weather conditions. It features a wear-resistant natural rubber wiping edge, ensuring a long service life and reliable wiping action. The BCA21 wiper blade operates smoothly and quietly, delivering a comfortable and quiet driving experience. Its precise fit design makes installation easy and convenient for various wiper arms. This wiper blade is engineered by BOSCH, a trusted principals known for its superior quality and performance. With BCA21, you can drive confidently, knowing that your wiper blade will keep your windshield clean and clear, even during heavy rain or harsh weather",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["BCA Wipers"],
  },
  {
    sku: "AG0237",
    slug: "bosch-wiper-blade-bca22",
    thumbnail: "/file/listings/bosch-placeholder.png",
    name: "BOSCH WIPER BLADE - BCA22",
    description: "Wiper Blade",
    information: "The BOSCH Wiper Blade - BCA22 is a high-performance wiper blade designed to deliver superior visibility and safety in all weather conditions. It features a wear-resistant natural rubber wiping edge that contributes to its long service life, ensuring consistent and reliable wiping performance. The specially coated wiping element ensures smooth and quiet operation, providing a pleasant and comfortable driving experience. This wiper blade is engineered for easy installation and a precise fit on specific wiper arms, ensuring optimal contact with the windshield for efficient clearing of rain, snow, and debris. With BOSCH's commitment to quality and innovation, the BCA22 wiper blade is a trusted choice for drivers seeking clear and unobstructed views during their journeys.",
    detail: "The BOSCH Wiper Blade - BCA22 is a reliable and high-performance wiper blade designed to provide superior visibility and safety on the road. Its model, BCA22, is engineered with a wear-resistant natural rubber wiping edge, ensuring a long service life and consistent wiping performance. The wiper blade's smooth and quiet operation, coupled with its easy installation on specific wiper arms, offers a hassle-free experience for drivers. Compatible with various weather conditions, the BCA22 wiper blade is a trusted choice for maintaining a clear windshield, be it during rain, snow, or dusty conditions. With BOSCH's renowned principals reputation and commitment to quality, this wiper blade guarantees superior performance and enhanced safety for every journey",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["BCA Wipers"],
  },
  {
    sku: "AG0238",
    slug: "bosch-wiper-blade-bca24",
    thumbnail: "/file/listings/bosch-placeholder.png",
    name: "BOSCH WIPER BLADE - BCA24",
    description: "Wiper Blade",
    information: "The BOSCH Wiper Blade - BCA24 is a premium-quality wiper blade designed to provide superior performance and clear visibility in all weather conditions. Its wear-resistant natural rubber wiping edge ensures a long service life and consistent wiping efficiency. The specially coated wiping element guarantees smooth and silent operation, enhancing the driving experience. This wiper blade is engineered for precise fitment and compatibility with specific wiper arms, ensuring a secure and reliable installation. With BOSCH's commitment to quality and innovation, the BCA24 wiper blade is a trusted choice for drivers seeking optimum safety and clarity on the road",
    detail: "The BOSCH Wiper Blade - BCA24 is a reliable and high-quality wiper blade designed to deliver exceptional performance and visibility in various weather conditions. It features a wear-resistant natural rubber wiping edge that ensures a long-lasting service life and consistent wiping efficiency. The specially coated wiping element guarantees smooth and silent operation, providing a pleasant driving experience. This wiper blade is engineered for a precise fit on specific wiper arms, ensuring a secure and reliable installation. With BOSCH's renowned commitment to quality and innovation, the BCA24 wiper blade is a trusted choice for drivers seeking optimal safety and clear visibility on the road",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["BCA Wipers"],
  },
  {
    sku: "AG0505",
    slug: "bosch-wiper-blade-bca26",
    thumbnail: "/file/listings/bosch-placeholder.png",
    name: "BOSCH WIPER BLADE - BCA26",
    description: "Wiper Blade",
    information: "The BOSCH Wiper Blade - BCA26 is a top-of-the-line wiper blade designed to provide superior performance and visibility in all weather conditions. Its wear-resistant natural rubber wiping edge ensures a long-lasting service life, while the specially coated wiping element guarantees smooth and silent operation. Engineered for precise fitment and compatibility with specific wiper arms, the BCA26 wiper blade delivers consistent and streak-free wiping, enhancing driving safety. With easy installation and reliable performance, this wiper blade is a trusted choice to keep your windshield clear and provide a comfortable driving experience. The BOSCH BCA26 wiper blade is a perfect combination of quality, durability, and functionality, making it an excellent addition to your vehicle's maintenance and safety features",
    detail: "The BOSCH Wiper Blade - BCA26 is a high-quality wiper blade designed to provide optimal performance and visibility during various weather conditions. Its wear-resistant natural rubber wiping edge contributes to a long service life, ensuring durability and reliability. The specially coated wiping element guarantees smooth and quiet operation, delivering a pleasant driving experience. The wiper blade is engineered for precise fitment and compatibility with specific wiper arms, ensuring consistent and streak-free wiping for clear visibility. Easy to install, the BCA26 wiper blade is a trusted choice for drivers seeking reliable and efficient windshield wiping. With BOSCH's reputation for excellence, this wiper blade is a valuable addition to your vehicle, ensuring safety and clarity on the road",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["BCA Wipers"],
  },
  {
    sku: "AG0239",
    slug: "bosch-wiper-blade-bca28",
    thumbnail: "/file/listings/bosch-placeholder.png",
    name: "BOSCH WIPER BLADE - BCA28",
    description: "Wiper Blade",
    information: "The BOSCH Wiper Blade - BCA28 is a premium-quality wiper blade designed to deliver superior performance and clear visibility during all weather conditions. It features a high-quality natural rubber wiping edge that is wear-resistant, contributing to its long service life. The wiper blade is specially coated to ensure smooth and quiet operation, providing a hassle-free driving experience. Engineered and designed for application-specific wiping systems, the BCA28 wiper blade fits perfectly and ensures consistent and streak-free wiping performance. This wiper blade is a reliable choice to keep your windshield clear and your driving experience safe and comfortable",
    detail: "The BOSCH Wiper Blade - BCA28 is a high-quality wiper blade designed to provide exceptional performance and visibility in various weather conditions. Its wear-resistant natural rubber wiping edge ensures a long service life, while the specially coated wiping element guarantees smooth and quiet operation. Engineered for application-specific wiping systems, the BCA28 wiper blade fits perfectly for consistent and streak-free wiping. With easy installation and reliable performance, this wiper blade is a trusted choice to keep your windshield clear and ensure a safe and comfortable driving experience. The BOSCH BCA28 wiper blade is the perfect combination of quality, durability, and functionality, making it an ideal addition to your vehicle's maintenance and safety features",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["BCA Wipers"],
  },
  {
    sku: "AG0256",
    slug: "bosch-xenon-hid-d2r",
    thumbnail: "/file/listings/AG0256.png",
    name: "BOSCH Xenon HID D2R",
    description: "Automative Bulb",
    information: "The BOSCH Xenon HID D2R is a high-performance automotive bulb designed to deliver exceptional lighting performance for vehicles equipped with D2R headlight systems. Using xenon high-intensity discharge (HID) technology, this bulb emits a powerful and bright light with a color temperature of 4300K, providing excellent visibility and clarity on the road. The D2R bulb is known for its long service life and reliable performance, making it a preferred choice for drivers seeking premium lighting solutions. With its precise construction and easy installation as a direct replacement, upgrading to the BOSCH Xenon HID D2R bulb guarantees enhanced safety and driving comfort during nighttime and low-light conditions",
    detail: "The BOSCH Xenon HID D2R is a high-quality automotive bulb designed to provide powerful and precise lighting for vehicles equipped with D2R headlight systems. Utilizing xenon high-intensity discharge (HID) technology, this bulb emits a brilliant white light with a color temperature of 4300K, ensuring excellent visibility and clarity on the road. With its long service life and reliable performance, the D2R bulb offers consistent brightness and durability, making it a trusted choice for drivers looking for top-notch lighting solutions. The bulb's accurate construction and easy installation as a direct replacement ensure a seamless upgrade, delivering enhanced safety and driving comfort during nighttime and low-light conditions. BOSCH's commitment to automotive lighting excellence is evident in the quality and performance of the Xenon HID D2R bulb",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["12V Bulb"],
  },
  {
    sku: "AG0265",
    slug: "bosch-xenon-hid-d3s",
    thumbnail: "/file/listings/AG0265.png",
    name: "BOSCH Xenon HID D3S",
    description: "Automative Bulb",
    information: "The BOSCH Xenon HID D3S is a top-of-the-line automotive bulb designed for vehicles equipped with D3S headlight systems. This high-intensity discharge (HID) bulb emits a powerful and bright white light, delivering exceptional visibility during nighttime driving or in low-light conditions. With its long-lasting performance and reliable operation, this bulb ensures consistent and effective lighting for extended periods. The D3S bulb utilizes xenon technology, which closely resembles natural daylight, reducing eye strain and fatigue for drivers. It provides a perfect fit and serves as a direct replacement for vehicles with D3S headlight systems, making it an ideal choice for those seeking superior lighting solutions for their cars",
    detail: "The BOSCH Xenon HID D3S is a high-quality automotive bulb designed to provide powerful and efficient lighting for vehicles equipped with D3S headlight systems. Using xenon high-intensity discharge (HID) technology, this bulb emits a bright white light with a color temperature of 5000K, closely resembling natural daylight. With its advanced design and precise construction, the D3S bulb ensures a perfect fit and easy installation as a direct replacement. Drivers can benefit from enhanced visibility, reduced eye strain, and improved safety during nighttime driving and in low-light conditions. Backed by BOSCH's reputation for excellence in automotive lighting, the D3S bulb guarantees long-lasting performance and durability. Upgrade your vehicle's lighting with the BOSCH Xenon HID D3S bulb for a safer and more enjoyable driving experience",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["12V Bulb"],
  },
  {
    sku: "AG0264",
    slug: "bosch-xenon-hid-d4s",
    thumbnail: "/file/listings/AG0264.png",
    name: "BOSCH Xenon HID D4S",
    description: "Automative Bulb",
    information: "The BOSCH Xenon HID D4S is a high-quality automotive bulb specifically designed for vehicles equipped with D4S headlight systems. This HID bulb type delivers powerful and bright illumination, providing enhanced visibility and safety during night-time driving or in low-light conditions. The xenon HID technology ensures a clear, white light that closely resembles natural daylight, reducing eye strain and fatigue for drivers. With its long-lasting performance and reliability, the BOSCH Xenon HID D4S is an excellent choice for those seeking superior lighting solutions for their vehicles",
    detail: "The BOSCH Xenon HID D4S is a high-quality automotive bulb designed to fit vehicles equipped with D4S headlight systems. This xenon HID bulb emits a bright and powerful white light, providing enhanced visibility during night-time driving or in low-light conditions. With its long-lasting performance and reliability, this bulb ensures consistent and effective lighting for extended periods. The xenon technology used in the D4S bulb closely resembles natural daylight, reducing eye strain and fatigue for drivers. It is a perfect fit and direct replacement for vehicles with D4S headlight systems, making it an excellent choice for those seeking superior lighting solutions for their cars",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["12V Bulb"],
  },
  {
    sku: "AG0266",
    slug: "bosch-xenon-white-hid-d1s",
    thumbnail: "/file/listings/AG0266.png",
    name: "BOSCH Xenon White HID D1S",
    description: "Automative Bulb",
    information: "The BOSCH Xenon White HID D1S is a top-of-the-line automotive bulb designed to deliver superior illumination for vehicles equipped with HID headlight systems. With its D1S bulb type and xenon white color temperature, it emits a powerful and bright white light that closely resembles natural daylight, providing exceptional visibility and safety during night-time driving or in low-light conditions. This HID bulb is a direct replacement for original D1S bulbs, ensuring a perfect fit and straightforward installation. Its advanced HID technology and long-lasting performance make it a reliable choice for drivers seeking high-quality lighting and a clear view of the road. Whether for daily commutes or adventurous trips, the BOSCH Xenon White HID D1S promises enhanced driving experiences",
    detail: "The BOSCH Xenon White HID D1S is a high-quality automotive bulb designed to provide exceptional illumination for vehicles equipped with HID headlight systems. Its D1S bulb type and xenon white color temperature deliver a bright and powerful white light, resembling natural daylight and significantly improving visibility during night-time driving or in low-light conditions. This HID bulb is a direct replacement for original D1S bulbs, ensuring a perfect fit and straightforward installation. With advanced HID technology and long-lasting performance, the BOSCH Xenon White HID D1S offers reliable and consistent lighting, making it an excellent choice for drivers seeking enhanced driving experiences and safety on the road",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["12V Bulb"],
  },
  {
    sku: "AG0267",
    slug: "bosch-xenon-white-hid-d2s",
    thumbnail: "/file/listings/AG0267.png",
    name: "BOSCH Xenon White HID D2S",
    description: "Automative Bulb",
    information: "The BOSCH Xenon White HID D2S is a high-intensity discharge (HID) bulb that delivers bright and powerful xenon white light for enhanced visibility on the road. It is designed to provide a perfect fit and direct replacement for the original D2S bulbs in HID headlight systems. With its advanced technology, the bulb emits a crisp white light that closely resembles natural daylight, improving clarity and reducing eye fatigue during night driving. The BOSCH Xenon White HID D2S bulb is built to last, ensuring a long service life and consistent performance, making it an ideal choice for drivers seeking superior illumination and safety on the road",
    detail: "The BOSCH Xenon White HID D2S is a high-quality automotive bulb designed to provide exceptional illumination for HID headlight systems. With its D2S bulb type and xenon white color temperature, it emits a bright and powerful white light that closely resembles natural daylight, ensuring enhanced visibility and safety while driving at night or in low-light conditions. This HID bulb is a direct replacement for original D2S bulbs, ensuring a perfect fit and easy installation. Its advanced HID technology and long service life make it a reliable choice for drivers seeking superior lighting performance and a crisp, clear view of the road ahead. Whether for everyday driving or special occasions, the BOSCH Xenon White HID D2S is a dependable and efficient solution to enhance your driving experience",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["12V Bulb"],
  },
  {
    sku: "AG0174",
    slug: "c3-bosch-battery-charger",
    thumbnail: "/file/listings/AG0174.png",
    name: "C3 BOSCH BATTERY CHARGER",
    description: "Intelligent four-stage automatic Battery Charger",
    information: "The Bosch C3 Battery Charger is an intelligent four-stage automatic Battery Charger with pulse charging and trickle charging capabilities",
    detail: "The C3 Battery Charger is a versatile and user-friendly device designed to automatically detect 6V or 12V lead-acid batteries and adjust the charging rate accordingly, providing a convenient and effortless charging experience. With its intelligent monitoring capabilities, this charger ensures that the battery remains at a full state of charge, effectively prolonging the battery's service life. Additionally, the C3 Battery Charger complies with the IP65 dust and splash-proof standard, offering reliable performance even in challenging environments. It is also certified to meet Australian electrical standards, providing peace of mind and safety during use. Whether for automotive, marine, or other applications, the C3 Battery Charger is a reliable and efficient choice for maintaining and extending the life of your lead-acid batteries",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Chargers"],
  },
  {
    sku: "AG0175",
    slug: "c7-bosch-battery-charger",
    thumbnail: "/file/listings/AG0175.png",
    name: "C7 BOSCH BATTERY CHARGER",
    description: "Intelligent six-stage automatic charger",
    information: "Bosch C7 Battery Charger is an intelligent six-stage automatic charger with great extra features including regeneration mode and power backup",
    detail: "The Bosch C7 Battery Charger is a highly efficient and versatile charging solution designed to automatically detect 12V or 24V lead-acid batteries, providing the required charge rate for optimal performance. Its user-friendly interface ensures convenience and easy operation. This charger is perfect for charging and maintaining various lead-acid battery types, including wet, gel, AGM, and VRLA/SLA batteries, with capacities of up to 230Ah for 12V and 120Ah for 24V systems. The C7 Battery Charger's regeneration mode allows it to recover deeply discharged batteries using a constant current of 1500mA. Moreover, it features a power backup function, enabling it to supply power to the vehicle's electrical system while simultaneously charging the battery, ensuring that the system's settings are maintained. With its advanced features and capabilities, the Bosch C7 Battery Charger is an excellent choice for efficiently managing and preserving the health of your lead-acid batteries.",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Chargers"],
  },
  // {
  //   sku: "AG0116",
  //   slug: "liberty-atf-dex-iii-h-200l",
  //   thumbnail: libertyPlaceholder,
  //   name: "LIBERTY ATF DEX III-H (200L)",
  //   description: "Automatic Transmission Fluid",
  //   information: "The LIBERTY ATF DEX III-H is a high-quality automatic transmission fluid designed to meet the stringent DEXRON III-H specifications. It comes in a 200L drum, making it suitable for use in large-scale operations, industrial machinery, and commercial applications. This transmission fluid is formulated with premium base oils and advanced additives to provide excellent thermal and oxidative stability, ensuring smooth and reliable shifting performance even under severe operating conditions. It offers superior protection against wear and helps to extend the service life of automatic transmissions. The LIBERTY ATF DEX III-H is compatible with a wide range of automatic transmissions, including those that require DEXRON III-H, DEXRON II, and MERCON fluids, making it a versatile choice for various vehicles and equipment",
  //   detail: "The LIBERTY ATF DEX III-H is a high-quality automatic transmission fluid designed to meet the stringent DEXRON III-H specifications. It comes in a 200L drum, making it suitable for use in large-scale operations, industrial machinery, and commercial applications. This transmission fluid is formulated with premium base oils and advanced additives to provide excellent thermal and oxidative stability, ensuring smooth and reliable shifting performance even under severe operating conditions. It offers superior protection against wear and helps to extend the service life of automatic transmissions. The LIBERTY ATF DEX III-H is compatible with a wide range of automatic transmissions, including those that require DEXRON III-H, DEXRON II, and MERCON fluids, making it a versatile choice for various vehicles and equipment. Whether used in fleets, heavy-duty vehicles, or industrial equipment, the LIBERTY ATF DEX III-H ensures optimal performance and reliability, making it a trusted choice for demanding applications",
  //   uom: "DRUM(S)",
  //   principals: ["Liberty"],
  //   brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
  //   types: ["Lubricants"],
  // },
  // {
  //   sku: "AGL0005",
  //   slug: "liberty-coolant-ready-to-use-5050-4x4ltr",
  //   thumbnail: libertyPlaceholder,
  //   name: "LIBERTY COOLANT READY TO USE 50:50 (4X4LTR)",
  //   description: "Anti-freeze Coolant",
  //   information: "Liberty COOLANT meets the exacting Anti-freeze requirements of Modern Heavy duty and light duty Passenger and Commercial Vehicles. Depending on the end use requirement the dilution ratio with distilled water can be varied between 30% to 50%.",
  //   detail: "Liberty COOLANT – READY TO USE (50 : 50) is a heavy duty automotive Radiator coolant for use in all the latest Passenger vehicles, commercial vehicles and Off-High Earth moving Machinery. This radiator coolant is made from Mono-Ethylene-Glycol and is blended with selective Anti-Corrosion package.",
  //   uom: "CARTON(S)",
  //   principals: ["Liberty"],
  //   brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
  //   types: ["Lubricants"],
  // },
  // {
  //   sku: "AG0130",
  //   slug: "liberty-diesel-fs-cj-4-sae-10w-40-200l-drum",
  //   thumbnail: "/file/listings/AG0130.png",
  //   name: "LIBERTY DIESEL FS CJ-4 SAE 10W-40 (200L DRUM)",
  //   description: "Diesel Engine Oil",
  //   information: "The LIBERTY DIESEL FS CJ-4 SAE 10W-40 is a high-quality diesel engine oil designed to meet the demanding CJ-4 specifications. It comes in a 200L drum, making it ideal for commercial fleets, heavy-duty applications, and industrial use. This engine oil provides excellent protection against wear, soot, and deposit formation, ensuring enhanced engine durability and reliable performance. Its advanced formula includes detergents and dispersants that effectively keep the engine clean and prevent harmful sludge buildup. The 10W-40 viscosity grade offers reliable cold-start protection and maintains stability under high temperatures, providing excellent lubrication throughout the engine's operating range. Suitable for use in modern diesel engines with advanced emissions control systems, such as diesel particulate filters (DPF) and selective catalytic reduction (SCR), the LIBERTY DIESEL FS CJ-4 SAE 10W-40 is the perfect choice for heavy-duty vehicles and equipment    ",
  //   detail: "The LIBERTY DIESEL FS CJ-4 SAE 10W-40 is a high-quality diesel engine oil designed to meet the demanding CJ-4 specifications. It comes in a 200L drum, making it ideal for commercial fleets, heavy-duty applications, and industrial use. This engine oil provides excellent protection against wear, soot, and deposit formation, ensuring enhanced engine durability and reliable performance. Its advanced formula includes detergents and dispersants that effectively keep the engine clean and prevent harmful sludge buildup. The 10W-40 viscosity grade offers reliable cold-start protection and maintains stability under high temperatures, providing excellent lubrication throughout the engine's operating range. Suitable for use in modern diesel engines with advanced emissions control systems, such as diesel particulate filters (DPF) and selective catalytic reduction (SCR), the LIBERTY DIESEL FS CJ-4 SAE 10W-40 is the perfect choice for heavy-duty vehicles and equipment",
  //   uom: "DRUM(S)",
  //   principals: ["Liberty"],
  //   brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
  //   types: ["Lubricants"],
  // },
  // {
  //   sku: "AG0090",
  //   slug: "liberty-diesel-max-ck-4-sae-15w-40-200l-drum",
  //   thumbnail: "/file/listings/AG0130.png",
  //   name: "LIBERTY DIESEL Max CK-4 SAE 15W-40 (200L Drum)",
  //   description: "Diesel Engine Oil",
  //   information: "The LIBERTY DIESEL Max CK-4 SAE 15W-40 is a premium diesel engine oil designed to meet the latest CK-4 specifications. It comes in a 200L drum, making it suitable for larger applications and heavy-duty use. This high-quality engine oil provides exceptional protection against wear, soot, and deposits, ensuring a longer engine life and improved performance. Its advanced formula offers superior detergency and dispersant properties to keep the engine clean and free from harmful contaminants. The 15W-40 viscosity grade provides excellent cold-start protection and maintains stable performance even at high operating temperatures. This oil is specially formulated for modern diesel engines, including those equipped with diesel particulate filters (DPF) and selective catalytic reduction (SCR) systems. Whether used in commercial vehicles, trucks, or heavy equipment, the LIBERTY DIESEL Max CK-4 SAE 15W-40 is a reliable choice for demanding industrial, agricultural, and transportation applications",
  //   detail: "The LIBERTY DIESEL Max CK-4 SAE 15W-40 is a high-performance diesel engine oil designed to meet the latest CK-4 specifications. It comes in a 200L drum, providing ample supply for heavy-duty applications and commercial fleets. This premium engine oil delivers exceptional wear protection, soot control, and deposit resistance, ensuring longer engine life and optimal performance. Its advanced formula includes superior detergency and dispersant properties, keeping the engine clean and free from harmful contaminants. The 15W-40 viscosity grade offers excellent cold-start protection and maintains stability even under high operating temperatures. Ideal for modern diesel engines equipped with diesel particulate filters (DPF) and selective catalytic reduction (SCR) systems, this oil is suitable for use in commercial vehicles, trucks, heavy equipment, and various industrial applications. Trust the LIBERTY DIESEL Max CK-4 SAE 15W-40 to provide reliable and efficient engine protection in demanding working conditions",
  //   uom: "200L Drum",
  //   principals: ["Liberty"],
  //   brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
  //   types: ["Lubricants"],
  // },
  // {
  //   sku: "AG0091",
  //   slug: "liberty-diesel-max-ck-4-sae-15w-40-20l-pail",
  //   thumbnail: libertyPlaceholder,
  //   name: "LIBERTY DIESEL Max CK-4 SAE 15W-40 (20L Pail)",
  //   description: "Diesel Engine Oil",
  //   information: "LIBERTY DIESEL Max CK-4 SAE 15W-40 is a premium-quality diesel engine oil engineered to meet the latest CK-4 specifications. It comes in a 20L pail, making it suitable for small to medium-sized applications. This advanced engine oil provides exceptional engine protection against wear, soot, and deposits, ensuring extended engine life and improved performance. Its superior detergency and dispersant properties keep the engine clean and free from harmful contaminants. With its 15W-40 viscosity grade, this oil provides excellent cold-start protection and maintains stable performance at high temperatures. It meets the demands of modern diesel engines, including those equipped with diesel particulate filters (DPF) and selective catalytic reduction (SCR) systems. The LIBERTY DIESEL Max CK-4 SAE 15W-40 is designed for various diesel-powered vehicles, trucks, and heavy equipment, making it a reliable choice for commercial, industrial, and agricultural applications",
  //   detail: "The LIBERTY DIESEL Max CK-4 SAE 15W-40 is a high-quality diesel engine oil designed to meet the latest CK-4 specifications. It comes in a 200L drum, making it suitable for larger applications and heavy-duty use. This advanced engine oil offers exceptional protection against wear, soot, and deposits, ensuring a longer engine life and improved performance. Its superior detergency and dispersant properties keep the engine clean and free from harmful contaminants. The 15W-40 viscosity grade provides excellent cold-start protection and maintains stable performance even at high operating temperatures. This oil is specially formulated for modern diesel engines, including those equipped with diesel particulate filters (DPF) and selective catalytic reduction (SCR) systems. Whether used in commercial vehicles, trucks, or heavy equipment, the LIBERTY DIESEL Max CK-4 SAE 15W-40 is a reliable choice for demanding industrial, agricultural, and transportation applications",
  //   uom: "20L Pail",
  //   principals: ["Liberty"],
  //   brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
  //   types: ["Lubricants"],
  // },
  // {
  //   sku: "AG0088",
  //   slug: "liberty-diesel-power-ci-4-sae-15w-40-200l-drum",
  //   thumbnail: libertyPlaceholder,
  //   name: "LIBERTY DIESEL POWER CI-4 SAE 15W-40 (200L Drum)",
  //   description: "Diesel Engine Oil",
  //   information: "LIBERTY DIESEL POWER CI-4 SAE 15W-40 is a high-performance diesel engine oil specially formulated to meet the demands of modern diesel engines. This 200L drum provides a large quantity suitable for commercial and industrial applications. The oil is designed to deliver excellent engine protection, enhanced fuel efficiency, and reduced emissions. Its advanced formula ensures optimal engine cleanliness, minimizing sludge and deposit formation. With its CI-4 specification, this engine oil is compatible with engines equipped with exhaust gas recirculation (EGR) and other emission control technologies. The oil's high thermal stability and resistance to oxidation ensure consistent performance even under extreme conditions, making it ideal for heavy-duty vehicles and machinery",
  //   detail: "LIBERTY DIESEL POWER CI-4 SAE 15W-40 is a high-quality diesel engine oil designed to meet the demanding requirements of modern diesel engines. This product comes in a 200L drum, making it suitable for large-scale industrial and commercial applications. The oil offers excellent engine protection against wear, deposits, and sludge formation, ensuring optimal engine cleanliness and prolonging engine life. It also helps improve fuel efficiency, reducing operating costs for businesses. With its CI-4 specification, this engine oil is compatible with engines equipped with exhaust gas recirculation (EGR) and other emission control technologies, contributing to reduced emissions. Its high thermal stability and resistance to oxidation ensure consistent and reliable performance even under extreme operating conditions. This versatile oil is ideal for heavy-duty vehicles, construction equipment, agricultural machinery, and other industrial applications, making it a reliable choice for various industries",
  //   uom: "200L Drum",
  //   principals: ["Liberty"],
  //   brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
  //   types: ["Lubricants"],
  // },
  // {
  //   sku: "AG0089",
  //   slug: "liberty-diesel-power-ci-4-sae-15w-40-20l-pail",
  //   thumbnail: libertyPlaceholder,
  //   name: "LIBERTY DIESEL POWER CI-4 SAE 15W-40 (20L Pail)",
  //   description: "Diesel Engine Oil",
  //   information: "LIBERTY DIESEL POWER CI-4 SAE 15W-40 is a high-performance diesel engine oil specially formulated to meet the demands of modern diesel engines. It comes in a 20L pail, making it suitable for various industrial and commercial applications. This engine oil is designed to provide excellent protection against wear, deposits, and sludge buildup, ensuring the longevity and reliability of diesel engines. Its advanced formulation enhances fuel efficiency and helps reduce emissions, making it environmentally friendly. The oil's outstanding thermal stability and resistance to oxidation ensure consistent performance even under extreme operating conditions. LIBERTY DIESEL POWER CI-4 SAE 15W-40 meets the CI-4 specification, which guarantees its compatibility with diesel engines equipped with exhaust gas recirculation (EGR) and other advanced engine technologies",
  //   detail: "LIBERTY DIESEL POWER CI-4 SAE 15W-40 is a premium-quality diesel engine oil designed to meet the rigorous demands of modern diesel engines. Packaged in a convenient 20L pail, this engine oil is suitable for various industrial and commercial applications. Its advanced formula provides exceptional protection against wear, deposits, and sludge formation, ensuring the longevity and optimal performance of diesel engines. The oil's high thermal stability and resistance to oxidation maintain consistent performance even under extreme operating conditions. Additionally, LIBERTY DIESEL POWER CI-4 SAE 15W-40 improves fuel efficiency and helps reduce harmful emissions, making it an eco-friendly choice. This product meets the CI-4 specification, ensuring compatibility with diesel engines equipped with exhaust gas recirculation (EGR) and other advanced engine technologies. Whether used in construction equipment, agricultural machinery, or heavy-duty vehicles, LIBERTY DIESEL POWER CI-4 SAE 15W-40 provides reliable and efficient engine protection",
  //   uom: "20L Pail",
  //   principals: ["Liberty"],
  //   brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
  //   types: ["Lubricants"],
  // },
  // {
  //   sku: "AG0092",
  //   slug: "liberty-gear-90-gl5-200l-drum",
  //   thumbnail: libertyPlaceholder,
  //   name: "LIBERTY Gear 90 GL5 (200L Drum)",
  //   description: "Gear Oil",
  //   information: "The LIBERTY Gear 90 GL5 is a high-quality gear oil offered in a 200L drum. This gear oil is formulated to meet the GL5 performance level, making it suitable for various heavy-duty industrial gear applications. It provides excellent protection against wear, pitting, and corrosion, ensuring smooth gear operation and extended equipment life. The LIBERTY Gear 90 GL5 is designed to withstand high loads and extreme pressure, making it ideal for use in gears, bearings, and differentials under severe operating conditions. With its exceptional thermal stability, it maintains its viscosity and lubricating properties at elevated temperatures, reducing the risk of breakdown and ensuring reliable gear performance. This gear oil meets international industry standards, guaranteeing its quality and compatibility with a wide range of industrial gear systems",
  //   detail: "The LIBERTY Gear 90 GL5 is a premium-quality gear oil designed for heavy-duty industrial gear systems. It comes in a 200L drum, ensuring an adequate supply for industrial applications. This gear oil meets the GL5 performance level, making it suitable for gears, bearings, and differentials operating under severe conditions. It provides exceptional wear protection, guarding against gear surface damage and extending equipment life. The oil's corrosion-resistant properties further contribute to equipment durability. Additionally, the LIBERTY Gear 90 GL5 exhibits excellent thermal stability, maintaining consistent viscosity and lubrication even at high temperatures, reducing the risk of gear failures. With its high load-carrying capacity and extreme pressure performance, this gear oil ensures smooth gear operation and reliable performance. It complies with international industry standards, assuring its quality and compatibility with various industrial gear systems",
  //   uom: "200L Drum",
  //   principals: ["Liberty"],
  //   brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
  //   types: ["Lubricants"],
  // },
  // {
  //   sku: "AG0035",
  //   slug: "liberty-grease-lc-2-15kg-pail",
  //   thumbnail: libertyPlaceholder,
  //   name: "LIBERTY GREASE LC 2 (15KG PAIL)",
  //   description: "Lithium Complex Grease",
  //   information: "LIBERTY GREASE LC 2 is a premium-quality lithium complex grease specially formulated to provide superior lubrication and protection in various industrial applications. This grease comes in a 15kg pail and offers excellent resistance to water washout and oxidation, ensuring long-lasting performance even in demanding environments. Its high dropping point and mechanical stability make it suitable for high-temperature applications, reducing the risk of oil separation and extending maintenance intervals. LIBERTY GREASE LC 2 delivers exceptional load-carrying capacity and anti-wear properties, safeguarding equipment from friction, wear, and corrosion. With its versatile nature, this grease is ideal for heavy-duty equipment, bearings, gears, and other industrial components, contributing to extended equipment life and improved operational efficiency",
  //   detail: "The LIBERTY GREASE LC 2 is a high-performance lithium complex grease offered in a 15kg pail. This premium-quality grease is designed to provide exceptional lubrication and protection for a wide range of industrial applications. It boasts excellent resistance to water washout and oxidation, ensuring long-lasting performance and reliability even in harsh operating conditions. With its high dropping point and mechanical stability, it remains effective at elevated temperatures, reducing the risk of oil separation and ensuring smooth equipment operation. The grease's outstanding load-carrying capacity and anti-wear properties safeguard machinery and components from friction, wear, and corrosion, contributing to extended equipment life and improved operational efficiency. Whether used in heavy-duty equipment, bearings, gears, or other industrial applications, the LIBERTY GREASE LC 2 delivers superior performance, reduced maintenance requirements, and enhanced productivity",
  //   uom: "PAIL",
  //   principals: ["Liberty"],
  //   brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
  //   types: ["Lubricants"],
  // },
  // {
  //   sku: "AG0317",
  //   slug: "liberty-grease-moly-ep-2-15kg",
  //   thumbnail: libertyPlaceholder,
  //   name: "LIBERTY GREASE MOLY EP 2 (15KG)",
  //   description: "Multipurpose Grease",
  //   information: "LIBERTY GREASE MOLY EP 2 is a premium-quality, multipurpose grease designed to provide superior lubrication and protection for a wide range of industrial applications. It comes in a 15kg container and is formulated with a special blend of molybdenum disulfide (moly) and extreme pressure (EP) additives, which enhance its load-carrying capacity and resistance to heavy loads, shock, and impact. This grease offers exceptional adhesion and cohesion properties, ensuring excellent staying power on surfaces and reducing the risk of grease migration. It provides reliable protection against wear, corrosion, and rust, even under harsh conditions, resulting in extended equipment life and reduced maintenance costs. LIBERTY GREASE MOLY EP 2 is suitable for use in various industrial machinery, bearings, gears, and other components subjected to heavy-duty operations, making it a versatile and reliable choice for ensuring optimal equipment performance",
  //   detail: "LIBERTY GREASE MOLY EP 2 is a high-quality, multipurpose grease specifically designed to offer exceptional lubrication and protection in various industrial applications. It comes in a 15kg container and contains molybdenum disulfide (moly) and extreme pressure (EP) additives, ensuring enhanced load-carrying capacity and resistance to heavy loads, shocks, and impacts. The grease exhibits excellent adhesion and cohesion properties, preventing grease migration and providing long-lasting performance on surfaces. It effectively protects against wear, corrosion, and rust even in challenging conditions, leading to prolonged equipment life and reduced maintenance requirements. LIBERTY GREASE MOLY EP 2 is suitable for industrial machinery, bearings, gears, and other components exposed to heavy-duty operations, making it a reliable and versatile choice for optimal equipment performance",
  //   uom: "PAIL",
  //   principals: ["Liberty"],
  //   brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
  //   types: ["Lubricants"],
  // },
  // {
  //   sku: "AG0180",
  //   slug: "liberty-grease-moly-lc-2-18kg",
  //   thumbnail: libertyPlaceholder,
  //   name: "LIBERTY GREASE MOLY LC 2 (18kg)",
  //   description: "Heavy-duty Grease",
  //   information: "LIBERTY GREASE MOLY LC 2 is a high-quality, heavy-duty grease specially formulated to provide exceptional lubrication and protection for various industrial applications. This grease comes in an 18kg container and is fortified with molybdenum disulfide (moly) to enhance its load-carrying capacity and reduce friction even under extreme conditions. Its excellent adhesion properties ensure long-lasting performance and resistance to water washout, making it suitable for applications exposed to harsh environments. LIBERTY GREASE MOLY LC 2 offers outstanding resistance to oxidation and provides reliable protection against wear, corrosion, and rust, extending the service life of equipment and reducing downtime. This versatile grease is recommended for use in heavy machinery, construction equipment, mining operations, and other demanding industrial settings where reliable lubrication is critical for optimal performance and productivity",
  //   detail: "LIBERTY GREASE MOLY LC 2 is a high-quality, heavy-duty grease specifically designed to offer exceptional lubrication and protection for various industrial applications. It comes in an 18kg container and is fortified with molybdenum disulfide (moly), which enhances its load-carrying capacity and reduces friction even under extreme conditions. This grease exhibits excellent adhesion properties, ensuring long-lasting performance and resistance to water washout, making it suitable for applications exposed to harsh environments. With outstanding resistance to oxidation, it provides reliable protection against wear, corrosion, and rust, thereby extending the service life of equipment and reducing downtime. LIBERTY GREASE MOLY LC 2 is recommended for use in heavy machinery, construction equipment, mining operations, and other demanding industrial settings where dependable lubrication is crucial for optimal performance and productivity",
  //   uom: "PAIL",
  //   principals: ["Liberty"],
  //   brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
  //   types: ["Lubricants"],
  // },
  // {
  //   sku: "AG0077",
  //   slug: "liberty-hyde-aw-32-20l-pail",
  //   thumbnail: libertyPlaceholder,
  //   name: "LIBERTY HYDE AW 32 (20L Pail)",
  //   description: "Hydraulic Oil ",
  //   information: "LIBERTY HYDE AW 32 is a high-quality hydraulic oil available in a convenient 20-liter pail. It is specially formulated to provide exceptional anti-wear protection, ensuring smooth and efficient operation of hydraulic systems. With a viscosity grade of 32, it offers excellent flow properties and works effectively in various operating conditions. The oil's superior oxidation stability helps to extend its service life, reducing the need for frequent oil changes and maintenance. It also provides reliable rust and corrosion protection, ensuring the longevity of hydraulic components. LIBERTY HYDE AW 32 is an ideal choice for industrial applications that require reliable hydraulic performance and optimal equipment protection",
  //   detail: "LIBERTY HYDE AW 32 is a premium hydraulic oil offered in a 20-liter pail, designed to deliver exceptional performance for hydraulic systems. With its viscosity grade of 32, this oil ensures smooth operation and efficient power transmission in a wide range of operating conditions. Its advanced anti-wear additives provide excellent protection to hydraulic components, extending their service life and reducing maintenance requirements. The oil's superior oxidation resistance helps maintain its stability over time, ensuring consistent performance and minimizing the risk of equipment failure. Additionally, LIBERTY HYDE AW 32 offers reliable protection against rust and corrosion, safeguarding critical components and enhancing the overall longevity of hydraulic systems. This product is an excellent choice for industrial applications where reliable hydraulic performance is crucial for productivity and operational efficiency",
  //   uom: "20L Pail",
  //   principals: ["Liberty"],
  //   brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
  //   types: ["Lubricants"],
  // },
  // {
  //   sku: "AG0080",
  //   slug: "liberty-hyde-aw-46-20l-pail",
  //   thumbnail: libertyPlaceholder,
  //   name: "LIBERTY HYDE AW 46 (20L Pail)",
  //   description: "Hydraulic Oil ",
  //   information: "LIBERTY HYDE AW 46 is a top-quality hydraulic oil designed to meet the needs of industrial hydraulic systems. It comes in a convenient 20-liter pail, providing an optimal quantity for various applications. This premium hydraulic oil offers exceptional anti-wear protection, ensuring smooth and reliable operation of hydraulic equipment. Its viscosity grade of 46 makes it suitable for a wide range of operating temperatures, ensuring consistent performance in diverse working conditions. The oil also exhibits excellent oxidation stability, maintaining its effectiveness over time and prolonging the service life of hydraulic systems. With reliable rust protection, it safeguards critical components, reducing maintenance requirements. LIBERTY HYDE AW 46 is the perfect choice for demanding industrial applications that require superior hydraulic performance",
  //   detail: "LIBERTY HYDE AW 46 is a premium hydraulic oil that comes in a 20-liter pail, offering convenience and sufficient quantity for various industrial applications. This hydraulic oil is formulated to provide outstanding anti-wear protection, ensuring smooth and reliable operation of hydraulic systems. With a viscosity grade of 46, it offers versatility and maintains consistent performance across a wide range of operating temperatures. The oil's excellent oxidation stability helps to prolong its effectiveness, contributing to extended service intervals and reduced maintenance needs. Additionally, it offers reliable rust protection, safeguarding critical hydraulic components from corrosion. LIBERTY HYDE AW 46 is an excellent choice for industrial settings that demand superior hydraulic performance and long-term reliability",
  //   uom: "20L Pail",
  //   principals: ["Liberty"],
  //   brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
  //   types: ["Lubricants"],
  // },
  // {
  //   sku: "LIBERTY",
  //   slug: "liberty-hyde-aw-68-18l-pail",
  //   thumbnail: libertyPlaceholder,
  //   name: "LIBERTY HYDE AW 68 (18L PAIL)",
  //   description: "Hydraulic fluid",
  //   information: "LIBERTY HYDE AW 68 is a high-quality hydraulic oil specifically formulated to meet the demands of industrial hydraulic systems. This product comes in an 18-liter pail, making it suitable for various applications and easy to handle. It provides excellent anti-wear protection, ensuring smooth and reliable operation of hydraulic equipment. With a viscosity grade of 68, it performs exceptionally well over a wide range of temperatures, maintaining its effectiveness in diverse working conditions. The oil exhibits outstanding oxidation stability, preserving its performance and extending the service life of hydraulic systems. Additionally, it offers reliable rust protection, safeguarding critical components and reducing maintenance requirements    ",
  //   detail: "LIBERTY HYDE AW 68 is a premium hydraulic oil designed to meet the demands of industrial hydraulic systems. It comes in an 18-liter pail, providing an ideal quantity for various applications. This high-quality oil offers excellent anti-wear protection, ensuring the smooth and reliable operation of hydraulic equipment. Its viscosity grade of 68 makes it suitable for a wide range of temperatures, enabling consistent performance in diverse working conditions. The oil also exhibits outstanding oxidation stability, maintaining its effectiveness over time and extending the service life of hydraulic systems. With reliable rust protection, it safeguards critical components, reducing maintenance requirements. LIBERTY HYDE AW 68 is the ideal choice for industrial applications that demand superior hydraulic performance",
  //   uom: "PAIL",
  //   principals: ["Liberty"],
  //   brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
  //   types: ["Lubricants"],
  // },
  // {
  //   sku: "AG0082",
  //   slug: "liberty-hyde-aw-68-200l-drum",
  //   thumbnail: libertyPlaceholder,
  //   name: "LIBERTY HYDE AW 68 (200L Drum)",
  //   description: "Hydraulic fluid",
  //   information: "LIBERTY HYDE AW 68 is a high-quality hydraulic oil designed for industrial applications, presented in a 200-liter drum. This hydraulic fluid boasts exceptional anti-wear properties, ensuring optimal protection for hydraulic systems, even under demanding conditions. Its advanced formula provides outstanding oxidation stability, preventing oil breakdown and ensuring long-lasting performance. The rust-resistant properties further safeguard critical components, promoting system longevity. With a viscosity grade of 68, it offers excellent flow characteristics and remains effective over a wide temperature range. The 200-liter drum packaging provides convenience for industrial settings that require substantial hydraulic oil quantities    ",
  //   detail: "LIBERTY HYDE AW 68 is a premium hydraulic oil designed to meet the requirements of industrial hydraulic systems. It is available in a 200-liter drum and provides excellent anti-wear protection, ensuring smooth and reliable operation of hydraulic equipment. With a high viscosity grade of 68, it offers outstanding performance over a wide range of temperatures, making it suitable for various industrial applications. The oil exhibits exceptional oxidation stability, preserving its performance and extending the lifespan of hydraulic systems. It also possesses rust protection properties, offering additional safeguarding for critical components, reducing maintenance needs and downtime. LIBERTY HYDE AW 68 is the ideal choice for businesses seeking high-performance hydraulic oil",
  //   uom: "200L Drum",
  //   principals: ["Liberty"],
  //   brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
  //   types: ["Lubricants"],
  // },
  // {
  //   sku: "AG0120",
  //   slug: "liberty-hyde-hvi-68-200l",
  //   thumbnail: libertyPlaceholder,
  //   name: "LIBERTY HYDE HVI 68 (200L)",
  //   description: "Hydraulic fluid",
  //   information: "LIBERTY HYDE HVI 68, available in a 200-liter drum, is a high-performance hydraulic fluid specially formulated to deliver excellent viscosity and shear stability under extreme conditions. This hydraulic oil is designed to provide exceptional anti-wear protection, ensuring smooth and efficient operation of hydraulic systems. Its advanced HVI (High Viscosity Index) technology allows the fluid to maintain consistent viscosity across a wide range of temperatures, offering superior thermal stability and performance. LIBERTY HYDE HVI 68 is recommended for use in various industrial and mobile hydraulic systems, including those subjected to high pressures and demanding environments. With its outstanding performance and large packaging, this hydraulic fluid is a cost-effective and reliable choice for industries requiring large quantities of hydraulic oil",
  //   detail: "LIBERTY HYDE HVI 68 is a high-performance hydraulic fluid available in a 200-liter drum. Designed with advanced HVI (High Viscosity Index) technology, this hydraulic oil ensures excellent viscosity and shear stability, even under extreme conditions. It provides exceptional anti-wear protection, contributing to smooth and efficient operation of hydraulic systems. The fluid's superior thermal stability allows it to maintain consistent performance across a wide temperature range. Recommended for industrial and mobile hydraulic systems, including those exposed to high pressures and demanding environments, LIBERTY HYDE HVI 68 is a reliable and cost-effective choice for industries requiring large quantities of hydraulic oil. With its convenient packaging and outstanding performance, it meets the demands of various hydraulic applications with ease",
  //   uom: "200L Drum",
  //   principals: ["Liberty"],
  //   brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
  //   types: ["Lubricants"],
  // },
  // {
  //   sku: "AG0118",
  //   slug: "liberty-industrial-gear-oil-ep-150-20l",
  //   thumbnail: libertyPlaceholder,
  //   name: "LIBERTY Industrial Gear Oil EP 150 (20L)",
  //   description: "Industrial Gear Oil",
  //   information: "LIBERTY Industrial Gear Oil EP 150 is a top-quality lubricant formulated to deliver excellent protection and efficiency for industrial gear systems. Its EP (Extreme Pressure) additives form a durable film on gear surfaces, preventing metal-to-metal contact and reducing wear even under high-pressure conditions. This gear oil exhibits outstanding thermal stability and resistance to oxidation, ensuring consistent performance and extended service life. The 20-liter packaging provides a convenient and cost-effective solution for industrial applications, offering smooth and reliable gear operation. Ideal for various industrial gearboxes and reducers, LIBERTY Industrial Gear Oil EP 150 is a reliable choice for enhancing gear performance and ensuring smooth operations in industrial settings",
  //   detail: "LIBERTY Industrial Gear Oil EP 150 in the 20-liter packaging is a high-performance lubricant designed to provide exceptional protection and efficiency for industrial gear systems. Its advanced EP (Extreme Pressure) additives form a durable film on gear surfaces, preventing metal-to-metal contact and minimizing wear, even under high-pressure conditions. This gear oil demonstrates remarkable thermal stability and oxidation resistance, ensuring consistent performance and extending the service life of industrial gearboxes and reducers. With its reliable and smooth gear operation, LIBERTY Industrial Gear Oil EP 150 is the perfect choice for heavy-duty industrial applications, offering cost-effective and convenient packaging to meet the demands of various industrial settings",
  //   uom: "PAIL",
  //   principals: ["Liberty"],
  //   brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
  //   types: ["Lubricants"],
  // },
  // {
  //   sku: "AG0117",
  //   slug: "liberty-industrial-gear-oil-ep-220-200l",
  //   thumbnail: libertyPlaceholder,
  //   name: "LIBERTY Industrial Gear Oil EP 220 (200L)",
  //   description: "Industrial Gear Oil",
  //   information: "LIBERTY Industrial Gear Oil EP 220 is a premium-grade lubricant specifically formulated to provide outstanding protection and performance for industrial gear systems. With its high viscosity index and exceptional load-carrying capacity, this gear oil is well-suited for heavy-duty applications that involve significant pressures and loads. Its advanced EP (Extreme Pressure) additives form a durable film on gear surfaces, preventing metal-to-metal contact and minimizing wear, even under extreme operating conditions. The oil's excellent thermal stability and resistance to oxidation ensure consistent performance and extended service life. Suitable for various industrial gearboxes and reducers, this 200-liter drum packaging offers a cost-effective and convenient solution for large-scale industrial operations, ensuring smooth and efficient gear operation",
  //   detail: "LIBERTY Industrial Gear Oil EP 220 is a high-performance lubricant designed to provide exceptional protection and efficiency for industrial gear systems. With its EP (Extreme Pressure) additives, this oil forms a robust film on gear surfaces, preventing metal-to-metal contact and reducing wear even under demanding conditions. Its outstanding thermal stability and resistance to oxidation ensure consistent performance and prolonged service life. The 200-liter drum packaging makes it an economical and convenient solution for large-scale industrial operations, delivering smooth and efficient gear operation. Ideal for heavy-duty applications involving significant pressures and loads, this EP 220 gear oil is suitable for various industrial gearboxes and reducers, ensuring reliable and optimized gear performance throughout its usage",
  //   uom: "DRUM(S)",
  //   principals: ["Liberty"],
  //   brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
  //   types: ["Lubricants"],
  // },
  // {
  //   sku: "AG0119",
  //   slug: "liberty-industrial-gear-oil-ep-220-20l",
  //   thumbnail: libertyPlaceholder,
  //   name: "LIBERTY Industrial Gear Oil EP 220 (20L)",
  //   description: "Industrial Gear Oil",
  //   information: "LIBERTY Industrial Gear Oil EP 220 is a high-quality gear oil specially formulated to provide exceptional performance and protection for industrial gear systems. This oil is designed to withstand heavy loads, extreme pressures, and high temperatures, making it suitable for a wide range of industrial applications. It contains premium base oils and advanced additives that ensure excellent wear protection, minimize friction, and extend equipment life. The EP (Extreme Pressure) properties of this gear oil enable it to handle the demanding conditions found in industrial gearboxes and reducers, preventing metal-to-metal contact and reducing wear. Its thermal stability and resistance to oxidation help maintain oil integrity even during prolonged operation, enhancing equipment efficiency and reliability. The 20-liter packaging ensures easy handling and storage, making it a convenient choice for industrial facilities",
  //   detail: "LIBERTY Industrial Gear Oil EP 220 is a high-performance lubricant designed to provide exceptional protection and efficiency for industrial gear systems. With its viscosity grade of 220, this gear oil is capable of handling heavy loads and extreme pressures commonly encountered in industrial applications. Its advanced EP (Extreme Pressure) additives form a protective barrier that prevents metal-to-metal contact, reducing wear and extending the life of gearboxes and reducers. The oil's thermal stability and resistance to oxidation ensure consistent performance even under high-temperature operating conditions. Its superior lubricating properties result in smooth and reliable gear operation, enhancing equipment efficiency and reliability. The 20-liter packaging makes it convenient for industrial facilities, providing easy handling and storage. LIBERTY Industrial Gear Oil EP 220 is a reliable choice to keep industrial gear systems running smoothly and efficiently",
  //   uom: "PAIL",
  //   principals: ["Liberty"],
  //   brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
  //   types: ["Lubricants"],
  // },
  // {
  //   sku: "AGL0007",
  //   slug: "liberty-mp-3-grease--15-ltrs-",
  //   thumbnail: libertyPlaceholder,
  //   name: "LIBERTY MP-3 GREASE ( 15 LTRS )",
  //   description: "Multi-purpose Grease",
  //   information: "The LIBERTY MP-3 Grease is a premium-quality multi-purpose grease designed to provide exceptional lubrication and protection in a wide range of applications. This grease is formulated using high-quality base oils and a special blend of additives to offer superior performance and longevity. It is suitable for use in various automotive, industrial, and agricultural equipment, including bearings, chassis, and other friction points. The LIBERTY MP-3 Grease provides excellent resistance to water washout, rust, and corrosion, ensuring optimal performance even in harsh environments. Its high load-carrying capacity and shear stability make it ideal for heavy-duty applications, reducing friction and wear, and extending equipment life. Packaged in a convenient 15-liter container, this grease is an economical and efficient choice for workshops, factories, and maintenance operations",
  //   detail: "The LIBERTY MP-3 Grease is a high-quality multi-purpose grease designed to deliver exceptional lubrication and protection across various applications. This versatile grease is formulated with top-grade base oils and a carefully crafted blend of additives, ensuring superior performance and longevity. It finds extensive use in automotive, industrial, and agricultural equipment, effectively lubricating bearings, chassis, and other friction points. The LIBERTY MP-3 Grease exhibits outstanding resistance to water washout, rust, and corrosion, making it reliable even in challenging environments. Its robust load-carrying capacity and shear stability make it ideal for heavy-duty applications, reducing friction and wear while prolonging equipment life. Packaged in a convenient 15-liter container, this grease is a cost-effective and efficient choice for workshops, factories, and maintenance operations, delivering reliable performance and protection to keep machinery running smoothly",
  //   uom: "PAIL",
  //   principals: ["Liberty"],
  //   brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
  //   types: ["Lubricants"],
  // },
  // {
  //   sku: "AG0097",
  //   slug: "liberty-performance-gl5-80w-90-20l-pail",
  //   thumbnail: libertyPlaceholder,
  //   name: "LIBERTY Performance GL5 80W-90 (20L Pail)",
  //   description: "Gear Oil",
  //   information: "The LIBERTY Performance GL5 80W-90 is a high-quality gear oil engineered to meet the demanding GL5 specifications. It is specially formulated for use in manual transmissions, differentials, and final drives that require the 80W-90 viscosity grade. This gear oil provides excellent protection against wear, rust, and corrosion, ensuring smooth and efficient operation of gears and bearings. Its advanced additives enhance thermal stability and resistance to oxidation, making it suitable for various operating conditions. Packaged in a convenient 20L pail, the LIBERTY Performance GL5 80W-90 is an ideal choice for workshops, automotive enthusiasts, and industrial applications where a moderate quantity of gear oil is required. With its reliable performance and consistent quality, this product ensures optimal gear and bearing performance, prolonging the life of critical components in various vehicles and machinery",
  //   detail: "The LIBERTY Performance GL5 80W-90 is a top-quality gear oil designed to meet the stringent GL5 specifications. This gear oil is specifically formulated for use in manual transmissions, differentials, and final drives that require the 80W-90 viscosity grade. It offers exceptional protection against wear, rust, and corrosion, ensuring the smooth and efficient operation of gears and bearings. Its advanced additives provide enhanced thermal stability and resistance to oxidation, making it suitable for a wide range of operating conditions. Packaged in a convenient 20L pail, the LIBERTY Performance GL5 80W-90 is an ideal choice for workshops, automotive enthusiasts, and industrial applications where a moderate quantity of gear oil is needed. With its consistent high performance and reliability, this product ensures optimal gear and bearing performance, extending the lifespan of critical components in various vehicles and machinery. Whether for automotive, industrial, or commercial use, this gear oil delivers outstanding results and meets industry standards for GL5 gear oils, making it a trusted choice for gear lubrication needs",
  //   uom: "20L Pail",
  //   principals: ["Liberty"],
  //   brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
  //   types: ["Lubricants"],
  // },
  // {
  //   sku: "AG0098",
  //   slug: "liberty-performance-gl5-85w-140-200l-drum",
  //   thumbnail: libertyPlaceholder,
  //   name: "LIBERTY Performance GL5 85W-140 (200L Drum)",
  //   description: "Gear Oil",
  //   information: "The LIBERTY Performance GL5 85W-140 is a high-quality gear oil designed to meet the performance requirements of GL5 specifications. It is specially formulated for use in heavy-duty manual transmissions, axles, and final drives that require an 85W-140 viscosity grade. This gear oil provides excellent protection against wear, corrosion, and oxidation, ensuring smooth and efficient operation of gear components even under high-stress conditions. Its advanced additives contribute to prolonged gear and bearing life, reducing maintenance costs and downtime. Packaged in a large 200L drum, the LIBERTY Performance GL5 85W-140 is ideal for commercial and industrial applications where a substantial supply of gear oil is necessary. With its superior performance and reliability, this product ensures the optimal functioning and longevity of critical machinery and equipment",
  //   detail: "The LIBERTY Performance GL5 85W-140 is a premium gear oil designed to meet the stringent GL5 specifications. It is specifically formulated to cater to heavy-duty manual transmissions, axles, and final drives requiring the 85W-140 viscosity grade. This gear oil offers exceptional protection against wear, corrosion, and oxidation, ensuring smooth and efficient gear performance even under high-stress conditions. Its advanced additives contribute to extended gear and bearing life, reducing maintenance costs and downtime. Packaged in a large 200L drum, the LIBERTY Performance GL5 85W-140 is an ideal choice for commercial and industrial applications where a substantial supply of gear oil is essential. With its superior performance and reliability, this product ensures the optimal functioning and longevity of critical machinery and equipment, making it a dependable choice for various heavy-duty applications",
  //   uom: "200L Drum",
  //   principals: ["Liberty"],
  //   brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
  //   types: ["Lubricants"],
  // },
  // {
  //   sku: "AG0099",
  //   slug: "liberty-performance-gl5-85w-140-20l-pail",
  //   thumbnail: libertyPlaceholder,
  //   name: "LIBERTY Performance GL5 85W-140 (20L Pail)",
  //   description: "Gear Oil",
  //   information: "The LIBERTY Performance GL5 85W-140 is a high-quality gear oil formulated to meet the requirements of GL5 performance level. It is specially designed for use in heavy-duty manual transmissions, axles, and final drives that require an 85W-140 viscosity grade. This gear oil provides excellent protection against wear, corrosion, and oxidation, ensuring smooth and efficient operation of the gear components. Its advanced formula helps extend the service life of gears and bearings, reducing maintenance costs and downtime. The 20L pail packaging offers convenience for professional use and ensures an adequate supply for various applications. Trust LIBERTY Performance GL5 85W-140 to deliver reliable performance and safeguard your valuable equipment",
  //   detail: "The LIBERTY Performance GL5 85W-140 is a high-quality gear oil designed to meet the GL5 performance level requirements. It is formulated for heavy-duty manual transmissions, axles, and final drives that demand an 85W-140 viscosity grade. This gear oil offers exceptional protection against wear, corrosion, and oxidation, ensuring smooth and efficient operation of gear components. Its advanced formula helps prolong the service life of gears and bearings, reducing maintenance costs and downtime. Packaged in a convenient 20L pail, the LIBERTY Performance GL5 85W-140 provides reliable performance and ample supply for various applications, making it a dependable choice for preserving and safeguarding valuable equipment",
  //   uom: "20L Pail",
  //   principals: ["Liberty"],
  //   brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
  //   types: ["Lubricants"],
  // },
  // {
  //   sku: "AGL0014",
  //   slug: "liberty-speed-max-sae-5w-40-6x4l",
  //   thumbnail: "/file/listings/AGL001.png"4,
  //   name: "LIBERTY SPEED MAX SAE 5W-40 (6x4L)",
  //   description: "Synthetic Engine Oil",
  //   information: "LIBERTY SPEED MAX SAE 5W-40 is a premium-quality synthetic engine oil that offers exceptional performance and protection for modern gasoline and diesel engines. It is designed to meet the demanding requirements of high-performance vehicles and provides excellent lubrication, ensuring smooth engine operation even under extreme conditions. The SAE 5W-40 viscosity grade offers quick oil flow during cold starts, reducing wear and tear on critical engine components. Liberty Speed Max is formulated with advanced additives that help reduce engine deposits, improve fuel efficiency, and extend the service life of the engine. It provides excellent thermal stability, ensuring consistent performance and protection even at high temperatures. The 6x4L packaging offers convenience and cost-effectiveness, making it an excellent choice for professional mechanics and car enthusiasts",
  //   detail: "The LIBERTY SPEED MAX SAE 5W-40 is a high-quality synthetic engine oil designed to deliver exceptional performance and protection for modern gasoline and diesel engines. Its SAE 5W-40 viscosity grade ensures smooth oil flow during cold starts, minimizing wear on critical engine components. This advanced oil formulation is equipped with special additives that reduce engine deposits, enhance fuel efficiency, and extend the engine's service life. With outstanding thermal stability, it maintains consistent performance even in high-temperature conditions. The 6x4L packaging provides convenience and cost-effectiveness, making it an excellent choice for both professional mechanics and car enthusiasts alike. Trust LIBERTY SPEED MAX to keep your engine running at its best and deliver the performance you need",
  //   uom: "CARTON(S)",
  //   principals: ["Liberty"],
  //   brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
  //   types: ["Lubricants"],
  // },
  {
    sku: "AG0177",
    slug: "r2424-bosch-aeristo-cabin-filter",
    thumbnail: "/file/listings/bosch-placeholder.png",
    name: "R2424 - BOSCH AERISTO CABIN FILTER",
    description: "Anti-allergic & anti-bacterial protection for clean air inside your car",
    information: "Bosch Premium Cabin Air Filters offer an excellent blend of original equipment fit and significantly enhanced quality, resulting in a cleaner and healthier passenger cabin air quality. With pollutants and allergens inside a vehicle potentially reaching up to six times higher concentration than outside, Bosch Premium Cabin Filters act as a reliable barrier, preventing harmful spores and pollen from entering the vehicle interior. By doing so, they not only safeguard passengers but also protect downstream HVAC components, ensuring a more pleasant and safe driving experience",
    detail: "Anti-Allergy Layer that effectively renders allergens harmless and eliminates bacteria, ensuring a healthier environment for all passengers. The inclusion of Activated Carbon enhances the filter's capability to absorb harmful gases and unpleasant smells. Ultra-fine microfiber filters fine dust particles (up to 2.5µm), soot and pollen. The filter's Carrier Fleece stabilizes the layers, ensuring optimum performance and durability. Additionally, the reduction of deposits protects the air conditioning system, ultimately leading to fresher air and a more comfortable journey for everyone on board",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Cabin Filters"],
  },
  {
    sku: "AG0179",
    slug: "r5546-bosch-aeristo-cabin-filter",
    thumbnail: "/file/listings/bosch-placeholder.png",
    name: "R5546 - BOSCH AERISTO CABIN FILTER",
    description: "Anti-allergic & anti-bacterial protection for clean air inside your car",
    information: "Bosch Premium Cabin Air Filters offer an excellent blend of original equipment fit and significantly enhanced quality, resulting in a cleaner and healthier passenger cabin air quality. With pollutants and allergens inside a vehicle potentially reaching up to six times higher concentration than outside, Bosch Premium Cabin Filters act as a reliable barrier, preventing harmful spores and pollen from entering the vehicle interior. By doing so, they not only safeguard passengers but also protect downstream HVAC components, ensuring a more pleasant and safe driving experience",
    detail: "Anti-Allergy Layer that effectively renders allergens harmless and eliminates bacteria, ensuring a healthier environment for all passengers. The inclusion of Activated Carbon enhances the filter's capability to absorb harmful gases and unpleasant smells. Ultra-fine microfiber filters fine dust particles (up to 2.5µm), soot and pollen. The filter's Carrier Fleece stabilizes the layers, ensuring optimum performance and durability. Additionally, the reduction of deposits protects the air conditioning system, ultimately leading to fresher air and a more comfortable journey for everyone on board",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Cabin Filters"],
  },
  {
    sku: "AG0176",
    slug: "r5578-bosch-aeristo-cabin-filter",
    thumbnail: "/file/listings/bosch-placeholder.png",
    name: "R5578 - BOSCH AERISTO CABIN FILTER",
    description: "Anti-allergic & anti-bacterial protection for clean air inside your car",
    information: "Bosch Premium Cabin Air Filters offer an excellent blend of original equipment fit and significantly enhanced quality, resulting in a cleaner and healthier passenger cabin air quality. With pollutants and allergens inside a vehicle potentially reaching up to six times higher concentration than outside, Bosch Premium Cabin Filters act as a reliable barrier, preventing harmful spores and pollen from entering the vehicle interior. By doing so, they not only safeguard passengers but also protect downstream HVAC components, ensuring a more pleasant and safe driving experience",
    detail: "Anti-Allergy Layer that effectively renders allergens harmless and eliminates bacteria, ensuring a healthier environment for all passengers. The inclusion of Activated Carbon enhances the filter's capability to absorb harmful gases and unpleasant smells. Ultra-fine microfiber filters fine dust particles (up to 2.5µm), soot and pollen. The filter's Carrier Fleece stabilizes the layers, ensuring optimum performance and durability. Additionally, the reduction of deposits protects the air conditioning system, ultimately leading to fresher air and a more comfortable journey for everyone on board",
    uom: "Each",
    principals: ["Bosch"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Cabin Filters"],
  },
  {
    sku: "AG0589",
    slug: "palma-battery-42b20l",
    thumbnail: "/file/listings/palma-placeholder.png",
    name: "PALMA BATTERY 42B20L",
    description: "Automative Battery",
    information: "The PALMA BATTERY 42B20L is a reliable and high-performance automotive battery designed to meet the demanding requirements of modern vehicles. With its advanced technology, this battery offers extended service life and consistent power delivery. The 42B20L model features high-density active material and optimized particle size, ensuring durability and long-lasting performance. It is specifically designed to support start-stop systems, making it ideal for vehicles seeking improved fuel efficiency. The battery's eco-friendly features include an optimized negative grid alloy, which prevents corrosion and extends battery life, even during high-depth-of-discharge cycling. With its compact and efficient design, the PALMA 42B20L is a perfect fit for various vehicle makes and models, providing reliable starting power and enduring performance on the road",
    detail: "AH: 35, CCA: 335",
    uom: "Each",
    principals: ["Palma"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Batteries"],
  },
  {
    sku: "AG0590",
    slug: "palma-battery-65b24l",
    thumbnail: "/file/listings/palma-placeholder.png",
    name: "PALMA BATTERY 65B24L",
    description: "Automative Battery",
    information: "The PALMA BATTERY 65B24L is a top-notch automotive battery engineered for exceptional performance and reliability. With its extended service life, this battery is built to endure and provide consistent power supply. Its advanced technology includes high-density active material and optimized particle size, ensuring longevity and superior performance. The 65B24L model is designed to support start-stop systems, making it ideal for modern vehicles seeking enhanced fuel efficiency. Its eco-friendly features include an optimized negative grid alloy, which prevents corrosion and extends battery life, especially during high-depth-of-discharge cycling. This battery's extra-high performance is achieved through a large expanded grid that reduces resistance and increases conductivity. For eco-conscious drivers looking for a powerful and long-lasting automotive battery, the PALMA 65B24L is an excellent choice",
    detail: "AH: 50, CCA: 470",
    uom: "Each",
    principals: ["Palma"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Batteries"],
  },
  {
    sku: "AG0591",
    slug: "palma-battery-65b24ls",
    thumbnail: "/file/listings/palma-placeholder.png",
    name: "PALMA BATTERY 65B24LS",
    description: "Replacement Battery",
    information: "The Palma PM65-12 12V 65Ah replacement battery is a high quality rechargeable sealed lead acid battery that is designed to provide excellent performance, durability and long life. This battery meets or exceeds original manufacturer specifications",
    detail: "Nominal Voltage: 12V, Nominal Capacity: 65Ah",
    uom: "Each",
    principals: ["Palma"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Batteries"],
  },
  {
    sku: "AG0592",
    slug: "palma-battery-80d23l",
    thumbnail: "/file/listings/palma-placeholder.png",
    name: "PALMA BATTERY 80D23L",
    description: "Automative Battery",
    information: "The PALMA BATTERY 80D23L is a high-quality automotive battery designed to deliver reliable and long-lasting performance. Its advanced technology ensures extended service life, providing exceptional value for your vehicle's power needs. With a focus on eco-friendliness, the battery features optimized negative grid alloy, which retards corrosion and prolongs battery life during high-depth-of-discharge cycling. The 80D23L model offers extra-high performance, thanks to its large expanded grid that reduces resistance and increases conductivity, ensuring consistent and reliable power output. Additionally, it is designed to support start-stop systems, contributing to reduced fuel consumption and a greener driving experience. The PALMA 80D23L is the perfect choice for modern vehicles seeking a reliable and eco-conscious battery solution",
    detail: "Capacity: 12V 68Ah, Reserve Capacity: 120min, CCA: 580A",
    uom: "Each",
    principals: ["Palma"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Batteries"],
  },
  {
    sku: "AG0593",
    slug: "palma-battery-90d26l",
    thumbnail: "/file/listings/palma-placeholder.png",
    name: "PALMA BATTERY 90D26L",
    description: "Automative Battery",
    information: "The PALMA BATTERY 90D26L is a top-quality automotive battery designed to provide reliable performance and durability. With its advanced technology, this battery offers extended service life, making it a long-lasting solution for your vehicle's power needs. It features high-density active material and optimized particle size to ensure superior performance under various conditions. The battery's maintenance-free design and eco-friendly construction contribute to its convenience and environmental friendliness. The PALMA 90D26L is ideal for start-stop vehicles and offers enhanced charging acceptance, providing consistent and reliable starting power. Its extra-high performance, along with the reliable starting capabilities, make it an excellent choice for modern vehicles seeking optimal power and efficiency",
    detail: "AH: 75, CCA: 620",
    uom: "Each",
    principals: ["Palma"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Batteries"],
  },
  {
    sku: "AG0594",
    slug: "palma-battery-105d31r",
    thumbnail: "/file/listings/palma-placeholder.png",
    name: "PALMA BATTERY 105D31R",
    description: "Automative Battery",
    information: "The PALMA BATTERY 105D31R is a high-quality automotive battery designed to deliver reliable performance and extended service life. It is built with advanced technology, featuring high-density active material and optimized particle size to ensure prolonged durability. The battery is suitable for various vehicle types and applications, providing consistent starting power even under extreme conditions. Its maintenance-free design and eco-friendly construction make it a practical and environmentally conscious choice. With enhanced charging acceptance and extra-high performance, the PALMA 105D31R battery is a dependable solution for modern vehicles, offering the power and reliability needed to keep your vehicle running smoothly",
    detail: "AH: 80, CCA: 670",
    uom: "Each",
    principals: ["Palma"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Batteries"],
  },
  {
    sku: "AG0595",
    slug: "palma-battery-efb-n55l",
    thumbnail: "/file/listings/palma-placeholder.png",
    name: "PALMA BATTERY EFB N55L",
    description: "Enhanced Flooded Battery",
    information: "EFB batteries, equipped with advanced technology, are specifically designed for start-stop vehicles that frequently start and stop the engine during a single trip. These batteries exhibit double the endurance compared to conventional ones, ensuring reliable and consistent high performance to meet higher demands. They are particularly suitable for start-stop vehicles lacking a regenerative braking system. The optimized EFB (Enhanced Flooded Battery) technology ensures exceptional levels of reliability, prolonged battery life, and consistent performance throughout its usage",
    detail: "Capacity: 12V 55Ah, Reserve Capacity: 92min, CCA: 500A",
    uom: "Each",
    principals: ["Palma"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Batteries"],
  },
  {
    sku: "AG0596",
    slug: "palma-battery-efb-q85l",
    thumbnail: "/file/listings/palma-placeholder.png",
    name: "PALMA BATTERY EFB Q85L",
    description: "Enhanced Flooded Battery",
    information: "EFB batteries, equipped with advanced technology, are specifically designed for start-stop vehicles that frequently start and stop the engine during a single trip. These batteries exhibit double the endurance compared to conventional ones, ensuring reliable and consistent high performance to meet higher demands. They are particularly suitable for start-stop vehicles lacking a regenerative braking system. The optimized EFB (Enhanced Flooded Battery) technology ensures exceptional levels of reliability, prolonged battery life, and consistent performance throughout its usage",
    detail: "Capacity: 12V 65Ah, Reserve Capacity: 120min, CCA: 560A",
    uom: "Each",
    principals: ["Palma"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Batteries"],
  },
  {
    sku: "AG0597",
    slug: "palma-battery-efb-s95l",
    thumbnail: "/file/listings/palma-placeholder.png",
    name: "PALMA BATTERY EFB S95L",
    description: "Enhanced Flooded Battery",
    information: "EFB batteries, equipped with advanced technology, are specifically designed for start-stop vehicles that frequently start and stop the engine during a single trip. These batteries exhibit double the endurance compared to conventional ones, ensuring reliable and consistent high performance to meet higher demands. They are particularly suitable for start-stop vehicles lacking a regenerative braking system. The optimized EFB (Enhanced Flooded Battery) technology ensures exceptional levels of reliability, prolonged battery life, and consistent performance throughout its usage",
    detail: "The extended service life of these batteries is achieved through high-density active material and optimized particle size. Special additives are employed to reduce active material shrinkage, increasing charging acceptance and enhancing the start/stop capability. With an extra-high performance, the batteries feature a large expanded grid that reduces resistance and boosts conductivity, ensuring consistent high performance. They are maintenance-free, eco-friendly, and utilize an optimized negative grid alloy, which retards corrosion and prolongs battery life during high-depth-of-discharge cycling. The innovative technology offers twice the deep-cycle resistance of conventional batteries, ensuring reliable starting even in extreme conditions. Additionally, these batteries demonstrate higher thermal stability for use in the engine compartment and hot climate regions, making them environmentally friendly and supportive of start-stop systems designed to reduce fuel consumption",
    uom: "Each",
    principals: ["Palma"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Batteries"],
  },
  {
    sku: "AG0598",
    slug: "palma-battery-efb70-ln3",
    thumbnail: "/file/listings/palma-placeholder.png",
    name: "PALMA BATTERY EFB70 LN3",
    description: "Enhanced Flooded Battery",
    information: "EFB batteries, equipped with advanced technology, are specifically designed for start-stop vehicles that frequently start and stop the engine during a single trip. These batteries exhibit double the endurance compared to conventional ones, ensuring reliable and consistent high performance to meet higher demands. They are particularly suitable for start-stop vehicles lacking a regenerative braking system. The optimized EFB (Enhanced Flooded Battery) technology ensures exceptional levels of reliability, prolonged battery life, and consistent performance throughout its usage",
    detail: "AH(20HR): 70, CCA: 720, RC: 120, Voltage: 12V",
    uom: "Each",
    principals: ["Palma"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Batteries"],
  },
  {
    sku: "AG0599",
    slug: "palma-battery-agm70-ln3",
    thumbnail: "/file/listings/palma-placeholder.png",
    name: "PALMA BATTERY AGM70 LN3",
    description: "Absorbent Glass Mat Type",
    information: "The Palma Battery AGM70 LN3 is a reliable and powerful AGM battery designed for a wide range of applications, including automotive, marine, and solar systems. With its advanced AGM technology, it provides exceptional performance and durability. The battery's spill-proof design and low self-discharge rate ensure safe and maintenance-free operation. Whether used in vehicles or renewable energy setups, the AGM70 LN3 offers dependable power and a longer service life",
    detail: "Capacity: 12V 70Ah, Reserve Capacity: 120min, CCA: 760A",
    uom: "Each",
    principals: ["Palma"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Batteries"],
  },
  {
    sku: "AG0600",
    slug: "palma-battery-agm80-ln4",
    thumbnail: "/file/listings/palma-placeholder.png",
    name: "PALMA BATTERY AGM80 LN4",
    description: "Absorbent Glass Mat Type",
    information: "The Palma Battery AGM80 LN4 is a high-performance AGM battery designed for automotive, marine, and solar applications. With advanced AGM technology, it offers excellent power, durability, and a longer service life. Its spill-proof design and low self-discharge rate make it a reliable and maintenance-free choice for various uses",
    detail: "Capacity: 12V 80Ah, Reserve Capacity: 140min, CCA: 800A",
    uom: "Each",
    principals: ["Palma"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Batteries"],
  },
  {
    sku: "AG0601",
    slug: "palma-battery-n150",
    thumbnail: "/file/listings/palma-placeholder.png",
    name: "PALMA BATTERY N150",
    description: "Heavy Duty Truck Batteries",
    information: "Palma Battery N150 is a high-quality automotive battery designed to meet the power demands of heavy-duty vehicles. With its robust construction and advanced technology, this battery provides reliable starting power and long-lasting performance. The battery features new plastic PP containers that offer exceptional strength and heat resistance, ensuring durability in various conditions. The plates are made from electrolytic purity lead, with a purity range of 99.994% to 99.996%, along with alloy compositions that enhance battery efficiency and longevity. American standard PE separators and high-quality chemistry materials contribute to the battery's superior performance. The Palma Battery N150 is engineered with precision and expertise to meet industry standards, making it a dependable choice for commercial and industrial applications",
    detail: "Norminal Voltage: 12V, Ultrahigh Discharge Rate",
    uom: "Each",
    principals: ["Palma"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Batteries"],
  },
  {
    sku: "AG0602",
    slug: "palma-battery-56219-din62",
    thumbnail: "/file/listings/palma-placeholder.png",
    name: "PALMA BATTERY 56219 DIN62",
    description: "Automative Car Battery",
    information: "The main materials used in our products are carefully selected to ensure high quality and reliable performance. For battery containers, we use new plastic PP, which offers superior strength and excellent heat resistance properties. Our plates are made of electrolytic purity lead with a range of 99.994% to 99.996% purity, along with low antimony and high calcium alloys to enhance battery efficiency. We incorporate American standard PE separators and use high-quality chemistry materials with precise administration to optimize battery function. Our products are built with advanced technology and scientific design, ensuring they meet stringent quality standards and deliver exceptional performance for various applications",
    detail: "L: 245* W: 175* H: 190* Th: 190(Mm)",
    uom: "Each",
    principals: ["Palma"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Batteries"],
  },
  {
    sku: "AG0603",
    slug: "palma-battery-57412-din74",
    thumbnail: "/file/listings/palma-placeholder.png",
    name: "PALMA BATTERY 57412 DIN74",
    description: "Automative Car Battery",
    information: "The Palma Battery 57412 DIN74 is a high-quality and reliable automotive battery designed to meet the demands of modern vehicles. With its DIN74 specification, this battery is suitable for a wide range of cars, trucks, and other vehicles. It offers excellent performance and durability, making it ideal for various applications. The Palma Battery 57412 DIN74 is built with advanced technology and premium materials to ensure reliable starting power and long service life. Whether you need a battery replacement or an upgrade, this product delivers outstanding performance and is certified to meet industry standards, providing peace of mind for your automotive power needs",
    detail: "Charging Voltage: 13.8V-14.2V , Rated Voltage: 12V",
    uom: "Each",
    principals: ["Palma"],
    brands: ["Alfa Romeo", "Aston Martin", "Audi", "Austin", "Bentley", "BMW", "BYD", "Chevrolet", "Chrysler", "Citroen", "Daihatsu", "Ferrari", "Fiat", "Ford", "Hino", "Honda", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "KIA", "Lamborghini", "Land Rover", "Lexus", "MAN", "Maserati", "Mazda", "Mercedes-Benz", "MG", "MINI", "Mitsubishi", "Nissan", "Opel", "Perodua", "Peugeot", "Porsche", "Proton", "Renault", "Rolls-Royce", "Rover", "Saab", "Scania", "SEAT", "Skoda", "Ssangyong", "Subaru", "Suzuki", "Tesla", "Toyota", "UD", "Volkswagen", "Volvo", "Yutong"],
    types: ["Batteries"],
  },
  {
    sku: "AG0296",
    slug: "ag0296-roof-panel-5-corrugation-2x1045x2356",
    thumbnail: "/file/listings/AG0296.png",
    name: "Roof panel 5 corrugation (5浪顶板) - 2x1045x2356",
    description: "Container Spare Part",
    information: null,
    detail: null,
    uom: "Each",
    principals: [],
    brands: [],
    types: [
      "Container Spare Part"
    ]
  },
  {
    sku: "AG0401",
    slug: "ag0401-roof-panel-4-corrugation-2x1045x2356",
    thumbnail: "/file/listings/AG0401.png",
    name: "Roof panel 4 corrugation (4浪顶板) - 2x1045x2356",
    description: "Container Spare Part",
    information: null,
    detail: null,
    uom: "Each",
    principals: [],
    brands: [],
    types: [
      "Container Spare Part"
    ]
  },
  {
    sku: "AG0295",
    slug: "ag0295-roof-panel-3-corrugation-2x1045x2356",
    thumbnail: "/file/listings/AG0295.png",
    name: "Roof panel 3 corrugation (3浪顶板) - 2x1045x2356",
    description: "Container Spare Part",
    information: null,
    detail: null,
    uom: "Each",
    principals: [],
    brands: [],
    types: [
      "Container Spare Part"
    ]
  },
  {
    sku: "AG0294",
    slug: "ag0294-side-panel-hc-16x1116x2700",
    thumbnail: "/file/listings/AG0294.png",
    name: "Side Panel HC (侧板 (高）) - 1.6x1116x2700",
    description: "Container Spare Part",
    information: null,
    detail: null,
    uom: "Each",
    principals: [],
    brands: [],
    types: [
      "Container Spare Part"
    ]
  },
  {
    sku: "AG0106",
    slug: "ag0106-front-end-panel-hc-2x1024x2700",
    thumbnail: "/file/listings/AG0106.png",
    name: "Front End Panel HC (前端板 (高）) - 2x1024x2700",
    description: "Container Spare Part",
    information: null,
    detail: null,
    uom: "Each",
    principals: [],
    brands: [],
    types: [
      "Container Spare Part"
    ]
  },
  {
    sku: "AG0325",
    slug: "ag0325-front-corner-post-gp-6x229x245x2400",
    thumbnail: "/file/listings/AG0325.png",
    name: "Front Corner Post GP (前角柱) - 6x229x245x2400",
    description: "Container Spare Part",
    information: null,
    detail: null,
    uom: "Each",
    principals: [],
    brands: [],
    types: [
      "Container Spare Part"
    ]
  },
  {
    sku: "AG0326",
    slug: "ag0326-front-corner-post-hc-6x229x245x2700",
    thumbnail: "/file/listings/AG0326.png",
    name: "Front Corner Post HC (前角柱) - 6x229x245x2700",
    description: "Container Spare Part",
    information: null,
    detail: null,
    uom: "Each",
    principals: [],
    brands: [],
    types: [
      "Container Spare Part"
    ]
  },
  {
    sku: "AG0382",
    slug: "ag0382-rear-corner-post-outer-gp-6x40x70x230x2400",
    thumbnail: "/file/listings/AG0382.png",
    name: "Rear Corner Post Outer GP (后外角柱) - 6x40x70x230x2400",
    description: "Container Spare Part",
    information: null,
    detail: null,
    uom: "Each",
    principals: [],
    brands: [],
    types: [
      "Container Spare Part"
    ]
  },
  {
    sku: "AG0383",
    slug: "ag0383-rear-corner-post-outer-hc-6x40x70x230x2700",
    thumbnail: "/file/listings/AG0383.png",
    name: "Rear Corner Post Outer HC (后外角柱（高）) - 6x40x70x230x2700",
    description: "Container Spare Part",
    information: null,
    detail: null,
    uom: "Each",
    principals: [],
    brands: [],
    types: [
      "Container Spare Part"
    ]
  },
  {
    sku: "AG0381",
    slug: "ag0381-rear-corner-post-inner-gp-12x40x113x2353",
    thumbnail: "/file/listings/AG0381.png",
    name: "Rear Corner Post Inner GP (后内角柱) - 12x40x113x2353",
    description: "Container Spare Part",
    information: null,
    detail: null,
    uom: "Each",
    principals: [],
    brands: [],
    types: [
      "Container Spare Part"
    ]
  },
  {
    sku: "AG0562",
    slug: "ag0562-rear-corner-post-inner-hc-12x40x113x2657",
    thumbnail: "/file/listings/AG0562.png",
    name: "Rear Corner Post Inner HC (后内角柱（高）) - 12x40x113x2657",
    description: "Container Spare Part",
    information: null,
    detail: null,
    uom: "Each",
    principals: [],
    brands: [],
    types: [
      "Container Spare Part"
    ]
  },
  {
    sku: "AG0111",
    slug: "ag0111-cross-member-4x45x45x122x2350",
    thumbnail: "/file/listings/AG0111.png",
    name: "Cross Member (底横梁) - 4x45x45x122x2350 ",
    description: "Container Spare Part",
    information: null,
    detail: null,
    uom: "Each",
    principals: [],
    brands: [],
    types: [
      "Container Spare Part"
    ]
  },
  {
    sku: "AG0112",
    slug: "ag0112-cross-member-4x45x75x122x2350",
    thumbnail: "/file/listings/AG0112.png",
    name: "Cross Member (底横梁) - 4x45x75x122x2350 ",
    description: "Container Spare Part",
    information: null,
    detail: null,
    uom: "Each",
    principals: [],
    brands: [],
    types: [
      "Container Spare Part"
    ]
  },
  {
    sku: "AG0533",
    slug: "ag0533-bottom-side-rail-c-type-45x50x162x5702",
    thumbnail: "/file/listings/AG0533.png",
    name: "Bottom Side Rail (C) type (底侧梁（C）) - 4.5x50x162x5702",
    description: "Container Spare Part",
    information: null,
    detail: null,
    uom: "Each",
    principals: [],
    brands: [],
    types: [
      "Container Spare Part"
    ]
  },
  {
    sku: "AG0507",
    slug: "ag0507-bottom-side-rail-dz-type-45x30x80x155x5702",
    thumbnail: "/file/listings/AG0507.png",
    name: "Bottom Side Rail (DZ) Type (底侧梁（DZ）) - 4.5x30x80x155x5702 ",
    description: "Container Spare Part",
    information: null,
    detail: null,
    uom: "Each",
    principals: [],
    brands: [],
    types: [
      "Container Spare Part"
    ]
  },
  {
    sku: "AG0107",
    slug: "ag0107-top-side-rail-3x60x60x5702",
    thumbnail: "/file/listings/AG0107.png",
    name: "Top Side Rail (底侧梁方管) - 3x60x60x5702",
    description: "Container Spare Part",
    information: null,
    detail: null,
    uom: "Each",
    principals: [],
    brands: [],
    types: [
      "Container Spare Part"
    ]
  },
  {
    sku: "AG0394",
    slug: "ag0394-door-sill-45x150x158x2400",
    thumbnail: "/file/listings/AG0394.png",
    name: "Door Sill (后底梁) - 4.5x150x158x2400",
    description: "Container Spare Part",
    information: null,
    detail: null,
    uom: "Each",
    principals: [],
    brands: [],
    types: [
      "Container Spare Part"
    ]
  },
  {
    sku: "AG0398",
    slug: "ag0398-extension-plate-3x397x2400",
    thumbnail: "/file/listings/AG0398.png",
    name: "Extension Plate (延伸板) - 3x397x2400",
    description: "Container Spare Part",
    information: null,
    detail: null,
    uom: "Each",
    principals: [],
    brands: [],
    types: [
      "Container Spare Part"
    ]
  },
  {
    sku: "AG0329",
    slug: "ag0329-door-header-upper-30x93x26x275x2400",
    thumbnail: "/file/listings/AG0329.png",
    name: "Door Header Upper (后顶横梁) - 3.0x93x26x275x2400",
    description: "Container Spare Part",
    information: null,
    detail: null,
    uom: "Each",
    principals: [],
    brands: [],
    types: [
      "Container Spare Part"
    ]
  },
  {
    sku: "AG0330",
    slug: "ag0330-door-header-lower-40x112x113x137x2400",
    thumbnail: "/file/listings/AG0330.png",
    name: "Door Header Lower (后顶横梁) - 4.0x112x113x137x2400",
    description: "Container Spare Part",
    information: null,
    detail: null,
    uom: "Each",
    principals: [],
    brands: [],
    types: [
      "Container Spare Part"
    ]
  },
  {
    sku: "AG0585",
    slug: "ag0585-roof-panel-3-corr-patch-2x1045x600mm",
    thumbnail: "/file/listings/AG0585.png",
    name: "ROOF PANEL 3 Corr - PATCH (3 浪顶板 (Patch)) - 2x1045x600mm",
    description: "Container Spare Part",
    information: null,
    detail: null,
    uom: "Each",
    principals: [],
    brands: [],
    types: [
      "Container Spare Part"
    ]
  },
  {
    sku: "AG0583",
    slug: "ag0583-roof-panel-4-corr-patch-2x1045x600mm",
    thumbnail: "/file/listings/AG0583.png",
    name: "ROOF PANEL 4 Corr - PATCH (4 浪顶板 (Patch)) - 2x1045x600mm",
    description: "Container Spare Part",
    information: null,
    detail: null,
    uom: "Each",
    principals: [],
    brands: [],
    types: [
      "Container Spare Part"
    ]
  },
  {
    sku: "AG0402",
    slug: "ag0402-roof-panel-5-corr-patch-2x1045x600mm",
    thumbnail: "/file/listings/AG0402.png",
    name: "ROOF PANEL 5 Corr - PATCH (5 浪顶板 (Patch)) - 2x1045x600mm",
    description: "Container Spare Part",
    information: null,
    detail: null,
    uom: "Each",
    principals: [],
    brands: [],
    types: [
      "Container Spare Part"
    ]
  },
  {
    sku: "AG0377",
    slug: "ag0377-c-sill-cone-c-channel-90x200x75",
    thumbnail: "/file/listings/AG0377.png",
    name: "C SILL CONE C-CHANNEL (防撞槽钢（C）) - 9.0x200x75",
    description: "Container Spare Part",
    information: null,
    detail: null,
    uom: "Each",
    principals: [],
    brands: [],
    types: [
      "Container Spare Part"
    ]
  },
  {
    sku: "AG0637",
    slug: "ag0637-front-bottom-rail-4x115x154x2400",
    thumbnail: "/file/listings/AG0637.png",
    name: "FRONT BOTTOM RAIL (前底梁) - 4x115x154x2400",
    description: "Container Spare Part",
    information: null,
    detail: null,
    uom: "Each",
    principals: [],
    brands: [],
    types: [
      "Container Spare Part"
    ]
  },
  {
    sku: "AG0642",
    slug: "ag0642-tunnel-rail-40x90x55x40x2400",
    thumbnail: "/file/listings/AG0642.png",
    name: "TUNNEL RAIL (鹅颈槽直梁) - 4.0x90x55x40x2400",
    description: "Container Spare Part",
    information: null,
    detail: null,
    uom: "Each",
    principals: [],
    brands: [],
    types: [
      "Container Spare Part"
    ]
  },
  {
    sku: "AG0643",
    slug: "ag0643-corten-plate-20x1219x2743",
    thumbnail: "/file/listings/AG0643.png",
    name: "CORTEN PLATE (平板) - 2.0x1219x2743",
    description: "Container Spare Part",
    information: null,
    detail: null,
    uom: "Each",
    principals: [],
    brands: [],
    types: [
      "Container Spare Part"
    ]
  },
  {
    sku: "AG0644",
    slug: "ag0644-corten-plate-40x1120x2350",
    thumbnail: "/file/listings/AG0644.png",
    name: "CORTEN PLATE (平板) - 4.0x1120x2350",
    description: "Container Spare Part",
    information: null,
    detail: null,
    uom: "Each",
    principals: [],
    brands: [],
    types: [
      "Container Spare Part"
    ]
  },
  {
    sku: "AG0328",
    slug: "ag0328-angle-bar-4x45x115x2350",
    thumbnail: "/file/listings/AG0328.png",
    name: "ANGLE BAR (角铁) - 4x45x115x2350",
    description: "Container Spare Part",
    information: null,
    detail: null,
    uom: "Each",
    principals: [],
    brands: [],
    types: [
      "Container Spare Part"
    ]
  },
  {
    sku: "AG0327",
    slug: "ag0327-angle-bar-4x30x50x2350",
    thumbnail: "/file/listings/AG0327.png",
    name: "ANGLE BAR (角铁) - 4x30x50x2350",
    description: "Container Spare Part",
    information: null,
    detail: null,
    uom: "Each",
    principals: [],
    brands: [],
    types: [
      "Container Spare Part"
    ]
  },
  {
    sku: "AG0568",
    slug: "ag0568-angle-bar-6x57x30x2420",
    thumbnail: "/file/listings/AG0568.png",
    name: "ANGLE BAR (角铁) - 6x57x30x2420",
    description: "Container Spare Part",
    information: null,
    detail: null,
    uom: "Each",
    principals: [],
    brands: [],
    types: [
      "Container Spare Part"
    ]
  },
  {
    sku: "AG0639",
    slug: "ag0639-angle-bar-4x75x45x2350",
    thumbnail: "/file/listings/AG0639.png",
    name: "ANGLE BAR (角铁) - 4x75x45x2350",
    description: "Container Spare Part",
    information: null,
    detail: null,
    uom: "Each",
    principals: [],
    brands: [],
    types: [
      "Container Spare Part"
    ]
  },
  {
    sku: "AG0640",
    slug: "ag0640-angle-bar-4x100x75x2350",
    thumbnail: "/file/listings/AG0640.png",
    name: "ANGLE BAR (角铁) - 4x100x75x2350",
    description: "Container Spare Part",
    information: null,
    detail: null,
    uom: "Each",
    principals: [],
    brands: [],
    types: [
      "Container Spare Part"
    ]
  },
  {
    sku: "AG0641",
    slug: "ag0641-angle-bar-6x102x102x2350",
    thumbnail: "/file/listings/AG0641.png",
    name: "ANGLE BAR (角铁) - 6x102x102x2350",
    description: "Container Spare Part",
    information: null,
    detail: null,
    uom: "Each",
    principals: [],
    brands: [],
    types: [
      "Container Spare Part"
    ]
  },
  {
    sku: "AG0636",
    slug: "ag0636-front-bottom-rail-4x115x154x2400",
    thumbnail: "/file/listings/AG0636.png",
    name: "FRONT BOTTOM RAIL (前底梁) - 4x115x154x2400",
    description: "Container Spare Part",
    information: null,
    detail: null,
    uom: "Each",
    principals: [],
    brands: [],
    types: [
      "Container Spare Part"
    ]
  },
  {
    sku: "AG0582",
    slug: "ag0582-door-edge-member-dvert-rail-32x100x50x2560mm",
    thumbnail: "/file/listings/AG0582.png",
    name: "DOOR EDGE MEMBER D/VERT RAIL - 3.2x100x50x2560mm",
    description: "Container Spare Part",
    information: null,
    detail: null,
    uom: "Each",
    principals: [],
    brands: [],
    types: [
      "Container Spare Part"
    ]
  },
  {
    sku: "AG0650",
    slug: "ag0650-door-side-post-dsh-door-vertical-rail",
    thumbnail: "/file/listings/AG0650.png",
    name: "DOOR SIDE POST (DSH) Door Vertical Rail",
    description: "Container Spare Part",
    information: null,
    detail: null,
    uom: "Each",
    principals: [],
    brands: [],
    types: [
      "Container Spare Part"
    ]
  },
  {
    sku: "AG0656",
    slug: "ag0656-ss-checkered-plates-4x8ft-4x1222x2436",
    thumbnail: "/file/listings/AG0656.png",
    name: "SS CHECKERED PLATES (4x8)FT - 4x1222x2436",
    description: "Container Spare Part",
    information: null,
    detail: null,
    uom: "Each",
    principals: [],
    brands: [],
    types: [
      "Container Spare Part"
    ]
  },
  {
    sku: "AG0427",
    slug: "ag0427-flat-panel-em-2440x1363x45mm",
    thumbnail: "/file/listings/AG0427.png",
    name: "FLAT PANEL - EM - 2440x1363x4.5mm",
    description: "Container Spare Part",
    information: null,
    detail: null,
    uom: "Each",
    principals: [],
    brands: [],
    types: [
      "Container Spare Part"
    ]
  },
  {
    sku: "AG0543",
    slug: "ag0543-plywood-flooring-1160x2400x3mm",
    thumbnail: "/file/listings/AG0543.png",
    name: "PLYWOOD FLOORING - 1160x2400x3mm",
    description: "Container Spare Part",
    information: null,
    detail: null,
    uom: "Each",
    principals: [],
    brands: [],
    types: [
      "Container Spare Part"
    ]
  },
  {
    sku: "AG0126",
    slug: "ag0126-corner-casting-top-left-tl-178x162x118",
    thumbnail: "/file/listings/AG0126.png",
    name: "Corner Casting (Top Left -TL) (角件 左上) - 178x162x118 ",
    description: "Container Spare Part",
    information: null,
    detail: null,
    uom: "Each",
    principals: [],
    brands: [],
    types: [
      "Container Spare Part"
    ]
  },
  {
    sku: "AG0127",
    slug: "ag0127-corner-casting-top-right-tr-178x162x118",
    thumbnail: "/file/listings/AG0127.png",
    name: "Corner Casting (Top Right - TR) (角件 右上) - 178x162x118 ",
    description: "Container Spare Part",
    information: null,
    detail: null,
    uom: "Each",
    principals: [],
    brands: [],
    types: [
      "Container Spare Part"
    ]
  },
  {
    sku: "AG0125",
    slug: "ag0125-corner-casting-bottom-left-bl-178x162x118",
    thumbnail: "/file/listings/AG0125.png",
    name: "Corner Casting (Bottom Left - BL) (角件 左下) - 178x162x118 ",
    description: "Container Spare Part",
    information: null,
    detail: null,
    uom: "Each",
    principals: [],
    brands: [],
    types: [
      "Container Spare Part"
    ]
  },
  {
    sku: "AG0124",
    slug: "ag0124-corner-casting-bottom-right-br-178x162x118",
    thumbnail: "/file/listings/AG0124.png",
    name: "Corner Casting (Bottom Right - BR) (角件 右下) - 178x162x118 ",
    description: "Container Spare Part",
    information: null,
    detail: null,
    uom: "Each",
    principals: [],
    brands: [],
    types: [
      "Container Spare Part"
    ]
  },
  {
    sku: "AG0397",
    slug: "ag0397-door-seal-retainer-strip-1x23x7x2249",
    thumbnail: "/file/listings/AG0397.png",
    name: "Door seal retainer strip (不锈钢门压条) - 1x23x7x2249 ",
    description: "Container Spare Part",
    information: null,
    detail: null,
    uom: "Each",
    principals: [],
    brands: [],
    types: [
      "Container Spare Part"
    ]
  },
  {
    sku: "AG0304",
    slug: "ag0304-door-handle-forged",
    thumbnail: "/file/listings/AG0304.png",
    name: "Door Handle Forged (门把手（花式）)",
    description: "Container Spare Part",
    information: null,
    detail: null,
    uom: "Each",
    principals: [],
    brands: [],
    types: [
      "Container Spare Part"
    ]
  },
  {
    sku: "AG0305",
    slug: "ag0305-hinge-blade",
    thumbnail: "/file/listings/AG0305.png",
    name: "Hinge Blade (门铰链)",
    description: "Container Spare Part",
    information: null,
    detail: null,
    uom: "Each",
    principals: [],
    brands: [],
    types: [
      "Container Spare Part"
    ]
  },
  {
    sku: "AG0300",
    slug: "ag0300-door-rear-cam-lh-without-hole",
    thumbnail: "/file/listings/AG0300.png",
    name: "DOOR REAR CAM LH - WITHOUT HOLE (锁头)",
    description: "Container Spare Part",
    information: null,
    detail: null,
    uom: "Each",
    principals: [],
    brands: [],
    types: [
      "Container Spare Part"
    ]
  },
  {
    sku: "AG0302",
    slug: "ag0302-door-rear-cam-rh-without-hole",
    thumbnail: "/file/listings/AG0302.png",
    name: "DOOR REAR CAM RH - WITHOUT HOLE (锁头)",
    description: "Container Spare Part",
    information: null,
    detail: null,
    uom: "Each",
    principals: [],
    brands: [],
    types: [
      "Container Spare Part"
    ]
  },
  {
    sku: "AG0301",
    slug: "ag0301-door-rear-cam-lh-with-hole",
    thumbnail: "/file/listings/AG0301.png",
    name: "DOOR REAR CAM LH - WITH HOLE (安全锁头)",
    description: "Container Spare Part",
    information: null,
    detail: null,
    uom: "Each",
    principals: [],
    brands: [],
    types: [
      "Container Spare Part"
    ]
  },
  {
    sku: "AG0303",
    slug: "ag0303-door-rear-cam-rh-with-hole",
    thumbnail: "/file/listings/AG0303.png",
    name: "DOOR REAR CAM RH - WITH HOLE (安全锁头 )",
    description: "Container Spare Part",
    information: null,
    detail: null,
    uom: "Each",
    principals: [],
    brands: [],
    types: [
      "Container Spare Part"
    ]
  },
  {
    sku: "AG0299",
    slug: "ag0299-door-gasket-j-type",
    thumbnail: "/file/listings/AG0299.png",
    name: "Door Gasket J type (密封条)",
    description: "Container Spare Part",
    information: null,
    detail: null,
    uom: "Each",
    principals: [],
    brands: [],
    types: [
      "Container Spare Part"
    ]
  },
  {
    sku: "AG0110",
    slug: "ag0110-tapping-screw-m6",
    thumbnail: "/file/listings/AG0110.png",
    name: "Tapping Screw M6 (地板钉)",
    description: "Container Spare Part",
    information: null,
    detail: null,
    uom: "Each",
    principals: [],
    brands: [],
    types: [
      "Container Spare Part"
    ]
  },
  {
    sku: "AG0031",
    slug: "ag0031-tapping-screw-m8-pnp-d25-02",
    thumbnail: "/file/listings/AG0031.png",
    name: "Tapping Screw M8 (地板钉) - PNP D25-02 ",
    description: "Container Spare Part",
    information: null,
    detail: null,
    uom: "Each",
    principals: [],
    brands: [],
    types: [
      "Container Spare Part"
    ]
  },
  {
    sku: "AG0109",
    slug: "ag0109-ventilator-small-type-pnp-d23-01",
    thumbnail: "/file/listings/AG0109.png",
    name: "Ventilator Small Type (通风器（小）) - PNP D23-01",
    description: "Container Spare Part",
    information: null,
    detail: null,
    uom: "Each",
    principals: [],
    brands: [],
    types: [
      "Container Spare Part"
    ]
  },
  {
    sku: "AG0430",
    slug: "ag0430-cont-fb-28x1160x2440",
    thumbnail: "/file/listings/AG0430.png",
    name: "CONT F/B (木地板) - 28x1160x2440",
    description: "Container Spare Part",
    information: null,
    detail: null,
    uom: "Each",
    principals: [],
    brands: [],
    types: [
      "Container Spare Part"
    ]
  },
  {
    sku: "AG0556",
    slug: "ag0556-cont-fb-28x640x2440",
    thumbnail: "/file/listings/AG0556.png",
    name: "CONT F/B (木地板) - 28x640x2440",
    description: "Container Spare Part",
    information: null,
    detail: null,
    uom: "Each",
    principals: [],
    brands: [],
    types: [
      "Container Spare Part"
    ]
  },
  {
    sku: "AG0605",
    slug: "ag0605-door-handle-pressed",
    thumbnail: "/file/listings/AG0605.png",
    name: "Door Handle Pressed",
    description: "Container Spare Part",
    information: null,
    detail: null,
    uom: "Each",
    principals: [],
    brands: [],
    types: [
      "Container Spare Part"
    ]
  },
  {
    sku: "AG0608",
    slug: "ag0608-door-keeper-left-with-hole",
    thumbnail: "/file/listings/AG0608.png",
    name: "DOOR KEEPER LEFT (WITH HOLE) (安全锁座（左）)",
    description: "Container Spare Part",
    information: null,
    detail: null,
    uom: "Each",
    principals: [],
    brands: [],
    types: [
      "Container Spare Part"
    ]
  },
  {
    sku: "AG0609",
    slug: "ag0609-door-keeper-right-with-hole",
    thumbnail: "/file/listings/AG0609.png",
    name: "DOOR KEEPER RIGHT (WITH HOLE) (安全锁座（右）)",
    description: "Container Spare Part",
    information: null,
    detail: null,
    uom: "Each",
    principals: [],
    brands: [],
    types: [
      "Container Spare Part"
    ]
  },
  {
    sku: "AG0628",
    slug: "ag0628-door-keeper-left-without-hole",
    thumbnail: "/file/listings/AG0628.png",
    name: "DOOR KEEPER LEFT (WITHOUT HOLE)  (锁座（左）)",
    description: "Container Spare Part",
    information: null,
    detail: null,
    uom: "Each",
    principals: [],
    brands: [],
    types: [
      "Container Spare Part"
    ]
  },
  {
    sku: "AG0629",
    slug: "ag0629-door-keeper-rightwithout-hole",
    thumbnail: "/file/listings/AG0629.png",
    name: "DOOR KEEPER RIGHT(WITHOUT HOLE) (锁座（右）)",
    description: "Container Spare Part",
    information: null,
    detail: null,
    uom: "Each",
    principals: [],
    brands: [],
    types: [
      "Container Spare Part"
    ]
  },
  {
    sku: "AG0584",
    slug: "ag0584-flat-rack-twistlock",
    thumbnail: "/file/listings/AG0584.png",
    name: "FLAT RACK TWISTLOCK (折叠箱扭锁)",
    description: "Container Spare Part",
    information: null,
    detail: null,
    uom: "Each",
    principals: [],
    brands: [],
    types: [
      "Container Spare Part"
    ]
  },
  {
    sku: "AG0630",
    slug: "ag0630-insuflex-48x3mmx914",
    thumbnail: "/file/listings/AG0630.png",
    name: "INSUFLEX (单⾯胶带) - 48x3mmx9.14",
    description: "Container Spare Part",
    information: null,
    detail: null,
    uom: "Each",
    principals: [],
    brands: [],
    types: [
      "Container Spare Part"
    ]
  },
  {
    sku: "AG0372",
    slug: "ag0372-bearing-bracket-outer-l",
    thumbnail: "/file/listings/AG0372.png",
    name: "BEARING BRACKET OUTER (L) (⼤托架)",
    description: "Container Spare Part",
    information: null,
    detail: null,
    uom: "Each",
    principals: [],
    brands: [],
    types: [
      "Container Spare Part"
    ]
  },
  {
    sku: "AG0645",
    slug: "ag0645-20ot-steel-wire-rope",
    thumbnail: "/file/listings/AG0645.png",
    name: "20OT STEEL WIRE ROPE (钢丝绳)",
    description: "Container Spare Part",
    information: null,
    detail: null,
    uom: "Each",
    principals: [],
    brands: [],
    types: [
      "Container Spare Part"
    ]
  },
  {
    sku: "AG0635",
    slug: "ag0635-40ot-steel-wire-rope",
    thumbnail: "/file/listings/AG0635.png",
    name: "40OT STEEL WIRE ROPE (钢丝绳)",
    description: "Container Spare Part",
    information: null,
    detail: null,
    uom: "Each",
    principals: [],
    brands: [],
    types: [
      "Container Spare Part"
    ]
  },
  {
    sku: "AG0340",
    slug: "ag0340-co2-wire",
    thumbnail: "/file/listings/AG0340.png",
    name: "CO2 WIRE",
    description: "Container Spare Part",
    information: null,
    detail: null,
    uom: "Each",
    principals: [],
    brands: [],
    types: [
      "Container Spare Part"
    ]
  },
  {
    sku: "AG0614",
    slug: "ag0614-bush",
    thumbnail: "/file/listings/AG0614.png",
    name: "BUSH",
    description: "Container Spare Part",
    information: null,
    detail: null,
    uom: "Each",
    principals: [],
    brands: [],
    types: [
      "Container Spare Part"
    ]
  },
  {
    sku: "AG0338",
    slug: "ag0338-drill-bit-size-52mm",
    thumbnail: "/file/listings/AG0338.png",
    name: "DRILL BIT (SIZE: 5.2mm)",
    description: "Container Spare Part",
    information: null,
    detail: null,
    uom: "Each",
    principals: [],
    brands: [],
    types: [
      "Container Spare Part"
    ]
  },
  {
    sku: "AG0612",
    slug: "ag0612-hinge-pin",
    thumbnail: "/file/listings/AG0612.png",
    name: "HINGE PIN",
    description: "Container Spare Part",
    information: null,
    detail: null,
    uom: "Each",
    principals: [],
    brands: [],
    types: [
      "Container Spare Part"
    ]
  },
  {
    sku: "AG0611",
    slug: "ag0611-bearing-bracket-outer-small",
    thumbnail: "/file/listings/AG0611.png",
    name: "BEARING BRACKET OUTER (SMALL) (托架（小）)",
    description: "Container Spare Part",
    information: null,
    detail: null,
    uom: "Each",
    principals: [],
    brands: [],
    types: [
      "Container Spare Part"
    ]
  },
  {
    sku: "AG0638",
    slug: "ag0638-decal-0-9-4-black-k-cut",
    thumbnail: "/file/listings/AG0638.png",
    name: "DECAL 0-9 4\" BLACK K-CUT",
    description: "Container Spare Part",
    information: null,
    detail: null,
    uom: "Each",
    principals: [],
    brands: [],
    types: [
      "Container Spare Part"
    ]
  },
  {
    sku: "AG0387",
    slug: "ag0387-decal-9-0-4-white-k-cut",
    thumbnail: "/file/listings/AG0387.png",
    name: "DECAL 9-0 4\" WHITE K-CUT",
    description: "Container Spare Part",
    information: null,
    detail: null,
    uom: "Each",
    principals: [],
    brands: [],
    types: [
      "Container Spare Part"
    ]
  },
  {
    sku: "AG0389",
    slug: "ag0389-decal-a-z-4-black-k-cut",
    thumbnail: "/file/listings/AG0389.png",
    name: "DECAL A-Z 4\" BLACK K-CUT",
    description: "Container Spare Part",
    information: null,
    detail: null,
    uom: "Each",
    principals: [],
    brands: [],
    types: [
      "Container Spare Part"
    ]
  },
  {
    sku: "AG0390",
    slug: "ag0390-decal-a-z-4-white-k-cut",
    thumbnail: "/file/listings/AG0390.png",
    name: "DECAL A-Z 4\" WHITE K-CUT",
    description: "Container Spare Part",
    information: null,
    detail: null,
    uom: "Each",
    principals: [],
    brands: [],
    types: [
      "Container Spare Part"
    ]
  },
  {
    sku: "AG0337",
    slug: "ag0337-insert-bit-nd3-size-ph3",
    thumbnail: "/file/listings/AG0337.png",
    name: "INSERT BIT ND3 (SIZE: PH3)",
    description: "Container Spare Part",
    information: null,
    detail: null,
    uom: "Each",
    principals: [],
    brands: [],
    types: [
      "Container Spare Part"
    ]
  },
  {
    sku: "AG0610",
    slug: "ag0610-door-retainer-plate-press-type",
    thumbnail: "/file/listings/AG0610.png",
    name: "DOOR RETAINER PLATE PRESS TYPE",
    description: "Container Spare Part",
    information: null,
    detail: null,
    uom: "Each",
    principals: [],
    brands: [],
    types: [
      "Container Spare Part"
    ]
  },
  {
    sku: "AG0341",
    slug: "ag0341-roller-refill",
    thumbnail: "/file/listings/AG0341.png",
    name: "ROLLER REFILL",
    description: "Container Spare Part",
    information: null,
    detail: null,
    uom: "Each",
    principals: [],
    brands: [],
    types: [
      "Container Spare Part"
    ]
  },
  {
    sku: "AG0646",
    slug: "ag0646-retainer-catch-for-forged-handle",
    thumbnail: "/file/listings/AG0646.png",
    name: "Retainer Catch For forged handle",
    description: "Container Spare Part",
    information: null,
    detail: null,
    uom: "Each",
    principals: [],
    brands: [],
    types: [
      "Container Spare Part"
    ]
  },
  {
    sku: "AG0648",
    slug: "ag0648-hinge-lug",
    thumbnail: "/file/listings/AG0648.png",
    name: "Hinge Lug",
    description: "Container Spare Part",
    information: null,
    detail: null,
    uom: "Each",
    principals: [],
    brands: [],
    types: [
      "Container Spare Part"
    ]
  },
  {
    sku: "AG0647",
    slug: "ag0647-locking-bar-2700",
    thumbnail: "/file/listings/AG0647.png",
    name: "locking bar (2700)",
    description: "Container Spare Part",
    information: null,
    detail: null,
    uom: "Each",
    principals: [],
    brands: [],
    types: [
      "Container Spare Part"
    ]
  },
  {
    sku: "AG0649",
    slug: "ag0649-csc-plate",
    thumbnail: "/file/listings/AG0649.png",
    name: "CSC Plate",
    description: "Container Spare Part",
    information: null,
    detail: null,
    uom: "Each",
    principals: [],
    brands: [],
    types: [
      "Container Spare Part"
    ]
  },
  // {
  //   sku: "AG0651",
  //   slug: "ag0651-tare-weight-decal-20gp",
  //   thumbnail: "/file/listings/AG0651.png",
  //   name: "Tare Weight Decal 20'GP",
  //   description: "Container Spare Part",
  //   information: null,
  //   detail: null,
  //   uom: "Each",
  //   principals: [],
  //   brands: [],
  //   types: [
  //     "Container Spare Part"
  //   ]
  // },
  // {
  //   sku: "AG0652",
  //   slug: "ag0652-tare-weight-decal-40hc",
  //   thumbnail: "/file/listings/AG0652.png",
  //   name: "Tare Weight Decal 40'HC",
  //   description: "Container Spare Part",
  //   information: null,
  //   detail: null,
  //   uom: "Each",
  //   principals: [],
  //   brands: [],
  //   types: [
  //     "Container Spare Part"
  //   ]
  // },
  {
    sku: "AG0563",
    slug: "ag0563-cutting-disc-4",
    thumbnail: "/file/listings/AG0563.png",
    name: "CUTTING DISC 4",
    description: "Container Spare Part",
    information: null,
    detail: null,
    uom: "Each",
    principals: [],
    brands: [],
    types: [
      "Container Spare Part"
    ]
  },
  {
    sku: "AG0657",
    slug: "ag0657-hansman-silicone-sealant-300gms-aluminium",
    thumbnail: "/file/listings/AG0657.png",
    name: "HANSMAN SILICONE SEALANT 300GMS ALUMINIUM",
    description: "Container Spare Part",
    information: null,
    detail: null,
    uom: "Each",
    principals: [],
    brands: [],
    types: [
      "Container Spare Part"
    ]
  },
  {
    sku: "AG0658",
    slug: "ag0658-hansman-silicone-sealant-300gms-black",
    thumbnail: "/file/listings/AG0658.png",
    name: "HANSMAN SILICONE SEALANT 300GMS BLACK",
    description: "Container Spare Part",
    information: null,
    detail: null,
    uom: "Each",
    principals: [],
    brands: [],
    types: [
      "Container Spare Part"
    ]
  },
  {
    sku: "AG0663",
    slug: "ag0663-csc-plate-08x210x270",
    thumbnail: "/file/listings/AG0663.png",
    name: "CSC Plate - 0.8x210x270",
    description: "Container Spare Part",
    information: null,
    detail: null,
    uom: "Each",
    principals: [],
    brands: [],
    types: [
      "Container Spare Part"
    ]
  },
  // {
  //   sku: "AG0664",
  //   slug: "ag0664-decal-40black-tare",
  //   thumbnail: "/file/listings/AG0664.png",
  //   name: "DECAL 40'/BLACK TARE",
  //   description: "Container Spare Part",
  //   information: null,
  //   detail: null,
  //   uom: "Each",
  //   principals: [],
  //   brands: [],
  //   types: [
  //     "Container Spare Part"
  //   ]
  // },
  // {
  //   sku: "AG0665",
  //   slug: "ag0665-decal-40white-tare",
  //   thumbnail: "/file/listings/AG0665.png",
  //   name: "DECAL 40'/WHITE TARE",
  //   description: "Container Spare Part",
  //   information: null,
  //   detail: null,
  //   uom: "Each",
  //   principals: [],
  //   brands: [],
  //   types: [
  //     "Container Spare Part"
  //   ]
  // },
  {
    sku: "AG0393",
    slug: "ag0393-door-handle-hub-rivet",
    thumbnail: "/file/listings/AG0393.png",
    name: "DOOR HANDLE HUB RIVET",
    description: "Container Spare Part",
    information: null,
    detail: null,
    uom: "Each",
    principals: [],
    brands: [],
    types: [
      "Container Spare Part"
    ]
  },
  {
    sku: "AG0666",
    slug: "ag0666-handle-hub",
    thumbnail: "/file/listings/AG0666.png",
    name: "HANDLE HUB",
    description: "Container Spare Part",
    information: null,
    detail: null,
    uom: "Each",
    principals: [],
    brands: [],
    types: [
      "Container Spare Part"
    ]
  },
  {
    sku: "AG0667",
    slug: "ag0667-lashing-ring",
    thumbnail: "/file/listings/AG0667.png",
    name: "LASHING RING",
    description: "Container Spare Part",
    information: null,
    detail: null,
    uom: "Each",
    principals: [],
    brands: [],
    types: [
      "Container Spare Part"
    ]
  },
  {
    sku: "AG0668",
    slug: "ag0668-lashing-hook",
    thumbnail: "/file/listings/AG0668.png",
    name: "LASHING HOOK",
    description: "Container Spare Part",
    information: null,
    detail: null,
    uom: "Each",
    principals: [],
    brands: [],
    types: [
      "Container Spare Part"
    ]
  },
  {
    sku: "AG0669",
    slug: "ag0669-anti-rack-ring",
    thumbnail: "/file/listings/AG0669.png",
    name: "ANTI RACK RING",
    description: "Container Spare Part",
    information: null,
    detail: null,
    uom: "Each",
    principals: [],
    brands: [],
    types: [
      "Container Spare Part"
    ]
  },
  {
    sku: "AG0700",
    slug: "ag0700-warning-decal-tape-50x350mm",
    thumbnail: "/file/listings/AG0700.png",
    name: "WARNING DECAL TAPE - 50x350mm",
    description: "Container Spare Part",
    information: null,
    detail: null,
    uom: "Each",
    principals: [],
    brands: [],
    types: [
      "Container Spare Part"
    ]
  },
  {
    sku: "AG0701",
    slug: "ag0701-slate-pen",
    thumbnail: "/file/listings/AG0701.png",
    name: "SLATE PEN",
    description: "Container Spare Part",
    information: null,
    detail: null,
    uom: "Each",
    principals: [],
    brands: [],
    types: [
      "Container Spare Part"
    ]
  },
  {
    sku: "AG0702",
    slug: "ag0702-kazoo-set",
    thumbnail: "/file/listings/AG0702.png",
    name: "KAZOO SET",
    description: "Container Spare Part",
    information: null,
    detail: null,
    uom: "Each",
    principals: [],
    brands: [],
    types: [
      "Container Spare Part"
    ]
  },
  {
    sku: "AG0703",
    slug: "ag0703-drainage-plug-l",
    thumbnail: "/file/listings/AG0703.png",
    name: "DRAINAGE PLUG (L)",
    description: "Container Spare Part",
    information: null,
    detail: null,
    uom: "Each",
    principals: [],
    brands: [],
    types: [
      "Container Spare Part"
    ]
  },
  {
    sku: "AG0704",
    slug: "ag0704-drainage-plug-s",
    thumbnail: "/file/listings/AG0704.png",
    name: "DRAINAGE PLUG (s)",
    description: "Container Spare Part",
    information: null,
    detail: null,
    uom: "Each",
    principals: [],
    brands: [],
    types: [
      "Container Spare Part"
    ]
  },
  {
    sku: "AG0705",
    slug: "ag0705-split-bush-s",
    thumbnail: "/file/listings/AG0705.png",
    name: "SPLIT BUSH (S)",
    description: "Container Spare Part",
    information: null,
    detail: null,
    uom: "Each",
    principals: [],
    brands: [],
    types: [
      "Container Spare Part"
    ]
  },
  {
    sku: "AG0706",
    slug: "ag0706-split-bush-l",
    thumbnail: "/file/listings/AG0706.png",
    name: "SPLIT BUSH (L)",
    description: "Container Spare Part",
    information: null,
    detail: null,
    uom: "Each",
    principals: [],
    brands: [],
    types: [
      "Container Spare Part"
    ]
  },
  {
    sku: "AG0707",
    slug: "ag0707-open-top-eyelet",
    thumbnail: "/file/listings/AG0707.png",
    name: "OPEN TOP EYELET",
    description: "Container Spare Part",
    information: null,
    detail: null,
    uom: "Each",
    principals: [],
    brands: [],
    types: [
      "Container Spare Part"
    ]
  },
  {
    sku: "AG0708",
    slug: "ag0708-open-top-tarpualin-20",
    thumbnail: "/file/listings/AG0708.png",
    name: "OPEN TOP TARPUALIN 20'",
    description: "Container Spare Part",
    information: null,
    detail: null,
    uom: "Each",
    principals: [],
    brands: [],
    types: [
      "Container Spare Part"
    ]
  },
  {
    sku: "AG0709",
    slug: "ag0709-open-top-tarpualin-40",
    thumbnail: "/file/listings/AG0709.png",
    name: "OPEN TOP TARPUALIN 40'",
    description: "Container Spare Part",
    information: null,
    detail: null,
    uom: "Each",
    principals: [],
    brands: [],
    types: [
      "Container Spare Part"
    ]
  },
  {
    sku: "AG0710",
    slug: "ag0710-u-type-gasket-25mtrs",
    thumbnail: "/file/listings/AG0710.png",
    name: "U TYPE GASKET (2.5MTRS)",
    description: "Container Spare Part",
    information: null,
    detail: null,
    uom: "Each",
    principals: [],
    brands: [],
    types: [
      "Container Spare Part"
    ]
  },
  {
    sku: "AG0711",
    slug: "ag0711-door-hinge-mearsk",
    thumbnail: "/file/listings/AG0711.png",
    name: "DOOR HINGE (MEARSK)",
    description: "Container Spare Part",
    information: null,
    detail: null,
    uom: "Each",
    principals: [],
    brands: [],
    types: [
      "Container Spare Part"
    ]
  },
  {
    sku: "AG0712",
    slug: "ag0712-door-retainer-plate-forged-type",
    thumbnail: "/file/listings/AG0712.png",
    name: "DOOR RETAINER PLATE FORGED TYPE",
    description: "Container Spare Part",
    information: null,
    detail: null,
    uom: "Each",
    principals: [],
    brands: [],
    types: [
      "Container Spare Part"
    ]
  },
  {
    sku: "AG0713",
    slug: "ag0713-3m-double-sided-tape-2",
    thumbnail: "/file/listings/AG0713.png",
    name: "3M DOUBLE SIDED TAPE 2\"",
    description: "Container Spare Part",
    information: null,
    detail: null,
    uom: "Each",
    principals: [],
    brands: [],
    types: [
      "Container Spare Part"
    ]
  },
  {
    sku: "AG0714",
    slug: "ag0714-csc-alphanum-stamp",
    thumbnail: "/file/listings/AG0714.png",
    name: "CSC ALPHA/NUM STAMP",
    description: "Container Spare Part",
    information: null,
    detail: null,
    uom: "Each",
    principals: [],
    brands: [],
    types: [
      "Container Spare Part"
    ]
  },
  {
    sku: "AG0298",
    slug: "ag0298-c-type-gasket-25mtrs",
    thumbnail: "/file/listings/AG0298.png",
    name: "C TYPE GASKET (2.5MTRS)",
    description: "Container Spare Part",
    information: null,
    detail: null,
    uom: "Each",
    principals: [],
    brands: [],
    types: [
      "Container Spare Part"
    ]
  },
  {
    sku: "AG0670",
    slug: "ag0670-gooseneck-rail-60x40x146x2400",
    thumbnail: "/file/listings/AG0670.png",
    name: "GOOSENECK RAIL (鹅颈槽直梁) - 6.0x40x146x2400",
    description: "Container Spare Part",
    information: null,
    detail: null,
    uom: "Each",
    principals: [],
    brands: [],
    types: [
      "Container Spare Part"
    ]
  }
]

export const brands = [
  {
    name: "Alfa Romeo",
    slug: "alfa-romeo",
    logo: "/file/brands/Alfa-Romeo-logo.png",
  },
  {
    name: "Aston Martin",
    slug: "aston-martin",
    logo: "/file/brands/Aston-Martin-logo.png",
  },
  {
    name: "Audi",
    slug: "audi",
    logo: "/file/brands/Audi-logo.png",
    featured: true,
  },
  {
    name: "Austin",
    slug: "austin",
    logo: "/file/brands/Austin-logo.png",
  },
  {
    name: "Bentley",
    slug: "bentley",
    logo: "/file/brands/Bentley-logo.png",
  },
  {
    name: "BMW",
    slug: "bmw",
    logo: "/file/brands/BMW-logo.png",
    featured: true,
  },
  {
    name: "BYD",
    slug: "byd",
    logo: "/file/brands/BYD-logo.png",
  },
  {
    name: "Chevrolet",
    slug: "chevrolet",
    logo: "/file/brands/Chevrolet-logo.png",
  },
  {
    name: "Chrysler",
    slug: "chrysler",
    logo: "/file/brands/chrysler-logo.png",
  },
  {
    name: "Citroen",
    slug: "citroen",
    logo: "/file/brands/Citroen-logo.png",
  },
  {
    name: "Daihatsu",
    slug: "daihatsu",
    logo: "/file/brands/Daihatsu-logo.png",
  },
  {
    name: "Ferrari",
    slug: "ferrari",
    logo: "/file/brands/Ferrari-logo.png",
  },
  {
    name: "Fiat",
    slug: "fiat",
    logo: "/file/brands/Fiat-logo.png",
  },
  {
    name: "Ford",
    slug: "ford",
    logo: "/file/brands/Ford-logo.png",
  },
  {
    name: "Hino",
    slug: "hino",
    logo: "/file/brands/Hino-logo.png",
  },
  {
    name: "Honda",
    slug: "honda",
    logo: "/file/brands/Honda-logo.png",
    featured: true,
  },
  {
    name: "Hyundai",
    slug: "hyundai",
    logo: "/file/brands/Hyundai-logo.png",
    featured: true,
  },
  {
    name: "Infiniti",
    slug: "infiniti",
    logo: "/file/brands/Infiniti-logo.png",
  },
  {
    name: "Isuzu",
    slug: "isuzu",
    logo: "/file/brands/Isuzu-logo.png",
  },
  {
    name: "Jaguar",
    slug: "jaguar",
    logo: "/file/brands/Jaguar-logo.png",
  },
  {
    name: "Jeep",
    slug: "jeep",
    logo: "/file/brands/jeep-logo.png",
  },
  {
    name: "KIA",
    slug: "kia",
    logo: "/file/brands/Kia-logo.png",
    featured: true,
  },
  {
    name: "Lamborghini",
    slug: "lamborghini",
    logo: "/file/brands/Lamborghini-logo.png",
  },
  {
    name: "Land Rover",
    slug: "land-rover",
    logo: "/file/brands/LandRover-logo.png",
  },
  {
    name: "Lexus",
    slug: "lexus",
    logo: "/file/brands/Lexus-logo.png",
    featured: true,
  },
  {
    name: "MAN",
    slug: "man",
    logo: "/file/brands/MAN-logo.png",
  },
  {
    name: "Maserati",
    slug: "maserati",
    logo: "/file/brands/Maserati-logo.png",
  },
  {
    name: "Mazda",
    slug: "mazda",
    logo: "/file/brands/Mazda-logo.png",
    featured: true,
  },
  {
    name: "Mercedes-Benz",
    slug: "mercedes-benz",
    logo: "/file/brands/Mercedes-Benz-logo.png",
    featured: true,
  },
  {
    name: "MG",
    slug: "mg",
    logo: "/file/brands/MG-logo.png",
  },
  {
    name: "MINI",
    slug: "mini",
    logo: "/file/brands/Mini-logo.png",
  },
  {
    name: "Mitsubishi",
    slug: "mitsubishi",
    logo: "/file/brands/Mitsubishi-logo.png",
  },
  {
    name: "Nissan",
    slug: "nissan",
    logo: "/file/brands/Nissan-logo.png",
  },
  {
    name: "Opel",
    slug: "opel",
    logo: "/file/brands/Opel-logo.png",
  },
  {
    name: "Perodua",
    slug: "perodua",
    logo: "/file/brands/Perodua-logo.png",
  },
  {
    name: "Peugeot",
    slug: "peugeot",
    logo: "/file/brands/Peugeot-logo.png",
  },
  {
    name: "Porsche",
    slug: "porsche",
    logo: "/file/brands/Porsche-logo.png",
  },
  {
    name: "Proton",
    slug: "proton",
    logo: "/file/brands/Proton-logo.png",
  },
  {
    name: "Renault",
    slug: "renault",
    logo: "/file/brands/Renault-logo.png",
  },
  {
    name: "Rolls-Royce",
    slug: "rolls-royce",
    logo: "/file/brands/Rolls-Royce-logo.png",
  },
  {
    name: "Rover",
    slug: "rover",
    logo: "/file/brands/Rover-logo.png",
  },
  {
    name: "Saab",
    slug: "saab",
    logo: "/file/brands/Saab.png",
  },
  {
    name: "Scania",
    slug: "scania",
    logo: "/file/brands/Scania.png",
  },
  {
    name: "SEAT",
    slug: "seat",
    logo: "/file/brands/SEAT.png",
  },
  {
    name: "Skoda",
    slug: "skoda",
    logo: "/file/brands/Skoda.png",
  },
  {
    name: "Ssangyong",
    slug: "ssangyong",
    logo: "/file/brands/Ssangyong.png",
  },
  {
    name: "Subaru",
    slug: "subaru",
    logo: "/file/brands/Subaru-logo.png",
  },
  {
    name: "Suzuki",
    slug: "suzuki",
    logo: "/file/brands/Suzuki-logo.png",
  },
  {
    name: "Tesla",
    slug: "tesla",
    logo: "/file/brands/Tesla-logo.png",
  },
  {
    name: "Toyota",
    slug: "toyota",
    logo: "/file/brands/Toyota-logo.png",
    featured: true,
  },
  {
    name: "UD",
    slug: "ud",
    logo: "/file/brands/UD.png",
  },
  {
    name: "Volkswagen",
    slug: "volkswagen",
    logo: "/file/brands/Volkswagen.png",
  },
  {
    name: "Volvo",
    slug: "volvo",
    logo: "/file/brands/Volvo.png",
  },
  {
    name: "Yutong",
    slug: "yutong",
    logo: "/file/brands/Yutong.png",
  },
]

const principals = [
  {
    name: "Bosch",
    slug: "bosch",
    description: "Bosch has been a leader in battery production since 1922, continuously innovating to meet the growing demand for high-quality and reliable battery power in the automotive industry. With today's cars being fitted with more and more components that run on electricity, having a high-quality and reliable battery power is ever more essential.",
    brief: "Reliable automotive batteries for superior performance, including Start-Stop and Alternator Management Systems.",
    logo: "/file/principals/bosch.png",
    website: "https://www.bosch.com.sg/",
    featured: true,
  },
  // {
  //   name: "Liberty",
  //   slug: "liberty",
  //   description: "Liberty Worldwide is a fast-growing multinational corporation with a product portfolio of high-performance Automobile, Industrial & Marine lubricants and greases of world-class quality.",
  //   brief: "Lubricants and greases worldwide through quality, innovation, and global partnerships. Preferred choice for businesses seeking exceptional lubrication solutions",
  //   logo: "/file/principals/liberty.png",
  //   website: "http://www.libertylubricants.com/",
  //   featured: true,
  // },
  {
    name: "Palma",
    slug: "palma",
    description: "Palma Battery Co., Ltd. offers high-quality battery solutions for automotive, industrial, and consumer electronics applications, delivering exceptional performance and reliability. Visit our website to explore our comprehensive product lineup and find the perfect battery solution for your needs.",
    brief: "Offers high-quality battery solutions for automotive, industrial, and consumer electronics applications, delivering exceptional performance and reliability.",
    logo: "/file/principals/palma.jpg",
    website: "https://www.palma-battery.net/",
    featured: true,
  }
]

type Product = {
  sku: string
  slug: string
  name: string
  thumbnail: any
  description: string
  detail: string
  information: string
  uom: string
  principals: string[]
  brands: string[]
  types: string[]
}

type Brand = {
  name: string
  slug: string
  logo: any
  featured?: boolean
}

type Principal = {
  name: string
  slug: string
  description: string
  brief: string
  logo: any
  website: string
  featured?: boolean
}

export type ListingConfig = {
  public_url: string
}

export class ListingService {

  private config: ListingConfig
  private products: Product[] = []
  private brands: Brand[] = []
  private principals: Principal[] = []

  constructor(config: ListingConfig) {
    this.config = config
    this.products = products.map((product) => {
      return {
        ...product,
        thumbnail: `${this.config.public_url}${product.thumbnail}`  
      }
    })
    this.brands = brands.map((brand) => {
      return {
        ...brand,
        logo: `${this.config.public_url}${brand.logo}`  
      }
    })
    this.principals = principals.map((principal) => {
      return {
        ...principal,
        logo: `${this.config.public_url}${principal.logo}`  
      }
    })
  }

  public async SearchProduct(p: SearchProductParam): Promise<SearchProductResult> {
    try {
      let products: Product[] = this.products
      const {
        page = 1,
        total_items,
        slugs = [],
        principals = [],
        brands = [],
        types = [],
        keyword,
      } = p

      if (slugs.length > 0) {
        const slugDict = new Set<string>()
        slugs.forEach((slug) => {
          slugDict.add(slug)
        })

        products = products.filter((product) => {
          return slugDict.has(product.slug)
        })
      }

      if (principals.length > 0) {
        const principalsDict = new Set<string>()
        principals.forEach((principal) => {
          principalsDict.add(principal)
        })

        products = products.filter((product) => {
          return product.principals.some((principal) => {
            return principalsDict.has(principal)
          })
        })
      }

      if (brands.length > 0) {
        const brandsDict = new Set<string>()
        brands.forEach((brand) => {
          brandsDict.add(brand)
        })

        products = products.filter((product) => {
          return product.brands.some((brand) => {
            return brandsDict.has(brand)
          })
        })
      }

      if (types.length > 0) {
        const typesDict = new Set<string>()
        types.forEach((type) => {
          typesDict.add(type)
        })

        products = products.filter((product) => {
          return product.types.some((type) => {
            return typesDict.has(type)
          })
        })
      }

      if (keyword) {
        const sanitizedKeyword = keyword.toLowerCase()
        products = products.filter((product) => {
          return (
            product.sku.toLowerCase().includes(sanitizedKeyword) ||
            product.name.toLowerCase().includes(sanitizedKeyword) ||
            product.principals.map((principal) => principal.toLowerCase()).includes(sanitizedKeyword) ||
            product.brands.map((brand) => brand.toLowerCase()).includes(sanitizedKeyword) ||
            product.types.map((type) => type.toLowerCase()).includes(sanitizedKeyword)
          )
        })
      }

      const totalItems = products.length

      if (total_items !== undefined) {
        const last = page * total_items
        const first = last - total_items

        products = products.slice(first, last)
      }

      return {
        success: new Status("success search products", ACTION_SUCCESS),
        data: {
          sumary: {
            page,
            total_items: totalItems
          },
          items: products,
        }
      }
    } catch (err: any) {
      return {
        error: new Status(err.message, UNEXPECTED_ERROR)
      }
    }
  }

  public async VerifyProduct(p: VerifyProductParam): Promise<VerifyProductResult> {
    try {
      const verifications: { exists: boolean }[] = []

      const searchProduct = await this.SearchProduct({
        slugs: p.slugs
      })
      if (searchProduct.error) {
        return {
          error: searchProduct.error
        }
      }

      const products = new Map<string, any>()
      searchProduct.data.items.forEach((product) => {
        products.set(product.slug, product)
      })

      p.slugs.forEach((slug) => {
        verifications.push({
          exists: products.has(slug)
        })
      })

      return {
        success: new Status("success verify products", ACTION_SUCCESS),
        data: {
          items: verifications,
        }
      }
    } catch (err: any) {
      return {
        error: new Status(err.message, UNEXPECTED_ERROR)
      }
    }
  }

  public async GetProduct(p: GetProductParam): Promise<GetProductResult> {
    try {
      const product = this.products.find(product => product.slug === p.slug)
      if (product === undefined) {
        return {
          error: new Status("product not found", RESOURCE_NOTFOUND)
        }
      }

      return {
        success: new Status("success get product", ACTION_SUCCESS),
        data: {
          ...product,
          products: []
        }
      }
    } catch (err: any) {
      return {
        error: new Status(err.message, UNEXPECTED_ERROR)
      }
    }
  }

  public async SearchBrand(p: SearchBrandParam): Promise<SearchBrandResult> {
    try {
      const {
        featured = false,
      } = p

      let brands: Brand[] = this.brands

      if (featured) {
        brands = brands.filter((brand) => {
          return brand.featured === true
        })
      }

      return {
        success: new Status("success search brand", ACTION_SUCCESS),
        data: {
          items: brands,
        }
      }
    } catch (err: any) {
      return {
        error: new Status(err.message, UNEXPECTED_ERROR)
      }
    }
  }

  public async SearchPrincipal(p: SearchPrincipalParam): Promise<SearchPrincipalResult> {
    try {
      const {
        featured = false,
      } = p

      let principals: Principal[] = this.principals
      if (featured) {
        principals = principals.filter((principal) => {
          return principal.featured === true
        })
      }

      return {
        success: new Status("success search principal", ACTION_SUCCESS),
        data: {
          items: principals,
        }
      }
    } catch (err: any) {
      return {
        error: new Status(err.message, UNEXPECTED_ERROR)
      }
    }
  }

}
