import { ListingService as LocalListingService } from "./static/Listing"
import { ListingService as ApiListingService } from "../services/api/Listing"
import { ContactService } from "../services/api/Contact"
import { CartService } from "../services/local/Cart"
import { ComparationService } from "../services/local/Comparation"

type CreateServiceParam = {
  dataSource?: string
  apiHost?: string
  publicUrl?: string
}

export class Service {

  public static createService(p?: CreateServiceParam) {
    const {
      dataSource,
      apiHost,
      publicUrl
    } = p || {
      dataSource: process.env.REACT_APP_DATA_SOURCE,
      apiHost: process.env.REACT_APP_API_HOST,
      publicUrl: process.env.REACT_APP_PUBLIC_URL
    }

    let listingService = undefined
    if (dataSource === "ipos") {
      listingService = new ApiListingService({
        host: apiHost,
        public_url: publicUrl
      })
    } else {
      listingService = new LocalListingService({
        public_url: publicUrl
      })
    }
    const cartService = new CartService(listingService)
    const comparationService = new ComparationService(listingService)

    const contactService = new ContactService({
      host: process.env.REACT_APP_API_HOST
    })

    return {
      listingService,
      cartService,
      comparationService,
      contactService
    }
  }

}
