import {
  SearchProductParam, SearchProductResult,
  VerifyProductParam, VerifyProductResult,
  GetProductParam, GetProductResult,
  SearchBrandParam, SearchBrandResult,
  SearchPrincipalParam, SearchPrincipalResult,
} from "../Listing"
import {
  Status, ACTION_SUCCESS, ACTION_FAILED,
  RESOURCE_NOTFOUND, UNEXPECTED_ERROR
} from "../Status"
import {
  Price
} from "../Commerce"

export const brands = [
  {
    name: "Alfa Romeo",
    slug: "alfa-romeo",
    logo: "/file/brands/Alfa-Romeo-logo.png",
  },
  {
    name: "Aston Martin",
    slug: "aston-martin",
    logo: "/file/brands/Aston-Martin-logo.png",
  },
  {
    name: "Audi",
    slug: "audi",
    logo: "/file/brands/Audi-logo.png",
    featured: true,
  },
  {
    name: "Austin",
    slug: "austin",
    logo: "/file/brands/Austin-logo.png",
  },
  {
    name: "Bentley",
    slug: "bentley",
    logo: "/file/brands/Bentley-logo.png",
  },
  {
    name: "BMW",
    slug: "bmw",
    logo: "/file/brands/BMW-logo.png",
    featured: true,
  },
  {
    name: "BYD",
    slug: "byd",
    logo: "/file/brands/BYD-logo.png",
  },
  {
    name: "Chevrolet",
    slug: "chevrolet",
    logo: "/file/brands/Chevrolet-logo.png",
  },
  {
    name: "Chrysler",
    slug: "chrysler",
    logo: "/file/brands/chrysler-logo.png",
  },
  {
    name: "Citroen",
    slug: "citroen",
    logo: "/file/brands/Citroen-logo.png",
  },
  {
    name: "Daihatsu",
    slug: "daihatsu",
    logo: "/file/brands/Daihatsu-logo.png",
  },
  {
    name: "Ferrari",
    slug: "ferrari",
    logo: "/file/brands/Ferrari-logo.png",
  },
  {
    name: "Fiat",
    slug: "fiat",
    logo: "/file/brands/Fiat-logo.png",
  },
  {
    name: "Ford",
    slug: "ford",
    logo: "/file/brands/Ford-logo.png",
  },
  {
    name: "Hino",
    slug: "hino",
    logo: "/file/brands/Hino-logo.png",
  },
  {
    name: "Honda",
    slug: "honda",
    logo: "/file/brands/Honda-logo.png",
    featured: true,
  },
  {
    name: "Hyundai",
    slug: "hyundai",
    logo: "/file/brands/Hyundai-logo.png",
    featured: true,
  },
  {
    name: "Infiniti",
    slug: "infiniti",
    logo: "/file/brands/Infiniti-logo.png",
  },
  {
    name: "Isuzu",
    slug: "isuzu",
    logo: "/file/brands/Isuzu-logo.png",
  },
  {
    name: "Jaguar",
    slug: "jaguar",
    logo: "/file/brands/Jaguar-logo.png",
  },
  {
    name: "Jeep",
    slug: "jeep",
    logo: "/file/brands/jeep-logo.png",
  },
  {
    name: "KIA",
    slug: "kia",
    logo: "/file/brands/Kia-logo.png",
    featured: true,
  },
  {
    name: "Lamborghini",
    slug: "lamborghini",
    logo: "/file/brands/Lamborghini-logo.png",
  },
  {
    name: "Land Rover",
    slug: "land-rover",
    logo: "/file/brands/LandRover-logo.png",
  },
  {
    name: "Lexus",
    slug: "lexus",
    logo: "/file/brands/Lexus-logo.png",
    featured: true,
  },
  {
    name: "MAN",
    slug: "man",
    logo: "/file/brands/MAN-logo.png",
  },
  {
    name: "Maserati",
    slug: "maserati",
    logo: "/file/brands/Maserati-logo.png",
  },
  {
    name: "Mazda",
    slug: "mazda",
    logo: "/file/brands/Mazda-logo.png",
    featured: true,
  },
  {
    name: "Mercedes-Benz",
    slug: "mercedes-benz",
    logo: "/file/brands/Mercedes-Benz-logo.png",
    featured: true,
  },
  {
    name: "MG",
    slug: "mg",
    logo: "/file/brands/MG-logo.png",
  },
  {
    name: "MINI",
    slug: "mini",
    logo: "/file/brands/Mini-logo.png",
  },
  {
    name: "Mitsubishi",
    slug: "mitsubishi",
    logo: "/file/brands/Mitsubishi-logo.png",
  },
  {
    name: "Nissan",
    slug: "nissan",
    logo: "/file/brands/Nissan-logo.png",
  },
  {
    name: "Opel",
    slug: "opel",
    logo: "/file/brands/Opel-logo.png",
  },
  {
    name: "Perodua",
    slug: "perodua",
    logo: "/file/brands/Perodua-logo.png",
  },
  {
    name: "Peugeot",
    slug: "peugeot",
    logo: "/file/brands/Peugeot-logo.png",
  },
  {
    name: "Porsche",
    slug: "porsche",
    logo: "/file/brands/Porsche-logo.png",
  },
  {
    name: "Proton",
    slug: "proton",
    logo: "/file/brands/Proton-logo.png",
  },
  {
    name: "Renault",
    slug: "renault",
    logo: "/file/brands/Renault-logo.png",
  },
  {
    name: "Rolls-Royce",
    slug: "rolls-royce",
    logo: "/file/brands/Rolls-Royce-logo.png",
  },
  {
    name: "Rover",
    slug: "rover",
    logo: "/file/brands/Rover-logo.png",
  },
  {
    name: "Saab",
    slug: "saab",
    logo: "/file/brands/Saab.png",
  },
  {
    name: "Scania",
    slug: "scania",
    logo: "/file/brands/Scania.png",
  },
  {
    name: "SEAT",
    slug: "seat",
    logo: "/file/brands/SEAT.png",
  },
  {
    name: "Skoda",
    slug: "skoda",
    logo: "/file/brands/Skoda.png",
  },
  {
    name: "Ssangyong",
    slug: "ssangyong",
    logo: "/file/brands/Ssangyong.png",
  },
  {
    name: "Subaru",
    slug: "subaru",
    logo: "/file/brands/Subaru-logo.png",
  },
  {
    name: "Suzuki",
    slug: "suzuki",
    logo: "/file/brands/Suzuki-logo.png",
  },
  {
    name: "Tesla",
    slug: "tesla",
    logo: "/file/brands/Tesla-logo.png",
  },
  {
    name: "Toyota",
    slug: "toyota",
    logo: "/file/brands/Toyota-logo.png",
    featured: true,
  },
  {
    name: "UD",
    slug: "ud",
    logo: "/file/brands/UD.png",
  },
  {
    name: "Volkswagen",
    slug: "volkswagen",
    logo: "/file/brands/Volkswagen.png",
  },
  {
    name: "Volvo",
    slug: "volvo",
    logo: "/file/brands/Volvo.png",
  },
  {
    name: "Yutong",
    slug: "yutong",
    logo: "/file/brands/Yutong.png",
  },
]

const principals = [
  {
    name: "Bosch",
    slug: "bosch",
    description: "Bosch has been a leader in battery production since 1922, continuously innovating to meet the growing demand for high-quality and reliable battery power in the automotive industry. With today's cars being fitted with more and more components that run on electricity, having a high-quality and reliable battery power is ever more essential.",
    brief: "Reliable automotive batteries for superior performance, including Start-Stop and Alternator Management Systems.",
    logo: "/file/principals/bosch.png",
    website: "https://www.bosch.com.sg/",
    featured: true,
  },
  // {
  //   name: "Liberty",
  //   slug: "liberty",
  //   description: "Liberty Worldwide is a fast-growing multinational corporation with a product portfolio of high-performance Automobile, Industrial & Marine lubricants and greases of world-class quality.",
  //   brief: "Lubricants and greases worldwide through quality, innovation, and global partnerships. Preferred choice for businesses seeking exceptional lubrication solutions",
  //   logo: "/file/principals/liberty.png",
  //   website: "http://www.libertylubricants.com/",
  //   featured: true,
  // },
  {
    name: "Palma",
    slug: "palma",
    description: "Palma Battery Co., Ltd. offers high-quality battery solutions for automotive, industrial, and consumer electronics applications, delivering exceptional performance and reliability. Visit our website to explore our comprehensive product lineup and find the perfect battery solution for your needs.",
    brief: "Offers high-quality battery solutions for automotive, industrial, and consumer electronics applications, delivering exceptional performance and reliability.",
    logo: "/file/principals/palma.jpg",
    website: "https://www.palma-battery.net/",
    featured: true,
  }
]

const DEFAULT_THUMBNAIL = "/image/placeholder-400x400.jpg"
const BOSCH_THUMBNAIL = "/file/listings/bosch-placeholder.png"
const PALMA_THUMBNAIL = "/file/listings/palma-placeholder.png"
const LIBERTY_THUMBNAIL = "/file/listings/liberty-placeholder.png"

type ListingSource = {
  slug: string
  name: string
  thumbnails: ThumbnailSource[]
  brief?: string
  description?: string
  brands: string[]
  tags: string[]
  categories: string[]
  products: ProductSource[]
}

type ThumbnailSource = {
  url: string
  is_primary: boolean
}

type ProductSource = {
  sku?: string
  uom_name?: string
  quantity?: number
  price?: Price
}

type Listing = {
  slug: string
  name: string
  thumbnail: any
  brief?: string
  description?: string
  principals: string[]
  brands: string[]
  types: string[]
  products: Product[]
}

type Product = {
  sku: string
  uomName: string
  quanamey: number
  price?: Price
}

type Brand = {
  name: string
  slug: string
  logo: any
  featured?: boolean
}

type Principal = {
  name: string
  slug: string
  description: string
  brief: string
  logo: any
  website: string
  featured?: boolean
}

type ListingConfig = {
  host: string
  public_url: string
}

export class ListingService {
  private config: ListingConfig
  private brands: Brand[] = []
  private principals: Principal[] = []

  constructor(config: ListingConfig) {
    this.config = config
    this.brands = brands
    this.principals = principals
  }

  // @todo: rename to `SearchListing` soon
  public async SearchProduct(p: SearchProductParam): Promise<SearchProductResult> {
    try {
      const {
        page = 1,
        total_items = 12,
        slugs = [],
        principals = [],
        brands = [],
        types = [],
        keyword,
      } = p

      let requestBody = new FormData()
      if (keyword) {
        requestBody.append("keyword", keyword)
      }

      const url = `${this.config.host}/script/search-listing.php`
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        redirect: "follow",
        body: requestBody
      })
      if (!response.ok) {
        return {
          error: new Status("failed search listing", ACTION_FAILED),
        }
      }

      const responseBody = await response.json()
      const isBodyValid = responseBody && responseBody.code && responseBody.data
      if (!isBodyValid) {
        return {
          error: new Status("failed decode listing", ACTION_FAILED),
        }
      }

      let listings: Listing[] = responseBody.data.items.map((listing: ListingSource) => {
        let thumbnail = `${this.config.public_url}${getDefaultThumbnail(listing.brands.map((item) => item.toLowerCase()))}`
        if (Array.isArray(listing.thumbnails) && listing.thumbnails.length > 0) {
          const primaryThumbnails = listing.thumbnails.filter((thumbnail) => thumbnail.is_primary)
          if (primaryThumbnails.length > 0) {
            thumbnail = primaryThumbnails[0].url
          }
        }

        return {
          ...listing,
          thumbnail,

          principals: listing.brands ? listing.brands.map((item) => item.toLowerCase()) : [],
          brands: listing.tags ? listing.tags.map((item) => item.toLowerCase()) : [],
          types: listing.categories ? listing.categories.map((item) => item.toLowerCase()) : [],

          products: listing.products ? listing.products.map((product) => {
            return {
              sku: product.sku || undefined,
              uomName: product.uom_name || undefined,
              quantity: product.quantity || undefined,
            }
          }) : []
        }
      })

      if (slugs.length > 0) {
        const slugDict = new Set<string>()
        slugs.forEach((slug) => {
          slugDict.add(slug)
        })

        listings = listings.filter((listing) => {
          return slugDict.has(listing.slug)
        })
      }

      if (principals.length > 0) {
        const principalsDict = new Set<string>()
        principals.forEach((principal) => {
          principalsDict.add(principal.toLowerCase())
        })

        listings = listings.filter((listing) => {
          return listing.principals.some((principal) => {
            return principalsDict.has(principal)
          })
        })
      }

      if (brands.length > 0) {
        const brandsDict = new Set<string>()
        brands.forEach((brand) => {
          brandsDict.add(brand.toLowerCase())
        })

        listings = listings.filter((listing) => {
          return listing.brands.some((brand) => {
            return brandsDict.has(brand)
          })
        })
      }

      if (types.length > 0) {
        const typesDict = new Set<string>()
        types.forEach((type) => {
          typesDict.add(type.toLowerCase())
        })

        listings = listings.filter((listing) => {
          return listing.types.some((type) => {
            return typesDict.has(type)
          })
        })
      }

      const totalItems = listings.length
      const last = page * total_items
      const first = last - total_items

      listings = listings.slice(first, last)

      return {
        success: new Status("success search listing", ACTION_SUCCESS),
        data: {
          sumary: {
            page,
            total_items: totalItems
          },
          items: listings,
        }
      }
    } catch (err: any) {
      return {
        error: new Status(err.message, UNEXPECTED_ERROR)
      }
    }
  }

  // @todo: rename to `VerifyListing` soon
  public async VerifyProduct(p: VerifyProductParam): Promise<VerifyProductResult> {
    try {
      const verifications: { exists: boolean }[] = []

      const searchProduct = await this.SearchProduct({
        slugs: p.slugs
      })
      if (searchProduct.error) {
        return {
          error: searchProduct.error
        }
      }

      const products = new Map<string, any>()
      searchProduct.data.items.forEach((product) => {
        products.set(product.slug, product)
      })

      p.slugs.forEach((slug) => {
        verifications.push({
          exists: products.has(slug)
        })
      })

      return {
        success: new Status("success verify listings", ACTION_SUCCESS),
        data: {
          items: verifications,
        }
      }
    } catch (err: any) {
      return {
        error: new Status(err.message, UNEXPECTED_ERROR)
      }
    }
  }

  // @todo: rename to `GetListing` soon
  public async GetProduct(p: GetProductParam): Promise<GetProductResult> {
    try {
      const {
        slug
      } = p

      const url = `${this.config.host}/script/get-listing.php?slug=${slug}`
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        redirect: "follow"
      })
      if (!response.ok) {
        return {
          error: new Status("failed search listing", ACTION_FAILED),
        }
      }

      const body = await response.json()
      const isBodyValid = body && body.code && body.data
      if (!isBodyValid) {
        return {
          error: new Status("failed decode listing", ACTION_FAILED),
        }
      }

      const isNotFound = body.code === RESOURCE_NOTFOUND
      if (isNotFound) {
        return {
          error: new Status("listing not found", RESOURCE_NOTFOUND)
        }
      }

      const listing = body.data as ListingSource

      let thumbnail = `${this.config.public_url}${getDefaultThumbnail(listing.brands.map((item) => item.toLowerCase()))}`
      if (Array.isArray(listing.thumbnails) && listing.thumbnails.length > 0) {
        const primaryThumbnails = listing.thumbnails.filter((thumbnail) => thumbnail.is_primary)
        if (primaryThumbnails.length > 0) {
          thumbnail = primaryThumbnails[0].url
        }
      }

      return {
        success: new Status("success get listing", ACTION_SUCCESS),
        data: {
          slug: listing.slug,
          name: listing.name,
          brief: listing.brief || undefined,
          description: listing.description || undefined,
          principals: listing.brands ? listing.brands.map((item) => item.toLowerCase()) : [],
          brands: listing.tags ? listing.tags.map((item) => item.toLowerCase()) : [],
          types: listing.categories ? listing.categories.map((item) => item.toLowerCase()) : [],
          thumbnail,
          products: listing.products ? listing.products.map((product) => {
            return {
              sku: product.sku || undefined,
              uomName: product.uom_name || undefined,
              quantity: product.quantity || undefined,
              price: product.price || undefined,
            }
          }) : [],
        }
      }
    } catch (err: any) {
      return {
        error: new Status(err.message, UNEXPECTED_ERROR)
      }
    }
  }

  public async SearchBrand(p: SearchBrandParam): Promise<SearchBrandResult> {
    try {
      const {
        featured = false,
      } = p

      let brands: Brand[] = this.brands

      if (featured) {
        brands = brands.filter((brand) => {
          return brand.featured === true
        })
      }

      return {
        success: new Status("success search brand", ACTION_SUCCESS),
        data: {
          items: brands,
        }
      }
    } catch (err: any) {
      return {
        error: new Status(err.message, UNEXPECTED_ERROR)
      }
    }
  }

  public async SearchPrincipal(p: SearchPrincipalParam): Promise<SearchPrincipalResult> {
    try {
      const {
        featured = false,
      } = p

      let principals: Principal[] = this.principals
      if (featured) {
        principals = principals.filter((principal) => {
          return principal.featured === true
        })
      }

      return {
        success: new Status("success search principal", ACTION_SUCCESS),
        data: {
          items: principals,
        }
      }
    } catch (err: any) {
      return {
        error: new Status(err.message, UNEXPECTED_ERROR)
      }
    }
  }

}

function getDefaultThumbnail(items: string[]): string {
  const brands = new Set(items)

  let thumbnail = DEFAULT_THUMBNAIL
  if (brands.has("bosch")) {
    thumbnail = BOSCH_THUMBNAIL
  } else if (brands.has("palma")) {
    thumbnail = PALMA_THUMBNAIL
  } else if (brands.has("liberty")) {
    thumbnail = LIBERTY_THUMBNAIL
  }

  return thumbnail
}