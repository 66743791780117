import * as React from "react"
import { Container } from "@allied/react-web/Container"
import { Meta } from "@allied/react-web/Meta"
import { Link } from "@allied/react-web/Router"
import { useAutoPosition } from "@allied/react-web/hooks"
import { Service } from "../services/Service"

const { listingService } = Service.createService()

type PageLink = {
  name: string
  to: string
}

export default function Sitemap(): React.JSX.Element {
  const [listings, setListings] = React.useState<PageLink[]>([])
  const [pages] = React.useState<PageLink[]>([
    {
      name: "Brands",
      to: "/car-brands"
    },
    {
      name: "Containers",
      to: "/container-enquiry"
    },
    {
      name: "Contact Us",
      to: "/general-enquiry"
    },
    {
      name: "Products",
      to: "/products"
    },
    {
      name: "Principals",
      to: "/principals"
    },
    {
      name: "Privacy Policy",
      to: "/security-privacy-policy"
    },
  ])

  async function searchMeta() {
    const searchListing = await listingService.SearchProduct({})
    if (searchListing.error) {
      return
    }

    setListings(searchListing.data?.items.map((listing) => {
      return {
        name: `Listing: ${listing.name}`,
        to: `/products/${listing.slug}`,
      }
    }) || [])
  }

  const links = [
    ...pages,
    ...listings,
  ]

  React.useEffect(() => {
    searchMeta()
  }, [])

  useAutoPosition()

  return (
    <>
      <Meta>
        {{
          title: `Sitemap`,
          description: `Sitemap`
        }}
      </Meta>

      <Container size="md">
        <div className="flex flex-col gap-6 py-10 md:py-20">
          <h1 className="font-semibold text-3xl">
            Sitemap
          </h1>

          <ul className="list-disc pl-6">
            {
              links.map((link, i: number) => {
                return (
                  <React.Fragment key={`link-item-${i}`}>
                    <li>
                      <Link to={link.to}>
                        {link.name}
                      </Link>
                    </li>
                  </React.Fragment>
                )
              })
            }
          </ul>
        </div>
      </Container>
    </>
  )
}
