import * as React from "react"
import { Meta } from "@allied/react-web/Meta"
import { Info } from "./../components/Page"

export default function NotFound(): React.JSX.Element {
  return (
    <>
      <Meta>
        {{
          title: "404 Page not found"
        }}
      </Meta>

      <Info />
    </>
  )
}
